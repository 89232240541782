import { SubscriptionPlan } from './../models/subscription_plan.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { switchMap, tap } from 'rxjs/operators';
import { Pagination } from 'app/components/common/pagination/pagination.types';

@Injectable({
    providedIn: 'root',
})
export class SubscriptionPlanService {

    private _subscriptions: BehaviorSubject<SubscriptionPlan[] | null> =
        new BehaviorSubject(null);

    constructor(
        private _httpClient: HttpClient,
    ) { }

    /**
     * Getter for contactus
     */
    get subscriptions$(): Observable<SubscriptionPlan[]> {
        return this._subscriptions.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    getSubscriptionPlan(
    ): Observable<any> {
        return this._httpClient
            .get<any>(
                environment.apiBaseUrl + '/subscription/get-subscription-plan',
            )
            .pipe(
                tap((response) => {
                    this._subscriptions.next(response.data);
                })
            );
    }

    getSubscriptionPlanById(id
    ): Observable<any> {
        return this._httpClient
            .get<any>(
                environment.apiBaseUrl + '/subscription/get-subscription-plan/' + id,
            );
    }
}
