import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AmazonService {
    constructor(private _httpClient: HttpClient,) {
    }

    exchangeLWAAuthorizationCode(razorPayObj): Observable<any> {
        return this._httpClient.post(
            environment.apiBaseUrl + '/amazon-sp-api/exchange-lwa-authorization-code',
            razorPayObj
        );
    }

}
