import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PaymentService {
    constructor(private _httpClient: HttpClient,) {
    }

    razorPayOrder(razorPayObj): Observable<any> {
        return this._httpClient.post(
            environment.apiBaseUrl + '/payment/create-payment-order',
            razorPayObj
        );
    }

    razorPayPaymentSuccess(razorPayData): Observable<any> {
        return this._httpClient.put(
            environment.apiBaseUrl + '/payment/payment-success',
            razorPayData
        );
    }

    deleteOrCancelPayment(razorPayData): Observable<any> {
        return this._httpClient.delete(
            environment.apiBaseUrl + '/payment/payment-delete',
            razorPayData
        );
    }

    fetchPaymentIntent(paymentId, customerEmail): Observable<any> {
        return this._httpClient.get(
            environment.apiBaseUrl + '/payment/fetch-payment/' + paymentId,
            {
                params: {
                    email: customerEmail
                },
            }
        ).pipe(
            switchMap((response: any) => {
                if (response.status === 200) {
                    return of(response);
                } else {
                    return throwError(response);
                }
            })
        );
    }
}
