import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminUserService } from 'app/services/admin/user.service';
import { LocalService } from 'app/services/local.service';
import { CustomerService } from 'app/services/customer.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isShow: boolean = false;
  constructor(
    private _loginService: CustomerService,
    private _adminUserService: AdminUserService,
    private _localService: LocalService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this._loginService.isLogin.subscribe((res) => {
      if (res) {
        this.isShow = true;
      }
      this._adminUserService.isLogin.subscribe((resAd) => {
        if (resAd) {
          this.isShow = true;
        }
      });
    });
  }

  logOut(): void {
    this._localService.clearValues();
    this._router.navigate(['/sign-in']);
    this._loginService.isLogin.next(false);
    this.isShow = false;
  }

}
