<!-- Header Area -->
<header class="header-area">
        <div class="top-header">
                <div class="container">
                        <div class="row align-items-center">
                                <div class="col-lg-6 col-sm-6">
                                        <ul class="left-info">
                                                <li><a href="mailto:hello@lemonmode.com"><i class='bx bxs-envelope'></i>
                                                                hello@lemonmode.com</a></li>
                                                <li><a href="tel:+823-456-879"><i class='bx bxs-phone-call'></i> +0123
                                                                456 789</a></li>
                                        </ul>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                        <ul class="right-info">
                                                <!-- <li class="mr-20"><a routerLink="/contact">Contact</a></li> -->
                                                <!-- <li class="mr-20"><a routerLink="/">Career</a></li>
                                                <li class="mr-20"><a routerLink="/">News & Media</a></li> -->
                                                <li><a href="https://www.facebook.com/" target="_blank"><i
                                                                        class='bx bxl-facebook'></i></a></li>
                                                <li><a href="https://twitter.com/" target="_blank"><i
                                                                        class='bx bxl-twitter'></i></a></li>
                                                <li><a href="https://in.linkedin.com/" target="_blank"><i
                                                                        class='bx bxl-linkedin'></i></a></li>
                                                <li><a href="https://www.instagram.com/" target="_blank"><i
                                                                        class='bx bxl-instagram'></i></a>
                                                </li>
                                        </ul>
                                </div>
                        </div>
                </div>
        </div>

        <div class="navbar-area">
                <div class="lemonmode-responsive-nav">
                        <div class="container">
                                <div class="lemonmode-responsive-menu">
                                        <!-- <div class="logo">
                                                <a routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
                                        </div> -->
                                </div>
                        </div>
                </div>

                <div class="lemonmode-nav">
                        <div class="container">
                                <nav class="navbar navbar-expand-md navbar-light">
                                        <a class="navbar-brand" routerLink="/"><img src="assets/img/lemonmode_logo.png"
                                                        alt="logo"></a>

                                        <!-- <div class="collapse navbar-collapse mean-menu"> -->
                                        <div class="navbar-collapse mean-menu">
                                                <ul class="navbar-nav ms-auto">
                                                        <li class="nav-item">
                                                                <a routerLink="/" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Home</a>

                                                                <!-- <ul class="dropdown-menu">
                                                                        <li class="nav-item"><a routerLink="/home-first"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                                        Demo - 1</a></li>

                                                                        <li class="nav-item"><a routerLink="/home-two"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                                        Demo - 2</a></li>

                                                                        <li class="nav-item"><a routerLink="/home-three"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                                        Demo - 3</a></li>
                                                                </ul> -->
                                                        </li>
                                                        <li class="nav-item"><a routerLink="/track-order"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Track
                                                                        Order</a>
                                                        </li>
                                                        <!-- <li class="nav-item"><a routerLink="/dashboard" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Dashboard</a>
                                                        </li> -->


                                                        <li class="nav-item"><a routerLink="/about" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">About
                                                                        Us</a></li>

                                                        <!-- <li class="nav-item">
                                                                <a href="javascript:void(0)" class="nav-link">Pages <i
                                                                                class='bx bx-chevron-down'></i></a>

                                                                <ul class="dropdown-menu">
                                                                        <li class="nav-item"><a routerLink="/team"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Team</a>
                                                                        </li>

                                                                        <li class="nav-item"><a
                                                                                        routerLink="/testimonials"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Testimonials</a>
                                                                        </li>

                                                                        <li class="nav-item"><a routerLink="/gallery"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Gallery</a>
                                                                        </li>

                                                                        <li class="nav-item"><a routerLink="/faq"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">FAQ</a>
                                                                        </li>

                                                                        <li class="nav-item">
                                                                                <a href="javascript:void(0)"
                                                                                        class="nav-link">User <i
                                                                                                class='bx bx-chevron-right'></i></a>

                                                                                <ul class="dropdown-menu">
                                                                                        <li class="nav-item"><a
                                                                                                        routerLink="/sign-in"
                                                                                                        class="nav-link"
                                                                                                        routerLinkActive="active"
                                                                                                        [routerLinkActiveOptions]="{exact: true}">Sign
                                                                                                        In</a></li>

                                                                                        <li class="nav-item"><a
                                                                                                        routerLink="/sign-up"
                                                                                                        class="nav-link"
                                                                                                        routerLinkActive="active"
                                                                                                        [routerLinkActiveOptions]="{exact: true}">Sign
                                                                                                        Up</a></li>
                                                                                </ul>
                                                                        </li>

                                                                        <li class="nav-item"><a
                                                                                        routerLink="/privacy-policy"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Privacy
                                                                                        Policy</a></li>

                                                                        <li class="nav-item"><a
                                                                                        routerLink="/terms-conditions"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Terms
                                                                                        &
                                                                                        Conditions</a></li>

                                                                        <li class="nav-item"><a
                                                                                        routerLink="/coming-soon"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Coming
                                                                                        Soon</a></li>

                                                                        <li class="nav-item"><a routerLink="/error"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">404
                                                                                        Error Page</a></li>
                                                                </ul>
                                                        </li> -->

                                                        <li class="nav-item">
                                                                <a href="javascript:void(0)" class="nav-link">Services
                                                                        <i class='bx bx-chevron-down'></i></a>

                                                                <ul class="dropdown-menu">
                                                                        <li class="nav-item"><a routerLink="/services"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">
                                                                                        All
                                                                                        Services</a></li>

                                                                        <!-- <li class="nav-item"><a routerLink="/services-details" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Single
                                            Services</a></li> -->

                                                                        <li class="nav-item"><a
                                                                                        routerLink="/air-fright-services-details"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Air
                                                                                        fright</a></li>
                                                                        <li class="nav-item"><a
                                                                                        routerLink="/ocean-fright-service-details"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">ocean
                                                                                        fright</a></li>

                                                                        <li class="nav-item"><a
                                                                                        routerLink="/road-fright-service-details"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">land
                                                                                        transport</a></li>

                                                                        <li class="nav-item"><a
                                                                                        routerLink="/cargo-storage-service-details"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Cargo
                                                                                        storage</a></li>

                                                                        <li class="nav-item"><a
                                                                                        routerLink="/warehousing-service-details"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">warehousing</a>
                                                                        </li>
                                                                        <li class="nav-item"><a
                                                                                        routerLink="/ecommerce-service-details"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">
                                                                                        E-commerce solution</a></li>
                                                                        <li class="nav-item"><a
                                                                                        routerLink="/express-service-details"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Express
                                                                                        Services</a></li>
                                                                        <li class="nav-item"><a
                                                                                        routerLink="/domesticTrucking-service-details"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Domestic
                                                                                        trucking</a></li>
                                                                </ul>
                                                        </li>

                                                        <!-- <li class="nav-item">
                                                                <a href="javascript:void(0)" class="nav-link">Blog <i
                                                                                class='bx bx-chevron-down'></i></a>

                                                                <ul class="dropdown-menu">
                                                                        <li class="nav-item"><a routerLink="/blog"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Blog</a>
                                                                        </li>

                                                                        <li class="nav-item"><a
                                                                                        routerLink="/blog-details"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Single
                                                                                        Blog</a></li>
                                                                </ul>
                                                        </li> -->

                                                        <li class="nav-item">
                                                                <a href="javascript:void(0)"
                                                                        class="nav-link">Information
                                                                        <i class='bx bx-chevron-down'></i></a>

                                                                <ul class="dropdown-menu">
                                                                        <li class="nav-item"><a
                                                                                        routerLink="/prohibitedItems"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">
                                                                                        Prohibited Items</a></li>

                                                                        <!-- <li class="nav-item"><a routerLink="/services-details" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Single
                                            Services</a></li> -->

                                                                        <li class="nav-item"><a
                                                                                        routerLink="/volumetric-weight-calculator"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Volumetric
                                                                                        Weight Calculator</a></li>
                                                                        <li class="nav-item"><a
                                                                                        routerLink="/rate-transit-times"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Rate
                                                                                        and Transit Times</a></li>

                                                                        <li class="nav-item"><a
                                                                                        routerLink="/fuel-surcharge"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Fuel
                                                                                        Surcharge</a></li>

                                                                        <li class="nav-item"><a
                                                                                        routerLink="/regulations"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Regulations</a>
                                                                        </li>

                                                                        <li class="nav-item"><a
                                                                                        routerLink="/duties-taxes"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Custom
                                                                                        Duties and Taxes</a>
                                                                        </li>

                                                                </ul>
                                                        </li>

                                                        <li class="nav-item"><a routerLink="/price" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Prices</a>
                                                        </li>

                                                        <li class="nav-item"><a routerLink="/contact" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Contact</a>
                                                        </li>
                                                        <!-- <li class="nav-item"><a routerLink="/customer/order-file"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Order
                                                                        File</a> </li> -->

                                                        <ng-container *ngIf="!isShow">
                                                                <li class="nav-item"><a routerLink="/sign-in"
                                                                                class="nav-link"
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{exact: true}">Sign
                                                                                In</a>
                                                                </li>
                                                                <!-- <li class="nav-item"><a routerLink="/sign-up"
                                                                                class="nav-link"
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{exact: true}">Sign
                                                                                Up</a>
                                                                </li> -->
                                                        </ng-container>
                                                        <ng-container *ngIf="isShow">
                                                                <li class="nav-item"><a routerLink="customer/dashboard"
                                                                                class="nav-link"
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{exact: true}">
                                                                                Dashboard</a>
                                                                </li>
                                                                <li class="nav-item">
                                                                        <a href="javascript:void(0)" class="nav-link">
                                                                                <i class='bx bx-user-circle'></i>
                                                                                <!-- <img class="rounded-full" style="width: 20px; height:20px; display: inline;" *ngIf="selectedImage"
                                                                            [src]="imagePath + selectedImage" alt="User" /> -->
                                                                        </a>
                                                                        <ul class="dropdown-menu">
                                                                                <li class="nav-item"><a
                                                                                                routerLink="/profile"
                                                                                                class="nav-link "
                                                                                                routerLinkActive="active"
                                                                                                [routerLinkActiveOptions]="{exact: true}">
                                                                                                MyAccount</a>
                                                                                </li>
                                                                                <li class="nav-item"><a class="nav-link"
                                                                                                routerLinkActive="active"
                                                                                                (click)="logOut()"
                                                                                                [routerLinkActiveOptions]="{exact: true}">Logout</a>
                                                                                </li>
                                                                        </ul>
                                                                </li>
                                                        </ng-container>
                                                        <li class="nav-item"><a routerLink="/quotation" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Get A
                                                                        Quote</a>
                                                        </li>
                                                </ul>

                                        </div>
                                </nav>
                        </div>
                </div>
        </div>
</header>
<!-- End Header Area -->