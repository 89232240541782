import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-price-details',
  templateUrl: './price-details.component.html',
  styleUrls: ['./price-details.component.scss']
})
export class PriceDetailsComponent implements OnInit {
  navOne: number = 1;
  navSecond: boolean = true;

  zone1 = [
    {
      country: 'Bahrain',
      exchange_rate_to_usd: 4.4,
      exchange_rate_to_local_currency: 3.3,
      currency_code: 'BHD',
    },
    {
      country: 'Bangladesh',
      exchange_rate_to_usd: 14.5,
      exchange_rate_to_local_currency: 6.5,
      currency_code: 'USD',
    },
    {
      country: 'Egypt',
      exchange_rate_to_usd: 12,
      exchange_rate_to_local_currency: 9,
      currency_code: 'USD',
    },
    {
      country: 'Ghana',
      exchange_rate_to_usd: 15,
      exchange_rate_to_local_currency: 11.5,
      currency_code: 'USD',
    },
    {
      country: 'Hong Kong',
      exchange_rate_to_usd: 156,
      exchange_rate_to_local_currency: 63,
      currency_code: 'HKD',
    },
    {
      country: 'India',
      exchange_rate_to_usd: 10,
      exchange_rate_to_local_currency: 8,
      currency_code: 'USD',
    },
    {
      country: 'Iraq',
      exchange_rate_to_usd: 21,
      exchange_rate_to_local_currency: 16,
      currency_code: 'USD',
    },
    {
      country: 'Jordan',
      exchange_rate_to_usd: 8.4,
      exchange_rate_to_local_currency: 6.8,
      currency_code: 'JOD',
    },
    {
      country: 'Kenya',
      exchange_rate_to_usd: 14,
      exchange_rate_to_local_currency: 10,
      currency_code: 'USD',
    },
    {
      country: 'Kuwait',
      exchange_rate_to_usd: 3.25,
      exchange_rate_to_local_currency: 2.75,
      currency_code: 'KWD',
    },
    {
      country: 'Lebanon',
      exchange_rate_to_usd: 11,
      exchange_rate_to_local_currency: 8.6,
      currency_code: 'USD',
    },
    {
      country: 'Libya',
      exchange_rate_to_usd: 28,
      exchange_rate_to_local_currency: 13,
      currency_code: 'LYD',
    },
    {
      country: 'Oman',
      exchange_rate_to_usd: 5.5,
      exchange_rate_to_local_currency: 3.7,
      currency_code: 'OMR',
    },
    {
      country: 'Saudi Arabia',
      exchange_rate_to_usd: 68,
      exchange_rate_to_local_currency: 37,
      currency_code: 'SAR',
    },
    {
      country: 'Singapore',
      exchange_rate_to_usd: 12.6,
      exchange_rate_to_local_currency: 8.9,
      currency_code: 'SGD',
    },
    {
      country: 'South Africa',
      exchange_rate_to_usd: 175,
      exchange_rate_to_local_currency: 99,
      currency_code: 'ZAR',
    },
    {
      country: 'Sri Lanka',
      exchange_rate_to_usd: 13.5,
      exchange_rate_to_local_currency: 7.5,
      currency_code: 'USD',
    },
    {
      country: 'Switzerland',
      exchange_rate_to_usd: 20,
      exchange_rate_to_local_currency: 8.8,
      currency_code: 'CHF',
    },
    {
      country: 'Tanzania',
      exchange_rate_to_usd: 15.34,
      exchange_rate_to_local_currency: 11.8,
      currency_code: 'USD',
    },
    {
      country: 'Turkey',
      exchange_rate_to_usd: 25,
      exchange_rate_to_local_currency: 15,
      currency_code: 'USD',
    },
    {
      country: 'Uganda',
      exchange_rate_to_usd: 19,
      exchange_rate_to_local_currency: 14,
      currency_code: 'USD',
    },
    {
      country: 'UAE',
      exchange_rate_to_usd: 43,
      exchange_rate_to_local_currency: 34,
      currency_code: 'AED',
    },
    {
      country: 'Cyprus',
      exchange_rate_to_usd: 13,
      exchange_rate_to_local_currency: 7,
      currency_code: 'EUR',
    },
  ].sort((a, b) => {
    // Convert country names to lowercase for case-insensitive comparison
    const countryA = a.country.toLowerCase();
    const countryB = b.country.toLowerCase();

    // Compare country names
    if (countryA > countryB) {
      return -1; // Country A comes before Country B
    } else if (countryA < countryB) {
      return 1; // Country A comes after Country B
    } else {
      return 0; // Countries are equal
    }
  });

  zone2 = [
    {
      country: 'Bahrain',
      exchange_rate_to_usd: 3.5,
      exchange_rate_to_local_currency: 2.0,
      currency_code: 'BHD',
    },
    {
      country: 'Bangladesh',
      exchange_rate_to_usd: 10.88,
      exchange_rate_to_local_currency: 4.88,
      currency_code: 'USD',
    },
    {
      country: 'Egypt',
      exchange_rate_to_usd: 9.0,
      exchange_rate_to_local_currency: 5.85,
      currency_code: 'USD',
    },
    {
      country: 'Ghana',
      exchange_rate_to_usd: 11.25,
      exchange_rate_to_local_currency: 8.63,
      currency_code: 'USD',
    },
    {
      country: 'Hong Kong',
      exchange_rate_to_usd: 117.08,
      exchange_rate_to_local_currency: 47.33,
      currency_code: 'HKD',
    },
    {
      country: 'India',
      exchange_rate_to_usd: 7.5,
      exchange_rate_to_local_currency: 6.0,
      currency_code: 'USD',
    },
    {
      country: 'Iraq',
      exchange_rate_to_usd: 15.75,
      exchange_rate_to_local_currency: 10.4,
      currency_code: 'USD',
    },
    {
      country: 'Jordan',
      exchange_rate_to_usd: 6.5,
      exchange_rate_to_local_currency: 4.5,
      currency_code: 'JOD',
    },
    {
      country: 'Kenya',
      exchange_rate_to_usd: 10.5,
      exchange_rate_to_local_currency: 7.5,
      currency_code: 'USD',
    },
    {
      country: 'Kuwait',
      exchange_rate_to_usd: 2.5,
      exchange_rate_to_local_currency: 1.5,
      currency_code: 'KWD',
    },
    {
      country: 'Lebanon',
      exchange_rate_to_usd: 8.25,
      exchange_rate_to_local_currency: 5.59,
      currency_code: 'USD',
    },
    {
      country: 'Libya',
      exchange_rate_to_usd: 21.0,
      exchange_rate_to_local_currency: 9.0,
      currency_code: 'LYD',
    },
    {
      country: 'Oman',
      exchange_rate_to_usd: 3.5,
      exchange_rate_to_local_currency: 2.0,
      currency_code: 'OMR',
    },
    {
      country: 'Saudi Arabia',
      exchange_rate_to_usd: 41.0,
      exchange_rate_to_local_currency: 21.0,
      currency_code: 'SAR',
    },
    {
      country: 'Singapore',
      exchange_rate_to_usd: 12.6,
      exchange_rate_to_local_currency: 8.9,
      currency_code: 'SGD',
    },
    {
      country: 'South Africa',
      exchange_rate_to_usd: 131.31,
      exchange_rate_to_local_currency: 74.3,
      currency_code: 'ZAR',
    },
    {
      country: 'Sri Lanka',
      exchange_rate_to_usd: 10.13,
      exchange_rate_to_local_currency: 5.63,
      currency_code: 'USD',
    },
    {
      country: 'Switzerland',
      exchange_rate_to_usd: 25.0,
      exchange_rate_to_local_currency: 8.8,
      currency_code: 'CHF',
    },
    {
      country: 'Tanzania',
      exchange_rate_to_usd: 15.34,
      exchange_rate_to_local_currency: 11.8,
      currency_code: 'USD',
    },
    {
      country: 'Turkey',
      exchange_rate_to_usd: 18.75,
      exchange_rate_to_local_currency: 9.75,
      currency_code: 'USD',
    },
    {
      country: 'Uganda',
      exchange_rate_to_usd: 14.25,
      exchange_rate_to_local_currency: 10.5,
      currency_code: 'USD',
    },
    {
      country: 'UAE',
      exchange_rate_to_usd: 33.0,
      exchange_rate_to_local_currency: 23.0,
      currency_code: 'AED',
    },
    {
      country: 'Cyprus',
      exchange_rate_to_usd: 13.0,
      exchange_rate_to_local_currency: 7.0,
      currency_code: 'EUR',
    },
  ].sort((a, b) => {
    // Convert country names to lowercase for case-insensitive comparison
    const countryA = a.country.toLowerCase();
    const countryB = b.country.toLowerCase();

    // Compare country names
    if (countryA > countryB) {
      return -1; // Country A comes before Country B
    } else if (countryA < countryB) {
      return 1; // Country A comes after Country B
    } else {
      return 0; // Countries are equal
    }
  });

  zone3 = [
    {
      country: '',
      exchange_rate_to_usd: 'Up to 5kg',
      exchange_rate_to_local_currency: 'Every additional 1 kg after 5kg',
      currency_code: '',
    },
    {
      country: 'All over UAE City Limits',
      exchange_rate_to_usd: 9,
      exchange_rate_to_local_currency: 1,
      currency_code: 'AED',
    },
    {
      country: 'Remote Area – UAE (ODA)',
      exchange_rate_to_usd: 15,
      exchange_rate_to_local_currency: 1,
      currency_code: 'AED',
    },
    {
      country: 'All over KSA City Limits',
      exchange_rate_to_usd: 25,
      exchange_rate_to_local_currency: 10,
      currency_code: 'AED',
    },
    {
      country: 'Remote Area - KSA (ODA)',
      exchange_rate_to_usd: 30,
      exchange_rate_to_local_currency: 10,
      currency_code: 'AED',
    },
  ];

  tableDomesticHeaderList = [
    {
      column_number: 1,
      column_name: 'Destination',
      class_names: ['destination'],
    },
    {
      column_number: 2,
      column_name: '',
      class_names: ['additional-kg', 'text-md-center'],
    },
    {
      column_number: 3,
      column_name: 'Weight Slab',
      class_names: ['first-kg',],
    },
    // {
    //   column_number: 2,
    //   column_name: 'Up to 5kg',
    //   class_names: ['first-kg', 'text-md-center'],
    // },
    {
      column_number: 4,
      column_name: 'Currency',
      class_names: ['text-md-center', 'currency'],
    },
  ];

  tableHeaderList = [
    {
      column_number: 1,
      column_name: 'Destination',
      class_names: ['destination'],
    },
    {
      column_number: 2,
      column_name: 'First 0.5 KG',
      class_names: ['first-kg', 'text-md-center'],
    },
    {
      column_number: 3,
      column_name: 'Additional 0.5 KG',
      class_names: ['additional-kg', 'text-md-center'],
    },
    {
      column_number: 4,
      column_name: 'Currency',
      class_names: ['text-md-center', 'currency'],
    },
  ];
  tableHeaderListSecondOne = [
    {
      column_number: 1,
      column_name: 'First 0.5 KG',
      class_names: ['first-kg', 'text-md-center'],
    },
    {
      column_number: 2,
      column_name: 'Additional 0.5 KG',
      class_names: ['additional-kg', 'text-md-center'],
    },
    {
      column_number: 3,
      column_name: 'Currency',
      class_names: ['text-md-center', 'currency'],
    },
  ];
  headerStyles = {
    backgroundColor: '#83AB17',
    color: 'whitesmoke',
    fontSize: '20px',
  };

  constructor() { }
  ngOnInit(): void {

  }

  toggleNavOne(data1): void {
    this.navOne = data1;
  }

  toggleNavSecond(data2): void {
    this.navSecond = data2;
  }

}
