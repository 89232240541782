<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Sign In</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Sign In</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Start Sign in Area -->
<div class="sign-in-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Sign in to Your Account!</h2>
        </div>

        <div class="sign-in-form">
            <form [formGroup]="loginForm">
                <div class="form-group">
                    <input type="email" class="form-control" id="email" placeholder="Email" formControlName="email">
                    <div *ngIf="loginForm.controls['email'].invalid &&
                                                        (loginForm.controls['email'].dirty ||
                                                            loginForm.controls['email'].touched || isSubmit)
                                                    " style="color: red;">
                        <span *ngIf="loginForm.controls['email'].errors.required">
                            Please enter your email address!
                        </span>
                        <span *ngIf="loginForm.controls['email'].errors.email">
                            Please enter a valid email address!
                        </span>
                    </div>
                </div>

                <div class="form-group">
                    <div style="display: flex;  align-items: center; position: relative;">
                        <input type="password" class="form-control" formControlName="password" id="password"
                            placeholder="Password" #passwordField>
                        <button style="position: absolute;right: 0.5rem" type="button" (click)="
                                                                        passwordField.type === 'password'
                                                                            ? (passwordField.type = 'text')
                                                                            : (passwordField.type = 'password')
                                                                    " matSuffix>
                            <i class="far fa-eye" *ngIf="passwordField.type === 'password'"></i>
                            <i class="fa fa-eye-slash" *ngIf="passwordField.type === 'text'"></i>
                        </button>
                    </div>
                    <div *ngIf="loginForm.controls['password'].invalid &&
                                                        (loginForm.controls['password'].dirty ||
                                                            loginForm.controls['password'].touched || isSubmit)
                                                    " style="color: red;">
                        <span *ngIf="loginForm.controls['password'].errors.required">
                            Please enter your password!
                        </span>
                    </div>
                </div>
                <div class="text-center">
                    <p class="account-decs " style="text-align: right;"><a routerLink="/forgot-password">Forgot
                            password</a></p>
                    <button type="button" class="btn default-btn-one" (click)="login()">Sign In</button>
                    <p class="account-decs">Not a member? <a routerLink="/sign-up">Sign Up</a></p>

                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Sign in  Area -->