import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fuel-surcharge',
  templateUrl: './fuel-surcharge.component.html',
  styleUrls: ['./fuel-surcharge.component.scss']
})
export class FuelSurchargeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
