<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Land Transport</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Land Transport</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<div class="air-fright-area">
    <h1>Land Transport Services</h1>
    <p>Road transport means transportation of goods and personnel from one place to the other on roads. Road is a route
        between two destinations, which has been either paved or worked on to enable transportation by way of motorised
        and non-motorised carriages. There are many advantages of road transport in comparison to other means of
        transport. The investment required in road transport is very less compared to other modes of transport such as
        railways and air transport. The cost of construction, operating cost and maintaining roads is cheaper than that
        of the railways.</p>
    <p>Road transport can be classified as transporting either goods and materials or transporting people. The major
        advantage of road transport is that it can enable door-to-door delivery of goods and materials and can provide a
        very cost-effective means of cartage, loading and unloading. Sometimes road transport is the only way for
        carrying goods and people to and from rural areas which are not catered to by rail, water or air transport.
        Delivery of goods between cities, towns and small villages is made possible only through road transport.
        However, in spite of various merits, road transport has some major limitations. For instance, there are more
        chances of accidents and breakdowns in case of road transport. So, motor transport is not as safe as other means
        of transport. Road transport is also quite less organised in comparison with other modes. It is irregular and
        undependable. Rates for road transportation are also unstable and unequal, while the speed in road transport is
        slow and limited, which is a major drawback. Transporting bulky goods over long distances is also unsuitable and
        costly.</p>
    <p>In modern days, road transport has a serious negative impact on the environment. Building roads requires melting
        of tar or formulation of concrete, which may harm the associated environment. Since roads have been a major
        enabler of motorised transport, these vehicles also emit a lot of pollution in the form of Nitrogen dioxide,
        volatile organic compounds, carbon monoxide and various harmful air pollutants, including benzene, which have an
        adverse respiratory health effects and a serious threat to global warming. While improvisation of roads is a
        serious topic of research, road transport of the future includes aspects like solar panel roads and cars where
        solar cells have replaced asphalt or tar, and there are vehicles with electric motors reducing emission. Road
        transport of the future aims to work on these negativities and turn them around.</p>
</div>

<!-- Single Service Area -->
<!-- <div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-details-text">
                    <div class="service-image">
                        <img src="assets/img/services/single-services1.jpg" alt="image">
                    </div>
                    <h3>Easy Shipment Solution</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse</p>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                        totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae
                        dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
                        fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                    <p>NNeque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit,
                        sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
                        voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit
                        laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui
                        in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat
                        quo</p>
                    <div class="image">
                        <img src="assets/img/services/single-services2.jpg" alt="image">
                    </div>
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was
                        born and I will give you a complete account of the system, and expound the actual teachings of
                        the great explorer of the truth, the master-builder of human happiness. No one rejects,
                        dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know
                        how to pursue pleasure rationally encounter consequences that are extremely painful.</p>
                    <p>Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is
                        pain, but because occasionally circumstances occur in which toil and pain can procure him some
                        great pleasure. To take a trivial example, which of us ever undertakes laborious physical
                        exercise, except to obtain some advantage from it? But who has any right to find fault with a
                        man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain
                        that produces</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title">Our Services</h3>

                    <ul>
                        <li><a routerLink="/services-details"><i class='bx bxs-truck'></i> Road Freight</a></li>
                        <li><a routerLink="/services-details"><i class='bx bxs-ship'></i> Ocean Freight</a></li>
                        <li><a routerLink="/services-details"><i class='bx bxs-plane-take-off'></i> Air Freight</a></li>
                        <li><a routerLink="/services-details"><i class='bx bx-transfer'></i> B2B Exchange</a></li>
                        <li><a routerLink="/services-details"><i class='bx bx-home'></i> Home Shipping</a></li>
                        <li><a routerLink="/services-details"><i class='bx bx-line-chart-down'></i> Office Moving</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Single Service Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <!-- <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required=""
                            autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->
