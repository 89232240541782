import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-devloper-document',
	templateUrl: './devloper-document.html',
	styleUrls: ['./devloper-document.scss']
})
export class DevloperDocumentComponent implements OnInit {
	@ViewChild('test', { static: false }) codeElement: ElementRef;
	url = '';
	scroll(el: HTMLElement): any {
		el.scrollIntoView();
	}

	ngOnInit(): void {
		this.url = environment.domain_name;
	}


	copyData(el: HTMLElement): any {
		const codeContent = el.textContent;

		// Create a new temporary textarea element to hold the code content
		const textarea = document.createElement('textarea');
		textarea.value = codeContent;
		document.body.appendChild(textarea);

		// Copy the text from the temporary textarea to the clipboard
		textarea.select();
		document.execCommand('copy');

		// Remove the temporary textarea
		document.body.removeChild(textarea);
	}

}
