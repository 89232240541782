<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Prices</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Prices</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<div class="partner-section container">
    <div class="container">
        <h2 class="partner-heading d-flex justify-content-center align-items-center" style="padding-top: 30px;">
            Drop and Ship rates details
        </h2>
        <h6 class="partner-subheading text-secondary text-md-center container">
            At our store, shipping rates are determined by the destination country and
            are calculated based on the total weight of your order. Rates may vary
            depending on the country you're shipping to.
        </h6>
        <br>
        <h6 class="partner-subheading text-secondary text-md-center container">
            To streamline your Drop and Ship experience, we've categorized the origins
            of your import boxes into two zones as outlined below:
        </h6>
    </div>
    <br>
    <!-- button -->
    <div class="container py-3  ">
        <ul class="nav nav-pills partner-nav rounded" id="pills-tab" style="width: 100%; background-color: #e0e0e0;"
            role="tablist">
            <li class="nav-item  partner-nav-item country text-black" style="width: 33%;">
                <a class="nav-link text-black btn-hover partner-nav-link mx-1 btn btn-block"
                    [ngClass]="{'btn-bg' : navOne === 1}" (click)="toggleNavOne(1)" aria-controls="countryzone1"
                    aria-selected="true">
                    <span>1 Zone</span>
                </a>
            </li>
            <li class="nav-item partner-nav-item country text-black" style="width: 33%;">
                <a class="nav-link text-black btn-hover partner-nav-link mx-1 btn btn-block "
                    [ngClass]="{'btn-bg' : navOne === 2}" (click)="toggleNavOne(2)" aria-controls="countryzone2"
                    aria-selected="false">
                    <span>2 Zone</span>
                </a>
            </li>
            <li class="nav-item partner-nav-item country text-black" style="width: 34%;">
                <a class="nav-link text-black btn-hover partner-nav-link mx-1 btn btn-block "
                    [ngClass]="{'btn-bg' : navOne === 3}" (click)="toggleNavOne(3)" aria-controls="countryzone3"
                    aria-selected="false">
                    <span>Domestic</span>
                </a>
            </li>
        </ul>
        <br>
        <div class="tab-content" id="pills-tabContent">
            <div *ngIf="navOne === 1" id="zone1" role="tabpanel">
                <div class="zone-header">
                    <div class="zone-title">1 Zone</div>
                    <div class="zone-zones">
                        Canada, China, France, Germany, Hong Kong, Italy, Japan, Malaysia,
                        Singapore, South Africa, South Korea, Spain, Thailand, UK and USA.
                    </div>
                </div>
                <div class="scroll-wrapper" style="overflow: auto hidden">
                    <div class="scroller" style="padding-top: 1; width: 1026">&nbsp;</div>
                </div>
                <div class="zone-table-responsive table-responsive" id="zone-table-responsive-1">
                    <table class="table table-striped zone-table">
                        <thead>
                            <tr>
                                <th *ngFor="let header of tableHeaderList" scope="col"
                                    [ngClass]="header.class_names.join(' ')" [style]="headerStyles">
                                    {{ header.column_name }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let country of zone1">
                                <th scope="row">{{ country.country }}</th>
                                <td class="text-md-center">{{ country.exchange_rate_to_usd }}</td>
                                <td class="text-md-center">
                                    {{ country.exchange_rate_to_local_currency }}
                                </td>
                                <td class="text-md-center">{{ country.currency_code }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div *ngIf="navOne === 2" id="zone2" role="tabpanel">
                <div class="zone-header">
                    <div class="zone-title">2 Zone</div>
                    <div class="zone-zones">
                        Egypt, Georgia, India, Jordan, Lebanon, Turkey, and UAE
                    </div>
                </div>
                <div class="scroll-wrapper" style="overflow: auto hidden">
                    <div class="scroller" style="padding-top: 1; width: 1026">&nbsp;</div>
                </div>
                <div class="zone-table-responsive table-responsive" id="zone-table-responsive-2">
                    <table class="table table-striped zone-table">
                        <thead>
                            <tr>
                                <th *ngFor="let header of tableHeaderList" scope="col"
                                    [ngClass]="header.class_names.join(' ')" [style]="headerStyles" class="bg-header">
                                    {{ header.column_name }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let country of zone2">
                                <th scope="row">{{ country.country }}</th>
                                <td class="text-md-center">{{ country.exchange_rate_to_usd }}</td>
                                <td class="text-md-center">
                                    {{ country.exchange_rate_to_local_currency }}
                                </td>
                                <td class="text-md-center">{{ country.currency_code }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div *ngIf="navOne === 3" id="zone3" role="tabpanel">
                <div class="zone-header">
                    <div class="zone-title">Domestic</div>
                    <div class="zone-zones">
                        UAE and Saudi Arabia (KSA)
                    </div>
                </div>
                <div class="scroll-wrapper" style="overflow: auto hidden">
                    <div class="scroller" style="padding-top: 1; width: 1026">&nbsp;</div>
                </div>
                <div class="zone-table-responsive table-responsive" id="zone-table-responsive-2">
                    <table class="table table-striped zone-table">
                        <thead>
                            <tr>
                                <th *ngFor="let header of tableDomesticHeaderList" scope="col"
                                    [ngClass]="header.class_names.join(' ')" [style]="headerStyles" class="bg-header">
                                    {{ header.column_name }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let country of zone3; let i = index">
                                <th scope="row">{{ country.country }}</th>
                                <td class="text-md-center" [ngClass]="i===0 ? 'header-text' : ''">{{
                                    country.exchange_rate_to_usd }}</td>
                                <td class="text-md-center" [ngClass]="i===0 ? 'header-text' : ''">
                                    {{ country.exchange_rate_to_local_currency }}
                                </td>
                                <td class="text-md-center">{{ country.currency_code }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!--  -->
    <h4 class="partner-heading country d-flex justify-content-center align-items-center mt-3">Morocco</h4>
    <!-- button -->
    <div class="container py-3  ">
        <ul class="nav nav-pills partner-nav rounded" id="pills-tab"
            style="width: 100%; background-color: #e0e0e0; padding-top: 4px; padding-bottom: 4px;" role="tablist">
            <li class="nav-item  partner-nav-item country text-black" style="width: 50%;">
                <a class="nav-link text-black btn-hover partner-nav-link mx-1 btn btn-block"
                    [ngClass]="{'btn-bg' : navSecond}" (click)="toggleNavSecond(true)" aria-controls="countryzone1"
                    aria-selected="true">
                    <span>1 Zone</span>
                </a>
            </li>
            <li class="nav-item partner-nav-item country text-black" style="width: 50%;">
                <a class="nav-link text-black btn-hover partner-nav-link mx-1 btn btn-block "
                    [ngClass]="{'btn-bg' : !navSecond}" (click)="toggleNavSecond(false)" aria-controls="countryzone2"
                    aria-selected="false">
                    <span>2 Zone</span>
                </a>
            </li>
        </ul>
        <br>
        <!-- Zone 2 content -->
        <div class="tab-content" id="pills-tabContent">
            <div *ngIf="navSecond; else zone2Content" id="countryzone1" role="tabpanel">
                <div class="zone-header country-header">
                    <div class="zone-title">1 Zone</div>
                    <div class="zone-zones">
                        USA, UK, UAE, Turkey, China, India, France, Singapore, Canada
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-striped zone-table">
                        <thead>
                            <tr>
                                <th *ngFor="let header of tableHeaderListSecondOne" scope="col"
                                    [ngClass]="header.class_names.join(' ')" [style]="headerStyles">
                                    {{ header.column_name }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row" class="text-md-center">135.00</th>
                                <td class="text-md-center">75.00</td>
                                <td class="text-md-center">BHD</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <ng-template #zone2Content>
                <div id="countryzone2" role="tabpanel">
                    <div class="zone-header country-header">
                        <div class="zone-title">2 Zone</div>
                        <div class="zone-zones">Hong Kong, Germany, Italy, Spain</div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped zone-table">
                            <thead>
                                <tr>
                                    <th *ngFor="let header of tableHeaderListSecondOne" scope="col"
                                        [ngClass]="header.class_names.join(' ')" [style]="headerStyles">
                                        {{ header.column_name }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row" class="text-md-center">250.00</th>
                                    <td class="text-md-center">75.00</td>
                                    <td class="text-md-center">BHD</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>

    <div class="container">
        <h2 class="mt-5 mb-4 text-center">Drop and Ship Rates Details</h2>
        <div class="section">
            <h3>1. For Cyprus Destination:</h3>
            <p>Rates from all Drop and Ship Origins (USA, UK, China, Hong Kong, South Africa, Germany, Italy,
                France, Spain,
                Singapore, Canada, UAE, Turkey, and India) to Cyprus, will be Euros 13 for the first 0.5 kg and
                Euros 7 for
                each additional 0.5 kg.</p>
        </div>
        <div class="section">
            <h3>2. For Iraq Destination:</h3>
            <p>Any shipment to Iraq above 5 kilograms will be charged an additional USD 10 per each 0.5 kilogram.
            </p>
        </div>
        <div class="section">
            <h3>3. For Kazakhstan Destination:</h3>
            <p>For the following cities (Aktau, Aktobe, Astana, Atyrau, Karagandy, Kokshetau, Kostanay, Kyzylorda,
                Pavlodar,
                Petropavlovsk, Semei, Shymkent, Taldykorgan, Taraz, Ust-Kamenogorsk, Uralsk) to Kazakhstan, rates
                from all
                Drop and Ship origins (US, UK, China, Hong Kong, India, Turkey, UAE, South Africa, Germany, and
                Italy) will
                be KZT 3775 for the first 0.5 kg and then KZT 2535 for each additional 0.5 kg.</p>
            <p>Rates for Almaty <b>ONLY</b>, from all Drop and Ship origins (US, UK, China, Hong Kong, India,
                Turkey, UAE,
                South
                Africa, Germany, and Italy) will be KZT 2775 for the first 0.5 kg and then KZT 2053 for each
                additional 0.5
                kg.</p>
        </div>
        <div class="section">
            <h3>4. For Malaysia Destination:</h3>
            <p>Rates from Drop and Ship following origins (US, Singapore, India, Turkey, UAE, Hong Kong, and China)
                to
                Malaysia, will be MYR 45 for the first 0.5 kg and then MYR 15 for each additional 0.5 kg.</p>
            <p>Rates from other Drop and Ship following origins (Italy, Spain, Germany, France, Canada, UK, and
                South
                Africa) to Malaysia, will be MYR 55 for the first 0.5 kg and then MYR 25 for each additional 0.5 kg.
            </p>
            <p>- Domestic service fees when shopping within Malaysia are as below:</p>
            <ul>
                <li>For West Malaysia: Rates will be MYR 10 for the first 1 kg and then MYR 5 for each additional 1
                    kg.</li>
                <li>For East Malaysia: Rates will be MYR 15 for the first 1 kg and then MYR 15 for each additional 1
                    kg.
                </li>
            </ul>
        </div>
        <div class="section">
            <p>5. Rates above apply for <b>international shipping only</b>; when origin matches destination,
                domestic
                shipping
                charges
                will apply.</p>
        </div>
    </div>
</div>