<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Rate and Transit Times</h2>
                    <!-- <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Air fright</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="Rate-transit">
    <form method="post" action="">
        <h1>Rate and Transit Times</h1>

        <p>We help you choose the best deal for your package! Compare the shipping rates, transit times across our
            service affiliates. Simply enter the details and get all the information you need.</p>
        <div class="col-md-12 form-line">
            <div class="form-group">
                <div class="row">
                    <div class="col-md-4">
                        <label>Shipper Zip Code <span style="color:red">*</span></label>
                    </div>
                    <div class="col-md-8">
                        <input type="number" (wheel)="stopScrollingWheel($event)" class="form-control" name="shipper_zip" id="shipper_zip"
                            placeholder="Enter Shipper Zip" required="">
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-4 mt-3">
                        <label>Shipper Country <span style="color:red">*</span></label>
                    </div>
                    <div class="col-md-8 mt-3">
                        <select class="custom-select" name="shippper_country" id="shippper_country" required="">
                            <option value="United States">United States</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-4 mt-3">
                        <label>Package Type <span style="color:red">*</span></label>
                    </div>
                    <div class="col-md-8 mt-3">
                        <select class="custom-select" name="shipper_pkg" id="shipper_pkg" required="">
                            <option value="Document" selected="">Document</option>
                            <option value="Package">Package</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-group two-cols">
                <div class="row">
                    <div class="col-md-4 mt-3">
                        <label>Weight <span style="color:red">*</span></label>
                    </div>
                    <div class="col-md-8 mt-3 gap-2" style="    display: inherit;">
                        <input type="number" (wheel)="stopScrollingWheel($event)" name="weight" id="weight" class="form-control" placeholder="0" required="">
                        <select class="custom-select" name="weight_type" id="weight_type">
                            <option value="Kg">Kg</option>
                            <option value="Lbs" selected="">Lbs</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 form-line">
            <div class="form-group">
                <div class="row">
                    <div class="col-md-4 mt-3">
                        <label style="display:inline;">Receiver Zip Code <span style="color:red">*</span></label>
                    </div>
                    <div class="col-md-8 mt-3">
                        <input type="number" (wheel)="stopScrollingWheel($event)" name="receiver_zip" id="receiver_zip" class="form-control" required=""
                            placeholder="Enter Shipper Zip">
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-4 mt-3">
                        <label>Receiver Country <span style="color:red">*</span></label>
                    </div>
                    <div class="col-md-8 mt-3">
                        <select class="custom-select" name="receiver_country" id="receiver_country" required="">
                            <option value="">Select</option>
                            <option value="2">Afghanistan</option>
                            <option value="3">Albania</option>
                            <option value="4">Algeria</option>
                            <option value="5">American Samoa</option>
                            <option value="6">Andorra</option>
                            <option value="7">Angola</option>
                            <option value="8">Anguilla</option>
                            <option value="9">Antigua And Barbuda</option>
                            <option value="10">Argentina</option>
                            <option value="11">Armenia</option>
                            <option value="12">Aruba</option>
                            <option value="13">Australia</option>
                            <option value="14">Austria</option>
                            <option value="15">Azerbaijan</option>
                            <option value="16">Bahamas</option>
                            <option value="17">Bahrain</option>
                            <option value="18">Bangladesh</option>
                            <option value="19">Barbados</option>
                            <option value="20">Belarus</option>
                            <option value="21">Belgium</option>
                            <option value="22">Belize</option>
                            <option value="23">Benin</option>
                            <option value="24">Bermuda</option>
                            <option value="25">Bhutan</option>
                            <option value="26">Bolivia</option>
                            <option value="240">Bonaire</option>
                            <option value="27">Bosnia And Herzegovina</option>
                            <option value="28">Botswana</option>
                            <option value="29">Bouvet Island</option>
                            <option value="30">Brazil</option>
                            <option value="31">British Indian Ocean Territory</option>
                            <option value="239">Brunei</option>
                            <option value="32">Brunei Darussalam</option>
                            <option value="33">Bulgaria</option>
                            <option value="34">Burkina Faso</option>
                            <option value="35">Burundi</option>
                            <option value="36">Cambodia</option>
                            <option value="37">Cameroon</option>
                            <option value="38">Canada</option>
                            <option value="241">Canary Islands, The</option>
                            <option value="39">Cape Verde</option>
                            <option value="40">Cayman Islands</option>
                            <option value="41">Central African Republic</option>
                            <option value="42">Chad</option>
                            <option value="43">Chile</option>
                            <option value="44">China</option>
                            <option value="45">Christmas Island</option>
                            <option value="46">Cocos (Keeling) Islands</option>
                            <option value="47">Colombia</option>
                            <option value="48">Comoros</option>
                            <option value="49">Congo</option>
                            <option value="262">Congo, DPR</option>
                            <option value="50">Cook Islands</option>
                            <option value="51">Costa Rica</option>
                            <option value="242">Costa Rica</option>
                            <option value="52">Cote D'Ivoire</option>
                            <option value="53">Croatia</option>
                            <option value="54">Cuba</option>
                            <option value="243">Curacao</option>
                            <option value="55">Cyprus</option>
                            <option value="56">Czech Republic</option>
                            <option value="57">Czechoslovakia</option>
                            <option value="58">Denmark</option>
                            <option value="59">Djibouti</option>
                            <option value="60">Dominica</option>
                            <option value="61">Dominican Republic</option>
                            <option value="62">East Timor</option>
                            <option value="63">Ecuador</option>
                            <option value="64">Egypt</option>
                            <option value="65">El Salvador</option>
                            <option value="66">Equatorial Guinea</option>
                            <option value="67">Eritrea</option>
                            <option value="68">Estonia</option>
                            <option value="69">Ethiopia</option>
                            <option value="70">Falkland Islands (Malvinas)</option>
                            <option value="71">Faroe Islands</option>
                            <option value="72">Fiji</option>
                            <option value="73">Finland</option>
                            <option value="74">France</option>
                            <option value="75">French Guiana</option>
                            <option value="76">French Polynesia</option>
                            <option value="77">French Southern Territories</option>
                            <option value="78">Gabon</option>
                            <option value="79">Gambia</option>
                            <option value="80">Georgia</option>
                            <option value="81">Germany</option>
                            <option value="82">Ghana</option>
                            <option value="83">Gibraltar</option>
                            <option value="84">Greece</option>
                            <option value="85">Greenland</option>
                            <option value="86">Grenada</option>
                            <option value="87">Guadeloupe</option>
                            <option value="88">Guam</option>
                            <option value="89">Guatemala</option>
                            <option value="245">Guernsey</option>
                            <option value="90">Guinea</option>
                            <option value="91">Guinea-bissau</option>
                            <option value="244">Guinea-Equatorial</option>
                            <option value="92">Guyana</option>
                            <option value="93">Haiti</option>
                            <option value="94">Heard And Mcdonald Islands</option>
                            <option value="237">Holland</option>
                            <option value="95">Honduras</option>
                            <option value="96">Hong Kong</option>
                            <option value="97">Hungary</option>
                            <option value="98">Iceland</option>
                            <option value="1">India</option>
                            <option value="100">Indonesia</option>
                            <option value="101">Iraq</option>
                            <option value="102">Ireland</option>
                            <option value="103">Islamic Republic Of Iran</option>
                            <option value="104">Israel</option>
                            <option value="105">Italy</option>
                            <option value="106">Jamaica</option>
                            <option value="107">Japan</option>
                            <option value="246">Jersey</option>
                            <option value="108">Jordan</option>
                            <option value="109">Kazakhstan</option>
                            <option value="110">Kenya</option>
                            <option value="111">Kiribati</option>
                            <option value="248">Korea, Rep. Of</option>
                            <option value="247">Korea, &nbsp;D.P.R Of</option>
                            <option value="249">Kosovo</option>
                            <option value="113">Kuwait</option>
                            <option value="114">Kyrgyzstan</option>
                            <option value="115">Laos</option>
                            <option value="116">Latvia</option>
                            <option value="117">Lebanon</option>
                            <option value="118">Lesotho</option>
                            <option value="119">Liberia</option>
                            <option value="120">Libyan Arab Jamahiriya</option>
                            <option value="121">Liechtenstein</option>
                            <option value="122">Lithuania</option>
                            <option value="123">Luxembourg</option>
                            <option value="124">Macau</option>
                            <option value="125">Macedonia</option>
                            <option value="126">Madagascar</option>
                            <option value="127">Malawi</option>
                            <option value="128">Malaysia</option>
                            <option value="129">Maldives</option>
                            <option value="130">Mali</option>
                            <option value="131">Malta</option>
                            <option value="250">Mariana Islands</option>
                            <option value="132">Marshall Islands</option>
                            <option value="133">Martinique</option>
                            <option value="134">Mauritania</option>
                            <option value="135">Mauritius</option>
                            <option value="136">Mayotte</option>
                            <option value="137">Mexico</option>
                            <option value="138">Micronesia</option>
                            <option value="139">Moldova</option>
                            <option value="140">Monaco</option>
                            <option value="141">Mongolia</option>
                            <option value="142">Montenegro</option>
                            <option value="143">Montserrat</option>
                            <option value="144">Morocco</option>
                            <option value="145">Mozambique</option>
                            <option value="146">Myanmar</option>
                            <option value="147">Namibia</option>
                            <option value="148">Nauru</option>
                            <option value="149">Nepal</option>
                            <option value="150">Netherlands</option>
                            <option value="151">Netherlands Antilles</option>
                            <option value="251">Nevis</option>
                            <option value="152">New Caledonia</option>
                            <option value="153">New Zealand</option>
                            <option value="154">Nicaragua</option>
                            <option value="155">Niger</option>
                            <option value="156">Nigeria</option>
                            <option value="157">Niue</option>
                            <option value="158">Norfolk Island</option>
                            <option value="159">Northern Mariana Islands</option>
                            <option value="160">Norway</option>
                            <option value="161">Oman</option>
                            <option value="162">Pakistan</option>
                            <option value="163">Palau</option>
                            <option value="164">Panama</option>
                            <option value="165">Papua New Guinea</option>
                            <option value="166">Paraguay</option>
                            <option value="167">Peru</option>
                            <option value="168">Philippines</option>
                            <option value="169">Pitcairn</option>
                            <option value="170">Poland</option>
                            <option value="171">Portugal</option>
                            <option value="172">Puerto Rico</option>
                            <option value="173">Qatar</option>
                            <option value="174">Reunion</option>
                            <option value="175">Romania</option>
                            <option value="176">Russian Federation</option>
                            <option value="177">Rwanda</option>
                            <option value="238">Saba</option>
                            <option value="252">Saint Helena</option>
                            <option value="178">Saint Lucia</option>
                            <option value="179">Samoa</option>
                            <option value="180">San Marino</option>
                            <option value="181">Sao Tome And Principe</option>
                            <option value="182">Saudi Arabia</option>
                            <option value="183">Senegal</option>
                            <option value="236">Serbia</option>
                            <option value="184">Seychelles</option>
                            <option value="185">Sierra Leone</option>
                            <option value="186">Singapore</option>
                            <option value="187">Slovakia</option>
                            <option value="188">Slovenia</option>
                            <option value="189">Solomon Islands</option>
                            <option value="190">Somalia</option>
                            <option value="253">Somaliland, Rep Of</option>
                            <option value="191">South Africa</option>
                            <option value="112">South Korea</option>
                            <option value="254">South Sudan</option>
                            <option value="192">Spain</option>
                            <option value="193">Sri Lanka</option>
                            <option value="255">St. Barthelemy</option>
                            <option value="256">St. Eustatius</option>
                            <option value="194">St. Helena</option>
                            <option value="195">St. Kitts And Nevis</option>
                            <option value="257">St. Lucia</option>
                            <option value="258">St. Maarten</option>
                            <option value="196">St. Pierre And Miquelon</option>
                            <option value="259">St. Vincent</option>
                            <option value="197">Sudan</option>
                            <option value="198">Suriname</option>
                            <option value="199">Svalbard And Jan Mayen Islands</option>
                            <option value="200">Swaziland</option>
                            <option value="201">Sweden</option>
                            <option value="202">Switzerland</option>
                            <option value="203">Syrian Arab Republic</option>
                            <option value="260">Tahiti</option>
                            <option value="204">Taiwan</option>
                            <option value="205">Tajikistan</option>
                            <option value="206">Tanzania</option>
                            <option value="207">Thailand</option>
                            <option value="208">Togo</option>
                            <option value="209">Tokelau</option>
                            <option value="210">Tonga</option>
                            <option value="211">Trinidad And Tobago</option>
                            <option value="212">Tunisia</option>
                            <option value="213">Turkey</option>
                            <option value="214">Turkmenistan</option>
                            <option value="215">Turks And Caicos Islands</option>
                            <option value="216">Tuvalu</option>
                            <option value="217">Uganda</option>
                            <option value="218">Ukraine</option>
                            <option value="219">United Arab Emirates</option>
                            <option value="220">United Kingdom</option>
                            <option value="221">United States</option>
                            <option value="222">United States Minor Outlying Islands</option>
                            <option value="223">Uruguay</option>
                            <option value="224">Uzbekistan</option>
                            <option value="225">Vanuatu</option>
                            <option value="261">Vatican City</option>
                            <option value="226">Venezuela</option>
                            <option value="227">Vietnam</option>
                            <option value="228">Virgin Islands</option>
                            <option value="229">Virgin Islands</option>
                            <option value="230">Wallis And Futuna</option>
                            <option value="231">Western Sahara</option>
                            <option value="232">Yemen</option>
                            <option value="233">Yugoslavia</option>
                            <option value="234">Zambia</option>
                            <option value="235">Zimbabwe</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group cust-cols-1">
                <div class="row">
                    <div class="col-md-4 mt-3">
                        <label>Dimensions (In inch) <span style="color:red">*</span></label>
                    </div>
                    <div class="col-md-8 d-flex mt-3 gap-2">
                        <!-- <span class="field_label">Inch</span> -->
                        <input type="number" (wheel)="stopScrollingWheel($event)" name="length" id="length" class="form-control" placeholder="Length"
                            required="">
                        <input type="number" (wheel)="stopScrollingWheel($event)" class="form-control" name="width" id="width" placeholder="Width"
                            required="">
                        <input type="number" (wheel)="stopScrollingWheel($event)" name="height" id="height" class="form-control" placeholder="Heigth"
                            required="">
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12 text-center">
            <input name="getRate" type="submit" class="default-btn-two pr-4 pl-4 mt-4" value="Get Rates">
        </div>

    </form>
</div>


<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <!-- <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required=""
                            autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>
