<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Prohibited Items</h2>
                    <!-- <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Air fright</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Single Service Area -->
<div class="air-fright-area">
    <h1>Prohibited Items Services</h1>
    <p>The taking after commodities are not acknowledged by lemonmode beneath any circumstances. Usually a result of a
        arrangement choice, taking after the complete thought of Operational, Lawful and Chance Administration
        suggestions.
    </p>
    <ul>
        <li>ive creatures (counting but not constrained to well evolved creatures, reptiles, angle, spineless creatures,
            creatures of land and water, feathered creatures, creepy crawlies, hatchlings and pupae)</li>
        <li>Chasing (creature) trophies, creature parts such as ivory and sharks blade, creature remains or cinders,
            creature by-products </li>
        <li>Human remains or fiery debris</li>
        <li>Bullion (of any profitable metal)</li>
        <li>Cash (lawful delicate - bank notes, money notes, coins) and travelers cheques</li>
        <li>Free valuable and semi-precious stones (cut or uncut, cleaned or unpolished)</li>
        <li>Total unique and impersonation guns and gun parts, ammo, explosives/explosive gadge</li>
        <li>Unlawful merchandise, such as fake products and opiates</li>
    </ul>
    <p>Property, the carriage of which is precluded by any law, control or statute of any government, state or nearby
        government of any nation to or through which the shipment may be carried.</p>
    <p>In the event that you're in any question as to the adequacy of your merchandise, it would be ideal if you contact
        your lemonmode agent earlier to shipping.</p>
    <h2>As often as possible Inquired Questions</h2>

    <!-- FAQ -->
    <div class="about-content faqcl mt-3" style="display: inherit !important;">
        <ul class="faq">
            <li class="q" (click)="toggleAnswer(0,showAnswer[0])">
                <i class="fa fa-chevron-right" aria-hidden="true"></i>  What Are Disallowed Things?
            </li>

            <li class="a" [ngClass]="{'show': showAnswer[0]}" style="display: none;">
                <p>Certain things cannot be gotten, put away, dispatched, imported and/or sent out due to
                    administrative, risk, security or other reasons. Exchanges including these commodities are entirely
                    denied, in any case of root or goal.</p>

                <p><strong>Please note: </strong>Disallowed things are illegal to be sent out from the beginning nation
                    or domain or imported to the goal nation or region.</p>
            </li>

            <li class="q" (click)="toggleAnswer(1,showAnswer[1])"><i class="fa fa-chevron-right" aria-hidden="true"></i>  What are Limited Things?</li>

            <li class="a" [ngClass]="{'show': showAnswer[1]}" style="display: none;">
                <p>Certain things have administrative, risk, security or other contemplations and require endorsement
                    from Administrative Compliance, Chance Administration and Lawful offices earlier to dealing with.
                    Exchanges including these commodities are limited, in any case of beginning or goal.</p>

                <p><strong>Please note: </strong> Limited things may require uncommon grants or licenses earlier to
                    their deal, send out from or purport to your indicated nation or domain.</p>

            </li>
        </ul>
    </div>

    <h5 class="mt-3">To check the nation particular directions for disallowed things, select nation from the dropdown menu</h5>
    <div class="countryRow">
        <div class="row">
            <div class="col-md-6 country-lable">Select Country</div>
            <select class="col-md-6 select-option w-auto" [(ngModel)]="selectedCountry" (change)="onChangeCountry($event)">
                <option value="coun1">Select Country</option>
                <option value="coun2">Brazil</option>
                <option value="coun3">Canada</option>
                <!--<option value="coun4">European Union</option>-->
                <option value="coun4">India</option>
                <option value="coun5">Singapore</option>
                <option value="coun6">Saudi Arabia</option>
                <option value="coun7">Turkey</option>
                <option value="coun8">United Arab Emirates</option>
                <option value="coun9">United Kingdom</option>
                <option value="coun10">United States of America</option>
            </select>
        </div>

        <div id="content-coun1" class=" " *ngIf="selectedCountry === 'coun1'">
        </div>

        <div id="content-coun2" *ngIf="selectedCountry === 'coun2'">
            <hr class="border-2 mt-2">
            <h3>Brazil Consequence Denials</h3>
            <ul>
                <li>Alcoholic refreshments</li>
                <li>Creature items</li>
                <li>Creature skins</li>
                <li>Antique</li>
                <li>Artwork (fine)</li>
                <li>Beef derived from cattle administered growth stimulants</li>
                <li>Books: hardback/paperback non-comm</li>
                <li>Cash</li>
                <li>Checks, cancelled</li>
                <li>Chemicals, Haz and Non-Hazardous</li>
                <li>Color prints for the theatrical and television market</li>
                <li>Computer software</li>
                <li>Dangerous Goods as defined by IATA (Intl. Air Transport Association)</li>
                <li>Diplomatic mail</li>
                <li>Drugs, Prescription and Non-Prescription</li>
                <li>Foodstuffs</li>
                <li>Fresh poultry meat and poultry products coming from U.S.</li>
                <li>Grain samples</li>
                <li>Jewelry, costume</li>
                <li>Labels</li>
                <li>Log and timber items from Liberia (wood chips, posts, post, fence pickets, sheets, flooring,
                    molding, bed, device handles, barrels, cartons, tableware/kitchenware, screens, blinds,
                    cases/boxes/chests for adornments and flatware, dress holders, clothespins, toothpicks, etc.)
                </li>
                <li>Medical/dental supplies &amp; gear</li>
                <li>Military gear</li>
                <li>Cash orders</li>
                <li>Perishables</li>
                <li>Individual impacts</li>
                <li>Plants and Plant Items</li>
                <li>Delight water crafts esteemed over US$3,500</li>
                <li>Harms over 2 grams</li>
                <li>Porcelains</li>
                <li>Precious stones</li>
                <li>Seeds</li>
                <li>Ship spares</li>
                <li>Soil</li>
                <li>Stamps (collectible)</li>
                <li>Televisions, television equipment</li>
                <li>Tobacco</li>
                <li>Traveler's checks</li>
            </ul>

            <h3>Brazil Moment Limitations</h3>
            <ul>
                <li>N/A</li>
            </ul>

        </div>

        <div id="content-coun3" class=" " *ngIf="selectedCountry === 'coun3'">
            <hr class="border-2">
            <h3>Canada Purport Denials</h3>
            <ul>
                <li>Coin, base or fake</li>
                <li>Traditions duty criminal code importation of hostile weapons</li>
                <li>False description of geographical origin of goods and goods with trademarks - tariff item 9897.00.00
                </li>
                <li>Guns and weapons (Canadian Guns Center)</li>
                <li>Merchandise made or delivered entirely or in portion by jail work</li>
                <li>Importation of utilized or second-hand engine vehicles</li>
                <li>D9-1-15 policy for the administration of tariff item 9899.00.00 - hate propaganda, treason and
                    sedition</li>
                <li>Used or second-hand mattresses</li>
                <li>Personal shipments of alcohol and tobacco</li>
                <li>Hemp products such as cosmetics, clothing, food, etc. containing Tetrahydrocannabinols (THC)</li>
                <li>Alcohol beverages</li>
                <li>Ammunition of any kind</li>
                <li>All live animals, dead animals and animals that have been mounted</li>
                <li>Money, cash, coins, currency, paper money and negotiable instruments equivalent to cash, such as
                    endorsed stocks, bonds and cash letters</li>
                <li>Collectible coins and stamps</li>
                <li>Dangerous materials and dangerous squander, counting, but not constrained to, utilized hypodermic
                    needles, syringes or other restorative squander (shipments to Canada classified as "Other Controlled
                    Materials - Residential" (ORM-D) are permitted in case they contain shopper commodities as it were
                    and are appropriately labeled.)</li>
                <li>Human corpses, human organs or body parts, human and animal embryos, or cremated or disinterred
                    human remains</li>
                <li>Dangerous Goods</li>
                <li>Firearms, weaponry and their parts (and replicas thereof)</li>
                <li>Explosives, fireworks, flares, matches</li>
                <li>Furs</li>
                <li>Plants, plant materials and seeds, including cut flowers</li>
                <li>Perishables (counting, but not restricted to, perishable food/foodstuffs/beverages, perishable
                    pharmaceuticals, and any other things requiring refrigeration or other natural controls)</li>
                <li>Pornographic and/or obscene material</li>
                <li>Tobacco, cigarettes and tobacco products</li>
                <li>Unaccompanied things</li>
                <li>Lottery tickets and betting gadgets where disallowed by law</li>
                <li>Shipments which will cause harm to, or delay of, gear, staff or other shipments</li>
                <li>Shipments that require any extraordinary permit or allow for transportation, importation or
                    exportation</li>
                <li>Shipments whose carriage, importation or exportation is prohibited by any law, statute or regulation
                </li>
                <li>Packages that are wet, leaking or emit an odor of any kind</li>
                <li>Improperly packaged shipments</li>
                <li>Waste or garbage for disposal</li>
                <li>Live insects</li>
                <li>Electronic Cigarettes that contains nicotine, propylene glycol and other chemicals listed on the
                    Canadian Food and Drug controlled substance list</li>
                <li>Medical Samples</li>
                <li>Visa Applications</li>
            </ul>
            <h3>Shipments being processed under:</h3>
            <ol type="a">
                <li>Duty drawback claims unless advance arrangements are made</li>
                <li>Temporary Import Bonds</li>
                <li>U.S. State Department licenses</li>
                <li>Carnets</li>
                <li>U.S. Sedate Authorization Organization send out allow</li>
                <li>Letters of credit</li>
                <li>Certificate of Registration</li>
                <li>Shipments moving into or out of U.S. Foreign Trade Zones or bonded warehouses</li>
            </ol>

            <h3>Canada Purport Limitations</h3>

            <p>The importation of certain classes of stock may be disallowed or limited to secure the economy and
                security of Canada, to protect buyer wellbeing and well being, and to protect residential plant and
                creature life. Numerous disallowed or confined imports are subject, in expansion to Traditions
                prerequisites, to the laws and directions managed by other Canadian Government organizations that the
                Canada Border Administrations Organization upholds. These laws and directions may, for case, forbid
                passage; restrain section to certain ports; confine steering, capacity, utilization, require treatment,
                labeling or preparing as a condition of discharge. Traditions discharge as it were takes put when the
                extra prerequisites are met. These prerequisites apply to all importation sorts, counting shipments made
                by mail. The most precluded or limited articles are recorded underneath. Merchants into Canada ought to
                counsel with the Canadian organizations administering the product for nitty gritty data and direction.
            </p>


        </div>

        <div id="content-coun4" class=" " *ngIf="selectedCountry === 'coun4'">
            <hr class="border-2">
            <h3>India Moment Disallowances</h3>
            <ul>
                <li>Publicizing brochures/pamphlet</li>
                <li>Alcoholic refreshments</li>
                <li>Animal products</li>
                <li>Animal skins</li>
                <li>Australian Lupin Seeds</li>
                <li>Baby Gender Test Kits</li>
                <li>Bird's eggs, in shell, fresh, preserved or cooked</li>
                <li>Bunker oil sample (for analysis)</li>
                <li>Catalogs</li>
                <li>Cereals other than seed quality (through FCI)</li>
                <li>Certain creatures and plants and parts or items falling beneath CITES (Tradition on Worldwide
                    Exchange in Imperiled Species of Wild Greenery and Fauna)</li>
                <li>Chemicals, Haz and Non-Haz</li>
                <li>Circuits &amp; circuit sheets</li>
                <li>Coal &amp; firewood</li>
                <li>Coffee</li>
                <li>Computer software</li>
                <li>Cotton seeds</li>
                <li>Credit card blanks (NI)</li>
                <li>Credit cards (NI)</li>
                <li>Deeds</li>
                <li>Diplomatic mail</li>
                <li>Drugs, Prescription and Non-Prescription</li>
                <li>Fabrics &amp; fabric samples</li>
                <li>Films: 8mm, 16mm &amp; 35mm</li>
                <li>Films: entertainment</li>
                <li>Films: promotional, training</li>
                <li>Fire extinguishers</li>
                <li>Foodstuffs</li>
                <li>Pearls and adornments containing valuable and semi-precious metal/stones in all shapes is denied in
                    any case to the esteem and must be transported as Broker Select Choice (BSO) to Trade Situated Units
                    (EOU), Extraordinary Financial Zones (SEZ) or Santacruz Hardware Trade Preparing Zone (SEEPZ) as it
                    were</li>
                <li>Grain samples</li>
                <li>Guts, bladder and stomach of animals other than fish</li>
            </ul>
            <h3>India Import Restrictions</h3>
            <ul>
                <li>Indian Travel permits are precluded for carriage by anybody but the holder of the international id.
                    Non-Indian Identifications from UN Commission, Tall Commissions &amp; Departments are satisfactory.
                </li>
                <li>Industrial equipment</li>
                <li>Inorganic Chemicals</li>
                <li>Adornments (containing valuable metals/stones)</li>
                <li>Labels</li>
                <li>Leather goods</li>
                <li>Liquids, Haz and Non-Haz</li>
                <li>Live Creatures other than characterized beneath Wild Life Act 1972</li>
                <li>Live plants</li>
                <li>Maps</li>
                <li>Meat of Bovine Creatures</li>
                <li>Meat of Wild Creatures</li>
                <li>Medical samples</li>
                <li>Medical/dental supplies &amp; hardware</li>
                <li>Cash Orders</li>
                <li>Normal Abrasives - Emery, Normal</li>
                <li>Negatives, counting x-rays, movies</li>
                <li>Nutmeg, mace and cardamom</li>
                <li>Oil products</li>
                <li>Natural or Inorganic Compounds of
                    <ol type="I">
                        <li>Valuable Metals,</li>
                        <li>Uncommon Soil Metals,</li>
                        <li>Radioactive Components of Isotopes</li>
                    </ol>
                </li>
                <li>Detached Night Vision Goggles</li>
                <li>Passports</li>
                <li>Perishables</li>
                <li>Personal Baggage</li>
                <li>Personal effects</li>
                <li>Petroleum Oil</li>
                <li>Phones/modems</li>
                <li>Pig Fat, Fat of bovine animals, sheep or goat</li>
                <li>Plants and Plant Products</li>
                <li>Plants</li>
                <li>Potatoes, Garlic</li>
                <li>Price tickets for garments</li>
            </ul>
        </div>

        <div id="content-coun5" class=" " *ngIf="selectedCountry === 'coun5'">
            <hr class="border-2">
            <h3>Singapore Moment Disallowances</h3>
            <ul>
                <li>Alcoholic beverages</li>
                <li>Animal products</li>
                <li>Animal skins</li>
                <li>Books: hardback/paperback non-comm</li>
                <li>Bunker oil sample (for analysis)</li>
                <li>Chewing gum (oral dental and medicated gum accepted)</li>
                <li>Cigarette lighters of pistol or revolver shape</li>
                <li>Coal &amp; firewood</li>
                <li>Cologne and Perfume, Haz and Non-Haz</li>
                <li>Communications equipment</li>
                <li>Compact discs</li>
                <li>Controlled or psychotropic substances</li>
                <li>Cosmetics</li>
                <li>Cotton seeds</li>
                <li>Drugs, Prescription and Non-Prescription</li>
                <li>Electronic Cigarettes</li>
                <li>Electronic games</li>
                <li>Endangered species of wildlife and their by-products</li>
                <li>Films: 8mm, 16mm &amp; 35mm</li>
                <li>Films: entertainment</li>
                <li>Films: promotional, training</li>
                <li>Firecrackers</li>
                <li>Foodstuffs</li>
                <li>Grain samples</li>
                <li>Laser discs</li>
                <li>Alcohols and cigarettes checked with the words "SINGAPORE Obligation NOT PAID" on the names, cartons
                    or bundles
                </li>
                <li>Magazines, periodicals, diaries</li>
                <li>Medical samples</li>
                <li>Military equipment</li>
                <li>Newspapers</li>
                <li>Disgusting articles, distributions, video tapes/discs and computer program</li>
                <li>Oil products</li>
                <li>Perishables</li>
                <li>Phones/modems</li>
                <li>Plant products</li>
                <li>Plants</li>
                <li>Radar equipment</li>
                <li>Radioactives</li>
                <li>Generation of copyrighted distributions, video tapes, video compact plates, laser circles, records
                    or cassettes</li>
                <li>Subversive and treasonable materials</li>
                <li>Seeds</li>
                <li>Soil tests</li>
                <li>Tapes, audio cassettes</li>
                <li>Tapes, video cassettes</li>
                <li>Broadcast communications gear</li>
                <li>Toys</li>
            </ul>
            <h3>Singapore Import Restrictions</h3>
            <ul>
                <li>Communistic Materials</li>
                <li>Gold (other than jewelry)</li>
                <li>Mineral Products</li>
                <li>Viagra</li>
                <li>Green tea, green tea items, drain items, meat, fish, natural products and vegetables from Japan
                    requires a Certificate of Beginning (COO) from the shipper</li>
                <li>Restorative Gadgets: All shippers bringing in restorative gadgets into Singapore must comply with
                    the administrative prerequisites from Wellbeing Science Specialist (HSA) by means of the TradeNet
                    Framework and must give all documentation (e.g., Importer's Permit, Item Enlistment or Authorization
                    Endorsement Status) to lemonmode for affirmation</li>
            </ul>

        </div>

        <div id="content-coun6" class=" " *ngIf="selectedCountry === 'coun6'">
            <hr class="border-2">
            <h3>Saudi Arabia Moment Disallowances</h3>
            <ul>
                <li>All shipments containing gems counting, but not constrained to, gold, silver, platinum, pearls,
                    stones and precious stones, etc. with a pronounced or commercial esteem of over US$5 are denied and
                    will be seized by the traditions specialists in Saudi Arabia</li>
                <li>Grown-up Toys</li>
                <li>Liquor and liquor related things</li>
                <li>All sorts of devout books (counting but not constrained to Qur'an, Book of scriptures, etc.)</li>
                <li>Any thing opposite to the Regal Family, Saudi Middle eastern or Muslim convictions or ethical
                    quality</li>
                <li>Any thing that includes a covered up camera or anything that can be utilized as a spying gadget
                    (pens, observes, etc.)</li>
                <li>Any things which delineate or show the female life systems (other than for strict therapeutic
                    purposes)</li>
                <li>Chemicals, Haz and Non-Haz</li>
                <li>Commercial shipments containing gems counting, but not restricted to, gold, silver, platinum,
                    pearls, stones and precious stones, etc.</li>
                <li>Communications equipment</li>
                <li>Compact discs</li>
                <li>Computer components &amp; parts</li>
                <li>Computer software</li>
                <li>Unsafe Merchandise as characterized by IATA (Intl. Discuss Transport Affiliation)</li>
                <li>Dates</li>
                <li>Diskettes</li>
                <li>Drugs, Medicine and Non-Prescription</li>
                <li>Dry Ice</li>
                <li>Electrical Stunner (individual utilize)</li>
                <li>Electronic Cigarettes</li>
                <li>Films: 8mm, 16mm &amp; 35mm</li>
                <li>Films: entertainment</li>
                <li>Films: promotional, training</li>
                <li>Foodstuffs</li>
                <li>Products either dispatched from or fabricated in Israel are totally prohibited</li>
                <li>Grain samples</li>
                <li>Herbal Products</li>
                <li>Herbal Tea</li>
                <li>Things of human concealment such as covers, etc.</li>
                <li>Things utilized for betting or recreations of chance</li>
                <li>Laser Pointers</li>
                <li>Magic Games</li>
                <li>Male Upgrade Supplements (creams, tablets or splashes)</li>
                <li>Restorative tests</li>
                <li>Medical/dental supplies &amp; gear</li>
                <li>Metal Locator (individual Utilize)</li>
                <li>Microfiche &amp; microfilm</li>
                <li>Phones/modems</li>
                <li>Plants and Plant Products</li>
                <li>Pornography in any form</li>
                <li>Radio equipment</li>
                <li>Radios or parts thereof</li>
                <li>Seeds</li>
                <li>Sex toys and other things related to sex</li>
                <li>Spice</li>
                <li>Stuffed creatures and Pokemon toys</li>
                <li>Manufactured Cannabis (K2)</li>
                <li>Tapes, sound cassettes</li>
                <li>Tapes, computer</li>
                <li>Tapes, video cassettes</li>
                <li>Tattoo Machine</li>
                <li>Broadcast communications hardware</li>
                <li>Tvs, tv gear</li>
                <li>Tobacco and Tobacco Clears out</li>
                <li>Toys worked by fuel</li>
                <li>Video Recreations (containing realistic fabric)</li>
                <li>Observes which have cameras joined to them</li>
            </ul>
            <h3>Saudi Arabia Purport Limitations</h3>
            <ul>
                <li>N/A</li>
            </ul>
        </div>

        <div id="content-coun7" class=" " *ngIf="selectedCountry === 'coun7'">
            <hr class="border-2">
            <h3>Turkey Purport Denials</h3>
            <ul>
                <li>Confined commodities when Bringing in Merchandise from the U.S. to Turkey: Contact lemonmode for
                    more data on the off chance that you're looking to buy these products for send out to Turkey.</li>
                <li>Acids</li>
                <li>Alcoholic beverages</li>
                <li>Batteries, Haz</li>
                <li>Bearer Documents</li>
                <li>Bio Products, Haz</li>
                <li>Chemicals, Haz</li>
                <li>Coffee</li>
                <li>Cologne and Perfume, Haz and Non-Haz</li>
                <li>Computer components &amp; parts</li>
                <li>Computer software</li>
                <li>Corrosives</li>
                <li>Cosmetics, Haz and Non-Haz</li>
                <li>Unsafe Merchandise as characterized by IATA (Intl. Discuss Transport Affiliation)</li>
                <li>Diplomatic mail</li>
                <li>Electronic equipment</li>
                <li>Flammables</li>
                <li>Foodstuffs</li>
                <li>Gases</li>
                <li>Glass products</li>
                <li>Ice, Dry</li>
                <li>Ice, Wet</li>
                <li>Infectious Substances</li>
                <li>Ivory</li>
                <li>Labels</li>
                <li>Liquids, Haz</li>
                <li>Liquor, Haz</li>
                <li>Magnetized Materials</li>
                <li>Maps</li>
                <li>Medical/dental supplies &amp; equipment</li>
                <li>Mobile/Cellular Phones (acknowledged as it were to companies with Moment Permit)</li>
                <li>Oxidizers</li>
                <li>Paints, Haz</li>
                <li>Perfume, Haz</li>
                <li>Personal effects</li>
                <li>Phones/modems</li>
                <li>Plant products</li>
                <li>Poisons</li>
                <li>Powders</li>
                <li>Radio equipment</li>
                <li>Radioactives</li>
                <li>Samples, textile</li>
                <li>Seeds</li>
                <li>Tea</li>
                <li>Textile articles</li>
                <li>Tobacco and Tobacco Leaves</li>
                <li>Toiletries, Haz</li>
                <li>Viagra</li>
            </ul>
            <h3>Turkey Import Restrictions</h3>
            <ul>
                <li>N/A</li>
            </ul>

        </div>

        <div id="content-coun8" class=" " *ngIf="selectedCountry === 'coun8'">
            <hr class="border-2">
            <h3>Joined together Middle easterner Emirates Purport Forbiddances</h3>
            <ul>
                <li>Communications hardware</li>
                <li>Compact circles</li>
                <li>Cotton</li>
                <li>Cotton seeds</li>
                <li>Diskettes</li>
                <li>Drugs, Medicine and Non-Prescription</li>
                <li>Electronic things (observes, car-keys, etc.) with a camera and pens with a camera</li>
                <li>Films: 8mm, 16mm &amp; 35mm</li>
                <li>Films: entertainment</li>
                <li>Films: promotional, training</li>
                <li>Foodstuffs</li>
                <li>Manufactured and copy cash, bank notes, etc.</li>
                <li>Illegal drugs (cannabis, cocaine, heroin, etc.)</li>
                <li>Illuminated nourishment items</li>
                <li>Isopropanol</li>
                <li>Israel manufactured products</li>
                <li>Items that are offensive to Muslim culture</li>
                <li>Things that don't follow to devout, ethics or points to cause debasement and clutter</li>
                <li>Laser disc</li>
                <li>Liquids, Haz and Non-Haz</li>
                <li>Magazines, periodicals, journals</li>
                <li>Medical samples</li>
                <li>Military equipment</li>
                <li>Perishables</li>
                <li>Phones/modems</li>
                <li>Radar Detectors</li>
                <li>Radar equipment</li>
                <li>Radio equipment</li>
                <li>Radios or parts thereof</li>
                <li>Satellite Receivers</li>
                <li>Seeds</li>
                <li>Synthetic Marijuana (K2 &amp; K9)</li>
                <li>Tapes, computer</li>
                <li>Tapes, video cassettes</li>
                <li>In fact, statues of any kind are disallowed. In any case, the Traditions Chief can grant uncommon
                    endorsement for the importation of statues. On the off chance that you want to moment a statue you
                    ought to type in the Traditions Executive asking consent to see on the off chance that lodging can
                    be made.</li>
                <li>Telecommunications equipment</li>
                <li>Tobacco and Tobacco Leaves</li>
                <li>Used Tires</li>
            </ul>
            <h3>Joined together Middle easterner Emirates Purport Limitations</h3>
            <ul>
                <li>Imports of liquor and pork items are entirely controlled.</li>
            </ul>

        </div>

        <div id="content-coun9" class=" " *ngIf="selectedCountry === 'coun9'">
            <hr class="border-2">
            <h3>Joined together Kingdom Purport Forbiddances</h3>
            <ul>
                <li>Flick and Gravity Blades; blades with edges that open consequently by operation of a spring gadget,
                    or discharged by gravity or centrifugal constrain</li>
                <li>Foul or Disgusting Fabric</li>
                <li>Hostile Weapons such as belt buckle blades, passing stars, tossing stars, and military expressions
                    gear, Pepper and comparable splashes</li>
                <li>Asbestos</li>
                <li>Counterfeit coins and bank notes</li>
                <li>Counterfeit or pirated goods</li>
                <li>Goods bearing false origin statements</li>
                <li>Goods infringing a trademark</li>
                <li>Goods infringing a copyright</li>
                <li>Lottery Material</li>
                <li>Prison-made goods</li>
                <li>Stamps (fictitious) including dies and plates for manufacture</li>
                <li>Video senders; equipment capable of transmitting video images</li>
                <li>Eggs</li>
                <li>Straw</li>
                <li>Soil</li>
                <li>Investigational Drugs</li>
                <li>Animal Hair and Wool</li>
                <li>All items containing the biocide dimethylfumarate (DMF)</li>
                <li>Non-perishable foodstuff weighing more than 10 kg to private people</li>
                <li>Items containing meat or drain items counting Whey Protein for individual utilization</li>
                <li>Shipments subject to Tradition on Universal Exchange in Imperiled Species of Wild Fauna and Greenery
                    (CITES)</li>
                <li>Live Creatures (counting creepy crawlies, natural product flies/drosophila melanogaster and
                    nematodes)</li>
                <li>Plants of Mangifera sp (Mangoes), Solanum melongena (Aubergine), Colocasia sp (Patra takes off),
                    Momordica sp (Severe gourd) and Trichosanthes sp (Wind gourd), starting in India.</li>
                <li>Covers up, skins and attire from the taking after creatures: Castor Canadensis, Lutra Canadensis,
                    Canis Iatrans, Canis lupus, lynx Canadensis, Felis rufus, martes zibellina, Procyon lotor, Ondatra
                    zibethicus, Martes flag, Taxidea taxus, Martes Americana and Mustela ermine are precluded from
                    certain nations.</li>
            </ul>

            <p>Due to the chance of defilement with E. coli microscopic organisms conceivably connected to the later
                episodes in Germany and France, the crisis degree forbids with quick impact the importation of seeds and
                beans from Egypt to all EU nations. The commodities are:</p>
            <ul>
                <li>Beetroot grows, radish grows</li>
                <li>Dried leguminous vegetables, shelled, whether or not skinned or split</li>
                <li>Fenugreek seeds</li>
                <li>Leguminous vegetables, shelled or unshelled, fresh or chilled</li>
                <li>Lucerne (horse feed) seeds</li>
                <li>Lucerne (horse feed) grows</li>
                <li>Mustard seeds, for sowing</li>
                <li>Other mustard seeds</li>
                <li>Other oil seeds and oleaginous natural products, whether or not broken</li>
                <li>Rocket sprouts</li>
                <li>Soya bean sprouts</li>
                <li>Soya beans, whether or not broken</li>
                <li>Sugar beet seeds</li>
                <li>Vegetable seeds</li>
            </ul>
            <h3>United Kingdom Import Restrictions</h3>
            <ul>
                <li>Horror Comics</li>
                <li>Disgusting or disgusting materials, in any case of media; DVDs, CD, video cassettes, movies, books
                    etc.</li>
                <li>Radio transmitters (walkie-talkies, CB Radios, Cordless phones) not endorsed for utilize within the
                    UK</li>
                <li>Rice (of U.S. Origin-Certificate of Congruity required)</li>
                <li>Cartridges bigger than 500g by discuss cargo into, through or from the UK, unless they start from a
                    known consignor with security courses of action endorsed by the Office of Transport</li>
            </ul>

        </div>

        <div id="content-coun10" class=" " *ngIf="selectedCountry === 'coun10'">
            <hr class="border-2">
            <h3>United States Import Prohibitions</h3>
            <ul>
                <li>All channels related to the utilize of unlawful drugs such as 'smoking pipes' utilized as sedate
                    gear Articles containing puppy or cat hide</li>
                <li>Hemp items such as beauty care products, clothing, nourishment, etc. that contains
                    tetrahydrocannabinols (THC)</li>
                <li>Immoral articles (as defined in 19 CFR 12.4) including films, pictures, writings, etc.</li>
                <li>Importation of medicine drugs by an person U.S. customer for individual utilize is denied unless FDA
                    endorsed. There are exceptions/restrictions: 1. medicine drugs, which are made within the U.S. and
                    after that traded, can as it were be returned to the U.S. producer. 2. Beneath constrained
                    circumstances as characterized and permitted by FDA controls, a small quantity of a medicine
                    medicate for individual utilize can be qualified for purport in which case the taking after least
                    data and documentation must be included on the commercial receipt and go with the shipment: a
                    duplicate of a substantial, composed doctor's medicine; total title, address and phone number of the
                    U.S. authorized treating doctor, title and address of the medicate producer; shape of pharmaceutical
                    (tablets, capsules, fluid, etc.); quantity; sort of bundling; sort of restorative condition being
                    treated; in case the pharmaceutical can be acquired within the U.S.; measurement and quality. Viable
                    Eminent 3, 2007 commercial shipments of live pigs, swine meat</li>
                <li>Kinder Surprise Eggs (Easter Chocolate Eggs with surprise inside)</li>
                <li>Merchandise from countries under U.S. Sanction or embargo</li>
                <li>Merchandise produced by convict, forced or indentured labor (as defined in 19 CFR 12.42)</li>
                <li>Petroleum or petroleum products of Syrian origin</li>
                <li>Switchblade/Balisong/gravity/ballistic knives</li>
                <li>The U.S. Fish and Wildlife (FWS) issued a Director's Order announcing it will prohibit the import,
                    export, and sale of elephant ivory, items made of rhinoceros horn, and other products made from
                    protected species listed in the Endangered Species Act (ESA), with a few exceptions. Under this new
                    order, ESA listed species are prohibited without an ESA permit except for those goods that qualify
                    as an antique. Shipments of African elephant ivory, including antiques imported for commercial use
                    are prohibited. In order to qualify as antique, the importer, exporter or seller must provide
                    documentation that the goods are 1 years or older; have not been repaired or modified, were legally
                    imported through an antique port, and were wholly made or containing part of an endgandered species.
                    FWS also required documented proof of the species identification.</li>
                <li>White phosphorus matches</li>
            </ul>
            <h3>The taking after things are precluded by means of Canada to U.S.</h3>
            <ul>
                <li>Liquor refreshments</li>
                <li>Creatures, such as winged creatures, angle, reptiles, dead creatures or creatures that have been
                    mounted</li>
                <li>Articles of bizarre esteem, such as invaluable craftsmanship, adornments, collectibles and
                    collectibles</li>
                <li>Cash, coins, cash, stamps, debatable stocks, bonds, bank drafts, cash letters, and other debatable
                    rebellious comparable to cash</li>
                <li>Common fireworks</li>
                <li>Holders of fluids with a volume surpassing 8 gallons (32 liters) or 70 lbs. (32 kg) in weight</li>
                <li>Cut blossoms</li>
                <li>Unsafe Merchandise or Unsafe Materials: Corrosives, explosives, toxics or other substances counting
                    Other Directed Materials (ORM-Ds) controlled by the Government Flying Organization (FAA) and/or
                    Transport Canada and Environment Canada</li>
                <li>Explosives, firecrackers, flares, and matches</li>
                <li>Combustible products</li>
                <li>Hides</li>
                <li>Unsafe Materials, perilous squander, counting, but not restricted to, utilized hypodermic needles,
                    syringes or other restorative squanders, and biohazards such as blood, pee, liquids and other
                    noninfectious demonstrative examples</li>
                <li>Human or creature remains, bodies, organs, embryos, body parts, or incinerated or disinterred human
                    remains</li>
                <li>Despicably bundled shipments</li>
                <li>Lottery tickets and betting gadgets where denied by federal/national, common, state or nearby law
                </li>
                <li>Bundles that are damp, spilling or that transmit an odor of any kind</li>
                <li>Perishables, counting, but not restricted to, nourishment, foodstuffs, refreshments requiring
                    refrigeration, pharmaceuticals</li>
                <li>Individual Impacts (i.e., family articles, family furniture, and/or clothing, frequently related
                    with a client migrating from one home to another)</li>
                <li>Plants, plant materials, seeds, counting cut blooms</li>
                <li>Pornographic materials</li>
                <li>Shipments which will cause harm to, or delay of, gear, staff or other shipments</li>
                <li>Shipments that require lemonmode or its member organize to get any extraordinary permit or allow for
                    transportation, importation or exportation</li>
                <li>Tobacco, cigarettes, tobacco items</li>
                <li>Unaccompanied baggage</li>
                <li>Utilized gasoline tanks (filled or purge) or any utilized gasoline-powered gadget or gear with an
                    indispensably fuel tank (full or purge). Unused, purge gasoline tanks or gasoline-powered gadgets
                    are satisfactory in unique unopened bundling</li>
                <li>Any articles which require a U.S. Division of State consequence permit or a Canadian Send out Allow
                </li>
                <li>Any shipment moving beneath an A.T.A. carnet</li>
            </ul>
            <h3>Joined together States Moment Confinements</h3>
            <ul>
                <li>Medicine drugs, which are made within the U.S. and after that traded, can as it were be returned to
                    the U.S. producer.</li>
                <ul>
                    <li>Beneath constrained circumstances as characterized and permitted by FDA directions, a little
                        amount of a medicine sedate for individual utilize may be qualified for moment in which case the
                        taking after least data and documentation must be included on the commercial receipt and go with
                        the shipment</li>
                </ul>

                <li>Infections, Serums, Poisons, Anti-Toxins and practically equivalent to items</li>
                <li>Residential Creature Items and Creature Bolstering Fabric</li>
                <li>Items determined from Wild Creatures, Winged creatures and Creepy crawlies (hides, eggs, plumage,
                    nectar, etc.)</li>
                <li>Seeds and non-propagating Agrarian Items</li>
                <li>Non-perishable Foodstuffs (canned products, etc.)</li>
                <li>Engine vehicles, Vessels and their related gear</li>
                <li>Electronic Items</li>
                <li>Social Property, counting Pre-Columbian fantastic or engineering form or wall painting</li>
                <li>Materials, Fleece and Hide Items</li>
                <li>Chemical substances (counting Fertilizers and Pesticides)</li>
                <li>Medication, Therapeutic Gadgets, Beauty care products</li>
                <li>Alcohols, Liquor</li>
                <li>Nuclear/Radioactive Materials</li>
                <li>Hemp seeds must be sterilized and Purport Licenses may be required</li>
                <li>Hemp items such as beauty care products, clothing, nourishment, etc. is worthy in the event that
                    they don't contain tetrahydrocannabinols (THC)</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Single Service Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <!-- <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required=""
                            autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->
