<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Customs Duties and Taxes</h2>
                    <!-- <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Air fright</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->


<!-- Single Service Area -->
<div class="duties-taxes">
    <h1>Customs Duties and Taxes</h1>
    <h4>Spare Costs, Time, and Exertion on Universal Shipping!</h4>
    <p>Nearly all shipments crossing universal borders are subject to obligation and assess evaluation by the bringing
        in
        country’s government. The goal country’s traditions authorities survey obligations and charges based on data
        given on the shipping name, the Commercial Receipt, and other important archives.
    </p>
    <p>Components that influence a shipment’s obligation and charge sum</p>
    <ul>
        <li>Product Value</li>
        <li>
            Trade Agreements
        </li>
        <li>
            Country of Manufacture
        </li>
        <li>
            Description and end use of the product
        </li>
        <li>
            The product’s Harmonized System (HS) code
        </li>
        <li>
            Country -Specific Regulations
        </li>

    </ul>
    <h4> Goods and Services Tax (GST) and Value-Added Tax (VAT)</h4>
    <p>A common utilization assess is surveyed on the esteem included to products and administrations. This assess is
        called GST – Merchandise and Administrations Charge in a few nations like Canada and Singapore. In European
        Union (EU) nations, this assess is known as a Value-Added Assess (VAT). It could be a charge on customer use and
        so businesses that are VAT- enrolled and completely assessable don't bear the ultimate costs of VAT. For
        EU-Specific VAT rates, visit European Commission (EC) Tax assessment and Traditions Union site.</p>

    <div class="countryRow">
        <div class="row">
            <div class="col-md-6 country-lable">Select Country</div>
            <select class="col-md-6 select-option w-auto" [(ngModel)]="selectedCountry" (change)="onChangeCountry($event)">
                <option value="coun1">Select Country</option>
                    <option value="coun2">Brazil</option>
                    <option value="coun3">Canada</option>
                    <option value="coun4">European Union</option>
                    <option value="coun5">India</option>
                    <option value="coun12">Japan</option>
                    <option value="coun6">Saudi Arabia</option>
                    <option value="coun7">Singapore</option>
                    <option value="coun8">Turkey</option>
                    <option value="coun9">United Arab Emirates</option>
                    <option value="coun10">United Kingdom</option>
                    <option value="coun11">United States of America</option>
            </select>
        </div>

        <div id="content-coun1" class="country-content" *ngIf="selectedCountry === 'coun1'">

        </div>

        <div id="content-coun2" class="country-content" *ngIf="selectedCountry === 'coun2'">
            <hr class="border-2">
            <h3>Import duty: </h3>

            <p>60% of the shipment’s CIF* value over $50.00</p>

            <p>ICMS tax: (a state value-added tax on services and circulation of goods) average of 18%</p>

            <p>* CIP/CIF = cost of goods + international freight + international insurance</p>

            <h3>Duties and Taxes </h3>

            <ul>
                <li>Import duty: based on the goods’ tax classification (NCM) </li>
                <li>ICMS: average of 18%</li>
                <li>IPI (manufactured products):</li>
                <li>Based on the goods’ tax classification (NCM) </li>
                <li>COFINS: 7.65% of the declared value </li>
                <li>PIS: 1.65% of the declared value</li>
            </ul>

            <p>Brazil has actualized the Mercosul Common Classification, known as the Nomenclatura Comum do Mercosul
                (NCM), reliable with the Harmonized Framework (HS) for duty classification. The total list and
                overhauled subtle elements can be gotten to here - Mercosul Common Outside Duty </p>

            <p>CMS may be a state government value-added charge appropriate to both imports and household items.</p>

            <p>The ICMS assess on imports is evaluated advertisement valorem on the CIF esteem, additionally moment
                obligation, furthermore IPI.</p>

            <p>In spite of the fact that merchants ought to pay the ICMS to clear the imported item through
                traditions,
                it isn't fundamentally a fetched thing for the merchant since the paid esteem speaks to a credit to
                the
                merchant. When the item is sold to the conclusion client, the merchant charges the ICMS, which is
                included within the last cost of the item and is paid by the conclusion client.</p>

        </div>

        <div id="content-coun3" class="country-content" *ngIf="selectedCountry === 'coun3'">
            <hr class="border-2">
            <p>Any item shipped to Canada may be subject to the <a
                    href="http://www.cra-arc.gc.ca/tx/bsnss/tpcs/gst-tps/gnrl/menu-eng.html" style="color:#fff;"
                    target="_blank">Goods and Services Tax (GST)</a> and/or duty. Unless specifically exempted, you
                must
                pay the 5% GST on items you import into Canada by mail. The CBSA calculates any duties owing based
                on
                the value of the goods in Canadian funds. The duty rates vary according to the type of goods you are
                importing and the country from which they came or were made in. Depending on the goods or their
                value,
                some other taxes may apply, such as excise duty or excise tax on luxury items.</p>

            <p>You do not have to pay the GST on the following goods that are imported into Canada by mail:</p>
            <ul>
                <li>goods worth CAN$20 or less; and</li>
                <li>gifts from family members or friends who live abroad when the worth is CAN$60 or less.</li>
            </ul>
            <p>In spite of the fact that merchants ought to pay the ICMS to clear the imported item through
                traditions,
                it isn't essentially a taken a toll thing for the merchant since the paid esteem speaks to a credit
                to
                the merchant. When the item is sold to the conclusion client, the merchant charges the ICMS, which
                is
                included within the last cost of the item and is paid by the conclusion client.</p>

            <p>A shipment's duty and tax amount is based on the following:</p>
            <ul>
                <li>Product value</li>
                <li>Trade agreements</li>
                <li>Country of manufacture</li>
                <li>Description and end use of the product</li>
                <li>The product's Harmonized System (HS) code</li>
                <li>Country-specific regulations</li>
            </ul>
            <h3><strong>Goods and Services Tax (GST)/Value-Added Tax (VAT)</strong></h3>

            <p>In spite of the fact that merchants need to pay the ICMS to clear the imported item through
                traditions,
                it isn't essentially a taken a toll thing for the merchant since the paid esteem speaks to a credit
                to
                the merchant. When the item is sold to the conclusion client, the merchant charges the ICMS, which
                is
                included within the last cost of the item and is paid by the conclusion client.</p>

            <p>The Canadian deals charges are the Common Deals Assess (PST), the Quebec Deals Assess (QST), the
                Merchandise and Administrations Charge (GST), and the Harmonized Deals Charge (HST) which could be a
                combination of the PST and the GST in a few areas.</p>

            <p>For more information on Taxes – <a href="http://www.cra-arc.gc.ca/tx/bsnss/tpcs/gst-tps/rts-eng.html"
                    style="color:#fff;" target="_blank">Click here</a></p>

            <p>For European Union (EU) nations, this charge is known as a Value-Added assess (VAT). Businesses that
                are
                VAT-registered and completely assessable don't bear the ultimate costs of VAT since it may be a
                charge
                on buyer consumption.</p>

            <p>To see EU-specific VAT rates, please visit the <a
                    href="http://ec.europa.eu/taxation_customs/index_en.htm" style="color:#fff;"
                    target="_blank">European Commission (EC) Taxation and Customs Union website</a>.</p>

            <div class="col-sm-12">
                <h1 style="color:#fff;">Frequently Asked Questions</h1>
                <br>
                <div class="about-content faqcl" style="display: inherit !important;">
                    <ul class="faq">
                        <li class="q"><i class="ion-chevron-right"></i>Why I am being charged duty and taxes?</li>

                        <li class="a">
                            <p>All shipments that are imported into Canada are subject to obligations and charges
                                evaluation by the Canada Border Administrations Organization (CBSA). The obligation
                                rate
                                changes depending on the sort of item and the nation where it was made. The sum of
                                obligations and charges for an thing fundamentally depends on the item's esteem in
                                Canadian dollars and the reason for the shipment such as a blessing. In expansion to
                                the
                                obligation, assess may too apply (e.g., GST, PST, QST, and HST).</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>Why wasn’t I advised about the duties and
                            taxes
                            when I made my online purchase?</li>

                        <li class="a">
                            <p>Products acquired online and transported to you from exterior your nation of home are
                                subject to obligations and charges. In the event that you're making an internet buy,
                                if
                                it's not too much trouble clarify with the sender who is mindful for the installment
                                of
                                any obligations and taxes as they may not be included within the buy cost.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>What is the gift exemption amount?</li>

                        <li class="a">
                            <p>For qualified endowments imported into Canada it is CAD$60 or less, per blessing,
                                indeed
                                in case a single blessing has more than one beneficiary (e.g., blessing to a
                                family).
                                Each blessing within the shipment must be clearly recognized as one blessing. In
                                case
                                the blessing is worth more than CAD$60, the sum over CAD$60 is subject to
                                obligations
                                and charges (e.g., in the event that a relative sends you a blessing worth CAD$200,
                                you
                                must pay any appropriate obligation furthermore the GST, PST, QST, and HST on
                                CAD$140).
                            </p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>How do I pay for obligations and charges?
                        </li>

                        <li class="a">
                            <ul>
                                <li>Pre-pay by credit card.</li>
                                <li>Pay by cash, cheque or credit card at the time of delivery</li>
                            </ul>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>I have ordered the same item in the past and
                            was
                            not charged duties and taxes. Why am I being charged this time?</li>

                        <li class="a">
                            <p>There are several reasons why the rate of duty and amount could vary, such as:</p>
                            <ul>
                                <li>The value of the shipment.</li>
                                <li>The country of manufacture.</li>
                                <li>The description of the goods on the air waybill or customs documents.</li>
                            </ul>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>Why am I being charged obligation on
                            merchandise
                            imported from the Joined together States (U.S.) or Mexico? Does the Canada-United
                            States-Mexico Assention (CUSMA) not apply?</li>

                        <li class="a">
                            <p>Underneath are a few cases when the CUSMA/USMCA/T-MEC rate of obligation would not be
                                appropriate on a shipment from the U.S. or Mexico.</p>

                            <ul>
                                <li>The item was not manufactured in a CUSMA/USMCA/T-MEC country (i.e., Canada,
                                    Mexico,
                                    and the U.S.).</li>
                                <li>A marked CUSMA/USMCA/T-MEC Certification of Root was not given for a commercial
                                    shipment esteemed at CAD$3300 or more noteworthy (electronic marks are
                                    satisfactory).</li>
                                <li>A articulation certifying the merchandise start from a CUSMA/USMCA/T-MEC nation
                                    was
                                    not given on the commercial receipt for a commercial shipment esteemed at less
                                    than
                                    CAD$3300.</li>
                            </ul>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>What are some customs-clearance related
                            charges I
                            can expect?</li>

                        <li class="a">
                            <p>It depends on the contents, value and origin of the shipment. You may be charged the
                                following:</p>
                            <ul>
                                <li>Duties and taxes</li>
                                <li>Delivery Fee or Advancement Fee</li>
                                <li>Ancillary charges, if applicable. </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

        </div>

        <div id="content-coun4" class="country-content" *ngIf="selectedCountry === 'coun4'">
            <hr class="border-2">
            <p>The rules set out within the WTO Traditions Valuation Assention have been transposed into the
                specifically appropriate EU traditions enactment as takes after:</p>

            <ul>
                <li>Union Customs Code (Regulation (EU) No 952/2013) Articles 69 to 76;</li>
                <li>Commission Implementing Regulation (EU) 2015/2447 Articles 127 to 146, Article 347 and Annexes
                    23-01
                    and 23-02;</li>
                <li>Commission Delegated Regulation (EU) 2015/2446, Article 71;</li>
                <li>Commission Delegated Regulation (EU) 2016/341, Article 6.</li>
            </ul>

            <p>Guidance on customs valuation has been available for some time by means of the EC Customs Valuation
                Compendium, containing, inter alia:</p>

            <ul>
                <li>Conclusions on specific valuation topics,</li>
                <li>Commentaries on specific valuation topics,</li>
                <li>An overview of rulings of the European Court of Justice (ECJ) in the customs valuation area (for
                    complete ruling texts, please consult the ECJ website), as well as</li>
                <li>Instruments adopted by the WCO technical committee on customs valuation.</li>
            </ul>

            <p>The Compendium is primarily for guidance and use by Member States’ administrations but is available
                to
                all interested parties. </p>

            <table width="100%">
                <tbody>
                    <tr>
                        <td><strong>Country
                                Details</strong></td>
                        <td><strong>B2C LOW VALUE</strong></td>
                        <td><strong>B2C MID VALUE</strong></td>
                        <td><strong>B2C HIGH VALUE</strong></td>
                    </tr>
                    <tr>
                        <td><strong>UK</strong></td>
                        <td>Below GBP 15
                            No VAT &amp; No Duty</td>
                        <td>GBP 15 - GBP 135
                            VAT =20%, No Duty</td>
                        <td>From GBP 135 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>France</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Germany</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Austria</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Belgium</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Bulgaria</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Croatia</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Cyprus</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Czech</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Denmark</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Estonia</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Finland</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Greece</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Hungary</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Ireland</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Italy</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Latvia</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Lithuania</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Luxembourg</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Malta</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Netherlands</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Poland</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Portugal</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Romania</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Slovakia</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Slovenia</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Spain</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                    <tr>
                        <td><strong>Sweden</strong></td>
                        <td>Below EUR 22
                            No VAT &amp; No Duty</td>
                        <td>EUR 22 - EUR 150
                            VAT =21%, No Duty</td>
                        <td>From EUR 150 &amp; above
                            VAT &amp; Duty applicable</td>
                    </tr>
                </tbody>
            </table>
            <br>
            <p><strong>For post Brexit regulations, select “United Kingdom” from the dropdown</strong></p>

        </div>

        <div id="content-coun5" class="country-content container" *ngIf="selectedCountry === 'coun5'">
            <hr class="border-2">
            <h3>What documents do you need for shipping to India?</h3>

            <p>Shipments to India got to be went with by printed duplicates of archives. At the side the commercial
                receipt and discuss waybill, you moreover have to be give the recipient’s KYC documentation in
                development. Additional printed material may be required for sending high-value or directed
                merchandise.
            </p>

            <p>Government of India’s, Central Board of Roundabout Charges and Traditions (CBIC) working beneath the
                Office of Income, Service of Back, bargains with the detailing of approach concerning require and
                collection of Traditions. The classification of arrangement concerning require and collection of
                Traditions. The classification of the imports and trades of merchandise are represented by the
                Traditions Act of 1962 and Traditions Tax Act of 1975. The act contains two plans, and indicates the
                classification that's based on the Harmonized Product Depiction and Coding Framework as “HS”
                moreover
                known as the “Tariff Plan “or the “Indian Traditions Tariff”. It too contains depiction of products
                chargeable to trade obligation.</p>

            <p>For more details on applied tariffs, customs duty rates applicable to imports, Harmonized Commodity
                Description and Coding System , check <a href="http://indiantradeportal.in/" target="_blank"
                    style="color:#fff;">India Trade Portal</a> and official Government of India Portal for
                calculating
                duty rates, electronic filing of certain import declarations and shipping bills (export goods
                declarations), electronic payment and online verification of import and export licenses, refer to <a
                    href="http://icegate.gov.in/" target="_blank" style="color:#fff;">http://icegate.gov.in</a></p>

            <p>For calculating traditions obligation, these are the components that are considered:</p>

            <ul>
                <li>Basic Customs Duty (BCD): Levied either as
                    <ol>
                        <li>A specific rate based on the unit of the item (weight, number, etc.)</li>
                        <li>Ad-valorem, based on the assessable value of the item.<br>
                            In some cases, combination of the two is used.
                        </li>
                    </ol>
                </li>
                <li>Social Welfare Surcharge: Levied at 10% of the value of goods.</li>
                <li>Coordinates Products and Administrations Charge (IGST): GST is pertinent on all imports into
                    India
                    within the frame of require of IGST. Required on the esteem of imported products + any
                    traditions
                    obligation chargeable on the merchandise.</li>
            </ul>

            <p><strong>Value on which IGST is Calculated = Value of Imported Goods + Basic Customs Duty + Social
                    Welfare
                    Surcharge</strong></p>

            <p><strong>IGST Payable = Value X IGST Rate</strong></p>

            <ul>
                <li>GST Stipend Cess: Cess could be a require which is able be pertinent in expansion to the normal
                    GST
                    charges. GST Cess is exacted on supply of certain informed merchandise- for the most part having
                    a
                    place to the extravagance and negative mark category.</li>

                <li>Against- Dumping Obligation: Required on indicated products imported from indicated nations
                    counting
                    the Joined together States</li>

                <li>Customs Handling Fee: 1% customs handling fee on all imports in addition to the applied customs
                    duty.</li>

                <li>Total Duty: For most goods,</li>
            </ul>

            <p><strong>Total Duty Payable = BCD + Customs Handling Fee</strong></p>

            <h3>Customs Clearance in India</h3>

            <p>A parcel that enters India, it goes through either express of formal customs clearance.</p>

            <ul>
                <li>Express Clearance: Or courier mode, is a streamlined process with minimal paperwork for
                    low-to-medium value goods such as gifts, samples or documents. </li>

                <li>Formal Clearance: Or cargo mode, is utilized for high-value, bulk or directed merchandise. This
                    prepare requires progress installment of obligations, extra printed material, and a traditions
                    broker to supervise the clearance process. lemonmode liaises with the foremost presumed brokers
                    and
                    points at rearranging the method for you.</li>
            </ul>

            <h3>Express and formal traditions clearance in India at a Look</h3>

            <table width="100%">
                <tbody>
                    <tr>
                        <td>&nbsp;</td>
                        <td>
                            <p>Express clearance</p>
                        </td>
                        <td>
                            <p>Formal clearance</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h3>Shipment types and values</h3>
                        </td>
                        <td>
                            <ul>
                                <li>Documents (no value)</li>
                                <li>Low-value goods (duty free, personal gifts up to 5,000 INR, samples
                                    up to 10,000 INR)</li>
                                <li>Medium-value goods (up to 100,000 INR)</li>
                                <li>Packages weighing up to 70 kg</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>High-value shipments (100,000 INR or more)</li>
                                <li>Some low- or medium-value shipments that contain regulated goods</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h3>Required documentation</h3>
                        </td>
                        <td>
                            <ul>
                                <li>Air waybill</li>
                                <li>Commercial invoice</li>
                                <li>KYC documentation</li>
                                <li>A packing list or purchase order are sometimes required (see below)</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Air waybill</li>
                                <li>Commercial invoice</li>
                                <li>KYC documentation</li>
                                <li>Additional documents are often required (see below)</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div id="content-coun12" class="country-content" *ngIf="selectedCountry === 'coun12'">
            <hr class="border-2">
            <h3>Customs Duties and Taxes on Imports</h3>

            <p>Customs threshold (from which tariffs are required)</p>

            <p>JPY 10,000</p>

            <p>Average Customs Duty (Excluding Agricultural Products)</p>

            <p>Japan applies a customs tariff that is among the lowest in the world (on average, 2.5%).</p>
            <p>

            </p>
            <p>For a summary of Japanese customs tariffs, please see the following <strong><a
                        href="https://www.wto.org/english/res_e/booksp_e/tariff_profiles19_e.pdf" target="_blank"
                        style="    color: #fff;">sheet</a></strong> provided by the WTO.</p>

            <p>Products Having a Higher Customs Tariff</p>

            <p>Agrarian items (one of the most elevated rate among industrialized nations) and a few handled
                nourishment
                items, in spite of the fact that the obligations connected proceed to go down; calfskin and a few
                fabricated products.</p>

            <h3>Preferential Rates</h3>

            <p>They are granted to imports from the countries with which Japan has signed trade agreements.<strong>
                    <a href="https://www.mofa.go.jp/policy/economy/fta/index.html" target="_blank"
                        style="    color: #fff;">(See list)</a></strong></p>

            <p><strong><a href="https://www.mofa.go.jp/policy/economy/gsp/explain.html#01" target="_blank"
                        style="    color: #fff;">If necessary, the Japanese customs website explains how the
                        Generalized
                        Preference System works in Japan.</a></strong></p>

            <h3>Customs Classification</h3>

            <p>Japan uses the<strong> <a href="http://www.wcoomd.org/en.aspx" target="_blank"
                        style="    color: #fff;">Harmonised Commodity Description and Coding System,</a></strong>
                generally referred to as the 'Harmonised System,' developed by the World Customs Organisation.</p>

            <h3>Method of Calculation of Duties</h3>

            <p>Ad Valorem on the CIF value of the imports.</p>

            <h3>Method of Payment of Customs Duties</h3>

            <p><strong><a href="https://www.boj.or.jp/en/index.htm/" target="_blank" style="    color: #fff;">The
                        central Bank of Japan has set up system of electronic payment.</a></strong>

            </p>
            <h3>Import Taxes (Excluding Consumer Taxes)</h3>
            <p>None</p>


        </div>

        <div id="content-coun6" class="country-content" *ngIf="selectedCountry === 'coun6'">
            <hr class="border-2">

            <p>The Government of KSA has chosen to extend the traditions obligation rates for an broad run of
                products.
                A add up to of 57 Chapters and more than 2,000 Tax Lines of the KSA Coordinates Traditions Duty are
                expected to be influenced. The total list of items influenced was as of late distributed on the
                Saudi
                Traditions site and can be gotten to through this interface (as it were the Arabic adaptation is as
                of
                now accessible): HS codes subject to expanded obligation rates.</p>

            <p>For more information, please visit the <a href="https://www.customs.gov.sa/en" target="_blank"
                    style="color:#fff;">website of Saudi Customs.</a></p>

            <table width="100%" class="mytabcl1">
                <tbody>
                    <tr>
                        <td valign="top" colspan="2">
                            <p>Customs Duties and Taxes on Imports</p>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top">
                            <p>Customs Threshold (From which tariffs are required)</p>
                        </td>
                        <td valign="top">
                            <p>No Customs threshold</p>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top">Customs Duty</td>
                        <td valign="top">
                            <ul>
                                <li>5% Duty on Goods valued over 1000 SAR</li>
                                <li>15% VAT on Declared Value + Duty</li>
                                <li>15% VAT on goods valued less than 1000 SAR</li>
                            </ul>
                            &nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td valign="top">
                            <p>Products Having a Higher Customs Tariff</p>
                        </td>
                        <td valign="top">
                            <p>On May 27, 2020, the Saudi Traditions Specialist discharged its corrected Harmonized
                                Duty
                                Plan to extend different traditions obligation rates viable June 10, 2020.</p>
                            <ul>
                                <li>Food and Beverages (from 7% to 25%), mineral products (15%),</li>
                                <li>Chemicals (from 5.5% to 6.5%),</li>
                                <li>Textiles fabrics and clothing (from 10% to 15%),</li>
                                <li>Plastic and rubber items (from 6.5% to 15%),</li>
                                <li>Building materials (up to 15%), metal products (20%),</li>
                                <li>Machinery, equipment and parts (up to 15%)</li>
                                <li>Motor vehicles and spare parts (15%).</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top">Preferential Rates

                        </td>
                        <td valign="top">In April 2017, the nation started requiring a 50 percent extract assess on
                            delicate drinks and a 100 percent assess on cigarettes, tobacco items, and vitality
                            drinks.
                            Certain products are absolved from the common outside duty. Imports of rice, child drain
                            and
                            creature bolster are subsidized; whereas coffee, tea and new ruddy meat enter the nation
                            duty-free. Saudi Arabia has no tax rate share (TRQ) necessity.

                            Saudi Arabia has signed various trade agreements (especially with the <a
                                href="http://www.gcc-sg.org/en-us/Pages/default.aspx" target="_blank"
                                style="color:#fff;">Gulf Co-operation Council</a> – GCC) which allow the member
                            countries total exemption of the customs duties. In addition, for certain Muslim
                            countries
                            of the <a href="http://www.arableagueonline.org/" target="_blank" style="color:#fff;">League
                                of Arab States</a> (Jordan, Kuwait, Libya, Morocco and Tunisia) the customs duty has
                            been reduced to 10%.

                        </td>
                    </tr>
                    <tr>
                        <td valign="top">
                            <p>Customs Classification</p>
                        </td>
                        <td valign="top">
                            <p>Saudi Arabia applies the Harmonised Customs Systems</p>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top">Method of Calculation of Duties

                        </td>
                        <td valign="top">The traditions obligations are calculated Advertisement valorem on the
                            C.I.F
                            value. A constrained number of things are subject to traditions obligations calculated
                            on
                            the premise of metric weight or capacity, instead of advertisement Valorem. The rates
                            for
                            these things are decently moo.

                        </td>
                    </tr>
                    <tr>
                        <td valign="top">
                            <p>Import Taxes (Excluding Consumer Taxes)</p>
                        </td>
                        <td valign="top">
                            <p>None</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!--<ul>
                <li>5% DUTIES ON OVER 1000 SAR</li>
                <li>15% VAT ON DECLARED VALUE + DUTY.</li>
                <li>15% VAT ON LESS THEN 1000SAR</li>
                </ul>-->
        </div>

        <div id="content-coun7" class="country-content" *ngIf="selectedCountry === 'coun7'">
            <hr class="border-2">
            <p>All products imported into Singapore are directed beneath the Traditions Act, the Products and
                Administrations Assess (GST) Act and the Direction of Imports and Sends out Act. Imported products
                are
                subject to GST and/or obligation installment. A traditions allow is required to account for the
                moment
                and charge installment of the products. Traditions and/or extract obligation (with advertisement
                valorem
                rates) and Products and Administrations Assess (GST) are exacted on the traditions esteem of
                imports.
            </p>

            <p><strong>However, some imports can:</strong></p>

            <ul>
                <li>Qualify for preferential tariff treatment</li>
                <li>Be exempted from duties refer to Customs (Duties Exemption) Order and Section 2(1A) of Customs
                    (Duties) Order)</li>
                <li>Be exempted from GST</li>
            </ul>

            <h3>Dutiable goods, which incur both GST and duty, are:</h3>

            <ul>
                <li>Intoxicating liquors</li>
                <li>Tobacco products</li>
                <li>Motor vehicles</li>
                <li>Petroleum products</li>
            </ul>

            <p>Ad valorem or specific duty rates may be applied for dutiable goods.</p>

            <p>All other products are non-dutiable and bring about GST as it were. GST is required at 7% of the CIF
                (taken a toll, protections and cargo) esteem) for products worth over SG$350. </p>

            <p>The GST incorporates obligations (in case it may be a dutiable great) and other charges, costs and
                costs
                coincidental to the deal and conveyance of the products into Singapore, whether or not appeared on
                the
                receipt. Dutiable merchandise incorporate inebriating mixers, tobacco items, engine vehicles, and
                petroleum items.</p>

            <p>Find out more about duties and GST levied in Singapore <a
                    href="https://www.customs.gov.sg/businesses/importing-goods/import-procedures/types-of-import-permits"
                    target="_blank" style="color:#fff">here.</a></p>

            <p>You can check further information <a
                    href="https://www.customs.gov.sg/businesses/importing-goods/import-procedures/types-of-import-permits"
                    target="_blank" style="color:#fff">here.</a> about the types of customs permits and situations
                where
                no customs permit is required.</p>

        </div>

        <div id="content-coun8" class="country-content" *ngIf="selectedCountry === 'coun8'">
            <hr class="border-2">
            <p>in arrange to begin the methods for the products that would be imported (i.e. to memorize the due
                charges
                or the reports that would be submitted to the traditions organizations), traditions tax measurements
                position of the issued goods, which are classified within the every year sanctioned Turkish
                Traditions
                Duty Plan, should be known.</p>

            <p>The charges on the imported products that are expressed underneath don't exist in any enactment all
                together.</p>

            <table width="100%" class="mytabcl1">
                <tbody>
                    <tr>
                        <td colspan="2">Turkey Customs Information at a Glance</td>
                    </tr>
                    <tr>
                        <td colspan="2">Turkey is a member of the <a href="http://www.wto.org/" style="color:#fff;"
                                target="_blank">WTO,</a> of the <a href="http://www.bsec-organization.org/"
                                style="color:#fff;" target="_blank">Black Sea Economic Cooperation (BSEC)</a>, of
                            the <a href="http://www.eco.int/" style="color:#fff;" target="_blank">Economic
                                Cooper</a><a href="http://www.eco.int/" style="color:#fff;" target="_blank">ation
                                Organization (ECO)
                            </a>and of the Southern Europe Cooperative Initiative (SECI), which encourages trade
                            with
                            all its member countries thanks to preferential tariffs.</td>
                    </tr>
                    <tr>
                        <td>Customs Classification

                            &nbsp;</td>
                        <td>Turkey uses the <a href="http://www.wcoomd.org/en.aspx" target="_blank"
                                style="color:#fff;">Harmonised Commodity Description and Coding System</a>,
                            generally
                            referred to as the 'Harmonised System,' developed by the World Customs Organisation. The
                            system comprises nearly 5,000 commodity groups, each identified by a six digit code, and
                            is
                            utilised by more than <a href="http://www.wcoomd.org/" style="color:#fff;"
                                target="_blank">200</a> countries.

                            &nbsp;</td>
                    </tr>
                    <tr>
                        <td>Method of Calculation of Duties

                            &nbsp;</td>
                        <td>Traditions obligations are calculated Advertisement Valorem on the CIF esteem of the
                            products. Traditions additional charges incorporate a value-added assess (VAT) exacted
                            on
                            most imported merchandise and administrations. The merchant is mindful for paying the
                            VAT.
                            The VAT is calculated on a C.I.F. premise furthermore obligation rate and any other
                            pertinent charges required some time recently the merchandise clear traditions.

                            &nbsp;</td>
                    </tr>
                    <tr>
                        <td>Method of Payment of Customs Duties

                            &nbsp;</td>
                        <td>Duties are payable in cash (by cash money order and by bank transfer).

                            &nbsp;</td>
                    </tr>
                    <tr>
                        <td>Import Taxes (Excluding Consumer Taxes)

                            &nbsp;</td>
                        <td>Rural items, anything their beginning, stay subject to a extra charge predetermined for
                            the
                            Lodging Finance, but this has been annulled for imported mechanical products.

                            &nbsp;</td>
                    </tr>
                </tbody>
            </table>
            <br>
            <p>Traditions obligation: Traditions obligation is decided by the Moment Administration, which is
                distributed by the Service of Economy within the Turkish Official Journal on the 31st of December
                and
                enters into drive on the 1st of January of the ensuing year.</p>

            <p>The taking after records of the Purport Administration cover the related merchandise that are
                expressed
                underneath:</p>

            <ul>
                <li>List No. I; agricultural products.</li>
                <li>List No. II; industrial products.</li>
                <li>List No. III; processed agricultural products.</li>
                <li>List No. IV; fish and other fisheries.</li>
                <li>List No. V; some industrial raw materials or intermediate goods (some chemicals,
                    micro-electronics,
                    etc.) that are not produced in the EU, within the scope of the Suspension List which is in
                    effect in
                    the EU.</li>
                <li>List No. VI: From the goods that are located in this list, only to the items identified in the
                    column of items name, customs duty is applied as 0% provided that they would be utilized in
                    civil
                    aircraft, and in this case the provisions of the customs legislation for the eventual use are
                    applied.</li>
            </ul>

            <p>Value Added Tax: The goods that are covered;</p>
            <ul>
                <li>In the List No.1 are applied 1%,</li>
                <li>In the List No. 2 are applied 8% of VAT.</li>
            </ul>

            <p><strong>Products that are not included in these records are by and large subject to 20% of
                    VAT.</strong>
            </p>

            <p>Extract Assess: The extract assess that would paid amid the consequence prepare is decided by the
                extra
                records of the Extract Assess Law, which are specified underneath.</p>

            <ul>
                <li>List No. I covers fuels and oils,</li>
                <li>List No. II covers vehicles,</li>
                <li>List No. III covers alcoholic drinks and tobacco products,</li>
                <li>List No. IV covers electronic goods, cosmetics and various products.</li>
            </ul>

            <p>Resource Utilization Support Fund: 6% of the funds is derived from the import with acceptance credit,
                deferred letter of credit, and cash on delivery.</p>

            <p>Since none of the charges exacted on imports is decided by the Service of Traditions and Exchange,
                the
                questions around traditions obligation, lodging advancement finance, anti-dumping assess,
                appropriations
                and extra money related risk ought to be coordinated towards the Ministry of Economy; the questions
                almost VAT and Extract Assess got to coordinated towards the Turkish Income Organization; the
                questions
                around the cuts of scholarly and imaginative works got to be coordinated towards the Service of
                Culture
                and Tourism, and the questions related to the TRT Bandrole need to be coordinated towards TRT.</p>

            <ul>
                <li><a href="https://www.trade.gov.tr/" style="color:#fff;" target="_blank">Ministry of Customs and
                        Trade</a></li>
                <li><a href="http://www.mfa.gov.tr/default.en.mfa" style="color:#fff;" target="_blank">Ministry of
                        Foreign Trade</a></li>
                <li><a href="http://www.sanayi.gov.tr/" style="color:#fff;" target="_blank">Ministry of Industry and
                        Trade</a></li>
            </ul>

        </div>

        <div id="content-coun9" class="country-content" *ngIf="selectedCountry === 'coun9'">
            <hr class="border-2">
            <p>Arranging an consequence item to the Joined together Middle easterner Emirates? lemonmode can
                disentangle
                it for you!</p>

            <p>Much obliged to the Law of Bound together Custom Taxes, the seven emirates of UAE share the same
                bringing
                in controls for most of the items. There are three sorts of charges exacted upon the imported items
                within the UAE: traditions obligation, value-added assess, and extract charge. Products being
                imported
                into Free Exchange Zones are absolved from obligations. Too, re-exports from U.A.E. Free Exchange
                Zones
                to third advertise goals past the GCC Traditions Zones are too exempted from any obligation.</p>

            <p>Be that as it may, on the off chance that products are imported with the deliberate of re-exporting
                them
                as a entirety or mostly to another nation, a store or Ensure proportionate to the appropriate tax
                sum on
                the products should be secured in lieu of Traditions Obligation. As of now this affirmation is
                constrained to those with a esteem higher than AED 20,000, but in case of vehicles.</p>

            <p>For merchandise predetermined specifically for Territory Traditions Zones or to a Free Exchange Zone
                for
                deal within the U.A.E. and/or re-exports to GCC Nations are subject to traditions consequence duty.
                Check the most recent upgraded standards for GCC Traditions Law that set the system for UAE’s
                Consequence Controls here.</p>

            <h3>Moment Charges within the UAE</h3>

            <p>Assess Rates depend on the category of items- here’s a see of how the different items are saddled.
            </p>

            <table width="100%" class="mytabcl1">
                <tbody>
                    <tr>
                        <td>Type</td>
                        <td>Customs Duty</td>
                        <td>Value-added Tax</td>
                        <td>Excise Tax</td>
                    </tr>
                    <tr>
                        <td>Tax Rate</td>
                        <td>0%-5% (50-100% for some products, i.e. tobacco, liquor)</td>
                        <td>0%-5%, some products are exempt from tax</td>
                        <td>100% on tobacco and energy drinks. 50% on carbonated drinks</td>
                    </tr>
                    <tr>
                        <td>Tax Payer</td>
                        <td>Importers</td>
                        <td>Every member of the supply chain</td>
                        <td>Sellers</td>
                    </tr>
                    <tr>
                        <td>Tax Collector</td>
                        <td>UAE Customs Authority</td>
                        <td>Tax Bureau</td>
                        <td>Tax Bureau</td>
                    </tr>
                    <tr>
                        <td>Commencement Time</td>
                        <td>N/A</td>
                        <td>1st, January 2018</td>
                        <td>1st, Oct 2017</td>
                    </tr>
                </tbody>
            </table>

            <p>Here’s a quick reckoner on various duties and taxes and UAE:</p>

            <h3>Import Duties:</h3>

            <p>The moment tax rate of the Joined together Middle easterner Emirates ranges from 0% to 5%for most
                items,
                with an normal charge rate of 4.61%. There are higher charges on a few extraordinary items such as
                50%
                for alcohol items and 100% for tobacco.</p>

            <p>The evaluation strategy of the purport tax, or custom esteem, is CIF, taken a toll, protections
                premium,
                and cargo. This implies that the payable assess of moment taxes of merchandise is calculated
                agreeing to
                the fetched of the merchandise and instruments, protections premium, and cargo rate. Traditions
                obligation is calculated on the CIF esteem (Cost/Insurance/Freight) for shipments esteemed at 1000
                Dirham (AED) and over.</p>

            <p>As UAE may be a portion of the Inlet Participation Committee (GCC) Traditions Union, which harmonized
                the
                taxes of merchandise entering any part nation- Saudi Arabia, Kuwait, Qatar, Bahrain, Oman, and the
                UAE,
                - in any case of the goal nation inside the GCC.</p>

            <p>For case, on the off chance that you're bringing in from USA into the UAE ordained to the Saudi
                showcase,
                you must pay the traditions obligation when the products enter the UAE showcase. No additional
                tariff
                has got to be paid once the products are exchanged from the UAE to Saudi Arabia.</p>

            <h3>Illustrations of traditions obligation rates within the UAE</h3>

            <ul>
                <li>Consumer electronics products: 0%</li>
                <li>Textiles and accessories: 5%</li>
                <li>Kitchen utensils: 5%</li>
                <li>Children’s products: 5%</li>
                <li>Home and furniture: 5%</li>
            </ul>

            <p>You'll be able discover the HS code and obligation rate for most items in this record. You'll too
                call us
                or e-mail us and ready to assist you choose which HS code and obligation rates apply to your items.
            </p>

            <h3>Value-added Tax</h3>

            <p>The Value-added Assess (VAT) is an circuitous assess exacted on most of the imported products to the
                UAE,
                eventually charged upon the ultimate buyers. Businesses must collect the VAT on sake of the
                government.
                As of now, the rate of VAT is 5% and applies to all shipments. Registration necessities</p>

            <br>
            <table class="mytabcl1">

                <tbody>
                    <tr>
                        <td width="301">Business Type</td>
                        <td width="301">VAT Registration Status</td>
                    </tr>
                    <tr>
                        <td width="301">Any business with taxable supplies and imports of more than AED 375,000 per
                            year
                            (approximately equals to USD 102,000)</td>
                        <td width="301">Mandatory to Register</td>
                    </tr>
                    <tr>
                        <td width="301">Businesses with supplies and imports between AED 187,500 and AED 375,000 per
                            year</td>
                        <td width="301"> Optional to Register</td>
                    </tr>
                    <tr>
                        <td width="301">Value of imports are lower than AED 187,500 per year.

                            &nbsp;</td>
                        <td width="301">Not Required</td>
                    </tr>
                </tbody>
            </table>

            <h3>Excise Tax</h3>

            <p>Extract assess targets on some particular items that posture hurt to human wellbeing or the
                environment.
            </p>

            <h3>Examples of levied excise goods in the UAE</h3>

            <ul>
                <li>50% on carbonated drinks</li>
                <li>100% on tobacco products</li>
                <li>100% on energy drinks</li>
                <li>100% on electronic smoking devices</li>
                <li>100% on liquids used in such devices and tools</li>
                <li>50% on any product with added sugar or other sweeteners</li>
            </ul>

            <p>For latest updated information we recommend you visit <a href="" style="color:#fff;"></a></p>

        </div>

        <div id="content-coun10" class="country-content" *ngIf="selectedCountry === 'coun10'">
            <hr class="border-2">
            <p>The Joined together Kingdom (UK) has long been a key player within the worldwide consequence and
                trade
                trade, since the time of colonization. To benefit desires of its citizens, SMEs (Little to Medium
                Ventures), and to encourage exchange, the UK imports a awesome bargain of products, FMCGs (Quick
                Moving
                Buyer Products), and apparatus, while at the same time sending out a extraordinary bargain of
                exactness
                products such as, but not constrained to, mechanical apparatus, engine vehicle parts, and
                pharmaceuticals.</p>

            <p>One of Brexit’s numerous impacts is the changes to the charge rules that will happen at the
                conclusion of
                the move period. This incorporates the development of merchandise between the Joined together
                Kingdom
                (UK) and the European Union (EU) getting to be imports and sends out for traditions and VAT
                purposes. In
                this regard, the government of the UK as of late distributed its standard traditions moment tax that
                will supplant the standard traditions moment tax of the EU after the conclusion of the move period.
            </p>

            <p>To supplant the EU’s traditions purport duty, the UK will apply its claim traditions duty, the UK
                Worldwide Duty (UKGT), to merchandise imported into the UK, either from an EU part state or any
                other
                ward. The UKGT will apply from January 1 2021, i.e. after the conclusion of the move period. Within
                the
                nonattendance of any special exchanging course of action, such as a free exchange understanding
                (FTA),
                the duty rates set out within the UKGT will apply to UK imports (subject to any reliefs or tax
                suspensions in put).</p>

            <p>In other words, the UKGT will, in rule, apply to EU imports into the UK after the conclusion of the
                move
                period, unless the EU and the UK conclude something else in a exchange assention. Such an
                understanding
                ought to, in rule, lower or diminish to zero the obligations on all or a few products; and the
                political
                statement sets out the aspiration of both parties to realize duty-free and quota-free get to to each
                other’s markets.</p>

            <p>It is critical to note that exchanges including the development of products between Northern Ireland
                and
                EU part states will proceed to be considered as intra-EU developments after the conclusion of the
                move
                period. Merchandise imported into Northern Ireland from EU part states will, hence, not be subject
                to UK
                traditions obligations. This uncommon administration applies as long because it has majority rule
                assent
                in Northern Ireland, with the primary assent period being four a long time after the conclusion of
                the
                move period.</p>

            <p>The UKGT dispenses with or diminishes numerous traditions taxes compared to the existing EU
                traditions
                taxes.</p>

            <p>Indeed in case the UK and the EU concur to an FTA that disposes of duties, businesses will still have
                to
                be meet the FTA’s rules of beginning to qualify for the particular obligation rates.</p>

            <p>The full list of the UKGT is published and <a
                    href="https://www.check-future-uk-trade-tariffs.service.gov.uk/tariff" target="_blank"
                    style="color:#fff;">searchable on the UK government’s website.</a></p>

            <p>The UK Global Tariff is available at: <a href="" style="color:#fff;"></a></p>
        </div>

        <div id="content-coun11" class="country-content" *ngIf="selectedCountry === 'coun11'">
            <hr class="border-2">
            <p>Products moved over the US border from any nation may or may not require traditions obligation or
                moment
                charges. Through merchandise from a few nations are absolved due to diverse universal exchange
                understandings, the Joined together States Traditions and Border Security (CBP) implements
                traditions
                rules. Traditions obligations shift by nation of beginning as well as sort of item.</p>

            <p>It would be ideal if you allude to HTS Code to calculate the precise rate due. CBP employments
                amplified
                adaptation of worldwide HS Codes- HTSUS that can be gotten to here.</p>

            <p>The least edge for purport charge is $800. Products esteemed underneath that are not subject to
                obligation. The rates of charge on shipments shift by nation of root. If it's not too much trouble
                contact us by filling up a basic frame and our group will get in touch with you!</p>

            <div class="col-sm-12">
                <h1 style="color:#fff;">Frequently Asked Questions</h1>
                <br>
                <div class="about-content faqcl" style="display: inherit !important;">
                    <ul class="faq">
                        <li class="q"><i class="ion-chevron-right"></i> What Is the Customs Duty Tax?</li>

                        <li class="a">
                            <p>Traditions specialists check whether shipments coming into the nation follow to
                                neighborhood law on, for occurrence, wellbeing, security, and IP laws.</p>

                            <p>They moreover implement traditions obligations controls. A traditions obligation, now
                                and
                                then called an moment obligation, may be a charge connected to items imported for
                                resale.</p>

                            <p>Purport obligations or custom obligation charge may be connected to ensure
                                neighborhood
                                generation, to penalize the nation of consequence, to penalize a item that would be
                                sold
                                underneath reasonable showcase esteem (anti-dumping), or basically as a source of
                                government income.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i> How are Customs Calculated?</li>

                        <li class="a">
                            <p>Two variables determine whether customs duties apply:</p>

                            <ul>
                                <li>Country of import and product,</li>
                                <li>Product (as defined by the HS code).</li>
                            </ul>

                            <p>If you don't mind note that, in spite of gossip, worldwide cargo shipments esteemed
                                beneath $2,500 are not absolved from Traditions obligation.</p>

                            <p>A perplexity between worldwide cargo traditions obligations rules and two other rules
                                has
                                likely caused this mixed up impression. Those other rules are that shipments
                                esteemed
                                underneath $2,500 are qualified for “informal traditions entry”, which Traditions
                                are
                                less exacting when checking express cargo (universal dispatch) shipments.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i> What are the Customs Duty Charges?</li>

                        <li class="a">
                            <p>It is simple to urge confounded by traditions charges since there are a few
                                distinctive
                                sorts of charges. A few are charged straightforwardly by the traditions specialist
                                and a
                                few are backhanded charges made by other parties included within the traditions
                                handle.
                            </p>

                            <p>The taking after table brings together these charges, why they are charged, and who
                                charges them so you'll be able understand your custom obligation charges within the
                                USA,
                                in China, or wherever else you will be shipping.</p>

                            <table class="mytabcl">
                                <tbody>
                                    <tr>
                                        <td style="width: 200px;"><strong>CHARGE</strong></td>
                                        <td style="width: 200px;"><strong>CHARGING PARTY</strong></td>
                                        <td style="width: 200px;"><strong>IMPORT COUNTRY</strong></td>
                                        <td><strong>WHAT THE CHARGE COVERS</strong></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Customs Clearance Fee</strong></td>
                                        <td>Forwarder or Customs Broker</td>
                                        <td>All countries</td>
                                        <td>Service charge to cover filing and clearance.</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Penalties</strong></td>
                                        <td>Customs</td>
                                        <td>All countries</td>
                                        <td>Fines applying when customs regulations are breached, ranging from minor
                                            charges, e.g. for mislabeled cargo, to heavy fines (and prison
                                            sentences)
                                            for fraud.</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Customs Examination (X-ray, Tailgate or Intensive)</strong></td>
                                        <td>Customs</td>
                                        <td>All countries</td>
                                        <td>Pass-through fee covering Customs use of third-party premises and
                                            services.
                                            Only applies to shipments undergoing inspection.</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Demurrage and Detention / Warehouse Fees</strong></td>
                                        <td>Air or Ocean Carrier</td>
                                        <td>All countries</td>
                                        <td>Pass-through fee from air or ocean carriers that covers shipment storage
                                            (demurrage and detention fees for FCL, warehouse fees for LCL).
                                            Shipments
                                            typically return from a customs inspection after the free period for
                                            these
                                            charges expires.</td>
                                    </tr>
                                    <tr>
                                        <td><strong>ISF Filing Fee</strong></td>
                                        <td>Forwarder or Customs Broker</td>
                                        <td>US only</td>
                                        <td>Service charge applying to ocean imports only, that covers filing in
                                            compliance with advance cargo reporting requirements. Often included as
                                            part
                                            of the Customs Clearance fee.</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Merchandise Processing Fee (MPF)</strong></td>
                                        <td>Customs</td>
                                        <td>US only</td>
                                        <td>Small mandatory service charge to cover shipment processing.</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Harbor Maintenance Fee (HMF)</strong></td>
                                        <td>Customs</td>
                                        <td>US only</td>
                                        <td>Small mandatory service charge to cover use of US ports and harbors.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Custom Bonds (Continuous Bond, Single Entry Bond, ISF
                                                Bond).</strong></td>
                                        <td>Customs</td>
                                        <td>US only</td>
                                        <td>Mandatory insurance policy for CBP against failure to pay duties and
                                            penalties in full.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                    </ul>
                </div>
            </div>

        </div>

        <!-- Add more content divs for other countries -->

    </div>
</div>
<!-- End Single Service Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <!-- <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required=""
                            autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->
