<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>About Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<div class="aboutParagraph">We are <span style="font-family:sans-serif;  font-size: 23px;">lemonmode</span> We have
    been in the Online sales
    service since more than 12 Years and have
    been helping lots of
    customers in India to get products available only in International market to be delivered to them in India with
    Maximum ease and burn to their Pocket.
    We have our preferred and best business partners overseas product wise who are the best options to get the Item
    available in International not only in the most competitive price but also a genuine product. As time and again we
    have always come across cases were in building a first copy of the product or assembling is done by fraudsters and
    then selling the same sub standard product to the customer at an International Price. So, to avoid of getting a
    duplicate or fake product. We directly import the item through our well known resources in International Market and
    making it available for customers at a very competitive price.</div>


<!-- About Safe Area -->
<div class="about-text-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-contant-others">
                    <div class="section-title">
                        <span>About Us</span>
                        <h2>Trusted & Faster Lemonmode Service Provider</h2>
                    </div>

                    <div class="about-two-text">
                        <p>To fast-track the process, trucks carrying expedited goods rarely stop along the way.
                            Expedited shipments move directly from pickup to delivery. Typically, trucking
                            companies offering this rapid service will move shipments in a single truck with two
                            drive.
                        </p>
                        <ul>
                            <li><i class='bx bx-check'></i> We provide the best logistic service worldwide</li>
                            <li><i class='bx bx-check'></i> All payment methods we accept for ordering our service.</li>
                            <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                        </ul>
                        <a routerLink="/contact" class="default-btn-one mr-3">Contact Us</a>
                        <div class="watch-video">
                            <div class="video-btn">
                                <a href="https://www.youtube.com/watch?v=XK603-FIyaA" class="popup-youtube"><i
                                        class="bx bx-play whiteText"></i> Watch Video</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="safe-image">
                    <img src="assets/img/about.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End About Safe Area -->

<!-- About Info -->
<!-- <div class="about-info-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="about-info-card">
                    <h3>Our Mission</h3>

                    <ul>
                        <li><i class='bx bx-check'></i> We provide best lemonmode service worldwide.</li>
                        <li><i class='bx bx-check'></i> All payment methods we accept.</li>
                        <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                        <li><i class='bx bx-check'></i> We provide best lemonmode service worldwide.</li>
                        <li><i class='bx bx-check'></i> All payment methods we accept.</li>
                        <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="about-info-card">
                    <h3>Our Promises</h3>

                    <ul>
                        <li><i class='bx bx-check'></i> We provide best lemonmode service worldwide.</li>
                        <li><i class='bx bx-check'></i> All payment methods we accept.</li>
                        <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                        <li><i class='bx bx-check'></i> We provide best lemonmode service worldwide.</li>
                        <li><i class='bx bx-check'></i> All payment methods we accept.</li>
                        <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="about-info-card">
                    <h3>Our Vission</h3>

                    <ul>
                        <li><i class='bx bx-check'></i> We provide best lemonmode service worldwide.</li>
                        <li><i class='bx bx-check'></i> All payment methods we accept.</li>
                        <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                        <li><i class='bx bx-check'></i> We provide best lemonmode service worldwide.</li>
                        <li><i class='bx bx-check'></i> All payment methods we accept.</li>
                        <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End About Info -->

<!-- Digital Area -->
<div class="digital-area ptb-100">
    <div class="container">
        <div class="digital-top-contant">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="digital-image">
                        <img src="assets/img/all-service-img-ship.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-contant-others">
                        <div class="about-two-text">
                            <h2>Faster, Safe and Trusted Lemonmode Services</h2>
                            <p>When you have goods that need to be delivered, theres no time to waste. Especially when
                                PwCs
                                Global Consumer Insights Survey indicates that expectations for fast, flexible and
                                reliable
                                shipments are higher than ever. The good news for shippers is that shipping doesnt have
                                to
                                be hard and time constraints are exactly why you may be interested in expedited
                                shipping.
                            </p>
                            <ul>
                                <li><i class='bx bx-check'></i> Worldwide service</li>
                                <li><i class='bx bx-check'></i> 24/7 support</li>
                                <li><i class='bx bx-check'></i> Tracking service</li>
                                <li><i class='bx bx-check'></i> First and reliable</li>
                            </ul>
                            <a routerLink="/contact" class="default-btn-one">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="digital-card-contant">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="digital-card">
                        <div class="card-text">
                            <i class='bx bx-cart-alt'></i>
                            <h3><span>1998</span> - Company Started</h3>
                            <p>orem ipsum dolor sit amet elit, sed do eiusmod tempor incididunt ut labore et .</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="digital-card">
                        <div class="card-text">
                            <i class='bx bx-map-alt'></i>
                            <h3><span>2008</span> - Office worldwide</h3>
                            <p>orem ipsum dolor sit amet elit, sed do eiusmod tempor incididunt ut labore et .</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="digital-card">
                        <div class="card-text">
                            <i class='bx bxs-truck'></i>
                            <h3><span>2004</span> - Vehicles Adding</h3>
                            <p>orem ipsum dolor sit amet elit, sed do eiusmod tempor incididunt ut labore et .</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="digital-card">
                        <div class="card-text">
                            <i class='bx bx-award'></i>
                            <h3><span>2012</span> - Award Of The Year Won</h3>
                            <p>orem ipsum dolor sit amet elit, sed do eiusmod tempor incididunt ut labore et .</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>
<!-- End Digital Area -->

<!-- partner Slider Area -->
<div class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Partner</span>
            <h2>20+ Companies Trusted Us and Getting Result</h2>
        </div>

        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-slider-item">
                <a href="https://www.amazon.com/" target="_blank"><img src="assets/img/market_logo_1.jpg"
                        alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="https://www.newegg.com/" target="_blank"><img src="assets/img/market_logo2.jpg" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="https://www.wish.com/" target="_blank"><img src="assets/img/market_logo3-1.jpg" alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="https://www.textbookrush.com/" target="_blank"><img src="assets/img/market_logo4-1.jpg"
                        alt="logo"></a>
            </div>

            <div class="partner-slider-item">
                <a href="https://www.valore.com/" target="_blank"><img src="assets/img/market_logo11-1.jpg"
                        alt="logo"></a>
            </div>
            <div class="partner-slider-item">
                <a href="https://www.ebay.com/" target="_blank"><img src="assets/img/market_logo9.jpg" alt="logo"></a>
            </div>
            <div class="partner-slider-item">
                <a href="https://www.walmart.com/" target="_blank"><img src="assets/img/market_logo5.jpg" alt="logo"></a>
            </div>
            <div class="partner-slider-item">
                <a href="https://www.ebay.com/" target="_blank"><img src="assets/img/market_logo6-1.jpg" alt="logo"></a>
            </div>
            <div class="partner-slider-item">
                <a href="https://www.houzz.com/" target="_blank"><img src="assets/img/market_logo7.jpg" alt="logo"></a>
            </div>
            <div class="partner-slider-item">
                <a href="https://www.bookstores.com/" target="_blank"><img src="assets/img/market_logo8.jpg" alt="logo"></a>
            </div>
            <div class="partner-slider-item">
                <a href="https://www.onbuy.com/gb/" target="_blank"><img src="assets/img/market_logo12-1.jpg" alt="logo"></a>
            </div>
            <div class="partner-slider-item">
                <a href="https://www.sears.com/" target="_blank"><img src="assets/img/market_logo13.jpg" alt="logo"></a>
            </div>
            <div class="partner-slider-item">
                <a href="https://www.alibris.com/" target="_blank"><img src="assets/img/market_logo14_19.jpg" alt="logo"></a>
            </div>
            <div class="partner-slider-item">
                <a href="https://www.textbookx.com/" target="_blank"><img src="assets/img/market_logo15-1.jpg" alt="logo"></a>
            </div>
            <div class="partner-slider-item">
                <a href="https://www.massgenie.com/" target="_blank"><img src="assets/img/market_logo16-1.jpg" alt="logo"></a>
            </div>
            <div class="partner-slider-item">
                <a href="https://www.abebooks.com/" target="_blank"><img src="assets/img/market_logo10-1.jpg" alt="logo"></a>
            </div>
        </div>
    </div>
</div>
<!-- End partner Slider Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <!-- <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and lemonmode industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="newsletter-form">
                        <form>
                            <input type="email" class="form-control" placeholder="Email Address" required
                                autocomplete="off">
                            <button type="submit" class="btn btn-primary">Subscribe</button>
                        </form>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->
