<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Forgot password</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Forgot password</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Start Sign in Area -->
<div class="sign-in-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Forgot password?</h2>
            <p>Fill the form to reset your password</p>
        </div>

        <div class="sign-in-form">
            <form [formGroup]="forgotPasswordForm">
                <div class="form-group">
                    <input type="email" class="form-control" id="email" placeholder="Enter your Email"
                        formControlName="email" name="email">
                    <div *ngIf="forgotPasswordForm.controls['email'].invalid &&
                                    (forgotPasswordForm.controls['email'].dirty ||
                                        forgotPasswordForm.controls['email'].touched || isSubmit)
                                " style="color: red;">
                        <span *ngIf="forgotPasswordForm.controls['email'].errors.required">
                            Please enter your email address!
                        </span>
                        <span *ngIf="forgotPasswordForm.controls['email'].errors.email">
                            Please enter your email address!
                        </span>
                    </div>
                </div>
                <div class="text-center">
                    <button type="button" class="btn default-btn-one" (click)="sendResetLink()">Send reset link</button>
                    <p class="account-decs">Return to <a routerLink="/sign-in">Sign In</a></p>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Sign in  Area -->