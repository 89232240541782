import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'app/services/customer.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  loginForm: FormGroup; isSubmit = false;
  redirectUrl: string | null;
  constructor(
    private toaster: ToastrService,
    private _router: Router,
    private _registerService: CustomerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
    this.route.queryParams.subscribe((params) => {
      this.redirectUrl = params['redirect'] || null;
    });
  }

  login(): void {
    if (this.loginForm.invalid) {
      // this.toaster.error('Fill all required fields.', '');
      this.isSubmit = true;
      return;
    }

    const formData = this.loginForm.value;
    this._registerService.login(formData).subscribe(
      (res: any) => {
        if (res) {
          this.loginForm.reset();
          this.toaster.success('Login Successfully...', '');

          // Redirect the user after login
          if (this.redirectUrl) {
            this._router.navigateByUrl(this.redirectUrl);
          } else {
            // If no redirect URL is present, navigate to the dashboard page (or any other desired page)
            this._router.navigate(['customer/dashboard']);
          }
        } else {
          this.toaster.error('Something went wrong!', '');
        }
      },
      ({ error }) => {
        this.toaster.error(error ? error.message : 'Something went wrong!', '');
      }
    );
  }
}
