<!-- Header Area -->
<header class="header-area">
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <ul class="left-info">
                        <li><a href="mailto:hello@lemonmode.com"><i class='bx bxs-envelope'></i>
                                hello@lemonmode.com</a></li>
                        <li><a href="tel:+823-456-879"><i class='bx bxs-phone-call'></i> +0123
                                456 789</a></li>
                    </ul>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <ul class="right-info">
                        <!-- <li class="mr-20"><a routerLink="/contact">Contact</a></li> -->
                        <!-- <li class="mr-20"><a routerLink="/">Career</a></li>
                        <li class="mr-20"><a routerLink="/">News & Media</a></li> -->
                        <li><a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://twitter.com/" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://in.linkedin.com/" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="https://www.instagram.com/" target="_blank"><i class='bx bxl-instagram'></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="isShow">
        <div class="navbar-area">
            <div class="lemonmode-nav">
                <div class="container">
                    <nav class="navbar navbar-expand-md navbar-light">
                        <a class="navbar-brand" routerLink="/"><img src="assets/img/lemonmode_logo.png" alt="logo"></a>
                        <!-- <div class="collapse navbar-collapse mean-menu"> -->
                        <div class="navbar-collapse mean-menu">
                            <ul class="navbar-nav ms-auto">
                                <li class="nav-item">
                                    <a routerLink="/" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Home</a>
                                </li>
                                <li class="nav-item"><a routerLink="/track-order" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Track
                                        Order</a>
                                </li>
                                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">About
                                        Us</a></li>
                                <li class="nav-item">
                                    <a href="javascript:void(0)" class="nav-link">Services
                                        <i class='bx bx-chevron-down'></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/services" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                                All
                                                Services</a></li>
                                        <li class="nav-item"><a routerLink="/air-fright-services-details"
                                                class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Air
                                                fright</a></li>
                                        <li class="nav-item"><a routerLink="/ocean-fright-service-details"
                                                class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">ocean
                                                fright</a></li>

                                        <li class="nav-item"><a routerLink="/road-fright-service-details"
                                                class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">land
                                                transport</a></li>

                                        <li class="nav-item"><a routerLink="/cargo-storage-service-details"
                                                class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Cargo
                                                storage</a></li>

                                        <li class="nav-item"><a routerLink="/warehousing-service-details"
                                                class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">warehousing</a>
                                        </li>
                                        <li class="nav-item"><a routerLink="/ecommerce-service-details" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                                E-commerce solution</a></li>
                                        <li class="nav-item"><a routerLink="/express-service-details" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Express
                                                Services</a></li>
                                        <li class="nav-item"><a routerLink="/domesticTrucking-service-details"
                                                class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Domestic
                                                trucking</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <a href="javascript:void(0)" class="nav-link">Information
                                        <i class='bx bx-chevron-down'></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/prohibitedItems" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                                Prohibited Items</a></li>
                                        <li class="nav-item"><a routerLink="/volumetric-weight-calculator"
                                                class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Volumetric
                                                Weight Calculator</a></li>
                                        <li class="nav-item"><a routerLink="/rate-transit-times" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Rate
                                                and Transit Times</a></li>

                                        <li class="nav-item"><a routerLink="/fuel-surcharge" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Fuel
                                                Surcharge</a></li>

                                        <li class="nav-item"><a routerLink="/regulations" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Regulations</a>
                                        </li>

                                        <li class="nav-item"><a routerLink="/duties-taxes" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Custom
                                                Duties and Taxes</a>
                                        </li>

                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Contact</a>
                                </li>
                                <!-- <li class="nav-item"><a class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Order</a>
                                </li> -->
                                <li class="nav-item">
                                    <a routerLink="/admin/dashboard" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Dashboard</a>
                                </li>
                                <!-- <li class="nav-item"><a routerLink="/admin/orders-file" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Order
                                        File</a>
                                </li> -->
                                <!-- <li class="nav-item"><a routerLink="/admin/shipment-status" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Shipment
                                        Status</a>
                                </li> -->
                                <!-- <li class="nav-item"><a routerLink="/admin/subscription-plan" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Subscription
                                        Plan</a>
                                </li> -->
                                <li class="nav-item">
                                    <a href="javascript:void(0)" class="nav-link">
                                        <i class='bx bx-user-circle'></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a class="nav-link" routerLinkActive="active"
                                                (click)="logOut()" [routerLinkActiveOptions]="{exact: true}">Logout</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </ng-container>
</header>
<!-- End Header Area -->