<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Volumetric Weight Calculator</h2>
                    <!-- <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Air fright</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<div class="air-fright-area">
    <h1>Volumetric Weight Calculator Services</h1>
    <p>The transport costs for your shipments are calculated on the premise of the whole weight of all bundles. A
        definitive figure for calculating is – does the dimentional weight surpasses the real weight of your bundle?
    </p>
    <p>To form the foremost cost-effective shipping decision, simply enter your bundle data underneath, and we are going
        calculate the volumetric weight for you.</p>
    <div class="row">
        <div class=" col">
            <h3>Volume Converter</h3>
            <form class=" custom-form pb-4" [formGroup]="volumetricForm">
                <label for="disabledTextInput">Enter the Dimensions (in inches) : </label>

                <div class="form-row gap-2">
                    <div class="col">
                        <input id="length" type="number" formControlName="length" (wheel)="stopScrollingWheel($event)"
                            name="length" class="form-control" placeholder="Length" required="">
                    </div>
                    <div class="col">
                        <input id="width" type="number" formControlName="width" (wheel)="stopScrollingWheel($event)"
                            name="width" class="form-control" placeholder="Width" required="">
                    </div>
                    <div class="col">
                        <input id="heigth" type="number" formControlName="height" (wheel)="stopScrollingWheel($event)"
                            name="heigth" class="form-control" placeholder="Height" required="">
                    </div>
                </div>

                <div class="form-group pt-4">
                    <label for="disabledTextInput">Dimensional Weight Results :</label>
                    <input id="volume_result" disabled="" formControlName="volume_result" type="text"
                        class="form-control" placeholder="0">
                </div>

                <div class="form-group pt-4">
                    <label for="disabledTextInput">Dimensional Weight Results :</label>
                    <label for="disabledTextInput" class="mt-2">L x W x H (cubic inches) / 139= Dimensional Weight.
                        Example: 30" x 24" x 22" = 15,840 / 139 = 114 lbs. (round up to the nearest pound).</label>
                    <!-- <input disabled type="text" id="disabledTextInput" class="form-control" placeholder="Disabled input" value=""> -->
                </div>

                <!-- <button class="btn btn-primary mt-3">Submit</button> -->
                <button type="button" value="Calculate" class="button" (click)="calculate()"
                    style="background: #83ab17;color:#fff;padding: 7px 14px;border:1px solid gray; margin-top: 20px;">Submit</button>


            </form>
        </div>
        <div class="col">
            <h3>Weight Converter</h3>
            <form class=" custom-form pb-4 pl-3" [formGroup]="unitForm">

                <div class="form-group">
                    <label for="disabledTextInput">Input Unit :</label>
                    <select id="input_unit" name="choose_wg" class="custom-select mr-sm-2"
                        (change)="onChangeUnit($event)">
                        <option selected="">Choose...</option>
                        <option value="Kg">Kg</option>
                        <option value="Pound">Pound</option>
                    </select>
                </div>

                <div class="form-group mt-4">
                    <label for="disabledTextInput">Value :</label>
                    <input id="weight_value" type="number" formControlName="weight_value"
                        (wheel)="stopScrollingWheel($event)" name="value" class="form-control"
                        placeholder="Enter Value">
                </div>

                <div class="form-group pt-2 mt-2">
                    <button type="button" value="Calculate" class="submit" (click)="calculateUnit()"
                        style="background: #83ab17;color:#fff;padding: 7px 14px;border:1px solid gray">Calculate</button>
                </div>

                <div id="wg_message" class="form-group">
                    <!-- <input disabled type="text" style="border: 1px solid #ff7171; background-color:#ffb9b9;" class="form-control" placeholder="Error"> -->
                    <!-- <input disabled type="text" style="border: 1px solid #48d64d; background-color:#88d78b;" class="form-control" placeholder="Sucess"> -->

                </div>


                <div class="form-group pt-2 mt-3">
                    <label class="mr-sm-2" for="inlineFormCustomSelect">Kilogram : </label>
                    <input disabled="" type="number" formControlName="kg" (wheel)="stopScrollingWheel($event)"
                        id="kilogram" class="form-control mt-1" placeholder="0">
                </div>

                <div class="form-group pt-2 mt-2">
                    <label class="mr-sm-2" for="inlineFormCustomSelect">Pound : </label>
                    <input disabled="" type="number" formControlName="pound" (wheel)="stopScrollingWheel($event)"
                        id="pound" class="form-control mt-1" placeholder="0">
                </div>
            </form>
        </div>
    </div>

</div>

<!-- Single Service Area -->

<!-- End Single Service Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <!-- <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required=""
                            autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->