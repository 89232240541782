<!-- Page banner Area -->
<div class="page-banner bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Our Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Our Services Area -->
<div class="our-services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h2>Safe, Faster and Relaible Lemonmode Services</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/land transport.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Road Freight</h3>
                        <p>Transport or Movement of people, animals or goods from one location to another location on
                            land.</p>
                        <a routerLink="/road-fright-service-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/ship-transport-service.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Ocean Freight</h3>
                        <p>BShippabo provides real-time supply chain visibility and expertise to growing businesses.</p>
                        <a routerLink="/ocean-fright-service-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/fight service .jpg" alt="image">
                    <div class="service-caption">
                        <h3>Air Freight</h3>
                        <p>Air freight, on the other hand, means the amount to be paid for the transportation of goods
                            by air.</p>
                        <a routerLink="/air-fright-services-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/cargo storage.png" alt="image">
                    <div class="service-caption">
                        <h3>Cargo Storage</h3>
                        <p>This section describes transportation and delivery in the logistics functions of storage and
                            cargo handling.</p>
                        <a routerLink="/cargo-storage-service-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/warehouse (1).jpg" alt="image">
                    <div class="service-caption">
                        <h3>Warehousing</h3>
                        <p>Warehousing is the act of storing goods that will be sold or distributed later.</p>
                        <a routerLink="/warehousing-service-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/e-commere-sol.webp" alt="image">
                    <div class="service-caption">
                        <h3>E-commerce solutions</h3>
                        <p>E-commerce solutions are the products and services that help a company conduct business.</p>
                        <a routerLink="/ecommerce-service-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/express-services.webp" alt="image">
                    <div class="service-caption">
                        <h3>Express Services</h3>
                        <p>Through this service, expatriate employee can check the work permit application status.</p>
                        <a routerLink="/express-service-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/domestic truking.png" alt="image">
                    <div class="service-caption">
                        <h3>Domestic Trucking</h3>
                        <p>Domestic Transportation and Road Transportation Services using own fleet vehicles.</p>
                        <a routerLink="/domesticTrucking-service-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-12 col-md-12">
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" routerLink="/services"><span
                                aria-hidden="true">&laquo;</span></a></li>
                    <li class="page-item active"><a class="page-link" routerLink="/services">1</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services">2</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services">3</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services"><span
                                aria-hidden="true">&raquo;</span></a></li>
                </ul>
            </div> -->
        </div>
    </div>
</div>
<!-- Our End Services Area -->

<!-- Best Logistic Area -->
<div class="best-logistic-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="logistic-text">
                    <h2>Best <span>Lemonmode Service</span> Solution Near you</h2>
                    <div class="row">

                        <div class="col-sm-2  text-center">
                            <img class="card-img-top" src="assets/img/fast.png" alt=""><br>Fast
                        </div>


                        <div class="col-sm-2  text-center">
                            <img class="card-img-top" src="assets/img/reliable.png" alt=""><br>Reliable
                        </div>
                        <div class="col-sm-2  text-center">

                            <img class="card-img-top" src="assets/img/Efficient.png" alt=""><br>Efficient
                        </div>

                        <div class="row">
                            <div class="col-sm-2  text-center">
                                <img class="card-img-top" src="assets/img/economical.png" alt=""><br>Economical
                            </div>


                            <div class="col-sm-2  text-center">
                                <img class="card-img-top" src="assets/img/secure.png" alt=""><br>Secure
                            </div>
                        </div>
                    </div>
                </div>
                <a routerLink="/about" class="default-btn-one">Learn More</a>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="logistic-image">
                    <img src="assets/img/all-service-img-ship.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Best Logistic Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <!-- <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required=""
                            autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->
