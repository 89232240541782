import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-warehousing-service',
  templateUrl: './warehousing-service.component.html',
  styleUrls: ['./warehousing-service.component.scss']
})
export class WarehousingServiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
