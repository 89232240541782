import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { BehaviorSubject } from 'rxjs';
@Injectable({
	providedIn: 'root',
})
export class LocalService {
	isShowNavigation = new BehaviorSubject(false);
	constructor(
		private storageService: StorageService,
	) { }

	// Set Value in local storage
	setJsonValue(key: string, value: any): void {
		this.storageService.secureStorage.setItem(key, value);
	}

	// Get the json value from local storage
	getJsonValue(key: string): any {
		return this.storageService.secureStorage.getItem(key);
	}

	isLoggedIn(): boolean {
		const token = this.getJsonValue('token');
		return !!token; // Double negation to convert to a boolean value
	}
	customerDetail(): any {
		const data = this.getJsonValue('user');
		return data;
	}

	// Clear the local storage
	clearValue(key): any {
		return this.storageService.secureStorage.removeItem(key);
	}

	// Remove all keys in local storage
	clearValues(): any {
		return [
			this.storageService.secureStorage.removeItem('token'),
			this.storageService.secureStorage.removeItem('user'),
			this.storageService.secureStorage.removeItem('adminUser'),
			this.storageService.secureStorage.removeItem('adminToken'),
		];
	}

}
