<!-- Page banner Area -->
<div class="page-banner bg-1" *ngIf="isPaymentSuccess">
    <div class="d-table">
        <div class="d-table-cell">
        </div>
    </div>
</div>


<div class="row checkout-thankyou" *ngIf="isPaymentSuccess;else loading">
    <div class="col mt-md-0 mt-5">
        <div class="card d-flex align-items-center justify-content-center">
            <div class="ribon"> <span class="fas fa-check"></span> </div>
            <div class="page-content">
                <div class="search__field">
                    <h4>Thank you!</h4>
                </div>
            </div>
            <b>Your subscription has been successfully processed.</b>
            <span>
                Thank you for subscribing to our service. You will receive updates and notifications.
            </span>
            <div class="btn btn-primary" routerLink="/customer/order-file"> Get Started </div>
        </div>
    </div>
</div>
<ng-template #loading>
    <app-preloader></app-preloader>
</ng-template>