<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Ocean Freight</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Ocean Freight</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<div class="air-fright-area">
    <h1>Ocean Freight Services</h1>
    <h2>ShipGlobal Ocean Freight Shipping- Ship from any port in the USA !</h2>
    <p>When you have a large cargo or higher volume to ship, Ocean Freight is your answer! Cost effective and
        environment friendly compared to other shipping options like air freight and land, book ocean freight shipping
        services conveniently with ShipGlobal. ShipGlobal offers instant quotes, full cost transparency, real time, 24/7
        sea freight tracking a comprehensive value for money option for ocean freight shipping!.</p>
    <p>The efficiency of our team handling services for our clients for over 20 years is further enhanced by the synergy
        we are able to achieve due to our vast network and tie-ups with all the major shipping companies in the world.
    </p>
    <p>We help you find the best viable solution for planning and routing your consignment via the cheapest or shortest
        route.</p>
    <p>Get an overview of all the available options to help you select the best mode of transport that suits your
        business needs.</p>

    <h5>ShipGlobal - Global Ocean Freight Forwarding With Customised Solutions</h5>
    <table>
        <tr>
            <th>EXPORT</th>
            <th>IMPORT</th>
        </tr>
        <tr>
            <td>USA to India</td>
            <td>India to USA</td>
        </tr>
        <tr>
            <td>USA to Europe</td>
            <td>Europe to USA </td>
        </tr>
        <tr>
            <td>USA to Belgium </td>
            <td>Belgium to USA </td>
        </tr>
        <tr>
            <td>USA to China</td>
            <td>China to USA</td>
        </tr>
        <tr>
            <td>USA to Asia Pacific</td>
            <td>Asia Pacific to USA</td>
        </tr>
    </table>
    <h5 style="margin-top: 50px;">Choose from a variety of options for a cost efficient and timely logistics solution!
    </h5>
    <div class="air-image">
        <div class="row">
            <div class="col">
                <img src="assets/img/ocean-img-1.png" alt="air">
                <div>Full Container Load (FCL)</div>
            </div>
            <div class="col">
                <img src="assets/img/ocean-img-2.png" alt="air">
                <div>Less Than Container-Load (LCL)</div>
            </div>
            <div class="col">
                <img src="assets/img/ocean-img-3.png" alt="air">
                <div>Buyers Consolidation & Purchase Order (PO) Management</div>
            </div>
            <div class="col">
                <img src="assets/img/ocean-img-4.png" alt="air">
                <div>Temperature-Controlled Loads</div>
            </div>
            <div class="col">
                <img src="assets/img/ocean-img-5.png" alt="air">
                <div>Liquid Transportation & Flexitanks </div>
            </div>
            <div class="col">
                <img src="assets/img/ocean-img-6.png" alt="air">
                <div>Break Bulk & Non-Containerized Loads</div>
            </div>
        </div>
    </div>
</div>

<!-- Single Service Area -->
<!-- <div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-details-text">
                    <div class="service-image">
                        <img src="assets/img/services/single-services1.jpg" alt="image">
                    </div>
                    <h3>Easy Shipment Solution</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse</p>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                        totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae
                        dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
                        fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                    <p>NNeque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit,
                        sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
                        voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit
                        laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui
                        in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat
                        quo</p>
                    <div class="image">
                        <img src="assets/img/services/single-services2.jpg" alt="image">
                    </div>
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was
                        born and I will give you a complete account of the system, and expound the actual teachings of
                        the great explorer of the truth, the master-builder of human happiness. No one rejects,
                        dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know
                        how to pursue pleasure rationally encounter consequences that are extremely painful.</p>
                    <p>Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is
                        pain, but because occasionally circumstances occur in which toil and pain can procure him some
                        great pleasure. To take a trivial example, which of us ever undertakes laborious physical
                        exercise, except to obtain some advantage from it? But who has any right to find fault with a
                        man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain
                        that produces</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title">Our Services</h3>

                    <ul>
                        <li><a routerLink="/services-details"><i class='bx bxs-truck'></i> Road Freight</a></li>
                        <li><a routerLink="/services-details"><i class='bx bxs-ship'></i> Ocean Freight</a></li>
                        <li><a routerLink="/services-details"><i class='bx bxs-plane-take-off'></i> Air Freight</a></li>
                        <li><a routerLink="/services-details"><i class='bx bx-transfer'></i> B2B Exchange</a></li>
                        <li><a routerLink="/services-details"><i class='bx bx-home'></i> Home Shipping</a></li>
                        <li><a routerLink="/services-details"><i class='bx bx-line-chart-down'></i> Office Moving</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Single Service Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <!-- <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required=""
                            autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->
