import { Component, Input, OnInit } from '@angular/core';
import { Toast } from './toast.interface';
import { ToasterService } from 'app/services/toaster.service';

@Component({
    selector: 'app-toaster-container',
    template: `
        <app-toaster
            *ngFor="let toast of toasts; let i = index"
            [toast]="toast"
            [i]="i"
            (remove)="remove($event)"
        ></app-toaster>
    `,
    styles: [],
})
export class ToasterContainerComponent implements OnInit {
    toasts: Toast[] = [];

    constructor(private toaster: ToasterService) { }

    ngOnInit(): void {
        this.toaster.toast$.subscribe((toast) => {
            this.toasts = [toast, ...this.toasts];
            setTimeout(() => this.toasts.pop(), toast.delay || 4000);
        });
    }

    remove(index: number): void {
        this.toasts = this.toasts.filter((v, i) => i !== index);
        //this.toasts.splice(index, 1);
    }
}
