export class Constants {
    public static pageLimit = 10;
    public static pageOptions = [5, 10, 25, 100];
    public static carrierCompanyList = ['Tirupati',
        'Blue Dart',
        'India Post',
        'DTDC',
        'Delhivery Courier',];

    public static carrierCompanySlug = [
        {
            name: 'Tirupati',
            slug: 'tirupati-courier',
        },
        {
            name: 'Blue Dart',
            slug: 'blue-dart-courier',
        },
        {
            name: 'India Post',
            slug: 'india-post',
        },
        {
            name: 'DTDC',
            slug: 'dtdc',
        },
        {
            name: 'Delhivery Courier',
            slug: 'delhivery-courier',
        },
        {
            name: 'ATC',
            slug: 'atc',
        },
        {
            name: 'Xpressbees',
            slug: 'xpressbees',
        },
        {
            name: 'EcomExpress',
            slug: 'ecom-express',
        },
        {
            name: 'Smartr Logistics',
            slug: 'smartr-logistics',
        },
    ];

    public static additioalCustomShipmentStatus = [
        { shipment_status: 'Dispatched from India Hub', status_index: 11 },
        { shipment_status: 'Order Delivered', status_index: 12 },
    ];
}

export const marketplaces = [
    // {
    //     'id': 'A2EUQ1WTGCTBG2',
    //     'countryCode': 'CA',
    //     'awsRegion': 'us-east-1',
    //     'sellerCentralURL': 'https://sellercentral.amazon.ca',
    //     'imageurl': 'assets/img/CA.png'
    // },
    // {
    //     'id': 'ATVPDKIKX0DER',
    //     'countryCode': 'US',
    //     'awsRegion': 'us-east-1',
    //     'sellerCentralURL': 'https://sellercentral.amazon.com',
    //     'imageurl': 'assets/img/US.png'
    // },
    // {
    //     'id': 'A1AM78C64UM0Y8',
    //     'countryCode': 'MX',
    //     'awsRegion': 'us-east-1',
    //     'sellerCentralURL': 'https://sellercentral.amazon.com.mx',
    //     'imageurl': 'assets/img/MX.png'
    // },
    // {
    //     'id': 'A2Q3Y263D00KWC',
    //     'countryCode': 'BR',
    //     'awsRegion': 'us-east-1',
    //     'sellerCentralURL': 'https://sellercentral.amazon.com.br',
    //     'imageurl': 'assets/img/BR.png'
    // },
    // {
    //     'id': 'A1RKKUPIHCS9HS',
    //     'countryCode': 'ES',
    //     'awsRegion': 'eu-west-1',
    //     'sellerCentralURL': 'https://sellercentral-europe.amazon.com',
    //     'imageurl': 'assets/img/ES.png'
    // },
    // {
    //     'id': 'A1F83G8C2ARO7P',
    //     'countryCode': 'UK',
    //     'awsRegion': 'eu-west-1',
    //     'sellerCentralURL': 'https://sellercentral-europe.amazon.com',
    //     'imageurl': 'assets/img/UK.png'
    // },
    // {
    //     'id': 'A13V1IB3VIYZZH',
    //     'countryCode': 'FR',
    //     'awsRegion': 'eu-west-1',
    //     'sellerCentralURL': 'https://sellercentral-europe.amazon.com',
    //     'imageurl': 'assets/img/FR.png'
    // },
    // {
    //     'id': 'AMEN7PMS3EDWL',
    //     'countryCode': 'BE',
    //     'awsRegion': 'eu-west-1',
    //     'sellerCentralURL': 'https://sellercentral.amazon.com.be',
    //     'imageurl': 'assets/img/BE.png'
    // },
    // {
    //     'id': 'A1805IZSGTT6HS',
    //     'countryCode': 'NL',
    //     'awsRegion': 'eu-west-1',
    //     'sellerCentralURL': 'https://sellercentral.amazon.nl',
    //     'imageurl': 'assets/img/NL.png'
    // },
    // {
    //     'id': 'A1PA6795UKMFR9',
    //     'countryCode': 'DE',
    //     'awsRegion': 'eu-west-1',
    //     'sellerCentralURL': 'https://sellercentral-europe.amazon.com',
    //     'imageurl': 'assets/img/DE.png'
    // },
    // {
    //     'id': 'APJ6JRA9NG5V4',
    //     'countryCode': 'IT',
    //     'awsRegion': 'eu-west-1',
    //     'sellerCentralURL': 'https://sellercentral-europe.amazon.com',
    //     'imageurl': 'assets/img/IT.png'
    // },
    // {
    //     'id': 'A2NODRKZP88ZB9',
    //     'countryCode': 'SE',
    //     'awsRegion': 'eu-west-1',
    //     'sellerCentralURL': 'https://sellercentral.amazon.se',
    //     'imageurl': 'assets/img/SE.png'
    // },
    // {
    //     'id': 'A21TJRUUN4KGV',
    //     'countryCode': 'PL',
    //     'awsRegion': 'eu-west-1',
    //     'sellerCentralURL': 'https://sellercentral.amazon.pl',
    //     'imageurl': 'assets/img/PL.png'
    // },
    // {
    //     'id': 'ARBP9OOSHTCHU',
    //     'countryCode': 'EG',
    //     'awsRegion': 'eu-west-1',
    //     'sellerCentralURL': 'https://sellercentral.amazon.eg',
    //     'imageurl': 'assets/img/EG.png'
    // },
    // {
    //     'id': 'A33AVAJ2PDY3EV',
    //     'countryCode': 'TR',
    //     'awsRegion': 'eu-west-1',
    //     'sellerCentralURL': 'https://sellercentral.amazon.com.tr',
    //     'imageurl': 'assets/img/TR.png'
    // },
    // {
    //     'id': 'A17E79C6D8DWNP',
    //     'countryCode': 'SA',
    //     'awsRegion': 'eu-west-1',
    //     'sellerCentralURL': 'https://sellercentral.amazon.sa',
    //     'imageurl': 'assets/img/SA.png'
    // },
    // {
    //     'id': 'A2VIGQ35RCS4UG',
    //     'countryCode': 'AE',
    //     'awsRegion': 'eu-west-1',
    //     'sellerCentralURL': 'https://sellercentral.amazon.ae',
    //     'imageurl': 'assets/img/AE.png'
    // },
    {
        'id': 'A21TJRUUN4KGV',
        'countryCode': 'IN',
        'awsRegion': 'eu-west-1',
        'sellerCentralURL': 'https://sellercentral.amazon.in',
        'imageurl': 'assets/img/IN.png'
    },
    // {
    //     'id': 'A19VAU5U5O7RUS',
    //     'countryCode': 'SG',
    //     'awsRegion': 'us-west-2',
    //     'sellerCentralURL': 'https://sellercentral.amazon.sg',
    //     'imageurl': 'assets/img/SG.png'
    // },
    // {
    //     'id': 'A39IBJ37TRP1C6',
    //     'countryCode': 'AU',
    //     'awsRegion': 'us-west-2',
    //     'sellerCentralURL': 'https://sellercentral.amazon.com.au',
    //     'imageurl': 'assets/img/AU.png'
    // },
    // {
    //     'id': 'A1VC38T7YXB528',
    //     'countryCode': 'JP',
    //     'awsRegion': 'us-west-2',
    //     'sellerCentralURL': 'https://sellercentral.amazon.co.jp',
    //     'imageurl': 'assets/img/JP.png'
    // },
];
