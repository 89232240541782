<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Profile</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Profile</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<div class="sign-in-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Update Your Account!</h2>
        </div>

        <div class="sign-in-form">
            <form [formGroup]="userProfileFormGroup">

                <!-- <div class="flex flex-col w-full lg:w-1/2 md:w-1/2 sm:w-1/2 lg:pr-2 md:pr-2 sm:pr-2">
                    <div class="relative w-24 h-24">
                        <ng-container *ngIf="selectedImage; else noImage">
                            <img class="w-full h-full rounded-full" style=" border-radius: 50%;"
                                [src]="imagePath + selectedImage" alt="User profile photo" />
                        </ng-container>
                        <ng-template #noImage>
                            <iconify-icon icon="bx:user-circle" height="100%" width="100%"></iconify-icon>
                        </ng-template>
                    </div>

                    <div class=" mt-10 mb-5">
                        <button type="button" class="btn default-btn-one" (click)="selectFile.click()">
                            <i class='bx bx-upload'></i>
                            {{'profile.upload_image' | translate }}
                        </button>
                        <input class="upload" name="image" type="file" hidden #selectFile
                            (change)="onFileUpload($event)" /><br />
                    </div>
                </div> -->
                <div class="form-group">
                    <input type="text" class="form-control" id="first_name" placeholder="First name"
                        formControlName="first_name" name="first_name">
                    <div *ngIf="
                                    userProfileFormGroup.controls['first_name'].invalid &&
                                    (userProfileFormGroup.controls['first_name'].dirty ||
                                        userProfileFormGroup.controls['first_name'] .touched || isSubmit)
                                " style="color: red;">
                        <span *ngIf="
                                        userProfileFormGroup.controls['first_name'].errors
                                            .required
                                    ">
                            Please enter your first name!
                        </span>
                    </div>
                </div>

                <div class="form-group">
                    <input type="text" class="form-control" id="last_name" placeholder="Last name"
                        formControlName="last_name" name="last_name">
                    <div *ngIf="
                                    userProfileFormGroup.controls['last_name'].invalid &&
                                    (userProfileFormGroup.controls['last_name'].dirty ||
                                        userProfileFormGroup.controls['last_name'] .touched || isSubmit)
                                " style="color: red;">
                        <span *ngIf="
                                        userProfileFormGroup.controls['last_name'].errors
                                            .required
                                    ">
                            Please enter your last name!
                        </span>
                    </div>
                </div>

                <div class="form-group">
                    <input type="email" class="form-control" id="email" placeholder="Email" formControlName="email"
                        name="email">
                    <div *ngIf="
                                    userProfileFormGroup.controls['email'].invalid &&
                                    (userProfileFormGroup.controls['email'].dirty ||
                                        userProfileFormGroup.controls['email'] .touched || isSubmit)
                                " style="color: red;">
                        <span *ngIf="
                                        userProfileFormGroup.controls['email'].errors
                                            .required
                                    ">
                            Please enter your email!
                        </span>
                        <span *ngIf="
                                        userProfileFormGroup.controls['email'].errors
                                            .email
                                    ">
                            Please enter your email address!
                        </span>
                        <span *ngIf="userProfileFormGroup.controls['email'].errors.email">
                            Please enter your email address!
                        </span>
                    </div>
                </div>

                <div class="form-group">
                    <input type="number" (wheel)="stopScrollingWheel($event)" class="form-control" id="phone_no"
                        placeholder="Phone Number" formControlName="phone_no" name="phone_no">
                    <div *ngIf="
                                    userProfileFormGroup.controls['phone_no'].invalid &&
                                    (userProfileFormGroup.controls['phone_no'].dirty ||
                                        userProfileFormGroup.controls['phone_no'] .touched || isSubmit)
                                " style="color: red;">
                        <span *ngIf="
                                        userProfileFormGroup.controls['phone_no'].errors
                                            .required
                                    ">
                            Phone number is Required!
                        </span>
                    </div>
                </div>

                <div class="text-center">
                    <button type="button" class="btn default-btn-one" (click)="updateProfile()"
                        [disabled]="isDisableFlow">Update</button>
                    <a class="account-decs" onMouseOver="this.style.textDecoration='underline'"
                        onMouseOut="this.style.textDecoration='none'"
                        style="color:#83ab17; font-weight: 700; cursor: pointer; margin-left: 5px;"
                        (click)="isChangePassword = !isChangePassword">
                        <span>
                            {{ isChangePassword ? "Don't want" : "Want" }} to change password?</span>
                    </a>
                </div>
            </form>

            <form [formGroup]="changePasswordFormGroup" *ngIf="isChangePassword" style="margin-top: 10px;">
                <div class="form-group">
                    <input type="password" class="form-control" id="old_password" placeholder="Current Password"
                        formControlName="old_password" name="old_password" required autocomplete="new-password">
                    <div *ngIf="
                                    changePasswordFormGroup.controls['old_password'].invalid &&
                                    (changePasswordFormGroup.controls['old_password'].dirty ||
                                        changePasswordFormGroup.controls['old_password'] .touched || isSubmit)
                                " style="color: red;">
                        <span *ngIf="
                                        changePasswordFormGroup.controls['old_password'].errors
                                            .required
                                    ">
                            Current Password is Required!
                        </span>
                    </div>
                </div>

                <div class="form-group">
                    <input type="password" class="form-control" id="password" placeholder="New Password"
                        formControlName="password" name="password">
                    <div *ngIf="
                                    changePasswordFormGroup.controls['password'].invalid &&
                                    (changePasswordFormGroup.controls['password'].dirty ||
                                        changePasswordFormGroup.controls['password'] .touched || isSubmit)
                                " style="color: red;">
                        <span *ngIf=" changePasswordFormGroup.controls['password'].errors.required">
                            New Password
                        </span>
                        <span *ngIf="changePasswordFormGroup.controls['password'].errors?.minlength">
                            Password must be at least 8 characters long.
                        </span>
                    </div>
                </div>

                <div class="form-group">
                    <input type="password" class="form-control" id="confirmPassword" placeholder="Confirm Pasword"
                        formControlName="confirmPassword" name="confirmPassword">
                    <div *ngIf="
                                    changePasswordFormGroup.controls['confirmPassword'].invalid &&
                                    (changePasswordFormGroup.controls['confirmPassword'].dirty ||
                                        changePasswordFormGroup.controls['confirmPassword'] .touched || isSubmit)
                                " style="color: red;">
                        <span *ngIf="
                                        changePasswordFormGroup.controls['confirmPassword'].errors
                                            .required
                                    ">
                            Confirm Password is Required!
                        </span>
                    </div>
                </div>
                <div class="text-center">
                    <button type="button" class="btn default-btn-one" (click)="changePassword()">Change</button>
                </div>
            </form>
        </div>
    </div>
</div>