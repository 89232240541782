import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { switchMap, tap } from 'rxjs/operators';
import { Constants } from 'app/shared/constant';
import { Pagination } from 'app/components/common/pagination/pagination.types';
import { ContactUs } from 'app/models/contact-us.model';

@Injectable({
    providedIn: 'root',
})
export class ContactUsService {
    pageSize: any = Constants.pageLimit;

    // Private
    private _pagination: BehaviorSubject<Pagination | null> =
        new BehaviorSubject(null);

    private _contactus: BehaviorSubject<ContactUs[] | null> =
        new BehaviorSubject(null);

    constructor(
        private _httpClient: HttpClient,
    ) { }

    /**
     * Getter for pagination
     */
    get pagination$(): Observable<Pagination> {
        return this._pagination.asObservable();
    }

    /**
     * Getter for contactus
     */
    get contactus$(): Observable<ContactUs[]> {
        return this._contactus.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get customers
     *
     *
     * @param page
     * @param size
     * @param sort
     * @param order
     * @param search
     */
    getContactList(
        page: number = 1,
        size: number = this.pageSize,
        sort: string = 'createdAt',
        order: 'asc' | 'desc' | '' = 'desc',
        search: string = '',
        filterQuery: any = {}
    ): Observable<{ pagination: Pagination; contacts: ContactUs[] }> {
        return this._httpClient
            .get<{ pagination: Pagination; contacts: ContactUs[] }>(
                environment.apiBaseUrl + '/contact/getContact',
                {
                    params: {
                        page: page,
                        limit: size,
                        sortBy: `${sort}:${order || 'desc'}`,
                        search,
                        ...filterQuery,
                    },
                }
            )
            .pipe(
                tap((response) => {
                    this._pagination.next(response.pagination);
                    this._contactus.next(response.contacts);
                })
            );
    }


    /**
     * Add Contact
     */
    createContact(formData: ContactUs): any {
        return this._httpClient
            .post(environment.apiBaseUrl + '/contact', formData)
            .pipe(
                switchMap((response: any) => {
                    if (response.status === 200) {
                        return of(response);
                    } else {
                        return throwError(response);
                    }
                })
            );
    }

    deleteContactById(id): any {
        return this._httpClient
            .delete(environment.apiBaseUrl + `/contact/deleteContact/${id}`)
            .pipe(
                switchMap((response: any) => {
                    if (response.status === 200) {
                        return of(response);
                    } else {
                        return throwError(response);
                    }
                })
            );
    }

}
