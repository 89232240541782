import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
// import { UserService } from 'src/app/services/user.service';
// import { ProfileServiceService } from 'src/app/services/profile-service.service';
// import { User } from 'src/app/models/user';
// import { PasswordValidation } from '../../common/password-validators';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { CustomerService } from 'app/services/customer.service';
import { LocalService } from 'app/services/local.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  userInput: any[] = [];
  imagePath = environment.imageUrl;
  isSuperAdmin: boolean;
  permission: any;
  selectedFile: File;
  id: string;
  langData;
  isLoading: boolean = false; isSubmit = false;
  isChangePassword: boolean = false;
  userProfileFormGroup: FormGroup;
  changePasswordFormGroup: FormGroup;
  selectedImage: string = '';
  isShow: boolean = false;
  isDisableFlow: boolean = false;
  today = new Date();
  userInfo: any = '';
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _formBuilder: FormBuilder,
    private _localService: LocalService,
    private _registerService: CustomerService,
    private _activatedRoute: ActivatedRoute,
    // private _userService: UserService,
    private toaster: ToastrService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this.userInfo = this._localService.getJsonValue('user');

    this.langData = localStorage.getItem('lang') || 'en';
    this.userProfileFormGroup = this._formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_no: ['', Validators.required],
    });

    this._activatedRoute.params.subscribe((res: any) => {
      if (!res) {
        return;
      }
      this.id = res.resetid;
    });
    this.changePasswordFormGroup = new FormGroup({
      old_password: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl('', [Validators.required]),
    });

    // if (this.userInfo !== null) {
    this._registerService.getUserbyId(this.userInfo?._id).subscribe(
      (res: any) => {
        this.userProfileFormGroup.patchValue(res.data);
        this._changeDetectorRef.markForCheck();
        this.isShow = true;
      },
      (err) => {
        this.userInput = [];
      }
    );
    // }
  }

  getProfile(): any {
    this._changeDetectorRef.markForCheck();

    // this._registerService.getUserById(this.userInfo._id).subscribe(
    //   (res: any) => {
    //     this.userProfileFormGroup.patchValue(res.data);
    //     this.selectedImage = res.data.avatar;
    //   },
    //   (err) => {
    //     this.userInput = [];
    //   }
    // );
  }

  // // -----------------------------------------------------------------------------------------------------
  // // @ Public methods
  // // -----------------------------------------------------------------------------------------------------

  updateProfile(): void {
    if (this.userProfileFormGroup.invalid) {
      return;
    }

    // Get the user form data
    const formData = this.userProfileFormGroup.getRawValue();
    this.isDisableFlow = true;
    this.isLoading = true;
    this._registerService
      .updateUserbyId(this.userInfo._id, formData)
      .subscribe(
        (data: any) => {
          this.isDisableFlow = false;
          this.getProfile();
          this.isLoading = false;

          // this.userInfo['name'] = formData.name;
          // this.userInfo['email'] = formData.email;

          // this._userService.user = this.userInfo;
          this.toaster.success('Profile has been updated successfully.', '');
          this._changeDetectorRef.markForCheck();
        },
        (err) => {
          this.isDisableFlow = false;
          this.isLoading = false;
          this.toaster.error('Some went wrong! please check you profile again.', '');
        }
      );
  }

  changePassword(): void {
    if (this.changePasswordFormGroup.invalid) {
      this.isSubmit = true;
      return;
    }
    // Get the user form data
    if (this.changePasswordFormGroup.value.password === this.changePasswordFormGroup.value.confirmPassword) {
      const formData = this.changePasswordFormGroup.getRawValue();
      this.isDisableFlow = true;
      this.isLoading = true;
      formData['userId'] = this.userInfo._id;
      this._registerService
        .updatePassword(formData)
        .subscribe(
          () => {
            this._changeDetectorRef.markForCheck();
            this.isDisableFlow = false;
            this.isLoading = false;
            this.isChangePassword = false;
            this.changePasswordFormGroup.reset();

            this.toaster.success('Password has been changed successfully.', '');

            this._router.navigate(['/sign-in']);
          },
          (err) => {
            // Re-enable the form
            this.changePasswordFormGroup.enable();

            // Reset the form
            // this.changePasswordFormGroup.reset();
            this.isDisableFlow = false;
            this.isLoading = false;
            // Set the alert
            this.toaster.error('Current Password is wrong.', '');

          }
        );
    } else {
      this.toaster.error('Password has been not matched.', '');

    }
  }

  onFileUpload(event): any {
    this.selectedFile = event.target.files[0];

    const imageSize = this.selectedFile.size;
    if (imageSize > 1024000) {
    }

    const allowedExtension = ['jpeg', 'jpg', 'png'];
    const mimeType = this.selectedFile.type.split('/')[1];
    if (!allowedExtension.includes(mimeType)) {
      if (this.langData === 'en') {
        this.toaster.error('Please select only JPEG/JPG/PNG file', '');
      } else {
        this.toaster.error('Veuillez sélectionner uniquement le fichier JPEG/JPG/PNG', '');
      }
      this._changeDetectorRef.markForCheck();
      return;
    }

    const readers = new FileReader();
    readers.readAsDataURL(this.selectedFile);
    this.OnUploadFile();
  }

  OnUploadFile(): any {
    const uploadFormData = new FormData();
    uploadFormData.append('avatar', this.selectedFile);

    // this._profileService
    //   .uploadImage(this.userInfo._id, uploadFormData)
    //   .subscribe((data: any) => {
    //     this.selectedImage = data.data.avatar;
    //     this._changeDetectorRef.markForCheck();
    //     this.userInfo["avatar"] = this.selectedImage;
    //     this._localService.setJsonValue('user', this.userInfo);
    //     this._userService.user = this.userInfo;
    //   });
  }

  ngOnDestroy(): void {
    //   // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  stopScrollingWheel(e): any {
    return e.target.blur();
  }
}
