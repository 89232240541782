<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Sign Up</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Sign Up</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Start Sign up Area -->
<div class="sign-up-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Create An Account!</h2>
        </div>

        <div class="sign-up-form">
            <form [formGroup]="registerForm">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" id="first_name" placeholder="First Name"
                                formControlName="first_name">
                            <div *ngIf="registerForm.controls['first_name'].invalid &&
                                                                (registerForm.controls['first_name'].dirty ||
                                                                    registerForm.controls['first_name'].touched || isSubmit)
                                                            " style="color: red;">

                                <span *ngIf="
                                                                    registerForm.controls['first_name'].errors
                                                                        .required
                                                                ">
                                    Please enter your first name!
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" id="last_name" placeholder="Last Name"
                                formControlName="last_name">
                            <div *ngIf="
                                                                                    registerForm.controls['last_name'].invalid &&
                                                                                    (registerForm.controls['last_name'].dirty ||
                                                                                        registerForm.controls['last_name'].touched || isSubmit)
                                                                                " style="color: red;">
                                <span *ngIf="
                                                                                        registerForm.controls['last_name'].errors
                                                                                            .required
                                                                ">
                                    Please enter your last name!
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <input type="number" (wheel)="stopScrollingWheel($event)" class="form-control"
                        placeholder="Enter Your Phone No" formControlName="phone_no">
                    <div *ngIf="
                                                        registerForm.controls['phone_no'].invalid &&
                                                        (registerForm.controls['phone_no'].dirty ||
                                                            registerForm.controls['phone_no'].touched || isSubmit)
                                                    " style="color: red;">
                        <span *ngIf="
                                                            registerForm.controls['phone_no'].errors
                                                                .required
                                                        ">
                            Please enter your phone number!
                        </span>

                    </div>
                </div>

                <div class="form-group">
                    <input type="email" class="form-control" id="email" placeholder="Email Address"
                        formControlName="email" name="email">
                    <div *ngIf="
                                                        registerForm.controls['email'].invalid &&
                                                        (registerForm.controls['email'].dirty ||
                                                            registerForm.controls['email'].touched || isSubmit)
                                                    " style="color: red;">
                        <span *ngIf="
                                                            registerForm.controls['email'].errors
                                                                .required
                                                        ">
                            Please enter your email address!
                        </span>
                        <span *ngIf="registerForm.controls['email'].errors.email">
                            Please enter a valid email address!
                        </span>
                    </div>
                </div>

                <div class="form-group">
                    <div style="display: flex;  align-items: center; position: relative;">
                        <input type="password" class="form-control" formControlName="password" id="password"
                            placeholder="Password" autocomplete="new-password" #passwordField>
                        <button style="position: absolute;right: 0.5rem" type="button" (click)="
                                                                                            passwordField.type === 'password'
                                                                                                ? (passwordField.type = 'text')
                                                                                                : (passwordField.type = 'password')
                                                                                        " matSuffix>
                            <i class="far fa-eye" *ngIf="passwordField.type === 'password'"></i>
                            <i class="fa fa-eye-slash" *ngIf="passwordField.type === 'text'"></i>
                        </button>
                    </div>
                    <div *ngIf="
                                                        registerForm.controls['password'].invalid &&
                                                        (registerForm.controls['password'].dirty ||
                                                            registerForm.controls['password'].touched || isSubmit)
                                                    " style="color: red;">
                        <span *ngIf="
                                                            registerForm.controls['password'].errors
                                                                .required
                                                        ">
                            Please enter your password!
                        </span>
                        <span *ngIf="registerForm.controls['password'].errors?.minlength">
                            Password must be at least 8 characters long.
                        </span>
                    </div>
                </div>

                <div class="form-group">
                    <input type="password" class="form-control" id="password" placeholder="Confirm Password"
                        formControlName="confirm_password">
                    <div *ngIf="
                                                        registerForm.controls['confirm_password'].invalid &&
                                                        (registerForm.controls['confirm_password'].dirty ||
                                                            registerForm.controls['confirm_password'].touched || isSubmit)
                                                    " style="color: red;">
                        <span *ngIf="
                                                            registerForm.controls['confirm_password'].errors
                                                                .required
                                                        ">
                            Please enter your Confirm Password!
                        </span>
                    </div>
                </div>

                <div class="form-group">
                    <input type="address" class="form-control" id="address" formControlName="address" name="address"
                        placeholder="Address">
                    <div *ngIf="registerForm.controls['address'].invalid &&(registerForm.controls['address'].dirty || registerForm.controls['address'].touched || isSubmit) "
                        style="color: red;">
                        <span *ngIf="registerForm.controls['address'].errors.required">
                            Please enter your address!
                        </span>
                    </div>
                </div>

                <div> Gender:
                    Male:
                    <input type="radio" name="gender" value="Male" formControlName="gender" class="mr-3" />
                    Female:
                    <input type="radio" name="gender" formControlName="gender" value="Female" />
                    <br><br>

                </div>

                <div class="text-center">
                    <button class="btn default-btn-one" type="button" (click)="createUsers()">Sign Up</button>
                    <p class="account-decs">Already have an account?<a routerLink="/sign-in">Sign In</a></p>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Sign up  Area -->