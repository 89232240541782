import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalService } from 'app/services/local.service';
import { PaymentService } from 'app/services/payment.service';

@Component({
  selector: 'app-checkout-thankyou',
  templateUrl: './checkout-thankyou.component.html',
  styleUrls: ['./checkout-thankyou.component.scss']
})
export class CheckoutThankyouComponent implements OnInit {

  isLoggedIn: boolean = false;
  isPaymentSuccess: boolean = false;
  constructor(private _paymentService: PaymentService, private route: ActivatedRoute, private router: Router, private _localService: LocalService) { }

  ngOnInit(): void {
    this.isLoggedIn = this._localService.isLoggedIn();
    const currentUser = this._localService.customerDetail();
    if (this.isLoggedIn) {
      this._paymentService.fetchPaymentIntent(this.route.snapshot.paramMap.get('payment_id'), currentUser.email).subscribe({
        next: () => {
          this.isPaymentSuccess = true;
        }, error: ({ error }) => {
          this.router.navigate(['/error']);
        }
      });
    }
  }

}
