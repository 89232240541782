import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prohibited-items',
  templateUrl: './prohibited-items.component.html',
  styleUrls: ['./prohibited-items.component.scss']
})
export class ProhibitedItemsComponent implements OnInit {

  showAnswer: boolean[] = [false, false];
  selectedCountry: string = 'coun1';
  constructor() { }

  toggleAnswer(index: number, flag): void {
    this.showAnswer = [false, false];

    if (index === 0) {
      this.showAnswer[0] = !flag;
    } else {
      this.showAnswer[1] = !flag;
    }
  }

  onChangeCountry(event: any): void {
    this.selectedCountry = event.target.value;
  }
  ngOnInit(): void {}

}
