import { Component, OnInit } from '@angular/core';
import { LocalService } from 'app/services/local.service';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  constructor(private _localService: LocalService) { }

  ngOnInit(): void {
    // this._localService.isShowNavigation.next(false);
  }

}
