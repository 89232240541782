import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminUserService } from 'app/services/admin/user.service';
import { LocalService } from 'app/services/local.service';
import { CustomerService } from 'app/services/customer.service';

@Component({
  selector: 'app-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.scss']
})
export class AdminNavbarComponent implements OnInit {

  isShow: boolean = false;
  constructor(
    private _loginService: CustomerService,
    private _adminUserService: AdminUserService,
    private _localService: LocalService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this._adminUserService.isLogin.subscribe((resAd) => {
      if (resAd) {
        this.isShow = true;
      }
    });
  }

  logOut(): void {
    this._localService.clearValues();
    this._router.navigate(['/admin']);
    this._loginService.isLogin.next(false);
    this.isShow = false;
  }

}
