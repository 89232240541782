import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerService } from 'app/services/customer.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  registerForm: FormGroup; isSubmit = false;
  constructor(
    private toaster: ToastrService,
    private _router: Router,
    private _registerService: CustomerService,
  ) { }

  ngOnInit(): void {
    this.registerForm = new FormGroup({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      phone_no: new FormControl('', [Validators.required,]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirm_password: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
    });
  }

  createUsers(): void {
    if (this.registerForm.invalid) {
      // this.toaster.error('Fill all required fields.', '');
      this.isSubmit = true;
      return;
    }
    const formData = this.registerForm.value;
    if (formData.password !== '' && formData.confirm_password === '') {
      this.toaster.error('Please enter confirm password!', '');
      return;
    }
    if (formData.password !== formData.confirm_password) {
      this.toaster.error('Password and confirm password does not match!', '');
      return;
    }
    this._registerService
      .createUser(formData)
      .subscribe((res) => {
        this.registerForm.reset();
        this.toaster.success('User Register Successfully...', '');
        setTimeout(() => {
          this._router.navigate(['/sign-in']);
        }, 1500);
      }, (error) => {
        this.toaster.error(error.user.message || 'Something went wrong', '');
      });
  }

  stopScrollingWheel(e): any {
    return e.target.blur();
  }

}
