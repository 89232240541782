import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'app/services/customer.service';

@Component({
    selector: 'auth-reset-password',
    templateUrl: './reset-password.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AuthResetPasswordComponent implements OnInit {
    @ViewChild('resetPasswordNgForm') resetPasswordNgForm: NgForm;

    resetPasswordForm: FormGroup;
    showAlert: boolean = false;
    token: string; isSubmit = false;
    /**
     * Constructor
     */
    constructor(
        private _registerService: CustomerService,
        private _activatedRoute: ActivatedRoute,
        private toaster: ToastrService,
        private _router: Router,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._activatedRoute.params.subscribe((res: any) => {
            if (!res) { return; }
            this.token = res.token;
        });
        // Create the form
        this.resetPasswordForm = new FormGroup({
            password: new FormControl('', [Validators.required, Validators.minLength(8)]),
            passwordConfirm: new FormControl('', [Validators.required]),
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Reset password
     */
    resetPassword(): void {
        // Return if the form is invalid
        if (this.resetPasswordForm.invalid) {
            // this.toaster.error('Fill all required field.', '');
            this.isSubmit = true;
            return;
        }
        if (this.resetPasswordForm.value.password !== this.resetPasswordForm.value.passwordConfirm) {
            this.toaster.error('Password and confirm password does not match!', '');
            return;
        }

        // Disable the form
        this.resetPasswordForm.disable();

        // Hide the alert
        this.showAlert = false;

        // Send the request to the server
        this._registerService
            .resetPassword(
                this.token,
                { password: this.resetPasswordForm.get('password').value }
            )
            .pipe(
                finalize(() => {
                    // Re-enable the form
                    this.resetPasswordForm.enable();

                    // Reset the form
                    this.resetPasswordNgForm.resetForm();
                })
            )
            .subscribe(
                (response) => {
                    this._router.navigate(['/sign-in']);
                },
                (err) => {
                    this.toaster.error(err.message, '');
                }
            );
    }


}
