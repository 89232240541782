<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Reset Password</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Reset Password</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Start Sign in Area -->
<div class="sign-in-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Reset your password </h2>
            <p>Create a new password for your account </p>
        </div>

        <div class="sign-in-form">
            <form [formGroup]="resetPasswordForm" #resetPasswordNgForm="ngForm">


                <div class="form-group">
                    <input type="password" class="form-control" id="password" [formControlName]="'password'"
                        autocomplete="new-password" placeholder="Password" name="password">
                    <div *ngIf="resetPasswordForm.controls['password'].invalid &&
                                    (resetPasswordForm.controls['password'].dirty ||
                                        resetPasswordForm.controls['password'].touched || isSubmit)
                                " style="color: red;">
                        <span *ngIf="resetPasswordForm.controls['password'].errors.required">
                            Please enter a password!
                        </span>
                        <span *ngIf="resetPasswordForm.controls['password'].errors?.minlength">
                            Password must be at least 8 characters long.
                        </span>
                    </div>
                </div>

                <div class="form-group">
                    <input type="password" class="form-control" id="password-confirm"
                        [formControlName]="'passwordConfirm'" placeholder="Password (Confirm)" name="password-confirm">
                    <div *ngIf="resetPasswordForm.controls['passwordConfirm'].invalid &&
                                    (resetPasswordForm.controls['passwordConfirm'].dirty ||
                                        resetPasswordForm.controls['passwordConfirm'].touched || isSubmit)
                                " style="color: red;">
                        <span *ngIf="resetPasswordForm.controls['passwordConfirm'].errors.required">
                            Please enter a confirmation Password!
                        </span>
                    </div>
                </div>

                <div class="text-center">
                    <button type="button" class="btn default-btn-one" (click)="resetPassword()">
                        Reset your password
                    </button>

                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Sign in  Area -->