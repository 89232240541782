import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination } from 'app/components/common/pagination/pagination.types';
import { OrderFile } from 'app/models/admin/order-file.model';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CustomerDetails } from 'app/models/admin/customer-details.model';
import { Constants } from 'app/shared/constant';

@Injectable({
    providedIn: 'root',
})
export class QuotationDetailsService {
    pageSize: any = Constants.pageLimit;
    userInfo: any = '';
    adminInfo: any = '';
    url: string;
    private _pagination: BehaviorSubject<Pagination | null> =
        new BehaviorSubject(null);
    private _quotationDetails: BehaviorSubject<CustomerDetails[] | null> =
        new BehaviorSubject(null);
    constructor(
        private _httpClient: HttpClient,
    ) {}
    get pagination$(): Observable<Pagination> {
        return this._pagination.asObservable();
    }

    get quotationDetails$(): Observable<CustomerDetails[]> {
        return this._quotationDetails.asObservable();
    }

	createOrder(formData: any): any {
		return this._httpClient.post(environment.apiBaseUrl + '/quotation/add', formData).pipe(
		  switchMap((response: any) => {
			if (response.status === 200) {
			  return of(response);
			} else {
			  return throwError(response);
			}
		  })
		);
	  }

}
