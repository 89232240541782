import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-regulations',
  templateUrl: './regulations.component.html',
  styleUrls: ['./regulations.component.scss']
})
export class RegulationsComponent implements OnInit {

  selectedCountry: string = 'coun1';
  constructor() { }

  onChangeCountry(event: any): void {
    this.selectedCountry = event.target.value;
  }

  ngOnInit(): void {

  }

}
