<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Fuel Surcharge</h2>
                    <!-- <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Air fright</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->


<!-- Single Service Area -->
<div class="fuel-content">
    <h2>Fuel Surcharge</h2>
    <marquee>Fuel Surcharge for Nov 11.5% Fuel Surcharge for Oct 10% Fuel Surcharge for Sept 10% Fuel Surcharge for Aug
        10% Fuel Surcharge for July 9% Fuel Surcharge for June 8%.</marquee>
    <p>A fuel extra charge is an additional charge that shipping companies (or third parties) charge to cover the
        fluctuating taken a toll of fuel. It is calculated as a rate of the base rate and is as a rule included to a
        shipper’s cargo charge to cover the fetched of operations. The fuel additional charge depends on the normal fuel
        cost and can be distinctive for each shipper or industry, depending on fuel taken a toll to income proportion.
        It
        covers extra fuel costs and keeps carriers beneficial, indeed when the taken a toll of fuel rises.

    </p>
    <p>No government organization controls a fuel extra charge approach. Each shipper and carrier independently arrange
        and set it in contracts. There's a tremendous space for extortion – there are no legitimate prerequisites to
        control passing collected fuel charges from a shipper to a individual who really pays for fuel for shipper’s
        stack. Fuel is one of the most noteworthy costs for a carrier, along side drivers’ pay. Employing a extra charge
        bolsters arrangement on long-term contracts, where base rates stay the same and the fuel additional charge acts
        as security from short-term fuel cost changes.</p>

    <h4>Contact us for more data on Fuel Extra charge and how it influences your Shipping costs.</h4>
</div>
<!-- End Single Service Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <!-- <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required=""
                            autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->
