import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-duties-taxes',
  templateUrl: './duties-taxes.component.html',
  styleUrls: ['./duties-taxes.component.scss']
})
export class DutiesTaxesComponent implements OnInit {

  selectedCountry: string = 'coun1';
  constructor() { }

  onChangeCountry(event: any): void {
    this.selectedCountry = event.target.value;
  }
  ngOnInit(): void {
  }

}
