import { ExpressServicesComponent } from './components/pages/express-services/express-services.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { AirFrightServiceComponent } from './components/pages/air-fright-service/air-fright-service.component';
import { LandTransportServiceComponent } from './components/pages/land-transport-service/land-transport-service.component';
import { OceanFrightServiceComponent } from './components/pages/ocean-fright-service/ocean-fright-service.component';
import { CargoStorageComponent } from './components/pages/cargo-storage/cargo-storage.component';
import { ECommerceServiceComponent } from './components/pages/e-commerce-service/e-commerce-service.component';
import { DomesticTruckingServicesComponent } from './components/pages/domestic-trucking-services/domestic-trucking-services.component';
import { WarehousingServiceComponent } from './components/pages/warehousing-service/warehousing-service.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { AuthResetPasswordComponent } from './components/pages/reset-password/reset-password.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthForgotPasswordComponent } from './components/pages/forgot-password/forgot-password.component';
import { ProhibitedItemsComponent } from './components/pages/prohibited-items/prohibited-items.component';
import { VolumetricCalculatorComponent } from './components/pages/volumetric-calculator/volumetric-calculator.component';
import { RateTransitTimesComponent } from './components/pages/rate-transit-times/rate-transit-times.component';
import { FuelSurchargeComponent } from './components/pages/fuel-surcharge/fuel-surcharge.component';
import { RegulationsComponent } from './components/pages/regulations/regulations.component';
import { DutiesTaxesComponent } from './components/pages/duties-taxes/duties-taxes.component';
import { TrackOrderComponent } from './components/pages/track-order/track-order.component';
import { NoAuthGuard } from './guards/noAuth.guard';
import { AmazonPageComponent } from './components/pages/amazon-page/amazon-page.component';
import { PaymentsComponent } from './components/pages/payments/payments.component';
import { CheckoutThankyouComponent } from './components/pages/checkout-thankyou/checkout-thankyou.component';
import { DashboardComponent } from './components/pages/admin/dashboard/dashboard.component';
import { DevloperDocumentComponent } from './components/pages/devloper-document/devloper-document';
import { QuotationComponent } from './components/pages/quotation/quotation.component';
import { PriceDetailsComponent } from './components/pages/price-details/price-details.component';

const routes: Routes = [
    { path: 'home-first', component: HomeOneComponent },
    // { path: '', component: HomeTwoComponent },
    { path: 'home-three', component: HomeThreeComponent },
    { path: 'about', component: AboutComponent },
    { path: 'team', component: TeamComponent },
    { path: 'testimonials', component: TestimonialsComponent },
    { path: 'gallery', component: GalleryComponent },
    { path: 'faq', component: FaqComponent },
    { path: 'sign-in', canActivate: [NoAuthGuard], component: SignInComponent },
    { path: 'sign-up', canActivate: [NoAuthGuard], component: SignUpComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'terms-conditions', component: TermsConditionsComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'services', component: ServicesComponent },
    { path: 'services-details', component: ServicesDetailsComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'blog-details', component: BlogDetailsComponent },
    { path: 'profile', canActivate: [AuthGuard], component: ProfileComponent },

    { path: 'price', component: PriceDetailsComponent },
    { path: 'contact', component: ContactComponent },
    {
        path: 'payment/:subscription_id', canActivate: [AuthGuard], component: PaymentsComponent,
    },
    {
        path: 'checkout-thankyou/:payment_id', canActivate: [AuthGuard], component: CheckoutThankyouComponent,
    },
    { path: 'air-fright-services-details', component: AirFrightServiceComponent },
    { path: 'road-fright-service-details', component: LandTransportServiceComponent },
    { path: 'ocean-fright-service-details', component: OceanFrightServiceComponent },
    { path: 'cargo-storage-service-details', component: CargoStorageComponent },
    { path: 'ecommerce-service-details', component: ECommerceServiceComponent },
    { path: 'express-service-details', component: ExpressServicesComponent },
    { path: 'domesticTrucking-service-details', component: DomesticTruckingServicesComponent },
    { path: 'warehousing-service-details', component: WarehousingServiceComponent },
    { path: 'reset-password/:token', canActivate: [NoAuthGuard], component: AuthResetPasswordComponent },
    { path: 'forgot-password', canActivate: [NoAuthGuard], component: AuthForgotPasswordComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'prohibitedItems', component: ProhibitedItemsComponent },
    { path: 'volumetric-weight-calculator', component: VolumetricCalculatorComponent },
    { path: 'rate-transit-times', component: RateTransitTimesComponent },
    { path: 'fuel-surcharge', component: FuelSurchargeComponent },
    { path: 'regulations', component: RegulationsComponent },
    { path: 'duties-taxes', component: DutiesTaxesComponent },
    { path: 'track-order', component: TrackOrderComponent },
    { path: 'authorization', component: AmazonPageComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'documentation', component: DevloperDocumentComponent },
    { path: 'quotation', component: QuotationComponent },
    // { path: 'admin/contact-list', component: ContactListComponent },
    {
        path: 'customer/dashboard', canActivate: [AuthGuard], loadChildren: () =>
            import('./components/pages/admin/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    // Admin routing
    {
        path: 'customer/order-file', canActivate: [AuthGuard], loadChildren: () =>
            import('./components/pages/admin/orders-file/orders-file.module').then(m => m.OrdersFileModule)
    },
    {
        path: 'customer/orders', canActivate: [AuthGuard], loadChildren: () =>
            import('./components/pages/admin/order-list/order-list.module').then(m => m.OrderListModule)
    },
    {
        path: 'admin',
        canActivate: [NoAuthGuard],
        loadChildren: () =>
            import('./components/pages/admin/admin.module').then(m => m.AdminModule)
    },

    // Here add new component

    {
        path: '',
        loadChildren: () =>
            import('./components/pages/home-two/home-two.module').then(m => m.HomeTwoModule)
    },
    { path: '**', component: ErrorComponent } // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
