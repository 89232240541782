import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { QuotationDetailsService } from 'app/services/quotation.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent implements OnInit {
  quotationForm: FormGroup;
  unitType: any = 'Lbs';
  shipperCountry; isSubmit = false;
  receiverCountry;
  constructor(
    private toaster: ToastrService,
    private _quotationDetailsService: QuotationDetailsService,
  ) { }

  ngOnInit(): void {
    this.quotationForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required,]),
      shipper_country: new FormControl('', [Validators.required]),
      shipper_zip_code: new FormControl('', [Validators.required]),
      receiver_country: new FormControl('', [Validators.required]),
      pieces: new FormControl('', [Validators.required]),
      weight: new FormControl('', [Validators.required]),
      comments: new FormControl('', [Validators.required]),
    });
  }

  onChangeUnit(event): any {
    this.unitType = event.target.value === 'Kg' ? 'Kg' : 'Lbs';
    this.quotationForm.get('weight').setValue(`${this.quotationForm.get('weight').value} ${this.unitType}`);
  }

  stopScrollingWheel(e): any {
    return e.target.blur();
  }

  addOrder(): any {
    if (this.quotationForm.invalid) {
      // this.toaster.error('Please fill all field', '');
      this.isSubmit = true;
      return;
    }
    const formData = this.quotationForm.value;
    if (this.containsHtmlTags(formData)) {
      // Handle the case where HTML content is detected
      // You can display an error message or take appropriate action
      this.toaster.error('Invalid Content.', '');
      return;
    }
    formData.weight = formData.weight + ' ' + this.unitType;
    this._quotationDetailsService.createOrder(formData).subscribe({
      next: (res: any) => {
        this.toaster.success('Quotation added successfully.', '');
        this.quotationForm.reset();
      },
      error: ({ error }) => {
        this.toaster.error(error ? error.message : 'Something went wrong.', '');
      },
    });
  }

  containsHtmlTags(formData: any): boolean {
    const regex = /<[^>]*>/; // Regular expression to match HTML tags
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const value = formData[key];
        if (typeof value === 'string' && regex.test(value)) {
          return true; // HTML content found
        }
      }
    }
    return false; // No HTML content found
  }
}
