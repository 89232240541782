<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Regulations</h2>
                    <!-- <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Air fright</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Single Service Area -->
<div class="regulation-content">
    <h2>Regulations</h2>
    <h6>Lemonmode - Specialists in Clearance forms and Documentation Back</h6>
    <p>Believe the specialists to guarantee clearance of merchandise through traditions specialists, other offices when
        your shipment arrives. Whether it is at ocean or air terminal or is arriving specifically from your stockroom,
        decrease hazard of punishments through our exact and significant traditions printed material administrations
        accessible for all traditions sections- Bringing in, Transiting, Warehousing, Free Zone, etc.

    </p>
    <ul>
        <li>Consequence and Trade clearance</li>
        <li>Warehousing and free zone</li>
        <li>Tall esteem clearance</li>
        <li>Traditions record arrangement</li>
        <li>Exhortation on traditions duty</li>
        <li>Merchandise classification</li>
        <li>Traditions installment delay</li>
    </ul>

    <div class="countryrow">
        <div class="row">
            <div class="col-md-6 country-lable">Select Country</div>
            <select class="col-md-6 select-option w-auto" [(ngModel)]="selectedCountry"
                (change)="onChangeCountry($event)">
                <option value="coun1">select country</option>
                <option value="coun2">Brazil</option>
                <option value="coun3">Canada</option>
                <option value="coun4">European Union</option>
                <option value="coun5">India</option>
                <option value="coun12">Japan</option>
                <option value="coun6">Saudi Arabia</option>
                <option value="coun7">Singapore</option>
                <option value="coun8">Turkey</option>
                <option value="coun9">United Arab Emirates</option>
                <option value="coun10">United Kingdom</option>
                <option value="coun11">United States of America</option>
            </select>
        </div>
        <div id="content-coun1" class=" " *ngIf="selectedCountry === 'coun1'">
        </div>
        <div style="color: #fff;" *ngIf="selectedCountry === 'coun2'">
            <br>
            <p>All shipments arriving in Brazil are completely assessed by the Government Traditions Benefit, so the
                shipment mode you select will specifically affect clearance time. With lemonmode, you'll moment your
                shipments via informal or formal modes. lemonmode is ready to exhort you on the finest mode for your
                shipment.</p>

            <p>The Brazilian government controls its outside exchange exchanges through an coordinates computerized
                framework known by its acronym, SISCOMEX.</p>

            <p>SISCOMEX centralizes the stream of data between all government organs and banks included in
                import/export
                operations. Online investigations of purport operations enrolled inside the Framework diminish the
                number of reports required and speed up the method.</p>

            <p>Through our endless benefit arrange and affiliations, lemonmode offers consistent and bother-
                traditions
                clearance arrangements. We have an broad conveyance arrange, covering the whole nation. We offer
                total
                data all through all stages of traditions handle. Our organize accomplices can give you most brief
                time
                between shipment entry and its clearance through traditions. Not as it were that, they too offer
                clearance-in-the-air handle with Traditions indeed some time recently the shipment arrives in
                Brazil.
            </p>

            <p>Through our experienced group of Traditions specialists, we offer proficient coordination with the
                Brazilian Traditions specialists, guaranteeing your shipments are cleared with negligible delays.
            </p>

            <h3>Documentation</h3>

            <h3>COMMERCIAL INVOICE</h3>

            <ul>
                <li>Usually a financial archive that formalizes the exchange of proprietorship of merchandise to a
                    buyer.</li>

                <li>All shipments that are dutiable within the nation of goal must be went with by a commercial
                    receipt.
                </li>
            </ul>

            <p>All commercial solicitations must meet the taking after data prerequisites: </p>

            <ul>
                <li>Assess ID for merchant </li>
                <li>Full portrayal and Harmonized Framework Code (HS)</li>
                <li>On the off chance that this shipment root may be a Mercosur nation it must incorporate the
                    Mercosur
                    Common Terminology number (NCM)</li>
                <li>Stock unit taken a toll, amount and cash</li>
                <li>pertinent INCOTERM utilized (DDP/DDU/EXW)</li>
            </ul>

            <h3>Formal Section Clearance</h3>

            <p>On the off chance that your shipment requires you to utilize the formal section clearance mode,
                Brazilian
                law requires that you just contract a traditions broker to help with the traditions clearance
                handle.
            </p>

            <table class="mytabclnew1" width="100%">
                <tbody>
                    <tr>
                        <td>
                            <p><strong>WHAT CAN BE IMPORTED Through FORMAL Passage?</strong></p>
                            <ul>
                                <li><strong>Materials for resale or with foreign exchange cover, regardless of
                                        declared
                                        value </strong></li>

                                <li><strong>Items with declared CIP/CIF value<sup>1</sup> over US $3,000</strong>
                                </li>

                                <li><strong>Gifts (as it were for organizations enrolled with the government)
                                    </strong>
                                </li>

                                <li><strong>Materials that require an consequence permit, such as alcoholic
                                        refreshments
                                        and tobacco</strong></li>

                                <li><strong>Unaccompanied luggage</strong></li>
                            </ul>

                            <p><strong>The total list is on Siscomex within the Mercosur Common Terminology
                                    (NCM).</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>Documentation Required<sup>2</sup></strong></p>
                            <ul>
                                <li><strong>Waybill must have the consignee’s CNPJ*/TAX ID</strong></li>

                                <li><strong>Commercial receipt and pressing list: unique duplicate marked in blue
                                        ink</strong></li>

                                <li><strong>Earlier purport permit: ought to be affirmed some time recently the date
                                        of
                                        shipment to the goal air terminal (for particular items). This permit is as
                                        it
                                        were vital on the off chance that required by the NCM</strong></li>

                                <li><strong>Substantial CPF/CNPJ Assess ID: the report must be appropriately
                                        enlisted by
                                        Government Traditions Benefit</strong></li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p><strong>Traditions Handle</strong></p>
                            <ul>
                                <li><strong>Primary necessity: merchant enrollment with the Government Traditions
                                        Benefit (RADAR)</strong></li>
                                <li><strong>lemonmode gives traditions clearance administrations to conduct the
                                        method
                                        through our benefit arrange and subsidiary benefit suppliers</strong></li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h3>Casual Passage Clearance</h3>
            <p>Did you know that in Brazil, you're able to utilize the casual passage clearance mode for individual
                shipments?</p>
            <table class="mytabclnew1" width="100%">
                <tbody>
                    <tr>
                        <td>
                            <p><strong>WHAT CAN BE IMPORTED Through Casual Section<sup>1,2</sup> </strong></p>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Purport clearance for shipments with a CIP/CIF esteem up to US $3,000<sup>3</sup> –
                                not
                                for resale</p>
                            <ul>
                                <li><strong>Books, periodicals, etc. </strong></li>

                                <li><strong>Tests</strong></li>

                                <li><strong>Wrapped up merchandise not for resale</strong></li>
                            </ul>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Documentation Required</p>
                            <ul>
                                <li><strong>Waybill (must have the importer’s CNPJ/TAX ID)</strong></li>

                                <li><strong>Commercial invoice<sup>4</sup></strong></li>
                            </ul>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Customs Process</p>
                            <ul>
                                <li><strong>It’s not necessary to hire a customs broker</strong></li>

                                <li><strong>Shipments are cleared on the same day of its arrival Taxes and Fees
                                    </strong></li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br>
            <p>1Will be dutiable. 2In case of a legal entity, foreign exchange closing is allowed to pay the
                exporter.
                3CIP/CIF = cost of goods + international freight + international insurance.</p>
            <p>For more subtle elements approximately Brazil’s purport controls, visit the Site of the Government
                Income
                Secretariat here -<a style="color:#fff;" href="" target="_blank"></a> </p>
        </div>

        <div id="content-coun3" class="country-content" *ngIf="selectedCountry === 'coun3'">
            <br>
            <p>There are so numerous things to think around after you are running a trade. And in the event that
                you're
                a U.S. trade looking to offer into Canada, otherwise you are a trade importing into Canada, the task
                list gets indeed longer. But numerous business people don't know the primary step to bringing in.
                That's
                why we have made The 10 Simple To Take after Steps To Bringing in Into Canada for Business
                visionaries.
            </p>

            <p>As a planned business person or modern trade proprietor, utilize these steps and joins to outside
                assets
                to explore through the complexities of bringing in into Canada.</p>

            <h3>Decide On the off chance that The Products Can Be Imported Into Canada</h3>

            <p>We frequently offer assistance first-time merchants get out of a Traditions stick as a result of not
                to
                begin with checking qualification of the imported merchandise. Since of this, we prescribe knowing
                what
                can and cannot be imported into Canada as step number one.</p>

            <p>To begin, you wish to</p>

            <ul>
                <li>Get an precise depiction of the merchandise you arrange to moment or send out.</li>
                <li>Distinguish the nation of beginning, producer, and send out of the products.</li>
                <li>Canada incorporates a run of products over which it forces purport controls. The Purport Control
                    List (ICL) of the Trade and Consequence Licenses Act records these merchandise.</li>
            </ul>

            <p>Another, you may ought to decide whether the products are controlled, directed or precluded by any
                Other
                Government Division (OGDs). OGDs control bunches of commodities relating to their agency (Wellbeing
                Canada, Environment Canada, and the Canadian Nourishment Review Organization are some ).</p>

            <p>There are over 10 OGDs that are included within the importation, in-transit development, and
                exportation
                of different commodities. In case your merchandise are controlled, they may require extraordinary
                licenses, certificates, licenses, extraordinary labeling, or a particular sort of bundling (i.e.
                child
                safe) depending on the product.</p>

            <!--<h3>How Other Government Departments Facilitate the Flow of Your Imported Goods</h3>-->

            <h3>Decide The Tax Classification, Rate Of Obligations And Charges, Esteem For Obligation</h3>

            <p>Once you have got guaranteed you'll be able moment your products into Canada, you must decide the;
            </p>

            <ul>
                <li>Duty classification</li>
                <li>Appropriate tax treatment</li>
                <li>Rate of obligation,</li>
                <li>Charges payable when bringing in products.</li>
            </ul>

            <p>The truth that each product that clears through Traditions must have an precise and adjust Harmonized
                Framework Classification (tax classification) connected to it is particularly imperative. This
                classification comes within the shape of code which distinguishes the thing and rate of obligation
                to
                CBSA.</p>

            <p>Whereas accurately classifying your commodities is key to maintaining a strategic distance from
                beneath
                or over installment of obligations and conceivable, it too diminishes the plausibility of Regulatory
                Financial Punishment Framework (AMPS) punishments, or seizure of your products.</p>

            <p><strong>Visit the Canada Border Administrations Organization site to decide how to calculate
                    obligations
                    and charges.</strong></p>

            <h3>Decide In the event that You'll Take Advantage Of A Free Exchange Assention</h3>

            <p>Free Exchange Understandings are assentions made between nations that want to diminish exchange
                boundaries on products fabricated in their particular nations. They permit for special obligation
                treatment to things that qualify from certain nations. They can moreover affect trades by lessening
                or
                disposing of obligation rates for qualifying merchandise.</p>

            <p>Canada has free exchange assentions with a few countries.</p>

            <p><strong>For more data on the particular Canadian Free Exchange Assentions, visit the Exchange
                    Arrangements and Assentions segment of the Division of Outside Issues and Universal Exchange
                    Canada.</strong></p>

            <h3>Get it Your Moment Duties And Openings</h3>

            <p>We exceedingly prescribe that you just pick up a great understanding of traditions controls and
                prerequisites. Selecting in exchange compliance instruction will instruct you:</p>

            <ul>
                <li>What reports ought to be made (and how to spot an mistake on the off chance that made by a
                    seller)
                </li>
                <li>Key exchange subjects such valuation, tax classification, free exchange understandings, e show,
                    etc.
                </li>
                <li>How to oversee exchange compliance</li>
            </ul>
            <p>The considerable information you get assist you in understanding coordinations and getting a feel for
                how
                exchanges move through the administrative handle.</p>

            <h3>Enlist Your Trade For Consequence Or Trade</h3>

            <p>Some time recently you'll be able purport into Canada, you must get an import/export account
                (commonly
                alluded to as an import/export permit). To do merely must complete several enlistment and
                authorizing
                prerequisites with civil, common and government governments early within the prepare.</p>

            <p>Once you total these steps, you'll have the necessities such as a Trade Number (BN), enrolled
                commerce
                title and a GST/HST account. Your Commerce Number is your single account number for managing with
                the
                government government with respect to charges, finance, import/export and other exercises.</p>

            <p>Canada Income Agency's Trade Enrollment Online is the one-stop-shop for all of your government
                commerce
                enlistment prerequisites.</p>

            <ul>
                <!-- <li>Call 1-800-959-5525; or</li> -->
                <li>Visit Business Registration Online</li>
            </ul>

            <h3>Decide How You'll Yield Your Section Announcement To Traditions</h3>

            <p>Businesses have numerous choices to communicate consequence statements to CBSA. A few are:</p>

            <ul>
                <li>Using a courier broker of freight forwarder who both ships your goods and submits the customs
                    declaration on your behalf.</li>
                <li>Creating an in-house team that submits it directly from your business to Customs via Electronic
                    Data
                    Interchange (EDI).</li>
                <li>Doing it yourself; arriving at the port of entry to meet your goods and make the declaration.
                </li>
                <li>Hiring a customs broker</li>
            </ul>

            <p>A Customs Broker can help you:</p>

            <ul>
                <li>Obtain shipment and contract documentation</li>
                <li>Review the prepared documentation for completeness and compliance with customs regulations</li>
                <li>Prepare and submit a declaration to Customs on your behalf at the port of arrival</li>
            </ul>

            <p>Aside from submitting a declaration on your behalf, customs brokers can also help your company reduce
                costs, improve efficiency, and mitigate risks related to cross-border trade.</p>

            <p>Most companies who import goods into Canada find that it is far too expensive and time-consuming to
                travel to the facility or port of arrival and await clearance, prepare a formal declaration, pay the
                charges due and then anticipate delivery of their product. Even more expensive is an in-house team.
            </p>

            <p>It can be worth the investment up-front, to at least consult with a customs broker in the planning
                stages, so that you can have a clear understanding of your risks and proper tariff classifications.
            </p>

            <h3>Determine How You Will Pay Duties And Taxes</h3>

            <p>Just like there are many options for submitting your entry declaration, there are a couple of options
                for
                paying duties and taxes. You may:</p>

            <ul>
                <li>Prepare the release and accounting documents yourself, or</li>
                <li>Hire a licensed customs broker to do so on your behalf and therefore, services fees would apply
                </li>
            </ul>

            <p>There are three different payment methods available:</p>

            <ol>
                <li>Coordinate security - Usually where you'd have your bond and security posted with Customs, but
                    this
                    may not be a viable alternative on the off chance that you're a modern company. When typically
                    in
                    put, you may pay Traditions on the final commerce day of the month for sums owing on your
                    articulation. On the off chance that installment is late, punishments will apply and conceivable
                    suspension of conceded installment benefits.</li>
                <li>Products and administrations assess (GST) coordinate - Once you're a GST Direct Registrant,
                    you'll
                    pick to pay Traditions on the final day of the month, but without posting a bond and getting
                    your
                    security. This does not cover obligation, as it were GST. The same conditions apply as over for
                    late
                    installment.</li>
                <li>Use of a customs broker's bond - Customs brokers already have bonds in place with Canada
                    Customs,
                    but would charge a fee for the use of their bond. You may need to post a deposit or meet other
                    criteria for this to happen. If you think this is the right option for you, speak to a customs
                    broker to find out their requirements.</li>
            </ol>
            <h3>Arrange For The Shipping Of Your Products</h3>

            <p>Another vital viewpoint is orchestrating transportation of the products. At this organize, you'll
                ought
                to decide how included you need to be within the prepare of getting the products from source to
                goal.
                Here once more, you've got numerous alternatives counting utilizing the administrations of:</p>

            <ul>
                <li>A cargo forwarder who will oversee the complete transportation handle counting numerous carriers
                    and
                    modes.</li>
                <li>Carriers specifically. In case your shipment is as it were utilizing one mode of transport, this
                    may
                    be a simple alternative to oversee.</li>
            </ul>

            <p>Moreover, you may got to recognize the mode of transportation that will be utilized. Different modes
                range accessible counting thruway, marine, rail, air, postal or dispatch benefit. Choosing a mode
                will
                lead you into selecting the most excellent strategy of shipping and thus communicating with the
                transportation company on cross-border necessities.</p>

            <h3>Recognize Your Terms Of Deal</h3>

            <p>It has never been less demanding to discover sources for merchandise around the world and after that
                offer straightforwardly to your clients. Be that as it may, some time recently you embark on this
                travel, you'll ought to know the rules of universal commerce something else known as Incoterms®. It
                is
                critical to distinguish your terms of deal as they clarify your shipping obligations and press out
                your
                landed costs.</p>

            <p>To memorize more approximately Incoterms®, visit the Incoterms® Rules for a brief depiction of the 11
                rules from the Incoterms® 2010 version.</p>

            <h3>Build A Customs Compliance Program</h3>

            <p>Particularly important is the ought to construct a traditions compliance program that works best for
                you.
                The key to keeping up traditions compliance of your commerce is to be educated. It is nice hone to
                know
                how traditions directions apply to your commerce. Whereas traditions brokers can survey your
                commerce
                action, accepting that do so frequently is an oversight by merchants. Subsequently, it is important
                to
                assign a company agent to screen Traditions action and work together with your traditions broker
                straightforwardly.</p>

            <p>This individual would screen Traditions handle, remain up to date with changing directions and get
                ready
                to resist a Traditions review.</p>

            <p>In expansion, make beyond any doubt your inner methods, reports, duty classification, free exchange
                assentions, valuation, and beginnings are in compliance with Traditions prerequisites. Too,
                understanding the joins between your inside operations, bookkeeping and Traditions methods can offer
                assistance address any deficiencies. Portion of a winning equation is to guarantee that your
                commerce
                arrange incorporates a technique for checking compliance over time.</p>

            <p>In conclusion, as you get ready to purport into Canada, know that there are numerous assets
                accessible to
                assist you. We hope this data will assist you arrange for a fruitful begin to your enterprise
                travel.
            </p>

        </div>

        <div id="content-coun4" class="country-content" *ngIf="selectedCountry === 'coun4'">
            <h3>Consequence controls for Europe</h3>

            <p>The European Union could be a political and financial union of 27 part states, comprising of nations
                based in Europe. It’s a single showcase based on four flexibilities – individuals, merchandise,
                administrations and capital. And all four can move openly between all part states. This implies
                merchandise can be transported between part states without the require for traditions control and
                installment of obligations and charges. So products from an EU nation can be transported to another
                EU
                nation in free circulation without a commercial receipt.</p>

            <p>Numerous EU Part States keep up their possess list of merchandise subject to consequence permitting.
                For
                data pertinent to part state moment licenses, it would be ideal if you counsel the important part
                state
                Nation Commercial Direct: EU Part States' Nation Commercial Guides EU Part States' Nation Commercial
                Guides</p>

            <h1>Regularly Inquired Questions</h1>

            <br>
            <div class="about-content" style="display: inherit !important;">
                <ul class="faq">
                    <li class="q"><i class="ion-chevron-right"></i> Which countries are part of the EU?</li>
                    <li class="a">
                        <p>The 27 part nations of the European Union are Austria, Belgium, Bulgaria, Croatia,
                            Cyprus,
                            Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland,
                            Italy,
                            Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia,
                            Slovenia, Spain and Sweden.</p>
                    </li>

                    <li class="q"><i class="ion-chevron-right"></i> What are the requirements to ship within the EU?
                    </li>
                    <li class="a">
                        <p>All businesses based within the EU that exchange universally must be enlisted with their
                            national specialist and have an EORI number. Businesses in non-EU nations too require an
                            EORI number in the event that they will be making traditions statements for shipments to
                            EU
                            nations.</p>
                    </li>

                    <li class="q"><i class="ion-chevron-right"></i> What is an EORI number?</li>

                    <li class="a">
                        <p>'EORI number’ is brief for ‘Economic Administrators Enlistment and Recognizable proof
                            Number’
                            and could be a framework of one of a kind distinguishing proof numbers utilized by
                            traditions specialists all through the European Union. More data around the EORI number
                            can
                            be found at Financial Administrator Distinguishing proof and Enrollment</p>
                    </li>

                    <li class="q"><i class="ion-chevron-right"></i> How do I get an EORI number?</li>

                    <li class="a">
                        <p>Shippers based interior the EU can ask an EORI number from the traditions specialist in
                            their
                            claim nation. Shippers based exterior the EU can ask it from the traditions specialist
                            within the EU nation where they to begin with hold up a traditions affirmation. Keep in
                            mind
                            to apply in progress because it can take up to a week or more in a few places.</p>
                    </li>

                    <li class="q"><i class="ion-chevron-right"></i>How do I ship to EU special territories?</li>

                    <li class="a">
                        <p>Inside Europe there are abroad and extraordinary regions such as Guadeloupe and the
                            Canary
                            Islands. These domains frequently have coordinate affiliation and assentions with the
                            EU,
                            which suggests that whereas they frame portion of the EU, they may require a traditions
                            affirmation to move merchandise in and out of their nation, and duties and charges may
                            have
                            to be be paid.</p>

                        <p>For more data almost the particular traditions directions of the EU's abroad and
                            extraordinary domains, check the government site of the pertinent region. You'll be able
                            too
                            inquire the collector or your chosen carrier.</p>
                    </li>

                    <li class="q"><i class="ion-chevron-right"></i> What is EFTA?</li>

                    <li class="a">
                        <p>It’s critical to note that whereas Norway, Switzerland, Iceland and Liechtenstein are a
                            portion of Europe, they’re not within the EU. But they have a exchange assention with
                            the EU
                            as another traditions union called EFTA, the European Free Trade Association. This
                            implies
                            you would like to pronounce your products at traditions, as well as give a commercial
                            receipt and other vital archives. Discover out more almost this subject on the EFTA
                            site.
                        </p>
                    </li>

                    <li class="q"><i class="ion-chevron-right"></i> How do I ship to the EU after Brexit?</li>

                    <li class="a">
                        <p>The Joined together Kingdom pulled back from the European Union on January 31st, 2020.
                            After
                            that, a move period applies until December 31st, 2020, amid which all EU rules and
                            directions proceed to apply to the UK. Unless the UK and EU both concur to amplify this
                            period, unused shipping necessities between them will come into impact on January 1st,
                            2021.
                            Shippers got to be more mindful of components such as EORI numbers, extra printed
                            material,
                            obligations and charges, and redress valuation of products.</p>
                    </li>

                    <li class="q"><i class="ion-chevron-right"></i> How do I ship to Europe from outside the EU?
                    </li>

                    <li class="a">
                        <p>When shipping to Europe from other landmasses the prerequisites depend on the sort of
                            merchandise and their esteem.</p>

                        <p>Individual shipments of low-value things more often than not as it were require a
                            commercial
                            receipt to clear traditions within the goal EU nation, counting an exact products
                            depiction
                            and adjust products esteem.</p>

                        <p>Commercial shipments or directed products may require additional documentation to comply
                            with
                            EU shipping directions. A few things to be beyond any doubt are:</p>
                        <ul>
                            <li>A correctly completed commercial invoice is always required.</li>
                            <li>Businesses in non-EU countries still need an EORI number to ship to the EU.</li>
                            <li>Other shipping documents that may be necessary are special licenses or import
                                permits –
                                depending on the goods. You can check this with your carrier.</li>
                            <li>If the shipper's country shares a trade agreement with the EU, then the shipment may
                                be
                                eligible for reduced or waived import taxes and duties – depending on the goods. You
                                can
                                check this with your carrier.</li>
                            <li>In general, the customs clearance process is more or less the same across all EU
                                countries.</li>
                            <li>The value limit on express clearance shipments can vary between EU countries.</li>
                            <li>Directed products necessities are regularly the same over EU nations, but it's
                                conceivable that a few nations have additional or other necessities.
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

        </div>

        <div id="content-coun5" class="country-content" *ngIf="selectedCountry === 'coun5'">
            <div class="col-sm-12 mytxtcl">
                <h3 style="text-align:left;">KYC PROCESS FOR CONSIGNEE'S IN INDIA</h3>

                <ul>
                    <li>Once the shipment is booked at root, lemonmode will send an auto produced message to the
                        proctor
                        on the phone number or e-mail address given on the Discuss Waybill to ask for the KYC
                        records.
                        Consequently, it is fundamental for the shipper to guarantee that the consignee’s title,
                        address
                        and contact points of interest on the Discuss Waybill and receipt are totally precise.</li>
                    <li>Consignees/ Merchants must transfer their adjust KYC through the connect shared within the
                        SMS /
                        E-mail to dodge conceivable delays in consequence clearance. The title and address on the
                        KYC
                        archives must coordinate the title and address specified on the Discuss Waybill and receipt.
                    </li>
                    <li>Traditions clearance will be started upon receipt of palatable KYC reports subsequently all
                        KYC’s transferred specifically through connect or shared through any other medium are
                        approved
                        some time recently they are submitted to traditions.</li>
                    <li>In the event that palatable KYC archives are not gotten inside 15 calendar days, the
                        shipment
                        will be considered undeliverable and returned to the shipper with charges, in the event that
                        any, will be charged back to the shipper</li>
                </ul>

                <h3 style="text-align:left;">KYC - Import Clearance</h3>

                <p>The underneath table gives more data approximately KYC records required for traditions clearance
                    as
                    commanded by Indian Traditions vide CBEC Circulars 09/2010, 33/2010 and 07/2015 for
                    distinguishing
                    proof/ confirmation of importers/exporters for traditions clearance performed on their sake by
                    lemonmode acting as an Authorized Dispatch.</p>

                <table border="1" cellpadding="0" class="mytablecl" cellspacing="0">
                    <tbody>
                        <tr>
                            <th>
                                <p>#</p>
                            </th>
                            <th>Category</th>
                            <th>Documents to be obtained</th>
                        </tr>
                        <tr>
                            <td valign="top">
                                <p>1</p>
                            </td>
                            <td valign="top">
                                <p><strong>Individual - Resident Indian</strong></p>

                                <p>Any one self certified record is adequate in the event that it contains both
                                    Character (Photo) and Address Confirmation.</p>

                                <p>In case an Person needs the conveyance to a diverse address or the given KYC
                                    archive
                                    does not coordinate with the conveyance address, it would be ideal if you give
                                    underneath any one of the extra self certified report to record the verification
                                    of
                                    conveyance address.</p>

                                <ol>
                                    <li>Bank Account Explanation</li>
                                    <li>Electricity Bill</li>
                                    <li>Telephone Bill</li>
                                    <li>Hotel Booking Receipt in case the delivery address is a hotel</li>
                                    <li>LPG Connection document with address</li>
                                    <li>Rent Agreement</li>
                                </ol>

                                <p><strong>Person underneath 18 a long time of age can too give a Birth Certificate
                                        as
                                        Confirmation of Character. Be that as it may, one ID verification of the
                                        Parent
                                        / Gatekeeper is additionally required with address verification.</strong>
                                </p>
                            </td>
                            <td style="vertical-align:center">
                                <ul>
                                    <li>Aadhar card</li>
                                    <li>Passport</li>
                                    <li>Voter ID</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td style="vertical-align:center">2</td>
                            <td style="vertical-align:center">
                                <p>&nbsp;Companies/Firms/Trusts/</p>

                                <p>Foundations registered under GST <strong>(Any two documents).</strong> If
                                    customers
                                    have registered under different branches across India, please provide all
                                    registered
                                    GSTN data.</p>
                            </td>
                            <td style="vertical-align:center">
                                <ul>
                                    <li>GST No</li>
                                    <li>IEC (Importer Exporter Code)</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td style="vertical-align:center">3</td>
                            <td style="vertical-align:center">
                                <p>Companies/Firms not registered under GST - <strong>Any two documents</strong></p>
                            </td>
                            <td style="vertical-align:center">
                                <ul>
                                    <li>Certificate of Incorporation</li>
                                    <li>Memorandum of Association</li>
                                    <li>Articles of Association</li>
                                    <li>Power of Attorney granted to its managers, officers or employees to transact
                                        business on its behalf</li>
                                    <li>Telephone bill in the name of the company</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td style="vertical-align:center">4</td>
                            <td style="vertical-align:center">
                                <p>&nbsp;Partnership firms not registered under GST-&nbsp;<strong>Any two
                                        documents</strong></p>
                            </td>
                            <td style="vertical-align:center">
                                <ul>
                                    <li>Enlistment certificate, on the off chance that enlisted</li>
                                    <li>Partnership deed</li>
                                    <li>Control of Attorney(PoA) allowed to a accomplice or an representative to
                                        transact trade on its sake</li>
                                    <li>Any officially valid document identifying the partners and the person
                                        holding
                                        the PoA and their addresses</li>
                                    <li>Telephone bill in the name of firm/partners</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td style="vertical-align:center">5</td>
                            <td style="vertical-align:center">
                                <p>&nbsp;Trusts /Foundations&nbsp;not registered under GST&nbsp;-<strong>&nbsp;Any
                                        two
                                        documents</strong></p>
                            </td>
                            <td valign="top">
                                <ul>
                                    <li>Certificate of enlistment, in the event that enrolled</li>
                                    <li>Power of Attorney (PoA) granted to transact business on its behalf</li>
                                    <li>Any officially valid document to identify the trustees, settlers,
                                        beneficiaries
                                        and&nbsp;those holding the PoA, founders/managers/directors and their
                                        addresses
                                    </li>
                                    <li>Resolution of the managing body of the foundation/association</li>
                                    <li>Telephone bill in the name of trust/foundation</li>
                                    <li>Name of trustees, settlers, beneficiaries and signatories</li>
                                    <li>Name and address of the founder, the managers, Directors and the&nbsp;
                                        beneficiaries, in full, complete and correct.</li>
                                    <li>Phone and fax number, mail address of the believe, author and trustees.</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td style="vertical-align:center">6</td>
                            <td style="vertical-align:top">
                                <p>Foreign National residing in India&nbsp;or visits India - Passport and Visa/PIO
                                    card
                                    self certified copies are mandatory.<br>
                                    Additionally please provide any&nbsp;<strong>ONE</strong>&nbsp;of the following
                                    address proof document. The address on this document &nbsp; should match with
                                    the
                                    delivery address.</p>

                                <ol>
                                    <li>Address proof of relative/friend if staying with &nbsp;them</li>
                                    <li>Hotel Booking Receipt</li>
                                    <li>Rent Agreement</li>
                                    <li>Stay Visa</li>
                                    <li>A deputation letter from the Parent company with duration of stay
                                        confirmation
                                    </li>
                                </ol>
                            </td>
                            <td style="vertical-align:center">
                                <p>&nbsp;(i) Passport<br>
                                    &nbsp;(ii) Visa / PIO card</p>
                            </td>
                        </tr>
                        <tr>
                            <td style="vertical-align:center">7</td>
                            <td style="vertical-align:center">
                                <p>Ambassadors/ Govt. Associations/ Govt. Investigate Organizing or any Govt.
                                    Authorities etc as endorsed beneath para 2.07 HBP 2015-20 i.e. Categories of
                                    merchant and exporter that are exempted from getting IEC.</p>
                            </td>
                            <td style="vertical-align:center">
                                <p>&nbsp;The IEC will be either Interesting Recognizable proof Number (UIN) issued
                                    by
                                    GSTN and authorized by DGFT or any common number informed by DGFT.</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h1>Frequently Asked Questions</h1>
            </div>

            <div class="col-sm-12">
                <br>
                <div class="about-content" style="display: inherit !important;">
                    <ul class="faq">
                        <li class="q"><i class="ion-chevron-right"></i>What is KYC?</li>
                        <li class="a">
                            <p>KYC- the acronym for Know Your Client could be a term utilized for distinguishing and
                                verifying the character of the client. Within the setting of expanding number of
                                offenses including different modus-operandi such as abuse of trade advancement
                                plans,
                                false availment of trade motivating forces, deceitful imports and obligation
                                avoidance
                                by sham IEC holders etc., the <strong>"Know Your Customer (KYC)" </strong>guidelines
                                have been put in place by The Ministry of Finance, Central Board of Excise &amp;
                                Customs
                                to ensure that they are not used intentionally or unintentionally by importers /
                                exporters who indulge in fraudulent activities.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>Why does lemonmode need to have my KYC?</li>
                        <li class="a">
                            <p>The KYC (Know Your Client) rules put an commitment on lemonmode to confirm the
                                forerunner, rightness of the IEC (Merchant Exporter Code), character of the client
                                and
                                their working within the announced address by utilizing solid, autonomous and true
                                archives, information or data.<strong>Hence, all consignees and importers based in
                                    India
                                    need to provide KYC documents for the clearance of all non-document imports,
                                    regardless of the value of such consignments.</strong></p>

                            <p>KYC reports (Government-recognized personality confirmation) have to be be displayed
                                to
                                traditions amid the purport clearance handle. Hence,it is vital that lemonmode is
                                given
                                with all the essential KYC reports some time recently the shipment arrives in India.
                                Nonappearance of KYC reports amid the purport clearance handle leads to clearance
                                delays
                                as shipments are confined by traditions, until KYC records of the proctor are given
                                by
                                the shipper at root or proctor based in India.</p>

                            <p>You are requested to please share your KYC documents with lemonmode via e-mail on <a
                                    style="color:#fff;" href="mailto:sales@shipglobal.us">sales@lemonmode.us</a> or
                                Phone 212 382 1741 (USA), 905 673 9631 (CANADA) with the Airwaybill / Reference
                                Number
                                of your shipment in the subject line.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>Why do I ought to yield Know Your Client
                            (KYC)
                            records? In expansion to KYC, are there any extra archives required for traditions
                            clearance
                            of my shipment?</li>

                        <li class="a">
                            <p>Know Your Client (KYC) reports are commanded by the Indian Traditions vide circular
                                09/2010, (corrected by circular no. 07/2015, circular no 13/2016, circular no
                                2/2018)
                                for clearance of shipment/s.</p>

                            <p><strong>The following documents are required:</strong></p>

                            <ol style="color: #000;">
                                <li>KYC archives - endorsed as per the sort of merchant are as beneath: a. For
                                    People-
                                    Lasting Account Number (Container) / Passport/Voter ID, Aadhaar b. For
                                    Companies/
                                    Trade establishments/Commercial Imports (counting sole propertiership) etc., -
                                    Merchandise and Administrations Charge Distinguishing proof Number (GSTIN) for
                                    GST
                                    enrolled foundations or UIN (Special Recognizable proof Number)/ Changeless
                                    Account
                                    Number (Container) for unregistered foundations.</li>
                                <li>Master letter – to authorize lemonmode Inc. and their accomplices (together
                                    hereinafter implied to as “lemonmode”, which expression joins their person
                                    holding
                                    companies and their conventions clearance administrators), to get ready the
                                    shipment
                                    for conventions clearance.</li>
                                <li>Merchant Exporter Code (IEC)- importers/exporters are issued 10-digit
                                    alpha-numeric
                                    number by the Executive Common Outside Exchange (DGFT) for imports and trades.
                                    IEC
                                    is required for handling the shipment for traditions clearance. Visit here to
                                    know
                                    exceptions to recording IEC.</li>
                            </ol>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>Why do I need to give an authority letter to
                            lemonmode to clear my consignment?</li>

                        <li class="a">
                            <p>The proctor must authorize the ‘Authorized Courier’ and/or the ‘Customs Broker’ (CB)
                                to
                                start traditions clearance counting filing of the bill of section beside the desired
                                clearance printed material. This authorization will permit lemonmode to act as your
                                authorized messenger and/or CB to handle your shipment with traditions.</p>

                            <p>You'll select to designate your possess traditions broker by giving instruction to
                                lemonmode at slightest 24 hours earlier to entry for a formal section or cargo
                                shipments
                                section as it were. Then again, you'll be able transport utilizing lemonmode
                                Universal
                                Priority-Broker Select Choice (BSO).</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>Why do I have to be yield GSTIN in the event
                            that
                            I am bringing in from a remote nation?</li>

                        <li class="a">
                            <p>It could be a command from Traditions beneath the Products and Administrations Assess
                                (GST) law. If you don't mind allude here on the pertinence of GST on merchandise
                                imported into India.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>What should I do if I don’t have GSTIN?</li>

                        <li class="a">
                            <p>As per KYC notice 02/2018 dated January 12, 2018, substances not enrolled or exterior
                                the
                                commitment of getting GST are required to supply a duplicate of UIN (One of a kind
                                Recognizable proof Number) or Skillet when recording for clearance. Such shipments
                                cannot profit GST Input Assess Credit advantage.</p>

                            <p>In case you are doing not have enrolled GSTIN for the state of purport on the show,
                                at
                                that point the shipment can be recorded utilizing UIN or Container.</p>

                            <p>If the registration was done recently, please provide a copy of the GSTIN provisional
                                certificate along with a self-declaration. To update the GSTIN registration, you may
                                write to <a style="color:#fff;"
                                    href="mailto:sales@shipglobal.us">sales@lemonmode.us</a>.</p>
                        </li>
                        <li class="q"><i class="ion-chevron-right"></i>Do I need to self-attest my documents prior
                            to
                            upload of KYC on <a style="color:#fff;" href="https://www.shipglobal.us/">www.lemonmode.US
                            </a>?</li>

                        <li class="a">
                            <p>We recommend uploading self-attested documents and marking them as “KYC submitted for
                                import clearance of shipments through ShipGloblemonmodeal”</p>

                            <ul>
                                <li>Company- documents need company stamp and authorized signatory signature</li>
                                <li>Partnership- documents should be stamped and verified by the authorized partner
                                    or
                                    the signatory of the firm</li>
                                <li>Sole proprietor/individual- self-attested documents with signature</li>
                            </ul>
                        </li>
                        <li class="q"><i class="ion-chevron-right"></i>Do we need to submit all documents on the
                            letter
                            head of the company?</li>

                        <li class="a">
                            <p>Authorization letter/ declarations to Customs etc., should be printed on the letter
                                head
                                of the company/ partnership firm/individual name including sole proprietor.</p>

                            <p>Within the nonattendance of a company letterhead, you'll give the affirmation on a
                                plain
                                piece of paper; if it's not too much trouble note usually worthy as it were in case
                                the
                                substance isn't a company or a organization firm such as sole proprietor/NGO etc.
                            </p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>If I am an account holder with lemonmode,
                            what
                            should I do to update my KYC information?</li>

                        <li class="a">
                            <p>If you submitted KYC documents at the time of opening an account with lemonmode,
                                please
                                share this information by emailing <a style="color:#fff;"
                                    href="mailto:sales@lemonmode.us">sales@lemonmode.us</a>. We may call or write
                                back
                                if:</p>

                            <ol>
                                <li>KYC information for clearance is missing as per the current customs requirements
                                </li>
                                <li>Mismatch in KYC documents versus the current shipment manifest information</li>
                                <li>KYC records are older than 6 years</li>
                                <li>Import address is not listed in the IEC branch list</li>
                                <li>These queries are treated as urgent, so we request you to write to us at <a
                                        style="color:#fff;" href="mailto:sales@lemonmode.us">sales@lemonmode.us</a>
                                    or
                                    call lemonmode helpdesk number for US 646 688 3302 and Canada 905 673 9631.</li>
                            </ol>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>I have been asked to submit the KYC documents
                            again but I have already done this before.</li>

                        <li class="a">
                            <p>We request your cooperation to verify any one of the following:</p>

                            <ol>
                                <li>Previous Air Waybill against which KYC documents were submitted</li>
                                <li>A lemonmode account number for which KYC documents have been updated</li>
                                <li>Re- submission of KYC documents if you submitted documents over 6 years ago</li>
                                <li>If the manifest information provided by the shipper does not match your shipping
                                    name, address or is a mismatch in lemonmode records, we request that you submit
                                    paperwork toward amendments or permission from Customs as an exception.</li>
                            </ol>

                            <p>These queries are treated with utmost urgency, so we request you write to us at <a
                                    style="color:#fff;" href="mailto:sales@lemonmode.us">sales@lemonmode.us</a> or
                                call
                                lemonmode helpdesk number for US 646 688 3302 and Canada 905 673 9631.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>I am unable to upload documents. What should
                            I
                            do?</li>

                        <li class="a">
                            <p>Please ensure the following before uploading documents</p>

                            <ol>
                                <li>Documents are in .TIFF, .PDF or .JPEG format</li>
                                <li>Documents should not be more than 1 MB in size</li>
                                <li>Upload one document at a time</li>
                                <li>Do not upload password protected documents</li>
                                <li>Do not upload following file types<br>
                                    a. File type- .ZIP or .RAR<br>
                                    b. File type- .URL, .EML, .BITURL</li>
                            </ol>

                            <p>Please refresh your page and restart; if the problem persists, kindly call or call
                                lemonmode helpdesk number for US 9136344407 and Canada.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>The KYC site is asking for an alternate
                            number.
                            What should I do if I don’t have an alternate number?</li>

                        <li class="a">
                            <p>While an alternate contact number is optional, please remember to update your primary
                                contact number.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>What should I do if I don’t have tracking
                            number
                            available with me?</li>

                        <li class="a">
                            <p>Check with the shipper for lemonmode Tracking number (also called Air Waybill
                                Number/AWB
                                No.)</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>How will I get an OTP (One-time Passcode)
                            number?
                        </li>

                        <li class="a">
                            <p>The OTP will be sent to your registered email while uploading your documents on the
                                KYC
                                website. The OTP is valid for 15 minutes.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>How much time does it take to receive the
                            OTP?
                        </li>

                        <li class="a">
                            <p>The OTP may take upto 15 minutes to generate, post which you can submit your KYC
                                documents</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>What should I do if I do not receive any OTP?
                        </li>

                        <li class="a">
                            <p>In the event that you are doing not get an OTP, compassionate attempt once more after
                                clearing your computer’s cache memory or deleting the browser history or employing a
                                distinctive browser. On the off chance that the issue endures, merciful call or call
                                lemonmode helpdesk number for US 9136344407 and Canada.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>I have uploaded the wrong documents, what
                            should
                            I do?</li>

                        <li class="a">
                            <p>lemonmode group will audit the submitted reports. On the off chance that the
                                submitted
                                archives are found incorrect/incomplete/ inadequately, you'll get a reference number
                                at
                                your enrolled e-mail. If it's not too much trouble utilize that to transfer adjust
                                records for clearance of your shipment.</p>

                        </li>
                        <li class="q"><i class="ion-chevron-right"></i>I have got a reference number after uploading
                            documents on the site. Why is that?</li>

                        <li class="a">
                            <p>lemonmode will review the documents uploaded on the website. If the documents are
                                incomplete/insufficient, you will receive a reference number at your registered
                                email
                                for updating proper KYC.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>Where can I find information on the status of
                            my
                            shipment post submission of my KYC documents?</li>

                        <li class="a">
                            <p>After you've got submitted the KYC reports, you'll get e-mail insinuation from our
                                KYC
                                review group on the status of the reports submitted. Once all reports have been
                                acknowledged, the shipment would be prepared for clearance after inside checks. Post
                                submission of the documents, you can track the status of the shipment after 24 hours
                                on
                                <a style="color:#fff;" href="mailto:sales@lemonmode.us">sales@lemonmode.us. </a>
                                lemonmode will get in touch with you to fulfill paperwork requirements if additional
                                paperwork is requested during the course of clearance.
                            </p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>It has been 30 days since my shipment arrived
                            in
                            India. Can I submit KYC documents for the clearance of the shipment now?</li>

                        <li class="a">
                            <p>A shipment can be arranged by Traditions after 30 days beneath Area 48 of the
                                Traditions
                                Act, 1962. The 30 days are calculated from the date of physical entry of products in
                                India. The shipment can be put up for clearance as it were after looking for consent
                                from the Appointee Commissioner of Traditions, with valid reasons for delay given
                                the
                                shipment isn't beneath deserting handle.</p>

                            <p>The KYC desk will advise only on compliance of KYC documents. Please write to <a
                                    style="color:#fff;" href="mailto:sales@lemonmode.us">sales@lemonmode.us</a> for
                                queries related to customs clearance of your shipment. Additionally, all letters
                                seeking
                                permission need to be addressed to Deputy Commissioner of Customs.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>Why do I have to submit KYC if my shipment
                            contains magazines, books, catalogues etc.?</li>

                        <li class="a">
                            <p>Shipments containing <strong>magazines, books, catalogues etc.</strong> are
                                non-document
                                shipments and hence require KYC documents.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>Who should I call if I need assistance
                            regarding
                            KYC?</li>

                        <li class="a">
                            <p>Please call lemonmode Inbound KYC helpdesk number or call lemonmode helpdesk number
                                for
                                US and Canada 905 673 9631.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>I have submitted the declaration/ explanation
                            letter to clarify the non-submission of an Importer and Exporter Code (IEC) when I
                            previously imported with lemonmode. Why should I keep submitting a fresh letter every
                            time?
                        </li>

                        <li class="a">
                            <p>IEC is mandatory for all imports and exports into the country until otherwise
                                exempted/permitted by customs. IEC ensures ease of identification and quick filing
                                of
                                shipments. Such shipments where IEC registered company name and address are
                                utilized,
                                have a faster clearance Turn Around time (TAT), without clarifications sought for
                                KYC
                                identification</p>

                            <p>Kindly note that this process is exceptional and subject to the permission from the
                                Customs. These cases are likely to take time while we will follow up on priority.
                            </p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>What should I do if my current shipping or
                            import
                            address is not a branch address registered with my IEC?</li>

                        <li class="a">
                            <p>If a consignee’s shipping address is not listed in the IEC certificate then he/she
                                needs
                                to submit the documents for listing the same with the DGFT. If already done, a
                                receipt/letter/acknowledgment of such submission can also be submitted for customs
                                clearance. Such clearances are subject to customs permission. Any exception needs to
                                be
                                represented to the Deputy Commissioner of customs with clearly stated reasons,
                                seeking
                                permission.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>Is any entity exempt from IEC requirement?
                        </li>

                        <li class="a">
                            <p>See here to know the list of entities recognized by Customs who can operate
                                clearances
                                under Customs exempt IEC list. This is subject to Customs permission.</p>

                            <p>Kindly note that this process is exceptional and subject to the permission from the
                                Customs. These cases are likely to take time while we will follow up on priority.
                            </p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>Where can I get more information on IEC
                            application and procedures etc.?</li>

                        <li class="a">
                            <p>Please visit Director General of Foreign Trade website<br>
                                FAQ on IEC - <a style="color:#fff;"
                                    href="https://dgft.gov.in/sites/default/files/FAQ07082018.pdf"
                                    target="_blank">https://dgft.gov.in/sites/default/files/FAQ07082018.pdf</a><br>
                                Information on IEC application - <a style="color:#fff;"
                                    href="http://dava.gov.in:8080/dgftiec/panSearch.jsp"
                                    target="_blank">http://dava.gov.in:8080/dgftiec/panSearch.jsp</a></p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>What should I do if there is a mismatch in
                            the
                            name declared by my shipper and the KYC documentation of my Company or Individual name?
                        </li>

                        <li class="a">
                            <p>For such cases, incorrect shipper/consignee/address/ any other shipment details will
                                have
                                to be amended with the permission of customs.</p>

                            <p>Please note that this request is subject to Customs permission and approval. This
                                might
                                require additional paperwork and shipper’s assistance based on customs/broker
                                review.
                            </p>

                            <p>Amendments involve additional processing time, prior to filing for clearance as it is
                                considered as an exception. For further assistance, please call lemonmode helpdesk
                                number for US 9136344407 and Canada</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>What should I do if my name has been
                            misspelled
                            by my shipper?</li>

                        <li class="a">
                            <p>The importer has to submit a declaration addressed to the Deputy Commissioner of
                                Customs
                                explaining the error and include all KYC documents of the importer.</p>

                            <p>Please note that this request is subject to Customs permission and approval. This
                                might
                                require additional paperwork and shipper’s assistance based on customs/lemonmode
                                /broker
                                review.</p>

                            <p>Amendments involve additional processing time, prior to filing for clearance as it is
                                considered an exception. For further assistance, please call lemonmode helpdesk
                                number
                                for US 9136344407 and Canada.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>I imported using a friend’s account/name and
                            he
                            does not want to give his KYC. What should I do?</li>

                        <li class="a">
                            <p>The Proctor said on the AWB, whether a companion or a relative, should give the KYC
                                and
                                other reports expressed in this over for customs clearance of the shipment. On the
                                other
                                hand, you'd need to get the proctor name/address revised with the traditions
                                authorization (Correction).</p>

                            <p>Letter for amendment should be addressed to the <strong>Deputy Commissioner of
                                    Customs,
                                    Courier Cell.</strong> Please also email a scan of the documents to <a
                                    style="color:#fff;" href="mailto:sales@lemonmode.us">sales@lemonmode.us</a>.
                                Please
                                note that this request is subject to Customs permission and approval. This might
                                require
                                additional paperwork and shipper’s assistance based on customs/broker review.</p>

                            <p>Amendments involve additional processing time, prior to filing for clearance as it is
                                considered an exception. For further assistance, please call lemonmode helpdesk
                                number
                                for US 646 688 3302 and Canada 905 673 9631.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>I do not have an email, and I am unable to
                            use
                            computer as I am a senior citizen/ differently abled or minor. What should I do?</li>

                        <li class="a">
                            <p>Please call lemonmode helpdesk number for US 9136344407 and Canada 905 673 9631and
                                register your request. Additionally you may have a representative, friend or
                                associate
                                to write into <a style="color:#fff;"
                                    href="mailto:sales@lemonmode.us">sales@lemonmode.us</a> registering your request
                                for
                                support in filling out the KYC formalities.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>What documents are required if I import a
                            personal package at my company office address?</li>

                        <li class="a">
                            <p>The merchant pronounced on the receipt and Discuss Waybill is treated as the
                                essential
                                proprietor of the shipment. In this case, the company will be regarded proprietor of
                                the
                                shipment i.e., imported products. In the event that an representative of the company
                                imports individual products at company address, he/she will require composed
                                endorsements / authorization from the company on their letterhead in progress. The
                                authorization ought to be issued by the Human Asset -Supervisor, or by an authorized
                                signatory of the company recognizing and authorizing the said imported products for
                                individual utilization of the representative.</p>

                            <p>All declarations/letters need to be addressed to the <strong>Deputy Commissioner of
                                    Customs, Courier Cell.</strong></p>

                            <p>If you don't mind note that this ask is subjected to the Traditions consent and
                                endorsement and might require extra printed material and shipper’s help based on
                                customs/lemonmode/broker survey.</p>

                            <p>These take additional time for clearance, and is considered as exception.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>My shipper has forgotten to mention company
                            name
                            on the invoice and the Air Waybill. The said import is for the company and is a
                            commercial/
                            sample shipment. What should I do to clear the shipment?</li>

                        <li class="a">
                            <p>For such cases, the consignee name will have to be amended with the permission of
                                Customs.</p>

                            <p>If it's not too much trouble note that this ask is subject to the Traditions
                                authorization and endorsement. This might require extra printed material and
                                shipper’s
                                help based on customs/lemonmode/broker audit.</p>

                            <p>Amendments involve additional processing time, prior to filing for clearance as it is
                                considered an exception. For further assistance, please call lemonmode helpdesk
                                number
                                for US and Canada.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>What documents are required if the import
                            shipment is addressed to the “Brand name” or “Sister concern” of the parent company?
                        </li>

                        <li class="a">
                            <p>For such cases, the consignee name will have to be amended with the permission of
                                customs.</p>

                            <p>Please note that this request is subject to the Customs permission and approval. This
                                might require additional paperwork and shipper’s assistance based on customs/broker
                                review.</p>

                            <p>Amendments involve additional processing time, prior to filing for clearance as it is
                                considered an exception. For further assistance, please call lemonmode helpdesk
                                number
                                for US 9136344407 and Canada.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>What should I do if shipper has used my
                            nickname
                            for shipping my package?</li>

                        <li class="a">
                            <p>For such cases, the consignee name will have to be amended with the permission of
                                customs.</p>

                            <p>Please note that this request is subject to the Customs permission and approval. This
                                might require additional paperwork and shipper’s assistance based on customs/broker
                                review.</p>

                            <p>Amendments involve additional processing time, prior to filing for clearance as it is
                                considered an exception. For further assistance, please call lemonmode helpdesk
                                number
                                for US 9136344407 and Canada.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>35. The shipper has addressed a company
                            shipment
                            to my residence. What should I do?</li>

                        <li class="a">
                            <p>For such cases, the consignee name will have to be amended with the permission of
                                customs.</p>

                            <p>Please note that this request is subject to the Customs permission and approval. This
                                might require additional paperwork and shipper’s assistance based on customs/broker
                                review.</p>

                            <p>Amendments involve additional processing time, prior to filing for clearance as it is
                                considered an exception. For further assistance, please call lemonmode helpdesk
                                number
                                for US 9136344407 and Canada.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>The shipment is addressed to my
                            relative/friend
                            or family who is deceased. What should I do?</li>

                        <li class="a">
                            <p>For such cases, the consignee name will have to be amended with the permission of
                                customs.</p>

                            <p>Please note that this request is subject to Customs permission and approval. This
                                might
                                require additional paperwork and shipper’s assistance based on customs/broker
                                review.
                            </p>

                            <p>Amendments involve additional processing time, prior to filing for clearance as it is
                                considered an exception. For further assistance, please call lemonmode helpdesk
                                number
                                for US 9136344407 and Canada.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>I am a foreign national; what documents will
                            I be
                            required to submit for KYC?</li>

                        <li class="a">
                            <p>Please submit the following documents:</p>

                            <ol>
                                <li>A copy of valid passport</li>
                                <li>A copy of your VISA endorsement on the passport</li>
                            </ol>
                        </li>
                        <li class="q"><i class="ion-chevron-right"></i>We are a government body and don’t have a
                            Unique
                            Identification number (UIN). What should we do?</li>

                        <li class="a">
                            <p>Please submit the following documents:</p>

                            <ol>
                                <li>Letter embraced by the Joint Secretary of the office in conjunction with
                                    Container.
                                    In nonattendance of UIN or Skillet, the shipment will need manual recording
                                    consent
                                    at Traditions. We require your bolster to assist clarifying this special case,
                                    and
                                    getting due consent at traditions.</li>
                            </ol>
                        </li>
                        <li class="q"><i class="ion-chevron-right"></i>We are a contact office set up in India, and
                            have
                            been given endorsement by Save Bank of India (RBI). What KYC reports ought to I yield?
                        </li>

                        <li class="a">
                            <p>As exempted substance e.g., substance affirmed by Save Bank of India (RBI), enlisted
                                as a
                                contact office in India, you're required to yield a duplicate of the taking after
                                records as onetime profiling necessity</p>

                            <ul>
                                <li>Duplicate of the letter from RBI or regarded specialist or verification that you
                                    simply have been enlisted beneath said arrangement or enlistment beneath the
                                    said
                                    plot or properly affirmed by segment / Government authorized staff/ Recorder
                                    beneath
                                    the said arrangement.</li>
                                <li>KYC documentation including- Certified Copy of Permanent Account Number (PAN) or
                                    Goods &amp; Services Tax Identification Number (GSTIN) or Unique Identification
                                    Number (UIN)</li>
                                <li>Copy of authorization to one deemed director (i.e. identified by Director
                                    Identification number, authorized signatory, or principal)</li>
                            </ul>

                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>What documents should I provide if I am a
                            guest
                            at a Hotel/Resort?</li>

                        <li class="a">
                            <p>Please submit the following documents:</p>

                            <ol>
                                <li>A duplicate of your substantial visa</li>
                                <li>A duplicate of your VISA endorsement on the passport</li>
                                <li>A declaration from the Hotel Front Desk, confirming your reservation and period
                                    of
                                    stay at the Hotel</li>
                                <li>A declaration from your end stating purpose of import and consumption in the
                                    country. (eg., exhibition)</li>
                            </ol>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>What should I do if I am a minor?</li>

                        <li class="a">
                            <p>The KYC documents of the parent/ guardian along with KYC documents of a minor are
                                required to be submitted in case a minor is receiving a shipment. A guardian or
                                principal or warden or dormitory in-charge should provide a declaration or
                                undertaking
                                of representation authorized by the minor’s parents. For further assistance, please
                                call
                                lemonmode helpdesk number for US 9136344407 and Canada.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>Do Embassies or The United Nations or WHO or
                            other diplomatic entities required to submit GSTIN number for their shipment?</li>

                        <li class="a">
                            <p>Unique Identification Number (UIN) is assigned for <strong>foreign diplomatic
                                    missions
                                    and embassies.</strong> UIN is required for filing of Bill of Entry for availing
                                exemptions applicable to such entity.</p>

                            <p>In the event that you are doing not know the UIN number for your division, it would
                                be
                                ideal if you check with the organization division of the government office, or with
                                convention officers managing with government bodies for clarification and
                                compliance.
                            </p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>What is the duty benefit for embassy
                            shipments?
                        </li>

                        <li class="a">
                            <p>Under GST taxation rules, Embassies/Consulates are exempted from GST and they are
                                required to provide their Unique Identification Number (UIN) for availing this
                                exemption.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>Why do I need to provide ICEGATE registered
                            IEC
                            and GSTIN for courier mode of import shipments?</li>

                        <li class="a">
                            <p>In arrange to claim Coordinates Merchandise and Administrations Assess (IGST) credit
                                on
                                your purport shipments, you're required to supply IEC and GSTIN at the time of
                                recording
                                Customs entry beneath dispatch mode. It would be ideal if you note merely will not
                                be
                                qualified to claim IGST credit in case any of the taking after reflect within the
                                Traditions frameworks at the time of recording:</p>

                            <ul>
                                <li>The Importer and Exporter Code (IEC) is not registered on the Customs portal -
                                    ICEGATE</li>
                                <li>The Goods and Service Tax Identification Number (GSTIN) is not registered on
                                    ICEGATE
                                </li>
                                <li>The registered Permanent Account number (PAN) is different on the IEC and GSTIN
                                    certificate</li>
                                <li>The wrong GSTIN is provided at the time of filing</li>
                            </ul>

                            <p>In case IEC is not provided, then Customs entry shall be filed on the basis of a
                                valid
                                PAN recorded in the GSTIN certificate and such shipments cannot avail IGST credit.
                            </p>

                        </li>
                        <li class="q"><i class="ion-chevron-right"></i>How does the KYC process work?</li>

                        <li class="a">
                            <p>Some time recently you dispatch to a unused address in India, the recipient ought to
                                assemble their KYC verification and provide it to your carrier. The carrier will
                                spare
                                the receiver’s points of interest in their KYC database for future shipments to the
                                same
                                address. This prepare must be rehashed each time you utilize a distinctive carrier,
                                send
                                products to a unused address or when the receiver’s subtle elements alter. Indeed in
                                the
                                event that a commerce has a few addresses, each area must be enrolled independently
                                in
                                your carrier’s KYC database.</p>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>How can the receiver submit KYC documents?
                        </li>

                        <li class="a">
                            <p>Each carrier has their own process for gathering KYC documents. If you’re using FedEx
                                as
                                a carrier, the receiver can follow these steps:</p>

                            <p>The shipper needs to ensure</p>

                            <ul>
                                <li>That the receiver’s title and address on the Commercial Receipt (CI) and
                                    Aviation
                                    route charge (Awb) – coordinate the KYC records progressing to be submitted by
                                    collector.</li>

                                <li>Confirm with collector in case his Coordinate the title and address on the Awb
                                    and
                                    C.I. coordinate against the KYC archives to be submitted by recipient.</li>

                                <li>Ensure no “Pet Names”, “Short names”, “Department names” etc. are not mentioned
                                    on
                                    Awb and C.I.</li>

                                <li>The receiver’s phone number and email must also be correct so that the receiver
                                    can
                                    be contacted directly. </li>

                                <li>Send the Aviation route charge data to the collector as before long as the
                                    shipment
                                    is transported with the connect site</li>

                                <li>The receiver needs to fulfil the KYC obligation by doing the following steps.
                                </li>
                                <li>
                                </li>
                                <li>Obtain his shipment Tracking number (Airway bill number- i.e. AWB) from shipper.
                                </li>
                                <li>Visit our website</li>
                                <li>Use the options and Job-aids given to fulfil the KYC obligation for his
                                    shipment.
                                </li>
                                <li>In case of any issues faced by receiver, they can write to <a style="color:#fff;"
                                        href="mailto:sales@lemonmode.us">sales@lemonmode.us</a>
                                </li>
                            </ul>
                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>What if the receiver’s KYC documents aren’t
                            ready?</li>

                        <li class="a">
                            <p>Lost KYC documentation not as it were delays your shipment, but can moreover lead to
                                punishment expenses. To guarantee a smooth handle, inquire your collector in the
                                event
                                that their KYC points of interest are as of now known by the carrier. In the event
                                that
                                not, inquire your recipient to assemble their reports to be submitted some time
                                recently
                                shipping.</p>
                        </li>



                    </ul>
                </div>
            </div>

        </div>
        <div id="content-coun12" class="country-content" *ngIf="selectedCountry === 'coun12'">
            <br>
            <h3>Export Procedure:</h3>

            <p>In order to export to Japan, exporters must declare the following to the Director-General of Customs:
            </p>
            <ul>
                <li>Nature of goods</li>
                <li>Quantity</li>
                <li>Price,</li>
                <li>Any other necessary particulars.</li>
            </ul>

            <p>After the fundamental physical examination, an send out allow must too be gotten. The exporter or the
                Customes broker named by them should get ready the trade announcement and attache solicitations and
                other supporting records required by Japanese laws and directions other than the Traditions Law
                (hereinafter alluded to as "other laws and controls "), by other archives, such as grants,
                endorsements,
                or licenses (e.g., exportation of deliberately touchy materials beneath the control of the Service
                of
                Economy, Exchange and Industry).</p>

            <p>The submitted trade statement is checked against solicitations and other supporting reports at
                Customs.Document checking is conducted when a measurable classification is accurately made agreeing
                to
                the Trade Measurable Plan, when the desired consent or endorsement is secured with regard to germane
                merchandise, and when a adjust application for endorsed extract charge exclusion goes with the
                merchandise which are to be exempted.</p>

            <p>In checking the submitted reports, Traditions chooses whether the merchandise ought to be physically
                inspected to find out the rightness of the classification of merchandise and to see whether the
                examinations required by laws and controls other than the Traditions Law have been completed.</p>

            <p>In guideline, Traditions examinations of merchandise are conducted at a Traditions examination zone
                within the Customhouse or where the products are put away in cases where the merchandise cannnot be
                brought to the Traditions examination zone.</p>

            <p>At the time of send out statement, the exporter is asked to yield two duplicates of the send out
                report.
                One is for insights and the other is kept at Traditions for needs such as trade certification.</p>

            <h3>Documents to Be Submitted</h3>

            <p>The following documents must be submitted to Customs.</p>

            <ul>
                <li>Export Declaration (Customs form C-5010)</li>
                <li>Invoice</li>
                <li>Other documents: Certifications, permits, or approvals required by other laws and regulations.
                </li>
            </ul>

            <h3>Affirmation of Other Laws and Directions</h3>

            <p>In expansion to the Traditions law, depending on the sort of cargo, there are cases which require a
                earlier allow or endorsement for send out of the cargo some time recently trade announcement. These
                must
                be issued by the other specialists, such as the Service of Economy, Exchange and Industry, and the
                Service of Wellbeing, Work and Welfare, in understanding with stipulations in other laws and
                directions.
            </p>

            <p>Agreeing to the stipulations of these other laws and directions, exporters of cargo, who are required
                to
                get licenses, or endorsements or pass examinations, must demonstrate to Traditions that these
                prerequisites have been met amid the Traditions clearance method, which at that point ought to be
                affirmed. Unless these prerequisites are demonstrated and affirmed, Traditions will not allow the
                cargo
                to be traded (Traditions Law, Article 70).</p>

            <p>The reason of these laws and controls is to direct unhindered and scattered sends out and to help
                within
                the ordinary advancement of outside exchange by either forbidding or confining the trade of certain
                cargoes. These laws and controls were sanctioned to attain regulatory goals through the affirmation
                of
                required licenses, endorsements, completion of inspection, and other conditions within the physical
                nearness of cargo at Traditions as the ultimate check-point.</p>

            <p>There are 15 laws and regulations concerning exports, of which major ones are as follows:</p>

            <p>(1) Export Trade Control Order</p>

            <p>(2) Export Exchange Control Order</p>

            <p>(3) Export-Import Trading Law</p>

            <p>(4) Law for the Protection of Cultural Properties</p>

            <p>(5) Forest Seeding Law</p>

            <p>(6) Law Concerning Wildlife Protection and Hunting</p>

            <p>(7) Narcotics and Psychotropics Control Law</p>

            <p>(8) Cannabis Control Law</p>

            <p>(9) Opium Law</p>

            <p>(10)Stimulant Drug Control Law</p>

            <p>For more information: <a href="https://www.customs.go.jp/english/summary/export.htm" target="_blank"
                    style="    color: #fff;">Read here</a> </p>

            <p>Any person wishing to import goods must declare them to the Director-General of Customs and obtain an
                import permit after examination (if necessary) of the goods concerned. The formalities start with
                the
                lodging of an import declaration and end with issuance of an import permit after the necessary
                examination and payment of Customs duty and excise tax. In this way, measures are taken to ensure
                the
                fulfillment of the requirements for the control of foreign exchange and other regulations concerning
                the
                importation of goods. More than 90 percent of import procedures is currently computerised.</p>

            <p>All steps and required documents are available on the <strong><a
                        href="https://www.customs.go.jp/english/summary/import.htm" target="_blank"
                        style="    color: #fff;">website</a></strong> of Japan Customs.</p>

            <p>The <strong><a href="https://www.customs.go.jp/zeikan/seido/telephone_e.htm" target="_blank"
                        style="    color: #fff;">Customs Counsellor System</a></strong> assists companies with
                import
                procedures.</p>

            <h3>Specific Import Procedures</h3>

            <p>Under the "immediate import permission system upon arrival", import permission may be granted as soon
                as
                cargo entry is confirmed. To be eligible for this system, importers must file a <strong><a
                        href="https://www.naccs.jp/e/" target="_blank" style="    color: #fff;">preliminary
                        declaration
                        online.</a></strong></p>

            <p>For goods whose value is less than or equal to JPY 100,000,<strong> <a
                        href="https://www.customs.go.jp/english/summary/kani_e.pdf" target="_blank"
                        style="    color: #fff;">a simplified declaration system applies.</a></strong></p>

            <h3>Importing Samples</h3>

            <p>Japan acceded to the <strong><a href="https://www.atacarnet.com/" target="_blank"
                        style="    color: #fff;">ATA</a></strong> Tradition in 1973. Products imported from
                contracting
                nations and domains can for the most part be subject to a strategy on the premise of the ATA
                Tradition
                and be exempted from Traditions obligation and extract charges payable. To discover out more, if you
                don't mind visit the <strong><a href="https://www.customs.go.jp/english/summary/temporary.htm"
                        target="_blank" style="    color: #fff;">Japan Customs website</a></strong></p>

        </div>
        <div id="content-coun6" class="country-content" *ngIf="selectedCountry === 'coun6'">
            <br>
            <p>The documentary regulation, required for the expedition of goods in Saudi Arabia necessitate the
                following documents </p>
            <ul>
                <li>Commercial receipt, , showing the nation of root, title of the carrier, brand and amount of
                    merchandise, and depiction of the products counting weight and esteem.</li>
                <li>Certificate of beginning, confirmed by a neighborhood chamber of commerce and interpreted into
                    Arabic.</li>
                <li>A charge of filling - Aviation route charge, at the side a steamship (or carrier company
                    certificate) which is an Added Affirmation to Charge of Filling or aviation route charge) ought
                    to
                    be issued by the steamship (or carriers) company in at slightest one unique</li>
                <li>Insurance Certificate if goods are insured</li>
                <li>Packing List</li>
                <li>Certificate of assurance (if CIF sale),</li>
                <li>Certification from Saudi Standards, Metrology and Quality Organization or SASO*, where ever
                    applicable.</li>
            </ul>
            <p>These documents should be stamped by the following organisations: </p>
            <ul>
                <li>Chamber of Commerce and Industry of the exporter country, </li>
                <li>"Bilateral" Chamber of Commerce, </li>
                <li>Consulate of Saudi Arabia from the exporter country.</li>
            </ul>
            <p>Please visit the <a style="color:#fff;" href="https://mci.gov.sa/en/Pages/default.aspx"
                    target="_blank">website</a> of the Saudi Ministry of Commerce for future updates.
                *Saudi Arabian Standard Organizations (SASO) is the regulatory authority controls quality of goods
                imported into Saudi Arabia. The conformity certificate is required to ensure all imported goods
                conform
                with Saudi standards</p>
            <table class="mytablecl" width="100%">
                <tbody>
                    <tr>
                        <td colspan="2"><strong>List of important websites for Saudi Arabia Import
                                Regulations</strong>
                        </td>
                    </tr>
                    <tr>
                        <td><strong>SASO – Saudi Arabian Standards Organization</strong></td>
                        <td><a style="color:#fff;" target="_blank"
                                href="http://www.saso.org.sa/English/Pages/Default.aspx">http://www.saso.org.sa/English/Pages/Default.aspx</a>

                        </td>
                    </tr>
                    <tr>
                        <td><strong>SFDA - Saudi Food &amp; Drug Authority</strong></td>
                        <td><a style="color:#fff;" target="_blank"
                                href="http://www.sfda.gov.sa/En/Home">http://www.sfda.gov.sa/En/Home</a>
                        </td>
                    </tr>
                    <tr>
                        <td><strong>MOCI - Ministry of Commerce &amp; Industry</strong></td>
                        <td><a style="color:#fff;" target="_blank"
                                href="http://mci.gov.sa/english/default.aspx">http://mci.gov.sa/english/default.aspx</a>
                        </td>
                    </tr>
                    <tr>
                        <td><strong>CITC - Communications &amp; Information Technology Commission</strong></td>
                        <td><a style="color:#fff;" target="_blank"
                                href="http://www.citc.gov.sa/English/Pages/default.aspx">http://www.citc.gov.sa/English/Pages/default.aspx</a>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <div id="content-coun7" class="country-content" *ngIf="selectedCountry === 'coun7'">
            <br>
            <p>With connections to over 600 ports in 120 countries, Singapore boasts the world’s second busiest
                container port and is the gateway to the ASEAN region.</p>

            <h3>Importing</h3>

            <p>The importing is governed by Singapore’s Customs Act, Goods and Services Tax (GST) Act, and
                Regulation of
                Imports and Exports Act govern the import of all goods into the country. GST and/or a duty payment
                applies to all goods imported into Singapore for domestic consumption. </p>

            <p>Singapore’s Customs Act, Goods and Services Tax (GST) Act, and Regulation of Imports and Exports Act
                govern the import of all goods into the country. The party who imports goods into Singapore for
                their
                own account or use or for the account or use of some other person is considered to be an importer.
                GST
                and/or a duty payment applies to all goods imported into Singapore for domestic consumption. </p>

            <p>In order to participate in import activities within Singapore, including applying for import permits
                or
                certificates, importers are advised to adhere to the following steps:</p>

            <ul>
                <li>Check Singapore Customs’ database for controlled and restricted goods.</li>
                <li>Obtain Unique Entity Number (UEN) by registering with the Accounting and Corporate Regulatory
                    Authority (ACRA) </li>
                <li>Enlist for an Inter-Bank GIRO Account (IBG) with Singapore Traditions to pay obligations, GST,
                    and
                    other expenses. To make an account, merchants can yield an IBG frame specifically to Singapore
                    Traditions.</li>
                <li>Security is required to be outfitted for endeavors including dutiable products, transitory
                    moment of
                    products for affirmed purposes, and for the operation of authorized premises, counting
                    distribution
                    centers and extract production lines. Bank or back company ensures and protections bonds are
                    worthy
                    shapes of security.</li>
                <li>To acquire a customs, import permit, importers may register themselves as a declaring agent or
                    appoint a declaring agent to act on their behalf. Permit applications are submitted online
                    through
                    the TradeNet system. When applying for the customs permit for containerized cargo, the relevant
                    container number and shipper seal number must be declared. </li>
            </ul>

            <h3>Documents for cargo clearance:</h3>

            <ul>
                <li>Printed copy of the approved customs permit</li>
                <li>Invoice</li>
                <li>Packing list</li>
                <li>Bill of Landing or Air Waybill</li>
            </ul>

            <p>In most cases, all records in association with the consequence of merchandise must be kept for five a
                long time from the date of traditions allow endorsement in either physical or computerized frame,
                and
                must be displayed to Singapore Traditions upon ask.</p>

            <p>Some time recently the real importation, the merchant is required to get a traditions allow.</p>

            <p>The importer is the party who imports the goods into Singapore:</p>

            <ul>
                <li>For their own account or use; or</li>
                <li>For the account or use of some other person</li>
            </ul>

            <p>In the event that an abroad company sold products to a nearby company and the commercial receipt
                shows
                the neighborhood client as the proctor, the neighborhood client will be the merchant of the
                products.
            </p>

            <p style="text-align:left;">For latest updates and more information, check Customs Singapore, the
                official
                website of Singapore Customs, here: <a href="https://www.customs.gov.sg/" target="_blank"
                    style="    color: #fff;">https://www.customs.gov.sg/</a>, <a
                    href="https://sso.agc.gov.sg/SL/RIEA1995-RG1" target="_blank"
                    style="    color: #fff;">https://sso.agc.gov.sg/SL/RIEA1995-RG1</a></p>

        </div>
        <div id="content-coun8" class="country-content" *ngIf="selectedCountry === 'coun8'">
            <br>
            <p>But for the products carried by vehicles passing through the inland waters or airspace of the
                Traditions
                Region of Turkey without halting, a rundown statement is submitted for the products brought into the
                Traditions Region of Turkey. Rundown statement is submitted some time recently the products arrive
                the
                Traditions Domain of Turkey.</p>

            <p>Products arriving the Traditions Region of Turkey are displayed to the traditions by the conveyor
                individual or, completely different circumstances, by the individual who expect obligation for the
                carriage taking after the entry of the merchandise. The individual showing the merchandise to the
                traditions might relate these products to the rundown announcement or traditions statement that are
                submitted already.</p>

            <p>Goods that are presented to the customs administrations are assigned a customs-approved treatment or
                use.
            </p>

            <p>After setting a customs-approved treatment or utilize to the merchandise secured by the rundown
                statement, exchanges related to them are completed; inside 45 days from the accommodation date of
                the
                rundown statement for the merchandise arriving by ocean, inside 20 days from the accommodation date
                of
                the outline affirmation for the merchandise coming through other ways.</p>

            <p>Merchandise have the status of incidentally put away products until they are made subject to
                customs-approved treatment or utilize taking after their accommodation to the traditions, and are
                recognized in this setting. Incidentally put away merchandise can fair be put away within the places
                endorsed by the traditions specialists through complying with the conditions decided by these
                specialists.</p>

            <p>Task of a customs-approved treatment or utilize to merchandise allude to one of these exchanges, such
                as
                subjection to a traditions administration, passage into a free zone, re-exportation exterior the
                Traditions Domain of Turkey, annihilation, or deserting to the traditions.</p>

            <p>Merchandise that are expecting to be made subject to a traditions administration might be announced
                to
                the competent traditions specialists in understanding with this administration. Traditions
                affirmation
                can be made in composed frame, through computer information handling procedure, orally or by any
                other
                act that communicates the eagerness of the proprietor of these products to create subject them to a
                traditions administration.</p>

            <p>In conventional strategy, composed verbalization is made by a conventions declaration. Assertion of
                release for free of circulation insinuates to the explanation shown by the person who is pointing to
                conduct the result of the commercial stock. That said, the articulation is required to be made
                through
                computer data planning strategy.</p>

            <p>Traditions obligations born from the importation of products should be informed to the declarant by
                means
                of the affirmation of discharge at no cost circulation, and the allow and congruity certificates
                that
                are required in understanding with other remote exchange enactment within the setting of the due
                commercial policy measures got to be included to the announcement of discharge at no cost
                circulation.
            </p>

            <p>An merchant needs as it were a assess number to purport all but limited things, which incorporate
                guns,
                unsafe materials, and other items which will be imported by authorized foundations as it were or for
                which endorsement from significant Turkish government offices are required.</p>

            <p>Control Certificates are required as it were for creatures, creature items, and certain plants such
                as
                seeds, seedlings, saplings and blossom bulbs. For more data allude to the taking after websites:</p>

            <ul>
                <li><a style="color:#fff;" target="_blank" href="https://www.trade.gov.tr/">Ministry of Customs and
                        Trade</a></li>
                <li><a style="color:#fff;" target="_blank" href="http://www.mfa.gov.tr/default.en.mfa">Ministry of
                        Foreign Trade</a></li>
                <li><a style="color:#fff;" target="_blank" href="http://www.sanayi.gov.tr/">Ministry of Industry and
                        Trade</a></li>
            </ul>

            <h3><em>Import Documentation</em></h3>

            <p>Turkish documentation methods require that a commercial receipt and charge of filling or aviation
                route
                charge go with all commercial shipments. Depending on the sort of item, merchants may be required to
                yield a Certificate of Beginning. Moment licenses and phytosanitary certificates are fundamental for
                nourishment and agrarian product imports.</p>

            <h3><em>Congruity Compliance (CE Check)</em></h3>

            <p>Companies offering to the Turkish advertise must yield prove of congruity compliance (CE Stamp)
                either by
                giving a similarity certificate from a informed body or a manufacturer-issued affirmation of
                similarity,
                which pronounces compliance with all important guidelines and mandate attaches. The affirmation of
                similarity must specify the pertinent directive(s), the title of the producer or its authorized
                agent,
                the title of the informed body (in the event that included), item data and reference to harmonized
                guidelines. In the event that the informed body is additionally included within the handle, the sort
                of
                examination certificate ought to too be submitted.</p>

            <h3><em>Commercial Receipt</em></h3>

            <p>The commercial receipt must be submitted in triplicate, counting the initial duplicate and must
                contain a
                total portrayal, amount, unit taken a toll, HS code, conveyance strategy of the products and nation
                of
                root as well as all required installment terms and letters of credit, on the off chance that the
                exchange was actualized through this installment strategy.</p>

            <h3><em>Certificate of Beginning</em></h3>

            <p>A Certificate of Beginning is required by certain outside nations for tax purposes, certifying the
                nation
                of root of indicated products. The certificate of beginning is to be arranged in copy. No
                adjustments
                are allowed on this archive, and it ought to be in English. A Certificate of Beginning is more often
                than not arranged by the exporter or the cargo forwarder and notarized and verified to by a
                neighborhood
                Chamber of Commerce or a World Exchange Center.</p>

            <h3><em>Bill of Lading/Airway Bill</em></h3>

            <p>Subtle elements within the charge of filling ought to compare precisely to those given in other
                shipping
                archives. The initial charge of filling ought to be submitted beside three duplicates.</p>

            <h3><em>Proforma Invoices</em></h3>

            <p>The professional forma receipt must not be more than six months ancient at the time of application.
                The
                words “pro forma” must be included on the archive. It must contain an unexpired choice (in the event
                that suitable), demonstrate cargo and protections charges independently, and bear the importer's
                title
                as well as the portrayal, unit cost, amount and delivery/payment strategy of indicated merchandise.
                Items falling beneath the EU Unused Approach Orders must be went with by either a self-declaration
                of
                similarity or a informed body’s issued certificate of congruity to be permitted section into the
                Turkish
                advertise. See the Benchmarks and CE Check area underneath.</p>

            <h3><em>Wellbeing Certification</em></h3>

            <p>Extraordinary wellbeing certificates are required for imports of plants, seeds, live creatures and
                creature items. Plants, counting natural products and vegetables, must be significantly free from
                bothers and maladies and must have been developed in an zone considerably free from denied bothers
                and
                maladies.</p>

            <h3><em>Uncommon Consequence Necessities</em></h3>

            <p>Liquor can be imported by the private division by getting permit and consent from the Tobacco Items
                and
                Alcoholic Drinks Advertise Administrative Specialist (TAPDK), an autonomous administrative body.
                Review
                of imported items is controlled by the Communique on Purport Review of Tobacco, Tobacco Items,
                Liquor
                and Alcoholic Refreshments (Direction on Item Security and Review: 2018/19). All things considered,
                non-tariff boundaries, strenuous documentation prerequisites, and tall obligation rates proceed to
                restrain exchange in alcoholic refreshments. Cigarettes can as it were be imported by cigarette
                makers,
                which are allowed consent by the government beneath extraordinary declare.</p>

            <p>All reports must be gotten from and/or affirmed by the important specialists within the nation of
                root.
                Documents must be submitted within the unique dialect with a interpretation into Turkish. Control
                certificates must be displayed to traditions specialists upon moment.</p>

            <h3><em>Risk-Based Exchange Control Framework (TAREKS)</em></h3>

            <p>The Service of Exchange propelled a Risk-Based Control Framework (TAREKS)” in 2010 to carry out
                security
                and quality checks on traded and imported products electronically and on a hazard premise. Outlined
                to
                be available online utilizing an e-signature, the most reason of this control framework is to extend
                the
                productivity of outside exchange, to supply secure and quality items to buyers and firms by
                controlling
                the passage of “risky” items and dealers to the showcase and to decrease holding up times at
                traditions.
            </p>

            <p>For more information refer to the official Turkish Government website <a style="color:#fff;"
                    href="https://www.trade.gov.tr/legislation/product-safety-and-technical-regulation/product-safety-and-quality-control-system-on-import-export-in-turkey"
                    target="_blank">here</a></p>

            <h1>Frequently Asked Questions</h1>

            <div class="col-sm-12">
                <br>
                <div class="about-content" style="display: inherit !important;">
                    <ul class="faq">
                        <li class="q"><i class="ion-chevron-right"></i>Where are normal customs duty rates for
                            Turkey?
                            Is there an exemption for low-value shipments, if so, at what level? Is there a binding
                            tariff information system or similar in place? Are there prior notification requirements
                            for
                            imports?</li>
                        <li class="a">
                            <p>Lists containing the applicable customs duty rates can be accessed in Turkish at the
                                Ministry’s website (<a style="color:#fff;" href="http://"
                                    target="_blank">www.ticaret.gov.tr/ithalat/ithalat-rejimi</a>). Moreover,
                                official
                                duty data or an progress administering can be given by the Traditions
                                Undersecretariat
                                upon a taxpayer’s composed ask. Merchants may ask official tax data concerning:</p>
                            <ul>
                                <li>The determination of import or export taxes: calculation of export tax refunds
                                    in
                                    the scope of the agricultural policy and all payments given to import or export;
                                    and
                                </li>
                                <li>Usage of import, export or prior consent documents: binding tariff information
                                    is
                                    binding from the date the information is given and valid for six years from the
                                    date
                                    the information is given.</li>
                            </ul>
                            <p>The exclusions from custom obligation and exemptions are recorded beneath article 167
                                of
                                the Traditions Law, whose fourth section directs the exception for low-value
                                shipments.
                                As a common hone, the President of the Republic decides the exclusions, counting the
                                exception for low-value shipments, in agreement with Turkey’s current exchange
                                arrangement.</p>
                            <ul>
                                <li>18 per cent for those consigned from the EU;</li>
                                <li>20 per cent for those dispatched from other countries; However, books or printed
                                    distributions that are sent to or secured by open teach and associations,
                                    libraries
                                    and exhibition halls, as well as associations locks in in instructive or logical
                                    investigate, are excluded from traditions obligation.</li>
                            </ul>

                            <p>Turkey’s traditions duty framework (Traditions Tax Measurements Positions) is based
                                on
                                the World Traditions Organization’s Harmonized Framework and is upgraded and
                                endorsed
                                each year by the President of the Republic. The Traditions Duty Insights Positions
                                sets
                                up 12-digit codes for the distinguishing proof and classification of imported and
                                traded
                                items.</p>

                        </li>
                        <li class="q"><i class="ion-chevron-right"></i>Where are special tariff rates, such as under
                            free trade agreements or preferential tariffs, and countries that are given preference
                            listed?</li>
                        <li class="a">
                            <p>Decreased traditions obligation rates are distributed within the records added to the
                                Moment Administration Proclaim and are given for within the FTAs concluded by
                                Turkey.
                                Article 16 of Choice No. 1/95 of the EC-Turkey Association Council of 22 December
                                1995
                                on executing the ultimate stage of the Traditions Union obliges Turkey to blend its
                                taxes so as to adjust with the EU’s particular traditions administration.</p>

                            <p>The EU’s administration is as it were somewhat connected, and in this way certain
                                nations
                                that are secured by the EU’s GSP administration are not included in Turkey’s GSP
                                administration. Those nations may be considered either as third nations or
                                recipients
                                from extraordinary motivation courses of action. (See the Attach 4 to the Moment
                                Administration Proclaim for the list of GSP recipient nations:
                                www.resmigazete.gov.tr/eskiler/2019/05/20190510-15.pdf.) The records of appropriate
                                traditions obligation rates depending on the concerned country’s status (GSP or not)
                                are
                                given in Turkish at the Ministry’s site (www.ticaret.gov.tr/ithalat/ithalat-rejimi).
                            </p>

                            <p>Turkey as of now benefits from the GSP administrations of Japan, Russia, Belarus,
                                Kazakhstan, Modern Zealand, Australia and Canada. The US as of late removed Turkey
                                from
                                its GSP administration.</p>

                        </li>

                        <li class="q"><i class="ion-chevron-right"></i>How can GSP treatment for a product be
                            obtained
                            or removed?</li>
                        <li class="a">
                            <p>GSP treatment for a product can be obtained from and removed by the Directorate
                                General
                                for Imports within the Ministry.</p>

                            <p>Compatible to the Moment Administration Declare, Turkey takes any essential degree
                                for
                                nations, foundations and companies that irritate the adjust of commerce and
                                installments
                                in Turkey’s commercial relations, breaching their commitments decided by worldwide
                                understandings or acting opposite to the rule of all inclusiveness inside the
                                setting of
                                universal assentions.</p>
                        </li>
                        <li class="q"><i class="ion-chevron-right"></i>Is there a duty suspension regime in place?
                            How
                            can duty suspension be obtained?</li>

                        <li class="a">
                            <p>Turkey incorporates a duty suspension framework. The items that are free of
                                traditions
                                obligations are recorded in Reference section V of the Purport Administration
                                Decree.
                                Producers found in Turkey can ask duty suspension for crude materials, semi-finished
                                merchandise or components to be utilized in their production which are not
                                accessible
                                either within the EU or in Turkey. Also, the sum of moment obligation spared must be
                                at
                                slightest €15,000.</p>

                            <p>All the demands are sent to the Financial Tax Questions Gather inside the European
                                Commission. Those demands are to begin with assessed by this bunch and the result is
                                sent to the Commission. After the ultimate choice by the Commission, suspension
                                updates
                                enter into constrain within the EU and Turkey at the same time. Suspension choices
                                are
                                substantial for five a long time.</p>
                        </li>
                        <li class="q"><i class="ion-chevron-right"></i>Where can customs decisions be challenged in
                            your
                            jurisdiction? What are the procedures?</li>

                        <li class="a">
                            <p>An application can be made either some time recently the competent intervention
                                commission inside 30 days after the notice or some time recently the Head
                                Directorate
                                inside 15 days from the date of notice.</p>

                            <p>On the off chance that the Head Directorate denies the application, the significant
                                parties can offer the choice some time recently the authoritative courts inside 30
                                days
                                from the date of notice of the concerned choice.</p>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
        <div id="content-coun9" class="country-content" *ngIf="selectedCountry === 'coun9'">
            <br>
            <p>On 13 July, the UK Government published its <a style="color:#fff;"
                    href="https://www.gov.uk/government/publications/the-border-operating-model" target="_blank">Border
                    Operating Model"</a> (<strong>"BOM"</strong>) which explains how the UK Government will operate
                controls on the import and export of goods between Great Britain and the EU from 1 January 20211.
                Certain procedures in the BOM, called the "Core Model", will affect all movements of goods between
                Great
                Britain and the EU. Other requirements will only apply to specific categories of goods. The BOM also
                distinguishes between requirements for certain <strong>"Controlled Goods"</strong> as listed in
                Annex C
                of the BOM, such as excise goods, controlled drugs and firearms, and non-controlled goods which are
                referred to as <strong>"Standard Goods".</strong></p>

            <p>Moment methods will be presented in three stages between 1 January and 1 July 2021, while trade
                methods
                will (broadly talking) be presented all at once on 1 January 2021. This article abridges the key
                focuses
                of these stages and investigates the commonsense issues that British businesses got to consider in
                planning.</p>

            <h3>Importing goods</h3>
            <p><strong>STAGE 1</strong> - <strong>from 1 January 2021</strong>: relates to all goods under the Core
                Model:</p>
            <ul>
                <li><strong>Customs declarations</strong>: Traders importing Standard Goods can either submit a
                    standard
                    customs declaration or use "deferred declarations", whereby an importer makes an entry in their
                    own
                    commercial record at the point of the goods' entry into Great Britain (called an "entry into
                    declarant's records", "<strong>EIDR</strong>"), and then submits a supplementary declaration to
                    HMRC
                    within six months. Traders importing Controlled Goods must submit a standard customs
                    declaration, or
                    use simplified declaration procedures if authorised to do so (see below). Tariffs will be
                    calculated
                    according to the UK Global Tariff<sup>2</sup>, which will take effect from 1 January 2021.</li>
                <li><strong>Safety &amp; Security ("S&amp;S") Declarations</strong>: Under the World Customs
                    Organisation's SAFE framework, S&amp;S import and export declarations provide advanced data on
                    consignments to customs authorities for risk analysis. Import declarations (entry summary
                    declarations) will not be required in Stage 1.</li>
                <li><strong>Intrastat</strong>: VAT-registered businesses currently required to submit monthly
                    Intrastat
                    arrivals declarations must continue to do so.</li>
                <li><strong>Goods Vehicle Movement Service</strong>: GVMS is a new IT-system that supports
                    "pre-lodgement" of declarations ahead of goods arriving at the border. Initially it will be
                    introduced only for transit movements (see below).</li>
                <li><strong>Additional requirements</strong>: There will be additional requirements for a range of
                    specific goods, including (among others): sanitary and phytosanitary ("<strong>SPS</strong>")
                    controls on animal products, fish, shellfish and their products, live animals and plants and
                    plant
                    products; excise goods; and certain goods subject to international conventions and commitments,
                    such
                    as rough diamonds.</li>
            </ul>
            <p>IA number of methods will be accessible to encourage imports, counting:</p>

            <ul>
                <li><strong>Transit</strong>: Traders will be able to move goods into British customs territory
                    under
                    the Common Transit Convention ("<strong>CTC</strong>"). The CTC enables the transit of goods
                    through
                    the EU, EFTA countries, Turkey, North Macedonia and Serbia. It is proposed that the office of
                    transit process will be completed digitally using GVMS. Traders can become registered as
                    "authorised
                    consignees" so that transit movements can end at their premises.</li>
                <li><strong>Simplified Declarations</strong>: Traders moving Controlled Goods may be able to use
                    simplified declarations to reduce process requirements at the border (this requires
                    authorisation).
                    Declarations can be made either by way of an EIDR or a simplified frontier declaration (which
                    requires less information than a full declaration), with additional information submitted to
                    HMRC
                    via a supplementary declaration at a later date.</li>
                <li><strong>Authorised Economic Operator Status:</strong> Traders can apply for AEO status for
                    moving
                    goods between Great Britain and the EU. This provides quicker access to simplified customs
                    procedures and, in certain circumstances, the right to fast-track shipments through certain
                    customs
                    and S&amp;S procedures.</li>
            </ul>
            <p><strong>STAGE 2</strong> - <strong>from 1 April 2021:</strong> at this stage there will be no changes
                to
                the Core Model, but additional requirements will be placed on certain goods subject to SPS controls.
            </p>

            <p><strong>STAGE 3</strong> - <strong>from</strong> <strong>1 July 2021</strong>:</p>

            <ul>
                <li><strong>Customs Declarations</strong>: Full import declarations will be required for all goods.
                    However simplified declaration procedures are available, which involve a simplified customs
                    declaration or alternatively an EIDR, followed by a supplementary declaration at a later date.
                    Imported goods will be subject to either (depending on the border location operator's choice)
                    the
                    "temporary storage model", where goods can be stored up to 90 days before being declared to
                    customs,
                    or (as noted above) the new "pre-lodgement model" supported by GVMS, where a customs declaration
                    is
                    submitted in advance of boarding on the EU side and processed <em>en route</em>.</li>
                <li><strong>Safety &amp; Security (S&amp;S) Declarations</strong>: Carriers will be required to
                    provide
                    entry summary declarations.</li>
                <li><strong>GVMS</strong>: GVMS will be in place for all imports, exports and transit movements at
                    border locations that have introduced it.</li>
                <li><strong>Additional requirements</strong>: Additional requirements will be introduced for certain
                    goods subject to SPS controls.</li>
            </ul>

            <p>In expansion the moment facilitations specified over, dealers will moreover be able to utilize
                traditions
                uncommon methods to suspend, decrease or claim help on the installment of traditions obligations and
                VAT
                beneath certain conditions. These incorporate traditions warehousing, internal and outward
                preparing,
                brief confirmation (for dealers set up exterior the UK bringing in products for particular
                employments)
                and approved utilize (for merchandise put to a endorsed conclusion utilize).</p>

            <h3>Exporting goods</h3>

            <p>Send out methods will be presented all at once on 1 January 2021:</p>

            <ul>
                <li><strong>Customs Declarations</strong>: British businesses sending goods from Great Britain will
                    have
                    to complete an export declaration.</li>
                <li><strong>Safety &amp; Security (S&amp;S)</strong>: Carriers will be required to provide S&amp;S
                    pre-arrival information by way of either a single export declaration that combines customs and
                    S&amp;S data, or a standalone exit summary declaration.</li>
                <li><strong>Additional requirements</strong>: There will be additional requirements for a range of
                    specific goods. For example, a licence will be required to export controlled dual-use items to
                    the
                    EU; a new Open General Export Licence has been created for this purpose.</li>
            </ul>
            <p>A number of procedures will be available to facilitate exports, including:</p>
            <ul>
                <li><strong>Travel</strong>: Exporters may be able to utilize the CTC to encourage their trade. For
                    this
                    reason they will have to be get it the office of travel handle for the custom domain their
                    products
                    are entering after taking off Awesome Britain. Businesses can ended up enrolled as "approved
                    consignors", which permits them to commence travel developments from their claim premises,
                    instead
                    of taking them to a government office of flight. </li>
                <li><strong>Customs Supervised Exports (CSE) and Designated Export Places (DEP)</strong>: business
                    premises can be authorised to consolidate and clear goods inland prior to their removal to the
                    point
                    of departure, instead of re-presenting the goods at the UK border.</li>
                <li><strong>Simplified Export Declaration</strong>: Exporters can be authorised to make a simplified
                    export declaration, or an EIDR, followed by a supplementary export declaration. An exit summary
                    declaration may or may not be required, depending on circumstances.</li>
            </ul>
            <p><sup>1</sup> The Border Working Show does not cover Northern Ireland, which is the subject of the
                Northern Ireland Convention.</p>

            <h3><a style="color:#fff;" target="_blank" name="_edn2"></a><strong><u>For more information : Please
                        visit
                        the following websites.</u></strong></h3>

            <ul>
                <li>HMRC (Her Majesty's Revenue and Customs) - <a style="color:#fff;" target="_blank"
                        href="http://www.hmrc.gov.uk/">http://www.hmrc.gov.uk/</a></li>
                <li>UKTI (UK Trade &amp; Investment) - <a style="color:#fff;" target="_blank"
                        href="http://www.ukti.gov.uk/">http://www.ukti.gov.uk</a></li>
                <li>IOE (The Institute of Export) – <a style="color:#fff;" target="_blank"
                        href="http://www.export.org.uk/">http://www.export.org.uk</a></li>
                <li>UKFT (UK Fashion &amp; Textile Association) - <a style="color:#fff;" target="_blank"
                        href="http://www.ukft.org/">http://www.ukft.org/</a></li>
                <li>BCC (British Chamber of Commerce) - <a style="color:#fff;" target="_blank"
                        href="http://www.britishchambers.org.uk/">http://www.britishchambers.org.uk/</a></li>
                <li>ECO (Export Control Organisation) - <a style="color:#fff;" target="_blank"
                        href="https://www.gov.uk/government/organisations/export-control-organisation">https://www.gov.uk/government/organisations/export-control-organisation</a>
                </li>
            </ul>

        </div>
        <div id="content-coun10" class="country-content" *ngIf="selectedCountry === 'coun10'">
            <br>
            <p>The UAE has been actually situated as a center for worldwide exchange between Asia and Europe on an
                east-west hub and the CIS and Africa on north– south hub. Depending on where products are ordained
                for,
                the U.A.E. can be classified as Terrain Traditions Zones or Free Exchange Zones. In common,
                merchandise
                ordained for the U.A.E.'s Traditions Zones are subject to obligation beneath the GCC's Common
                Traditions
                Law whereas products predetermined for Gratis Exchange Zones are absolved from obligation. The
                U.A.E. is
                house to around thirty-seven <a style="color:#fff;" href="http://www.uaefreezones.me/"
                    target="_blank">Free
                    Trade Zones.</a></p>

            <table style="width:100%;text-align:left;    font-size: 16px;" class="mytablecl">
                <tbody>
                    <tr style="text-align:center;">
                        <td><strong>Import Procedure- Documentation and Process at a Glance</strong></td>
                    </tr>
                    <tr>
                        <td>Delivery Order from a shipping agent addressed to a company licensed in the UAE</td>
                    </tr>
                    <tr>
                        <td>Original bill of loading for (seaports)</td>
                    </tr>
                    <tr>
                        <td>Unique receipt from the exporter tended to to a authorized merchant within the nation
                            specifying add up to amount, merchandise portrayal, and add up to esteem for each thing
                            (in
                            triplicate) *</td>
                    </tr>
                    <tr>
                        <td>Copy of the trade licence of buyer and seller</td>
                    </tr>
                    <tr>
                        <td>Certificate of origin approved by the Chamber of Commerce in the country of origin
                            detailing
                            the origin of goods *</td>
                    </tr>
                    <tr>
                        <td>Transport certificate *</td>
                    </tr>
                    <tr>
                        <td>The customs entry declaration</td>
                    </tr>
                    <tr>
                        <td>A shape or letter of exception from traditions obligations in cases where exclusion
                            necessities are satisfied, counting a Nearby Buy Arrange (LPO)</td>
                    </tr>
                    <tr>
                        <td>Detailed packing list: Weight, method of packing and HS code for each individual article
                            contained in the shipment*</td>
                    </tr>
                    <tr>
                        <td>Import permit from the competent agencies in the event of importing restricted goods; *
                        </td>
                    </tr>
                    <tr>
                        <td>A wellbeing or phytosanitary certificate or, for handled merchandise, an trade
                            certificate
                            affirming that the item is fit for human utilization</td>
                    </tr>
                    <tr>
                        <td>A halal certificate for meat ingredients*</td>
                    </tr>
                    <tr>
                        <td>A non-radiation certificate for some products (optional for European products) *</td>
                    </tr>
                    <tr>
                        <td>Transport documents that are required for import clearance.</td>
                    </tr>
                    <tr>
                        <td>Reports with a must be confirmed by the Government office of the Joined together Middle
                            easterner Emirates and the Chamber of Commerce within the nation of root of the items.
                            For more information on the UAE Customs, please visit the <strong><a style="color:#fff;"
                                    href="https://www.fca.gov.ae/En/UAE-Customs/Pages/CustomsInUAE.aspx"
                                    target="_blank">website</a></strong> of the Federal Customs Authority.

                            &nbsp;</td>
                    </tr>
                </tbody>
            </table>
            <br>
            <p>Check the latest updated norms for GCC Customs Law that set the framework for UAE’s Import
                Regulations
                here <a style="color:#fff;"
                    href="http://www.dubaicustoms.gov.ae/en/Publications/Documents/GCCCommonCustomsLawEnglish.pdf"
                    target="_blank">GCC Common Customs Law</a></p>


        </div>
        <div id="content-coun11" class="country-content" *ngIf="selectedCountry === 'coun11'">
            <h3>Purport in USA with lemonmode - Get universal skill to assist your commerce in USA</h3>

            <p>Whether its bringing in or trading to a endless showcase like USA, we have the specified encounter
                and
                all the administrations beneath one umbrella to handle it all for you. Our associations with
                presumed
                Licensed Customs brokers in US assist you with on-site traditions clearance at all worldwide portals
                we
                benefit.</p>

            <p>Our team has extensive country-by-country knowledge of customs regulations. With long standing
                relationships of over 2 decades with Customs authorities to enable fast Customs clearance, since we
                make
                sure that majority of all dutiable shipments are cleared prior to approval in the US.</p>

            <p>With committed operations work drive, you'll rest ensured that fortunate printed fabric is ready and
                holding up for objective entryway long a few time as of late the shipments arrive.</p>

            <p>We guarantee speedier turn-around and way better control of the method with dynamic information
                suited to
                legitimate purport compliance.</p>

            <p>In keeping with lemonmode’s commitment to the most noteworthy level of competence, we offer
                proficient
                direction our group of specialists give direction for Worldwide Exchange Issues and Compliance for
                Traditions Brokerage Administrations, Exchange Administrative Compliance as well as exchange
                advancement
                administrations.</p>

            <h1>General Requirements</h1>

            <h3>Power of Attorney (POA)</h3>

            <p>In a few Traditions clearance circumstances, lemonmode needs a properly-executed POA to clear the
                merchandise in your title and to get ready the essential Traditions archives to guarantee a smooth
                clearance handle.</p>

            <p>Once a POA is completed, the company who executed the POA, is alluded to and acts as the Merchant of
                Record (IOR) with US Traditions.</p>

            <p>We at that point watch out of the point by point steps fundamental to ensure a consistent exchange
                and to
                supply you with a single source for all your shipping needs.</p>

            <p>It is vital to have the POA executed well in development of entry in arrange to anticipate clearance
                delay. Hence, lemonmode must have the POA on record some time recently these sorts of clearances can
                start.</p>

            <h3>lemonmode requires POA for the following clearance situations:</h3>

            <ol>
                <li>When the consignee request that entry be processed in their name</li>

                <li>All goods whether American and Foreign made goods valued over $800</li>

                <li>All stock returning to the Joined together States after having been exchanged for introduction,
                    utilized abroad, repaired on board, energize arranged abroad, etc.</li>

                <li>Shipments containing alcoholic beverage and/or tobacco products</li>

                <li>Shipments containing civil aircraft parts (including HTS 8803 and civil aircraft agreements)
                </li>

                <li>All shipment entries that are required to be flagged for Reconciliation Shipments that require
                    these
                    special types of formal entry</li>

                <li>Shipments of goods regulated by Food and Drug (FDA), Fish &amp; Wildlife (FWS), US Dept of
                    Agriculture (USDA), and Partner Government Agency (PGA) valued over $5,000 USD </li>

                <li>Shipments entered duty free under various International Trade Agreements (e.g. GSP, NAFTA,
                    DR-CAFTA,
                    Singapore FTA, etc) when:<br>
                    i. For all textile shipments over $5,000<br>
                    ii. For all non-textile shipments over $50,000</li>
            </ol>

            <p>For more updated details Click on this website- US Customs and Border Protection</p>

            <h3>Manager Distinguishing proof NUMBER (EIN), Inner Income Administrations Charge NUMBER (IRS) OR
                Harbour
                Relegated NUMBER</h3>

            <p>All U.S. Traditions brokers are required to report the consignee’s Manager Recognizable proof Number
                (EIN) or IRS number when the clearance is in that company’s title. The Merchant of Record’s EIN or
                IRS
                is given amid the execution of the Control of Lawyer (POA), which could be a necessity by U.S.
                Traditions &amp; Border Assurance (CBP). When the IOR does not have an EIN or IRS number, a
                Traditions
                Doled out Number can be gotten. Regularly, these traditions relegated numbers are for outside
                merchants
                of Record and lemonmode can help clients with this handle.</p>

            <p>The approval is essential to the POA execution handle, the taking after sorts of confirmation ought
                to be
                given at the same time with the POA in arrange to maintain a strategic distance from any traditions
                clearance delay.</p>

            <table width="100%" style="text-align:left;">
                <tbody>
                    <tr>
                        <td valign="top"><strong>Acceptable Proof (Companies)</strong>
                        </td>
                        <td valign="top"><strong>Acceptable Proof (Individuals)</strong></td>
                    </tr>
                    <tr>
                        <td valign="top">
                            <ul>
                                <li>Copies of IRS forms</li>

                                <li>Form 147C</li>

                                <li>Form 1040</li>

                                <li>Form 2363</li>

                                <li>Form 941/941-V</li>

                                <li>Form SS-4*</li>

                                <li>Form 1065</li>

                                <li>Form 8109/8109-C</li>

                                <li>Form 7004</li>

                                <li>Form 355-ES</li>

                                <li>Form 1096</li>

                                <li>Form 1120/1120-S</li>

                                <li>Form 8879</li>

                                <li>Form W-2</li>
                            </ul>

                            *An SS-4 or W4 with IRS validation / approval is acceptable for SS# proof.
                        </td>
                        <td valign="top">Copy of front and back of Social Security Card</td>
                    </tr>
                    <tr>
                        <td valign="top">Letter from IRS</td>
                        <td valign="top">Front page of IRS 1040 Form</td>
                    </tr>
                </tbody>
            </table>
            <h3>Customs Clearances</h3>
            <p>There are generally three types of Customs clearances in the United States. </p>

            <table width="100%" style="text-align:left;">
                <tbody>
                    <tr>
                        <td valign="top"><strong>Entry Type</strong></td>
                        <td valign="top"><strong>Clearance Parameters</strong></td>
                        <td valign="top"><strong>Customs Clearance Process</strong></td>
                        <td valign="top"><strong>Importer Requirements</strong></td>
                    </tr>
                    <tr>
                        <td valign="top"><strong>De Minimis</strong></td>
                        <td valign="top">$0 to $800 USD

                            General Commodities

                            Restrictions may apply:

                            <ul>
                                <li>Partner Government Agency</li>

                                <li>Licensed Goods</li>

                                <li>Alcohol &amp; Tobacco</li>
                            </ul>
                        </td>
                        <td valign="top">Consolidated Manifest Clearance as Section 1321


                            DUTY FREE clearance</td>
                        <td valign="top">&nbsp;</td>
                    </tr>
                    <tr>
                        <td valign="top"><strong>Informal </strong>

                            <strong> </strong>
                        </td>
                        <td valign="top">Informal $801 to $2,500 USD American Goods Returned General Commodities


                            Restrictions may apply:
                            <ul>
                                <li>Partner Government Agency</li>

                                <li>Licensed Goods</li>

                                <li>Alcohol &amp; Tobacco</li>
                            </ul>
                        </td>
                        <td valign="top">Customs Clearance as Express
                            Consignment “Informal” manifest clearance under DHL’s bond

                            DUTY payment required</td>
                        <td valign="top">&nbsp;</td>
                    </tr>
                    <tr>
                        <td rowspan="2"><strong>Formal</strong></td>
                        <td valign="top">$2,501 to $100,000 USD</td>
                        <td valign="top">
                            <ul>
                                <li>Formal passage prepare</li>
                                <li>Shipments are held for isolated Computerized Broker Interface (ABI) passage.
                                    Obligation and Stock Handling Charge (MPF) installment required</li>
                            </ul>
                        </td>
                        <td valign="top">Proof of Tax ID Number (EIN)

                        </td>
                    </tr>
                    <tr>
                        <td valign="top">Over $100,000 and restricted commodities:
                            <ul>
                                <li>Partner Government Agency</li>

                                <li>Licensed Goods</li>

                                <li>Alcohol &amp; Tobacco</li>

                                <li>Civil Aircraft Parts</li>

                                <li>American Goods Returned Free Trade Agreements</li>
                            </ul>
                        </td>
                        <td valign="top">
                            <ul>
                                <li>Formal entry process</li>
                                <li>Shipments are held for separate Automated Broker Interface (ABI) entry. Duty and
                                    Merchandise Processing Fee (MPF)</li>
                            </ul>
                        </td>
                        <td valign="top">
                            <ul>
                                <li>Power of Attorney (POA) and Customs Bond</li>
                                <li>Continuous Bond</li>
                                <li>Single Transaction Bond</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h3>CUSTOMS BONDS</h3>

            <p>A Traditions Bond could be a contract utilized to guarantee that a individual or company will perform
                commitments related to the passage of stock arriving from a remote origin into the Joined together
                States. This bond may be a necessity for all passages and must be acquired from a CBP endorsed
                protections (surety) company. The bond is utilized in lieu of posting a cash store with the U. S.
                Government and lemonmode will help you with this handle. In case the passage is made with lemonmode
                or
                its accomplice systems as the Merchant of Record, at that point lemonmode’s Traditions Bond will
                fulfill
                the necessities and there's no require for the client to post their claim bond. In any case, in case
                the
                client is the Merchant of Record, the IOR is required to have a Traditions Bond. Two sorts of bond
                are
                accessible for Traditions passages:</p>
            <ul>

                <li>Single Exchange Bond (STB) or Single-Entry Bond (SEB)- Can as it were be utilized for one
                    Traditions
                    exchange or passage.</li>

                <li>Persistent Exchange Bond (CTB) is utilized to cover all Traditions exchanges or shipments inside
                    a
                    year. A CTP ought to be gotten earlier to shipments to assist dodge delays in travel.</li>
            </ul>

            <p style="text-align:center;"><a style="color:#fff;" href="https://hts.usitc.gov/current"
                    target="_blank">View
                    Harmonized Tariff Schedule on USA Customs Official Site</a></p>

        </div>



        <!--<button class="button button--aylen button--border-thick button--inverted button--text-upper button--size-s"><a style="color:#fff;"  href="https://www.ahcdo.org.au/resources/public-resources/find-a-haemophilia-treament-centre" target="_blank">Click here for the complete list</a></button>-->
    </div>
    <div class="coun12 box textstcl" style="display: none;">
        <br>
        <h3>Export Procedure:</h3>

        <p>In order to export to Japan, exporters must declare the following to the Director-General of Customs:</p>
        <ul>
            <li>Nature of goods</li>
            <li>Quantity</li>
            <li>Price,</li>
            <li>Any other necessary particulars.</li>
        </ul>

        <p>After the fundamental physical examination, an send out allow must too be gotten. The exporter or the
            Customes broker named by them should get ready the trade announcement and attache solicitations and
            other supporting records required by Japanese laws and directions other than the Traditions Law
            (hereinafter alluded to as "other laws and controls "), by other archives, such as grants, endorsements,
            or licenses (e.g., exportation of deliberately touchy materials beneath the control of the Service of
            Economy, Exchange and Industry).</p>

        <p>The submitted trade statement is checked against solicitations and other supporting reports at
            Customs.Document checking is conducted when a measurable classification is accurately made agreeing to
            the Trade Measurable Plan, when the desired consent or endorsement is secured with regard to germane
            merchandise, and when a adjust application for endorsed extract charge exclusion goes with the
            merchandise which are to be exempted.</p>

        <p>In checking the submitted reports, Traditions chooses whether the merchandise ought to be physically
            inspected to find out the rightness of the classification of merchandise and to see whether the
            examinations required by laws and controls other than the Traditions Law have been completed.</p>

        <p>In guideline, Traditions examinations of merchandise are conducted at a Traditions examination zone
            within the Customhouse or where the products are put away in cases where the merchandise cannnot be
            brought to the Traditions examination zone.</p>

        <p>At the time of send out statement, the exporter is asked to yield two duplicates of the send out report.
            One is for insights and the other is kept at Traditions for needs such as trade certification.</p>

        <h3>Documents to Be Submitted</h3>

        <p>The following documents must be submitted to Customs.</p>

        <ul>
            <li>Export Declaration (Customs form C-5010)</li>
            <li>Invoice</li>
            <li>Other documents: Certifications, permits, or approvals required by other laws and regulations.</li>
        </ul>

        <h3>Affirmation of Other Laws and Directions</h3>

        <p>In expansion to the Traditions law, depending on the sort of cargo, there are cases which require a
            earlier allow or endorsement for send out of the cargo some time recently trade announcement. These must
            be issued by the other specialists, such as the Service of Economy, Exchange and Industry, and the
            Service of Wellbeing, Work and Welfare, in understanding with stipulations in other laws and directions.
        </p>

        <p>Agreeing to the stipulations of these other laws and directions, exporters of cargo, who are required to
            get licenses, or endorsements or pass examinations, must demonstrate to Traditions that these
            prerequisites have been met amid the Traditions clearance method, which at that point ought to be
            affirmed. Unless these prerequisites are demonstrated and affirmed, Traditions will not allow the cargo
            to be traded (Traditions Law, Article 70).</p>

        <p>The reason of these laws and controls is to direct unhindered and scattered sends out and to help within
            the ordinary advancement of outside exchange by either forbidding or confining the trade of certain
            cargoes. These laws and controls were sanctioned to attain regulatory goals through the affirmation of
            required licenses, endorsements, completion of inspection, and other conditions within the physical
            nearness of cargo at Traditions as the ultimate check-point.</p>

        <p>There are 15 laws and regulations concerning exports, of which major ones are as follows:</p>

        <p>(1) Export Trade Control Order</p>

        <p>(2) Export Exchange Control Order</p>

        <p>(3) Export-Import Trading Law</p>

        <p>(4) Law for the Protection of Cultural Properties</p>

        <p>(5) Forest Seeding Law</p>

        <p>(6) Law Concerning Wildlife Protection and Hunting</p>

        <p>(7) Narcotics and Psychotropics Control Law</p>

        <p>(8) Cannabis Control Law</p>

        <p>(9) Opium Law</p>

        <p>(10)Stimulant Drug Control Law</p>

        <p>For more information: <a href="https://www.customs.go.jp/english/summary/export.htm" target="_blank"
                style="    color: #fff;">Read here</a> </p>

        <p>Any person wishing to import goods must declare them to the Director-General of Customs and obtain an
            import permit after examination (if necessary) of the goods concerned. The formalities start with the
            lodging of an import declaration and end with issuance of an import permit after the necessary
            examination and payment of Customs duty and excise tax. In this way, measures are taken to ensure the
            fulfillment of the requirements for the control of foreign exchange and other regulations concerning the
            importation of goods. More than 90 percent of import procedures is currently computerised.</p>

        <p>All steps and required documents are available on the <strong><a
                    href="https://www.customs.go.jp/english/summary/import.htm" target="_blank"
                    style="    color: #fff;">website</a></strong> of Japan Customs.</p>

        <p>The <strong><a href="https://www.customs.go.jp/zeikan/seido/telephone_e.htm" target="_blank"
                    style="    color: #fff;">Customs Counsellor System</a></strong> assists companies with import
            procedures.</p>

        <h3>Specific Import Procedures</h3>

        <p>Under the "immediate import permission system upon arrival", import permission may be granted as soon as
            cargo entry is confirmed. To be eligible for this system, importers must file a <strong><a
                    href="https://www.naccs.jp/e/" target="_blank" style="    color: #fff;">preliminary declaration
                    online.</a></strong></p>

        <p>For goods whose value is less than or equal to JPY 100,000,<strong> <a
                    href="https://www.customs.go.jp/english/summary/kani_e.pdf" target="_blank"
                    style="    color: #fff;">a simplified declaration system applies.</a></strong></p>

        <h3>Importing Samples</h3>

        <p>Japan acceded to the <strong><a href="https://www.atacarnet.com/" target="_blank"
                    style="    color: #fff;">ATA</a></strong> Tradition in 1973. Products imported from contracting
            nations and domains can for the most part be subject to a strategy on the premise of the ATA Tradition
            and be exempted from Traditions obligation and extract charges payable. To discover out more, if you
            don't mind visit the <strong><a href="https://www.customs.go.jp/english/summary/temporary.htm"
                    target="_blank" style="    color: #fff;">Japan Customs website</a></strong></p>

    </div>
    <!--<button class="button button--aylen button--border-thick button--inverted button--text-upper button--size-s"><a style="color:#fff;"  href="http://ahadap.org/jberp/uploads/2020/04/Thailand.pdf" target="_blank">Click here for the complete list</a></button>-->

</div>

<!-- End Single Service Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <!-- <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required=""
                            autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->
