import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrackingOrderService {

  constructor(private _httpClient: HttpClient) { }

  getTrackingOrderDetails(selectedType, value: string): any {
    return this._httpClient.get<any>(environment.apiBaseUrl + '/tracking-detail/get-tracking-shipment-details', {
      params: {
        value: value,
        selectedType: selectedType
      }
    });
  }
}
