<!-- Footer Area-->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <div class="logo">
                        <img src="assets/img/lemonmode_logo.png" alt="logo">
                    </div>
                    <div class="icon">
                        <p><i class='bx bxs-location-plus'></i>India, Canada, Japan,
                            Germany, Australia, US,
                            UK, New Zealand, .....</p>
                    </div>
                    <ul class="footer-socials">
                        <li><a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://twitter.com/" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://in.linkedin.com/" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="https://www.instagram.com/" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-80">
                    <h3>Company</h3>

                    <ul class="footer-text">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/services">Services</a></li>
                        <!-- <li><a routerLink="/">Features</a></li>
                        <li><a routerLink="/">Cost calculator</a></li>
                        <li><a routerLink="/">Latest News</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-50">
                    <h3>Support</h3>

                    <ul class="footer-text">
                        <li><a routerLink="/faq">FAQ’s</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                        <!-- <li><a routerLink="/">Community</a></li> -->
                        <li><a routerLink="/contact">Contact Us</a></li>
                        <li><a routerLink="/documentation">Api's</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <h3>Contact Info</h3>

                    <ul class="info-list">
                        <li><i class='bx bxs-location-plus'></i> 6th Floor, Barbosa, Sidney</li>
                        <li><i class='bx bxs-envelope'></i> <a href="mailto:hello@lemonmode.com">hello@lemonmode.com</a>
                        </li>
                        <li><i class='bx bxs-envelope'></i> <a
                                href="mailto:contact@lemonmode.com">contact@lemonmode.com</a>
                        </li>
                        <li><i class='bx bxs-phone'></i> <a href="tel:+0123456789">+0123 456 789</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Footer bottom Area -->
<!-- <div class="footer-bottom">
    <div class="container">
        <p>© Lemonmode is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
    </div>
</div> -->
<!-- End Footer bottom Area -->

<!-- Go Top -->
<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
</div>
<!-- End Go Top -->
