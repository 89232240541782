import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { switchMap, tap } from 'rxjs/operators';
import { Pagination } from 'app/components/common/pagination/pagination.types';
import { CustomerSubscriptionPlan } from 'app/models/customer_subscription_plan_service.model';

@Injectable({
    providedIn: 'root',
})
export class CustomerSubscriptionPlanService {

    private _customersubscriptions: BehaviorSubject<CustomerSubscriptionPlan[] | null> =
        new BehaviorSubject(null);

    constructor(
        private _httpClient: HttpClient,
    ) { }

    /**
     * Getter for contactus
     */
    get customersubscriptions$(): Observable<CustomerSubscriptionPlan[]> {
        return this._customersubscriptions.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    getCustomerSubscriptionPlanById(id): Observable<any> {
        return this._httpClient
            .get<any>(
                environment.apiBaseUrl + '/customer-subscription/get-subscription-by-customer-id/' + id,
            );
    }

    getTotalRecord(): Observable<any> {
        return this._httpClient
            .get<any>(
                environment.apiBaseUrl + '/customer/admin-dashboard-count',
            );
    }
}
