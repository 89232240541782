import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerSubscriptionPlanService } from 'app/services/customer_subscription_plan_service.service';
import { LocalService } from 'app/services/local.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  customer_id: string;
  customersubscriptionPlanData: any;
  customer_first_name: any;
  customer_last_name: any;
  totalRecord;
  isSuperAdmin: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private _customerSubscriptionPlanService: CustomerSubscriptionPlanService,
    private _localService: LocalService,
    private _router: Router) { }

  ngOnInit(): void {
    this._customerSubscriptionPlanService.getTotalRecord().subscribe((res) => {
      this.totalRecord = res.data;
    });
    const customerData = this._localService.customerDetail();
    this.customer_id = customerData ? customerData?._id : '64a4c7d921212121211f1669';
    this.customer_first_name = customerData ? customerData.first_name : 'admin';
    this.customer_last_name = customerData ? customerData.last_name : '';
    if (!customerData) { this.isSuperAdmin = true; }
    this.getCustomerSubscriptionPlanByCustomerId(this.customer_id);
  }

  navigateToHomePage(): void {
    // Navigate to the home page component with the fragment identifier
    this._router.navigate(['/'], { fragment: 'middle-section' });
  }

  getCustomerSubscriptionPlanByCustomerId(customer_id): any {
    this._customerSubscriptionPlanService.getCustomerSubscriptionPlanById(customer_id).pipe(takeUntil(this._unsubscribeAll)).subscribe((response) => {
      this.customersubscriptionPlanData = response.data;
    });
  }

  redirectOrderDetail(type): void {
    if (type) {
      this._router.navigate(['/customer/dashboard/view-order-detail']);
    }
  }

  redirectOrderDetailPage(): void {
    localStorage.setItem('filter', '1');
    this._router.navigate(['/admin/orders']);
  }
  redirectRechargeDetail(customer_id: string): void {
    this._router.navigate(['/customer/dashboard/', customer_id]);
  }

}
