import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LocalService } from '../local.service';
import { User } from 'app/models/admin/user.model';
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AdminUserService {
	isLogin = new BehaviorSubject(false);
	constructor(
		private _httpClient: HttpClient,
		private _localService: LocalService
	) { }

	login(formData): Observable<any> {
		const obj = {
			email: formData.email,
			password: formData.password,
		};
		return this._httpClient
			.post<any>(environment.apiBaseUrl + '/auth/login', obj)
			.pipe(
				switchMap((response: any) => {
					if (response.status === 200) {
						this._localService.setJsonValue('adminUser', response.user);
						this._localService.setJsonValue('adminToken', response.tokens.access.token);
						this.isLogin.next(true);
						return of(response);
					} else {
						return throwError(response);
					}
				})
			);
	}

	updateUserbyId(id: string, formData: User): any {
		return this._httpClient.put<User>(environment.apiBaseUrl + '/auth/update-profile/' + id, formData);
	}

	updatePassword(formData: User): any {
		return this._httpClient.post<User>(environment.apiBaseUrl + '/auth/change-password', formData);
	}

	getUserbyId(id: string): any {
		return this._httpClient.get<User>(environment.apiBaseUrl + '/auth/get-user/' + id);
	}

	forgotPassword(data): any {
		return this._httpClient.post<User>(environment.apiBaseUrl + '/auth/forgot-password', data);
	}

	resetPassword(token, data): any {
		return this._httpClient.post<User>(environment.apiBaseUrl + '/auth/reset-password/' + token, data);
	}

}
