import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { LocalService } from './services/local.service';
import { CustomerService } from './services/customer.service';
import { AmazonService } from './services/amazon.service';
import { ToastrService } from 'ngx-toastr';
import { AdminUserService } from './services/admin/user.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'lemonmode-ng';
    location: any;
    routerSubscription: any;
    isSuperAdmin: any = false;
    isShow: any = false;
    isAdminShow: any = false;
    isShowFooter: boolean = true;
    isShowNavigation: boolean = false;
    routeArray = [
        '/customer/order-file',
        '/customer/dashboard',
        '/admin/dashboard',
        '/admin/orders-file',
        '/customer/orders',
        '/admin/contact-list',
        '/admin/shipment-status',
        '/admin/uae-shipment-status',
        '/admin/subscription-plan',
        '/admin/carrier-company-configuration',
        '/admin/email-configuration',
        '/admin/customer-details',
        '/admin/orders',
    ];
    constructor(
        private router: Router,
        private _localService: LocalService,
        private _loginService: CustomerService,
        private _amazonService: AmazonService,
        private toaster: ToastrService,
        private _adminLoginService: AdminUserService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this._localService.isShowNavigation.subscribe((res) => {
            // res ? this.isShowNavigation = true : this.isShowNavigation = false;
            this.isShowNavigation = res ? true : false;
        });
        this.router.events.subscribe(
            (event: any) => {
                if (event instanceof NavigationEnd)
                {
                    if (event.url.startsWith('/admin/customer-details/'))
                    {
                        const dynamicId = event.url.split('/').pop(); // Extract dynamic ID from the URL
                        if (dynamicId)
                        {
                            // Remove the old URL with the previous dynamic ID from routeArray, if it exists
                            const oldUrlIndex = this.routeArray.findIndex(url => url.includes('/admin/customer-details/'));
                            if (oldUrlIndex !== -1)
                            {
                                this.routeArray.splice(oldUrlIndex, 1);
                            }

                            // Add the new URL with the current dynamic ID to routeArray
                            const newUrl = event.url;
                            this.routeArray.push(newUrl);
                        }
                    }
                    if (this.routeArray.includes(event.url))
                    {
                        this._loginService.isLogin.subscribe((res) => {
                            if (res)
                            {
                                this.isShow = true;
                            }
                            this._adminLoginService.isLogin.subscribe((resAd) => {
                                if (resAd)
                                {
                                    this.isAdminShow = true;
                                }
                            });
                        });
                        this.isShowFooter = false;
                    } else
                    {
                        if (event.url === '/documentation')
                        {
                            this.isShowFooter = false;
                        } else
                        {
                            this.isShowFooter = true;
                        }
                        this.isShow = false;
                        this.isAdminShow = false;
                    }
                }
            }
        );

        this.recallJsFuntions();
        const localStorage_data = this._localService.getJsonValue('user');
        if (localStorage_data)
        {
            this.isSuperAdmin = localStorage_data.isSuperAdmin;
            this._loginService.isLogin.next(true);
            this.route.queryParams
                .subscribe((paramsArg) => {
                    const { tracking_number, ...params } = paramsArg;
                    if (Object.keys(params).length > 0)
                    {
                        this._amazonService.exchangeLWAAuthorizationCode({ ...params, customer_id: localStorage_data._id, customer_email: localStorage_data.email }).subscribe({
                            next: (payload: any) => {

                            },
                            error: ({ error }) => {
                                this.toaster.error(error ? error.message : 'Something went wrong.', '');
                            },
                        });
                    }
                }
                );
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe from the routerSubscription to prevent memory leaks
        if (this.routerSubscription)
        {
            this.routerSubscription.unsubscribe();
        }
    }

    recallJsFuntions(): void {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart)
                {
                    $('.preloader').fadeIn('slow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe((event) => {
                $('.preloader').fadeIn('slow');
                $.getScript('../assets/js/custom.js')
                    .done(() => {
                        $('.preloader').fadeOut('slow');
                        this.location = this.router.url;
                        window.scrollTo(0, 0);
                    })
                    .fail(() => {
                        // Handle the case where the custom.js script failed to load
                        $('.preloader').fadeOut('slow');
                    });
            });
    }
}
