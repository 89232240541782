<div class="tabs-to-dropdown">
  <div class="nav-wrapper d-flex align-items-center justify-content-between">
    <ng-container *ngIf="isShow">
      <ul class="nav nav-pills d-none d-md-flex" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link" routerLink="/customer/dashboard" routerLinkActive="active">Dashboard</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" routerLink="/customer/order-file" routerLinkActive="active">Order File</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" routerLink="/customer/orders" routerLinkActive="active">Orders</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" routerLink="/authorization" routerLinkActive="active">Amazon Authorization</a>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="isAdminShow">
      <ul class="nav nav-pills d-none d-md-flex" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link" routerLink="/admin/dashboard" routerLinkActive="active">Dashboard</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" routerLink="/admin/orders-file" routerLinkActive="active">Order File</a>
        </li>
        <li class="nav-item"><a routerLink="/admin/orders" class="nav-link" routerLinkActive="active">Orders</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" routerLink="/admin/contact-list" routerLinkActive="active">Contact List</a>
        </li>
        <li class="nav-item"><a routerLink="/admin/subscription-plan" class="nav-link"
            routerLinkActive="active">Subscription Plan</a>
        </li>
        <li class="nav-item"><a routerLink="/admin/customer-details" class="nav-link" routerLinkActive="active">Customer
            Details</a>
        </li>
        <li class="nav-item1">
          <a href="javascript:void(0)" class="nav-link">General
            Settings
            <i class='bx bx-chevron-down'></i></a>
          <ul class="dropdown-menu">
            <li class="nav-item1">
              <a routerLink="/admin/carrier-company-configuration" routerLinkActive="active" class="nav-link">Carrier
                Company Configuration
              </a>
            </li>
            <li class="nav-item1"><a routerLink="/admin/email-configuration" class="nav-link"
                routerLinkActive="active">Email
                Configuration</a>
            </li>
            <li class="nav-item1" role="presentation">
              <a class="nav-link" routerLink="/admin/shipment-status" routerLinkActive="active">Shipment Status</a>
            </li>
            <li class="nav-item1" role="presentation">
              <a class="nav-link" routerLink="/admin/uae-shipment-status" routerLinkActive="active">Shipment
                Status(UAE)</a>
            </li>
          </ul>
        </li>
      </ul>
    </ng-container>
  </div>
</div>