import { Component, OnInit } from '@angular/core';
import { marketplaces } from 'app/shared/constant';


@Component({
  selector: 'app-amazon-page',
  templateUrl: './amazon-page.component.html',
  styleUrls: ['./amazon-page.component.scss']
})
export class AmazonPageComponent implements OnInit {
  selectedCountry: string | null = null;
  marketplaces = marketplaces;
  mostPopularMarketplaces = marketplaces.slice(0, 5); // Adjust the number as per your preference
  allMarketplaces = marketplaces;
  amazonButtonClicked = false;

  // redirectToMarketplace(url: string) {
  //   window.open(url, '_blank');
  // }
  constructor() { }

  ngOnInit(): void {
  }

  redirectToMarketplace(): any {
    // Check if a country is selected before redirecting
    if (this.selectedCountry) {
      const marketplace = this.getMarketplaceByCountryCode(this.selectedCountry);
      if (marketplace) {
        // Redirect to the selected country's seller central URL in a new tab
        window.open(marketplace.sellerCentralURL + `/apps/authorize/consent?application_id=amzn1.sp.solution.be44f24d-94fe-47db-9d7a-10ec6152d60e&state=-37131022&scope=
        sellingpartnerapi::migration&version=beta&redirect_uri=https://ctasis.com/`,
          '_blank');
      }
    }

    // Reset the selectedCountry to null after redirection
    this.selectedCountry = '';
  }

  // get country by country code from constant file
  getMarketplaceByCountryCode(countryCode: string): any {
    return marketplaces.find(marketplace => marketplace.countryCode === countryCode);
  }
  // when user click button then country will be displayed
  toggleOpened(): any {
    this.amazonButtonClicked = true;
  }
}
