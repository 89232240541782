import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'environments/environment';
import SecureStorage from 'secure-web-storage';

@Injectable({ providedIn: 'root' })
export class StorageService {
	SECRET_KEY = environment.SECRET_KEY;
	/* eslint-disable @typescript-eslint/explicit-function-return-type */
	public secureStorage = new SecureStorage(localStorage, {
		hash: (key: any) => {
			key = CryptoJS.SHA256(key, this.SECRET_KEY);
			return key.toString();
		},
		// Encrypt the localstorage data
		encrypt: (data: any) => {
			data = CryptoJS.AES.encrypt(data, this.SECRET_KEY);
			data = data.toString();
			return data;
		},
		// Decrypt the encrypted data
		decrypt: (data: any) => {
			data = CryptoJS.AES.decrypt(data, this.SECRET_KEY);
			data = data.toString(CryptoJS.enc.Utf8);
			return data;
		},
	});
}
