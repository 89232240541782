import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-volumetric-calculator',
  templateUrl: './volumetric-calculator.component.html',
  styleUrls: ['./volumetric-calculator.component.scss']
})
export class VolumetricCalculatorComponent implements OnInit {
  volumetricForm: FormGroup;
  unitForm: FormGroup;
  result: number; isSubmit = false;
  unitType;
  constructor(private toaster: ToastrService) { }

  ngOnInit(): void {
    this.volumetricForm = new FormGroup({
      length: new FormControl('', [Validators.required]),
      width: new FormControl('', [Validators.required]),
      height: new FormControl('', [Validators.required,]),
      volume_result: new FormControl(''),
    });
    this.unitForm = new FormGroup({
      weight_value: new FormControl('', [Validators.required]),
      kg: new FormControl(''),
      pound: new FormControl(''),
    });
  }

  calculate(): any {
    const data = this.volumetricForm.getRawValue();
    const length = data.length;
    const width = data.width;
    const height = data.height;
    const cubicInches = length * width * height;
    const dimensionalWeight = Math.ceil(cubicInches / 139);
    this.volumetricForm.get('volume_result').setValue(dimensionalWeight + ' ' + 'LBS');
  }

  onChangeUnit(event): any {
    this.unitType = event.target.value;
  }

  calculateUnit(): any {
    if (this.unitType === undefined || this.unitForm.invalid) {
      // this.toaster.error('Please select all fields.', '');
      this.isSubmit = true;
      return;
    }
    const data = this.unitForm.getRawValue();
    let kgValue; let poundValue;

    if (this.unitType === 'Kg') {
      poundValue = Math.round(data.weight_value * 2.20462);
      kgValue = data.weight_value;
    } else {
      kgValue = Math.round(data.weight_value * 0.453592);
      poundValue = data.weight_value;
    }

    this.unitForm.get('kg').setValue(kgValue);
    this.unitForm.get('pound').setValue(poundValue);
    this.unitForm.get('weight_value').setValue('');

  }

  stopScrollingWheel(e): any {
    return e.target.blur();
  }
}
