import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rate-transit-times',
  templateUrl: './rate-transit-times.component.html',
  styleUrls: ['./rate-transit-times.component.scss']
})
export class RateTransitTimesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  stopScrollingWheel(e): any {
    return e.target.blur();
  }
}
