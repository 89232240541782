import { Component, OnInit } from '@angular/core';
import { AdminUserService } from 'app/services/admin/user.service';
import { CustomerService } from 'app/services/customer.service';

@Component({
  selector: 'app-custom-navbar',
  templateUrl: './custom-navbar.component.html',
  styleUrls: ['./custom-navbar.component.scss']
})
export class CustomNavbarComponent implements OnInit {
	isShow: boolean = false;
	isAdminShow: boolean = false;
	constructor(
	  private _loginService: CustomerService,
	  private _adminUserService: AdminUserService,
	) { }

  ngOnInit(): void {
    this._loginService.isLogin.subscribe((res) => {
      if (res) {
        this.isShow = true;
      }
      this._adminUserService.isLogin.subscribe((resAd) => {
        if (resAd) {
          this.isAdminShow = true;
        }
      });
    });
  }

}
