import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TrackingOrderService } from 'app/services/tracking-order.service';
import { ToastrService } from 'ngx-toastr';
import { format } from 'date-fns';
import { Constants } from 'app/shared/constant';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-track-order',
  templateUrl: './track-order.component.html',
  styleUrls: ['./track-order.component.scss']
})
export class TrackOrderComponent implements OnInit {
  @ViewChild('trackingInput', { static: false }) trackingInput: ElementRef;
  @ViewChild('noOrderFile') noOrderFileTemplate!: ElementRef<any>;
  method: string = 'tracking_number';
  trackingNumber: string;
  orderNumber: string;
  OrderDetails: any;
  trackingDetails: any = [];
  noOrderFound: boolean = false;
  selectedType: string = 'tracking_number';
  placeholder = 'Enter tracking number';
  progressWidth = 0;
  carrierCompanySlug = Constants.carrierCompanySlug;
  actualTrackingDetails: any = [];
  isDisplayTrackingDetails: boolean;

  constructor(private _trackingOrderService: TrackingOrderService, private toaster: ToastrService,
    private _activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe((res: any) => {
      if (res && res.tracking_number) {
        this.getOrderTrackingDetails(res.tracking_number);
      }
    });
  }

  getOrderTrackingDetails(value: string): void {
    if (value === '') {
      return;
    }
    this.OrderDetails = null;
    this.actualTrackingDetails = [];
    this._trackingOrderService.getTrackingOrderDetails(this.selectedType, value).subscribe({
      next: (response: any) => {
        this.noOrderFound = false;
        this.OrderDetails = response.orderDetails;
        this.trackingDetails = response.trackingData;
        if (response.actualTrackingDetails && response.actualTrackingDetails.status === 200) {
          const { trackingData } = response.actualTrackingDetails;
          this.actualTrackingDetails = trackingData || [];
        }
        this.trackingDetails.sort((a, b) => new Date(b.status_index).getTime() - new Date(a.status_index).getTime());
        this.updateStatus();
        this.updateProgressBarWidth();
        value = '';
        this.trackingInput.nativeElement.value = '';
      },
      error: ({ error }) => {
        this.noOrderFound = true;
        // this.toaster.error(error ? error.message : 'Something went wrong.', '');
        this.OrderDetails = [];
        this.trackingDetails = [];
        this.trackingInput.nativeElement.value = '';
      },
    });
  }

  DispatchDate(date): any {
    const statusDate = new Date(date);
    return statusDate.setHours(statusDate.getHours() - 1);
  }

  toggleDetails(id: string): void {
    this.isDisplayTrackingDetails = this.isDisplayTrackingDetails ? false : true;
    if (this.isDisplayTrackingDetails) { document.getElementById('gotoTop').focus({ preventScroll: false }); }
  }

  onSelectNumber(event: string): any {
    this.selectedType = event;
    this.placeholder = (event === 'order_number') ? 'Enter order number' : 'Enter tracking number';
  }

  updateStatus(): void {
    let latestStatus = '';
    if (this.actualTrackingDetails.length > 0) {
      const dateObject = new Date(this.actualTrackingDetails[0].date);
      const formattedDate = format(dateObject, 'yyyy-MM-dd');
      latestStatus = this.actualTrackingDetails[0].status + '\n' + formattedDate;

    } else if (this.trackingDetails.length > 0) {
      const dateObject = new Date(this.trackingDetails[0].date);
      const formattedDate = format(dateObject, 'yyyy-MM-dd');
      latestStatus = this.trackingDetails[0].status + '\n' + formattedDate;
    }

    setTimeout(() => {
      const deliverstatusInput: HTMLInputElement = document.getElementById('deliverstatus') as HTMLInputElement;
      if (deliverstatusInput) {
        deliverstatusInput.value = latestStatus || 'Pending';
      }
    }, 300);
  }

  updateProgressBarWidth(): void {
    if (this.trackingDetails.length > 0) {
      const totalStatuses = 10;
      let latestStatusIndex = this.trackingDetails[0].status_index > totalStatuses ? 10 : this.trackingDetails[0].status_index;
      if (this.OrderDetails.isShipped === true) {
        latestStatusIndex = 10;
      }
      const progressWidthPercentage = (latestStatusIndex / totalStatuses) * 100;
      this.progressWidth = progressWidthPercentage;
    }
  }

  getSlug(cName): any {
    const cSlug = this.carrierCompanySlug.find(ele => ele.name === cName);
    return cSlug ? cSlug.slug : '';
  }
}
