import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-express-services',
  templateUrl: './express-services.component.html',
  styleUrls: ['./express-services.component.scss']
})
export class ExpressServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
