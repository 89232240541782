<app-preloader></app-preloader>
<ng-container *ngIf="isShowNavigation;else customerNavigation">
    <app-admin-navbar></app-admin-navbar>
    <ng-container *ngIf="isAdminShow">
        <app-custom-navbar></app-custom-navbar>
    </ng-container>
    <router-outlet></router-outlet>
    <ng-container *ngIf="isShowFooter">
        <app-footer *ngIf="!(location === '/coming-soon')"></app-footer>
    </ng-container>
</ng-container>

<ng-template #customerNavigation>
    <app-navbar *ngIf="!(location === '/coming-soon')"></app-navbar>
    <ng-container *ngIf="isShow">
    <app-custom-navbar></app-custom-navbar>
</ng-container>
    <router-outlet></router-outlet>
    <ng-container *ngIf="isShowFooter">
        <app-footer *ngIf="!(location === '/coming-soon')"></app-footer>
    </ng-container>
</ng-template>
