<div class="page-banner">
	<div class="container-fluid" style="margin-top: 100px;">
		<div class="row">
			<div class="col">
				<nav id="navbar" style="overflow-y: auto; text-align: left; margin-top: 100px; height: 500px;">
					<header style="text-align: center;"><b style="font-size: 18px;">Devloper Documentation</b></header>
					<ul class="nav flex-column">
						<header style=" text-align: left; margin-top: 10px;">Introduction</header>
						<li class="nav-item">
							<a class="nav-link introduction" (click)="scroll(authentication)">Authentication</a>
						</li>
						<li class="nav-item">
							<a class="nav-link introduction" (click)="scroll(throttling)">Throttling Limit</a>
						</li>
						<header style=" text-align: left; margin-top: 20px;">APIs</header>
						<li class="nav-item">
							<a class="nav-link content" (click)="scroll(tracking_details)">Get Tracking Details</a>
						</li>
						<li class="nav-item">
							<a class="nav-link content" (click)="scroll(tracking_status)">Tracking Status Description</a>
						</li>
						<header style=" text-align: left; margin-top: 20px;">Programming Languages</header>
						<li class="nav-item">
							<a class="nav-link components" (click)="scroll(PHP)">Php</a>
						</li>
						<li class="nav-item">
							<a class="nav-link reference" (click)="scroll(Java)">Java</a>
						</li>
						<li class="nav-item">
							<a class="nav-link reference" (click)="scroll(Python)">Python</a>
						</li>
						<li class="nav-item">
							<a class="nav-link reference" (click)="scroll(CURL)">CURL</a>
						</li>
						<li class="nav-item">
							<a class="nav-link reference" (click)="scroll(HTTP)">HTTP</a>
						</li>
						<li class="nav-item">
							<a class="nav-link reference" (click)="scroll(c)">C#</a>
						</li>
					</ul>
				</nav>
				
			</div>

			<div class="col-md-9">
				<main id="main-doc">
					<section class="main-section" id="Introduction">
						<header>
							<h2 #authentication>Introduction</h2>
						</header>
						<article>
							<p style="margin-top: 30px;">Welcome, The purpose of Lemonmode API documentation is to help user understand and make
								use of API calls to get tracking details for lemonmode tracking number.</p>
						</article>
					</section>

					<section class="main-section" #Layout>
						<header>
							<h2>Authentication</h2>
						</header>
						<article>
							<p style="margin-top: 30px;">Making use of basic authentication, each request must include an authentication header,
								with a base-64 encoded value. Credentials for basic authentication is provided via mail.
							</p>
							<p style="margin-top: 20px;"> Code Example: <b style="background: black; color: white; padding: 5px; border-radius: 3px;"> 'Authorization: Basic [base64(username:password)]'</b></p>
							<hr>
						</article>
						<header>
							<h2 style="text-align: left; margin-top: 30px;" #throttling>Throttling Limit</h2>
						</header>
						<article>
							<p style="margin-top: 20px;">Throttle limit of API call is 25 hits per min.</p>
							<hr>
						</article>
					</section>

					<section class="main-section" id="Layout">
						<header>
							<h2>APIs</h2>
						</header>
						<article>
							<p>API section covers all calls used for the operations as mentioned below-</p>
							<h3 #tracking_details>Get Tracking Details</h3>
							<p>This call help us to retrieve all tracking details with respect to Lemonmode tracking Id
								or Order Number.
							</p>
							<p><b>Input parameters-</b> Lemonmode Tracking ID/ Order Number.</p>
							<p><b>Url end point-</b></p>
							<p><b>Sandbox-</b></p>
							<p>For Lemonmode Tracking number-</p>
							<p style="color: #3943f8;">http://{{this.url}}/sandbox?tracking_number={{ '{' }}lemon mode tracking number{{ '}' }}</p>
							<p>For Order number-</p>
							<p style="color: #3943f8;">http://{{this.url}}/sandbox?order_id={{ '{' }}Order number{{ '}' }}</p>
							<p><b>Production-</b></p>
							<p>For Lemonmode Tracking number-</p>
							<p style="color: #3943f8;">http://{{this.url}}/operations?tracking_number={{ '{' }}lemon mode tracking number{{ '}' }}</p>
							<p>For Order number-</p>
							<p style="color: #3943f8;">http://{{this.url}}/operations?order_id={{ '{' }}Order number{{ '}' }}</p>
							<p><b>Method-</b></p>
							<p>GET</p>
							<hr>
							<h3 #tracking_status>Tracking Status Description</h3>
							<div class="container table-responsive py-4">
								<table class="table table-bordered table-hover">
									<thead class="thead-dark">
										<tr>
											<th scope="col">Status</th>
											<th scope="col">Description</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>LOC</td>
											<td>Order Placed</td>
										</tr>
										<tr>
											<td>LITU</td>
											<td>Order Manifested</td>
										</tr>
										<tr>
											<td>LRU</td>
											<td>Shipment arrived at Hub</td>
										</tr>
										<tr>
											<td>LTI</td>
											<td>In Transit to India</td>
										</tr>
										<tr>
											<td>LAE</td>
											<td>Arrived at Europe Hub</td>
										</tr>
										<tr>
											<td>LAI</td>
											<td>Arrived in India</td>
										</tr>
										<tr>
											<td>LUC</td>
											<td>Under Custom Clearance</td>
										</tr>
										<tr>
											<td>OP</td>
											<td>Dispatched from Mumbai Hub</td>
										</tr>
										<tr>
											<td>Dispatched</td>
											<td>Order Dispatched</td>
										</tr>
										<tr>
											<td>OT</td>
											<td>Order in Transit</td>
										</tr>
										<tr>
											<td>OO</td>
											<td>Order Out for Delivery</td>
										</tr>
										<tr>
											<td>NDR</td>
											<td>OFailed Attempt at Delivery</td>
										</tr>
										<tr>
											<td>DL</td>
											<td>Order Delivered</td>
										</tr>
										<tr>
											<td>RTO</td>
											<td>Order Returned Back</td>
										</tr>
										<tr>
											<td>RTO-OO</td>
											<td>RTO out for delivery</td>
										</tr>
										<tr>
											<td>RTP</td>
											<td>RTO Reached Pickrr Warehouse</td>
										</tr>
										<tr>
											<td>Booked</td>
											<td>Order Booked</td>
										</tr>
										<tr>
											<td>Manifested</td>
											<td>Manifested</td>
										</tr>
										<tr>
											<td>RTS</td>
											<td>RTS</td>
										</tr>
										<tr>
											<td>RTO-IT</td>
											<td>RTO-In Transit</td>
										</tr>
										<tr>
											<td>RTO-Pending</td>
											<td>RTO Pending</td>
										</tr>
										<tr>
											<td>RTO-Dispatched</td>
											<td>RTO Dispatched</td>
										</tr>
										<tr>
											<td>LOST</td>
											<td>LOST</td>
										</tr>
										<tr>
											<td>Delivered</td>
											<td>Delivered</td>
										</tr>
										<tr>
											<td>Cancelled</td>
											<td>Cancelled</td>
										</tr>
										<tr>
											<td>Picked</td>
											<td>Picked</td>
										</tr>
										<tr>
											<td>Pickup Pending</td>
											<td>Pickup Pending</td>
										</tr>
										<tr>
											<td>Failed</td>
											<td>Failed</td>
										</tr>
									</tbody>
								</table>
							</div>
							<hr>

							<h3>Programming Languages</h3>
							<p>Section intro goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
								finibus condimentum nisl id vulputate. Praesent aliquet varius eros interdum suscipit.
								Donec eu purus sed nibh convallis bibendum quis vitae turpis. Duis vestibulum diam
								lorem, vitae dapibus nibh facilisis a. Fusce in malesuada odio.</p>
							<h3 #PHP>PHP</h3>
							<div style="display: flex;">
									<p><b class="code_example">Code Example:</b></p>
									<div style="margin-left: auto; margin-top: 35px; margin-right: 25px;" >
										<button (click)="copyData(test)"><i class='fa-solid fa-copy'  title="Copy" style="font-size: 25px; margin-left: auto;"></i></button>
								</div>
							</div>
							<pre>
										<code #test>
											<?php
											$curl = curl_init();
											curl_setopt_array($curl, array(
											CURLOPT_URL => 'http://{{this.url}}/operations?tracking_number=1710446256',
											CURLOPT_RETURNTRANSFER => true,
											CURLOPT_ENCODING => '',
											CURLOPT_MAXREDIRS => 10,
											CURLOPT_TIMEOUT => 0,
											CURLOPT_FOLLOWLOCATION => true,
											CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
											CURLOPT_CUSTOMREQUEST => 'GET',
											CURLOPT_HTTPHEADER => array(
											'Authorization: Basic [base64(username:password)]'
											),
											));
											$response = curl_exec($curl);
											curl_close($curl);
											echo $response;
											?></code>
									  </pre>
							<hr>
							<h3 #Java>Java</h3>
							<div style="display: flex;">
								<p><b class="code_example">Code Example:</b></p>
								<div style="margin-left: auto; margin-top: 35px; margin-right: 25px;" >
									<button (click)="copyData(test1)"><i class='fa-solid fa-copy'  title="Copy" style="font-size: 25px; margin-left: auto;"></i></button>
							</div>
						</div>
							<pre>
							<code #test1>
							OkHttpClient client = new OkHttpClient().newBuilder()
							  .build();
							Request request = new Request.Builder()
							  .url("http://{{this.url}}/operations?tracking_number=1710446256")
							  .method("GET", null)
							  .addHeader("Authorization", "Basic [base64(username:password)]")
							  .build();
							Response response = client.newCall(request).execute();
							</code>
							</pre>
							<hr>
							<h3 #Python>Python</h3>
							<div style="display: flex;">
								<p><b class="code_example">Code Example:</b></p>
								<div style="margin-left: auto; margin-top: 35px; margin-right: 25px;" >
									<button (click)="copyData(test2)"><i class='fa-solid fa-copy'  title="Copy" style="font-size: 25px; margin-left: auto;"></i></button>
							</div>
						</div>
							<pre>
							<code #test2>
								import http.client
								conn = http.client.HTTPSConnection("{{this.url}}")
								payload = ""
								headers = {{ '{' }}
								  "Authorization": "Basic [base64(username:password)]"
								  {{ '}' }}
								conn.request("GET", "/operations?tracking_number=1710446256", payload, headers)
								res = conn.getresponse()
								data = res.read()
								print(data.decode("utf-8"))</code>
							</pre>
							<hr>
							<h3 #CURL>CURL</h3>
							<div style="display: flex;">
									<p><b class="code_example">Code Example:</b></p>
									<div style="margin-left: auto; margin-top: 35px; margin-right: 25px;" >
										<button (click)="copyData(test3)"><i class='fa-solid fa-copy'  title="Copy" style="font-size: 25px; margin-left: auto;"></i></button>
								</div>
							</div>
							<pre>
							<code #test3>
							curl --location --request GET 'http://{{this.url}}/operations?tracking_number=1710446256' \
							--header 'Authorization: Basic [base64(username:password)]'</code>
							</pre>
							<hr>
							<h3 #HTTP>HTTP</h3>
							<div style="display: flex;">
									<p><b class="code_example">Code Example:</b></p>
									<div style="margin-left: auto; margin-top: 35px; margin-right: 25px;" >
										<button (click)="copyData(test4)"><i class='fa-solid fa-copy'  title="Copy" style="font-size: 25px; margin-left: auto;"></i></button>
								</div>
							</div>
							<pre>
							<code #test4>
							GET /operations?tracking_number=1710446256 HTTP/1.1
							Host: {{this.url}}
							Authorization: Basic [base64(username:password)]
							</code>
							</pre>
							<hr>
							<h3 #c>C#</h3>
							<div style="display: flex;">
								<p><b class="code_example">Code Example:</b></p>
								<div style="margin-left: auto; margin-top: 35px; margin-right: 25px;" >
								<button (click)="copyData(test5)"><i class='fa-solid fa-copy'  title="Copy" style="font-size: 25px; margin-left: auto;"></i></button>
							</div>
						</div>
							<pre>
							<code #test5>
							ar client = new RestClient('http://{{this.url}}/operations?tracking_number=1710446256');
							client.Timeout = -1;
							var request = new RestRequest(Method.GET);
							request.AddHeader('Authorization', 'Basic [base64(username:password)]');
							IRestResponse response = client.Execute(request);
							Console.WriteLine(response.Content);</code>
							</pre>
						</article>
					</section>
				</main>
				<hr>
				<footer>
					<div class="contact">
						<a href="https://www.facebook.com/" target="_blank">
							<span class="icon"><i class='bx bxl-facebook' style="font-size: 25px;"></i></span></a>
						<a href="https://twitter.com/" target="_blank">
							<span class="icon"><i class='bx bxl-twitter' style="font-size: 25px;"></i></span></a>
						<a href="https://in.linkedin.com/" target="_blank">
							<span class="icon"><i class='bx bxl-linkedin' style="font-size: 25px;"></i></span></a>
						<a href="https://www.instagram.com/" target="_blank">
							<span class="icon"><i class='bx bxl-instagram' style="font-size: 25px;"></i></span></a>
					</div>
					<p>Copyright ©</p>
				</footer>
			</div>
		</div>
	</div>
</div>
