<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <div class="search__field" style="width: 100%;">
                        <form (ngSubmit)="getOrderTrackingDetails(trackingInput.value)">
                            <select name="method" class="search__method" [(ngModel)]="method"
                                (change)="onSelectNumber($event.target.value)">
                                <option value="tracking_number">Tracking Number</option>
                                <option value="order_number">Order Number</option>
                            </select>
                            <input type="text" name="search" [placeholder]="placeholder" class="search__input"
                                #trackingInput style="width: 60%;">
                            <button type="submit" name="submit" class="search__button"><i
                                    class="fas fa-search"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<div *ngIf="OrderDetails">
    <div class="container" style="margin-bottom: 0px;">
        <article class="card">
            <header class="card-header"> My Orders / Tracking </header>
            <div class="card-body">
                <div class="d-flex justify-content-between">
                    <h6>Order Number: {{ OrderDetails?.order_number }}</h6>
                    <h6>Tracking Number: <span class="text-success"> {{ OrderDetails?.tracking_number }}</span></h6>
                </div>
                <article class="card">
                    <div class="card-body row">
                        <div class="col"> <strong>Customer Name: </strong> <br>{{ OrderDetails?.buyer_name ?
                            OrderDetails?.buyer_name : 'N/A' }} </div>
                        <div class="col" *ngIf="OrderDetails?.carrier_name"> <strong>Shipping BY:</strong> <br> {{
                            OrderDetails?.carrier_name }}
                            <!-- , | <i class="fa fa-phone"></i>
                            +1598675986 -->
                        </div>
                        <div class="col" *ngIf="OrderDetails?.carrier_name"> <strong>Tracking #:</strong> <br>
                            <span>
                                <ng-container *ngIf="(OrderDetails?.carrier_name !== ''); else noLink">
                                    <ng-container
                                        *ngIf="OrderDetails?.carrier_name !== 'Tirupati' && OrderDetails?.carrier_name  !== ''">
                                        <a>{{ OrderDetails?.carrier_tracking_number }}
                                        </a>
                                    </ng-container>
                                    <ng-container *ngIf="OrderDetails?.carrier_name === 'Tirupati'">
                                        <a href="http://shreetirupaticourier.net/Frm_DocTrack.aspx?docno={{ OrderDetails?.carrier_tracking_number }}"
                                            target="_blank">{{ OrderDetails?.carrier_tracking_number }}
                                            <i class='bx bxs-hand-up'
                                                style="font-size: 24px; color: red;cursor: pointer;"></i>
                                        </a>
                                    </ng-container>
                                </ng-container>
                                <ng-template #noLink>
                                    <div>{{OrderDetails?.carrier_tracking_number}} <i class='bx bxs-hand-up'
                                            style="font-size: 24px; color: red;"></i></div>
                                </ng-template>
                            </span>
                        </div>
                        <div class="col"> <strong>Current Status:</strong> <br> <span style="display: inline-flex">
                                &nbsp;
                                <input class="text-wrap" type="button" value="" id="deliverstatus" style="
                                                    color: rgb(202 138 4) !important;
                                                    border-radius: 3px;
                                                    border: none;
                                                    background-color: rgb(254 249 195);
                                                  " />
                            </span> </div>
                    </div>
                </article>
                <div class="tracking_details_content">
                    <span>NEWYORK</span>
                    <span style="color: #83ab17; margin-top: 7px"><i
                            class="fas fa-store"></i>{{OrderDetails?.store_name}}</span>
                    <span>{{ OrderDetails?.city }}</span>
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="track_content">
                        <div class="left_box circle">
                            <div class="icon">
                                <i class="fas fa-map-pin"></i>
                            </div>
                        </div>
                        <div class="middle_box">
                            <div class="progress" [style.width.%]="progressWidth"></div>
                        </div>
                        <div class="right_box circle">
                            <div class="icon">
                                <i class="fas fa-truck"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
    <div class="report_field mt-0">
        <div class="shadow-lg overflow-hidden " style="width: 66%;">
            <div class="container mb-0">
                <h3>
                    Tracking History
                </h3>
                <ng-container *ngIf="actualTrackingDetails.length > 0 || trackingDetails.length > 0;">
                    <h4>
                        <ng-container *ngIf="actualTrackingDetails.length > 0; else noAcTracking">
                            Your Order Is {{actualTrackingDetails[0].activity}}
                        </ng-container>
                        <ng-template #noAcTracking>
                            Your Order Is {{trackingDetails[0].status}}
                        </ng-template>
                    </h4>
                    <hr />
                    <div class="time-chart ui-widget">
                        <ul class="timeline">
                            <li>&nbsp;</li>
                            <ng-container *ngIf="actualTrackingDetails.length > 0; else noLink">
                                <li class="green">
                                    <br />
                                    <p class="description "> {{ actualTrackingDetails[0].status }} </p>
                                    <p class="green "> Location: {{ actualTrackingDetails[0].location | replaceStrings
                                        }}
                                    </p>
                                    <p class="green "> {{ actualTrackingDetails[0].date | date :'dd MMM YYYY' }} {{
                                        actualTrackingDetails[0].time}}
                                    </p>
                                    <div *ngIf="actualTrackingDetails && actualTrackingDetails.length > 1">
                                        <button class="ml-auto sm:ml-0 mr-3 py-0 btn btn-yellow" mat-button
                                            (click)="toggleDetails()">
                                            {{ isDisplayTrackingDetails ? ' View Details - ' : ' View Details + '}}
                                        </button>
                                    </div>
                                </li>
                            </ng-container>
                            <ng-container *ngFor="let trackingDetail of trackingDetails; let i = index">
                                <ng-container
                                    *ngIf=" (trackingDetail.status_index>0 && trackingDetail.status !== 'Order Delivered') || (actualTrackingDetails.length === 0 && trackingDetail.status === 'Order Delivered' )  ">
                                    <li class="green">
                                        <br />
                                        <p class="description "> {{ trackingDetail.status }} </p>
                                        <p class="green ">
                                            <ng-container
                                                *ngIf="actualTrackingDetails.length > 0 && (trackingDetail.status === 'Dispatched from India Hub' || trackingDetail.status === 'Dispatched from UAE Hub') ; else dateUpdation">
                                                {{ DispatchDate(actualTrackingDetails[actualTrackingDetails.length -
                                                1].date) | date
                                                :'dd MMM YYYY HH:mm ' }}

                                            </ng-container>
                                            <ng-template #dateUpdation>
                                                {{ trackingDetail.date | date :'dd MMM YYYY HH:mm ' }}
                                            </ng-template>
                                        </p>
                                    </li>
                                </ng-container>
                            </ng-container>
                            <li>&nbsp;</li>
                        </ul>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngIf="isDisplayTrackingDetails">
                <button type="button" id="gotoTop" style="border: none;"></button>
                <ng-container *ngTemplateOutlet="rowDetailsTemplate "></ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #rowDetailsTemplate1>
    <ng-container *ngFor="let trackingDetail of actualTrackingDetails; let i = index">
        <ng-container *ngIf="i !== 0  ">
            <li class="green">
                <br />
                <p class="description "> {{ trackingDetail.activity }} </p>
                <p class="green "> Location: {{ trackingDetail.location | replaceStrings }}</p>
                <p class="green "> {{ trackingDetail.date | date :'dd MMM YYYY HH:mm ' }}
                </p>
            </li>
        </ng-container></ng-container>
</ng-template>

<ng-template #rowDetailsTemplate>
    <div class="report_field mt-0">
        <table class="table table-hover"
            style="height: 20px; border-collapse: collapse; text-align: left;vertical-align:middle">
            <thead>
                <tr>
                    <th style="width: 18%; background-color: #83ab17; color: white;">Scanned at</th>
                    <th style="width: 20%; background-color: #83ab17; color: white;">Location</th>
                    <th style="width: 62%; background-color: #83ab17; color: white;">Remark</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let trackingDetail of actualTrackingDetails; let i = index">
                    <ng-container *ngIf="i !== 0  ">
                        <td>{{ trackingDetail.date | date :'dd MMM YYYY ' }} {{trackingDetail.time}} </td>
                        <td>{{ trackingDetail.location | replaceStrings }}</td>
                        <td>{{ trackingDetail.status }}</td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<div *ngIf="noOrderFound">
    <div class="text-center details__field__1">
        <div class="field">
            <span><strong>Order Number</strong></span>
            <span>: NA</span>
        </div>
        <div class="field">
            <span><strong>Customer Name</strong></span>
            <span style="color:red">: NA</span>
        </div>
        <div class="field">
            <span><strong>Current Status</strong></span>
            <!-- <span>: UNDELIVERED SHIPMENT HELD AT LOCATION</span> -->
            <span>
                <p style="display: inline-flex;">:&nbsp;<input class="text-wrap" type="button" id="deliverstatus"
                        style="color:green !important;;border: 1px solid #c4c4c4;border-radius: 3px;"
                        value="Status N/A"></p>
            </span>
            <!--<span>:<input class="text-wrap" type="button" id="deliverstatus" style="color:green !important;border: 1px solid #c4c4c4;border-radius: 3px;" value="Status N/A"></span>-->
        </div>
    </div>
</div>