import { takeUntil } from 'rxjs/operators';
import { SubscriptionPlanService } from 'app/services/subscription_plan.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalService } from 'app/services/local.service';
import { PaymentService } from 'app/services/payment.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { environment } from 'environments/environment';
// import { WindowRefService } from 'app/services/window-ref.service';

declare let Razorpay: any;

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  loading: boolean = false;
  paymentForm: FormGroup;
  razorPayOptions: any;
  razorPayData: any;
  payment_creation_id = null;
  submitted: boolean = false;
  subscriptionId = null;
  subscriptionResponse = null;
  currentUser = null;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private fb: FormBuilder, private _subscriptionPlanService: SubscriptionPlanService, private _paymentService: PaymentService, private toaster: ToastrService,
    private _localService: LocalService, private route: ActivatedRoute, private _router: Router,) { }
  ngOnInit(): void {
    this.loading = false;
    this.createForm();
    this.razorPayOptions = {
      'key': '',
      'amount': '',
      'currency': 'INR',
      'name': 'Lemonmode',
      'description': 'Thank you for nothing. Please give us some money',
      'image': `${environment.siteURL}/assets/img/lemonmode_logo.png`,
      'order_id': '',
      'prefill': {
        name: 'Surbhi',
        email: 'abc@gmail.com',
      },
      'handler': (response): void => {
      },
      'notes': {
        'address': 'Thank you for saving people in need'
      },
      'theme': {
        'color': '#83ab17'
      },
      'modal': {},
    };

    this.currentUser = this._localService.customerDetail();

    this.subscriptionId = this.route.snapshot.paramMap.get('subscription_id');
    this.getSubscriptionById(this.subscriptionId);
  }

  getSubscriptionById(subscriptionId): void {
    this._subscriptionPlanService.getSubscriptionPlanById(subscriptionId).pipe(takeUntil(this._unsubscribeAll)).subscribe((response) => {
      this.subscriptionResponse = response.data;
      // Set the user details in the payment form
      this.paymentForm.patchValue({
        user_name: this.currentUser.first_name + ' ' + this.currentUser.last_name,
        user_email: this.currentUser.email,
        amount: this.subscriptionResponse.sub_plan_price
      });
      this.razorPayOptions.prefill = {
        name: this.currentUser.first_name + ' ' + this.currentUser.last_name,
        email: this.currentUser.email,
      };
    });
  }

  createForm(): void {
    this.paymentForm = this.fb.group({
      user_name: ['', [Validators.required]], amount: ['', [Validators.required]],
      user_email: ['', [Validators.required]]
    });
  }

  buyRazorPay(): void {
    if (this.paymentForm.invalid) {
      return;
    }
    this.submitted = true;
    this.loading = true;
    this.razorPayData = this.paymentForm.value;
    this.razorPayData['customer_id'] = this.currentUser._id;
    this.razorPayData['subscription_id'] = this.subscriptionId;
    this.razorPayData['sub_plan_limit'] = this.subscriptionResponse.sub_plan_limit;
    this.razorPayData['subcription_amount'] = this.subscriptionResponse.sub_plan_price;
    this._paymentService.razorPayOrder(this.razorPayData).subscribe({
      next: (payload: any) => {
        payload = payload.payload;
        if (payload['key'] && payload['dbRes']['order']['id'] && payload['dbRes']['order']['amount']) {
          this.razorPayOptions.key = payload['key'];
          this.razorPayOptions.order_id = payload['dbRes']['order']['id'];
          this.razorPayOptions.amount = payload['dbRes']['order']['amount'];
          this.razorPayOptions.handler = this.razorPayResponseHandler;
          this.razorPayOptions.modal.ondismiss = ((): void => {
            // handle the case when user closes the form while transaction is in progress
            this.toaster.error('Transaction cancelled.', '');
          });
          this.payment_creation_id = payload['dbRes']['_id'];
          this.razorPayData['_id'] = payload['dbRes']['_id'];
          this.razorPayData['recipient_email'] = payload['dbRes']['recipient_email'];
          sessionStorage.setItem('payment', JSON.stringify(this.razorPayData));
          const rzp1 = new Razorpay(this.razorPayOptions);
          rzp1.open();
        } else {
          // bro show error here
        }
      },
      error: ({ error }) => {
        this.toaster.error(error ? error.message : 'Something went wrong.', '');
      },
    });
  }

  razorPayResponseHandler = async (response): Promise<any> => {
    const storage_data = sessionStorage.getItem('payment');
    const sess = JSON.parse(storage_data);
    const paymentObject = {
      _id: sess._id,
      payment: response,
      amount: sess.amount,
      recipient_email: sess.recipient_email,
      user_name: sess.user_name,
      user_email: sess.user_email,
      customer_id: sess.customer_id,
      subscription_id: sess.subscription_id,
      sub_plan_limit: sess.sub_plan_limit,
    };

    await this._paymentService.razorPayPaymentSuccess(paymentObject).subscribe({
      next: (payload: any) => {
        sessionStorage.removeItem('payment');
        this._router.navigate([`/checkout-thankyou/${response.razorpay_payment_id}`]);
      }, error: ({ error }) => {
        this.toaster.error(error ? error.message : 'Something went wrong.', '');
        this._paymentService.deleteOrCancelPayment({ _id: paymentObject._id }).subscribe((success_delete) => {
          console.log('error in payment payment suucessfull deleted from db');
        }, (err) => {
          console.log('error', err);
        });
      }
    });
  };

  stopScrollingWheel(e): any {
    return e.target.blur();
  }
}
