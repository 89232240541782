import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
// import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { AuthResetPasswordComponent } from './components/pages/reset-password/reset-password.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ToasterComponent } from './components/common/toast/toaster.component';
import { ToasterContainerComponent } from './components/common/toast/toaster-container.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { AuthForgotPasswordComponent } from './components/pages/forgot-password/forgot-password.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { AuthInterceptor } from './services/http-error.interceptor';
import { AdminNavbarComponent } from './components/common/admin-navbar/admin-navbar.component';
import { AirFrightServiceComponent } from './components/pages/air-fright-service/air-fright-service.component';
import { LandTransportServiceComponent } from './components/pages/land-transport-service/land-transport-service.component';
import { OceanFrightServiceComponent } from './components/pages/ocean-fright-service/ocean-fright-service.component';
import { CargoStorageComponent } from './components/pages/cargo-storage/cargo-storage.component';
import { WarehousingServiceComponent } from './components/pages/warehousing-service/warehousing-service.component';
import { ECommerceServiceComponent } from './components/pages/e-commerce-service/e-commerce-service.component';
import { ExpressServicesComponent } from './components/pages/express-services/express-services.component';
import { DomesticTruckingServicesComponent } from './components/pages/domestic-trucking-services/domestic-trucking-services.component';
import { ProhibitedItemsComponent } from './components/pages/prohibited-items/prohibited-items.component';
import { VolumetricCalculatorComponent } from './components/pages/volumetric-calculator/volumetric-calculator.component';
import { RateTransitTimesComponent } from './components/pages/rate-transit-times/rate-transit-times.component';
import { FuelSurchargeComponent } from './components/pages/fuel-surcharge/fuel-surcharge.component';
import { RegulationsComponent } from './components/pages/regulations/regulations.component';
import { DutiesTaxesComponent } from './components/pages/duties-taxes/duties-taxes.component';
import { TrackOrderComponent } from './components/pages/track-order/track-order.component';
import { AmazonPageComponent } from './components/pages/amazon-page/amazon-page.component';
import { PaymentsComponent } from './components/pages/payments/payments.component';
import { CheckoutThankyouComponent } from './components/pages/checkout-thankyou/checkout-thankyou.component';
import { CustomNavbarComponent } from './components/common/custom-navbar/custom-navbar.component';
import { DevloperDocumentComponent } from './components/pages/devloper-document/devloper-document';
import { QuotationComponent } from './components/pages/quotation/quotation.component';
import { ReplaceStringsPipe } from './shared/removeWord.pipe';
import { PriceDetailsComponent } from './components/pages/price-details/price-details.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeOneComponent,
    HomeThreeComponent,
    PreloaderComponent,
    NavbarComponent,
    CustomNavbarComponent,
    FooterComponent,
    AboutComponent,
    TeamComponent,
    TestimonialsComponent,
    GalleryComponent,
    AuthResetPasswordComponent,
    FaqComponent,
    DevloperDocumentComponent,
    ProfileComponent,
    SignInComponent,
    AuthForgotPasswordComponent,
    SignUpComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    ComingSoonComponent,
    ToasterContainerComponent,
    ToasterComponent,
    ErrorComponent,
    ServicesComponent,
    ServicesDetailsComponent,
    BlogDetailsComponent,
    BlogComponent,
    ContactComponent,
    AdminNavbarComponent,
    AirFrightServiceComponent,
    LandTransportServiceComponent,
    OceanFrightServiceComponent,
    CargoStorageComponent,
    WarehousingServiceComponent,
    ECommerceServiceComponent,
    ExpressServicesComponent,
    DomesticTruckingServicesComponent,
    ProhibitedItemsComponent,
    VolumetricCalculatorComponent,
    RateTransitTimesComponent,
    FuelSurchargeComponent,
    RegulationsComponent,
    DutiesTaxesComponent,
    TrackOrderComponent,
    AmazonPageComponent,
    PaymentsComponent,
    CheckoutThankyouComponent,
    QuotationComponent,
    ReplaceStringsPipe,
    PriceDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({ timeOut: 1500 }),
    ReactiveFormsModule
  ],
  exports: [ReplaceStringsPipe],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    // HttpClient
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
