<div class="">
    <div class="mt-3">
    </div>
    <h2 class="text-center">Hello, {{customer_first_name}}
        {{customer_last_name}}
    </h2>
    <ng-container *ngIf="isSuperAdmin &&totalRecord">
        <div id="root">
            <div class="container pt-3">
                <div class="row">
                    <!-- First Row: 3 Cards -->
                    <div class="col-lg-4 col-md-6 mb-4 c-dashboardInfo">
                        <div class="wrap">
                            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Total
                                Customers<svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24"
                                    aria-hidden="true" role="presentation"></svg></h4>
                            <span
                                class="hind-font caption-12 c-dashboardInfo__count">{{totalRecord.totalCustomer}}</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 mb-4 c-dashboardInfo">
                        <div class="wrap">
                            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Total
                                Orders<svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24"
                                    aria-hidden="true" role="presentation"></svg></h4>
                            <span class="hind-font caption-12 c-dashboardInfo__count">{{totalRecord.totalOrder}}</span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 mb-4 c-dashboardInfo">
                        <div class="wrap">
                            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Imported
                                Files<svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24"
                                    aria-hidden="true" role="presentation"></svg></h4>
                            <span
                                class="hind-font caption-12 c-dashboardInfo__count">{{totalRecord.totalImportedFiles}}</span>
                        </div>
                    </div>

                    <!-- Second Row: 2 Cards -->
                    <div class="col-lg-12 col-md-12 mb-4 c-dashboardInfo">
                        <div class="wrap">
                            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Processed
                                In Amazon<svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24"
                                    aria-hidden="true" role="presentation"></svg></h4>
                            <span
                                class="hind-font caption-12 c-dashboardInfo__count">{{totalRecord.totalProcessedInAmz}}</span>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 mb-4 c-dashboardInfo">
                        <div class="wrap">
                            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">Processing
                                With Carrier<svg class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24"
                                    aria-hidden="true" role="presentation"></svg></h4>
                            <span
                                class="hind-font caption-12 c-dashboardInfo__count">{{totalRecord.totalCarrierProcessed}}</span>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 mb-4 c-dashboardInfo">
                        <div class="wrap" (click)="redirectOrderDetailPage()">
                            <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                                Carrier Processing pending<svg class="MuiSvgIcon-root-19" focusable="false"
                                    viewBox="0 0 24 24" aria-hidden="true" role="presentation"></svg></h4>
                            <span
                                class="hind-font caption-12 c-dashboardInfo__count">{{totalRecord.totalPandingToCarrier}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!isSuperAdmin">
        <div class="mt-3">
            <div class="container" style="background-color: #fafafb;">
                <div class="row">
                    <div class="col-md-12">
                        <div class="ml-5 float-left">
                            <p> Get Started In just a few easy steps...</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="card m-3 p-5 text-center">
                            <div class="icon">
                                <i class='bx bx-grid-alt' style="font-size: 50px; color: #8cd735;"></i>
                            </div>
                            <h4>Order Placed</h4>
                            <p>Your order has been successfully placed.</p>
                            <button class="btn decorated-btn" (click)="redirectOrderDetail()">View Order</button>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card m-3 p-5 text-center">
                            <div class="icon">
                                <i class='bx bxs-credit-card-front' style="font-size: 50px; color: #8cd735;"></i>
                            </div>
                            <h4>Recharge</h4>
                            <p>Your account has been successfully recharged.</p>
                            <button class="btn decorated-btn" (click)="redirectRechargeDetail(customer_id)">View
                                Details</button>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card m-3 p-5 text-center">
                            <div class="icon">
                                <i class='bx bxs-check-circle' style="font-size: 50px; color: #8cd735;"></i>
                            </div>
                            <h4>Order Shipped</h4>
                            <p>Your order has been shipped and is on its way!</p>
                            <button routerLink="/track-order" class="btn decorated-btn">Track Order</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="fs-1 m-4 text-center">Your Subscription Plans</div>
            <div class="plan-info">
                <p class="text-4xl">
                    You are currently subscribed to {{ customersubscriptionPlanData?.length || 0 }} plan{{
                    customersubscriptionPlanData?.length !== 1 ? 's' : '' }}.
                    {{ customersubscriptionPlanData?.length > 0 ? 'Your plans will expire as you reach the maximum
                    number of tracking orders allowed.' : 'You have no active plans.Please click below to choose a
                    subscription that suits your needs.' }}
                </p>
                <button (click)="navigateToHomePage()" class="default-btn-one"
                    *ngIf="customersubscriptionPlanData?.length===0">Subscribe Now</button>


            </div>
            <div class="container">
                <div class="row">
                    <div class="col" *ngFor="let plan of customersubscriptionPlanData">
                        <div class="custom-card">
                            <div class="service-card">
                                <i class='{{ plan.plan_icon }}'></i>
                                <div *ngIf="plan.used_credit > 0 && plan.sub_plan_limit !== plan.used_credit"
                                    class="status-container">
                                    <div class="status-label">Active</div>
                                </div>
                                <div *ngIf=" plan.used_credit > 0 && plan.sub_plan_limit === plan.used_credit"
                                    class="status-container">
                                    <div class="status-expired">Expired</div>
                                </div>
                                <h3>{{ plan?.sub_plan_name }}</h3>
                                <span class="price"> &#x20B9;<span class="number">{{plan.sub_plan_price}}</span>
                                </span>
                                <p>Get access {{ plan.sub_plan_limit}} Tracking Order</p>
                                <p><strong>Order Used: </strong><span style="color: red; font-size: large;"><b>{{
                                            plan.used_credit }}</b></span>
                                    Order
                                </p>
                                <p><strong>Open to Use: </strong><span style="color: blue; font-size: large;">
                                        <b>{{ plan.sub_plan_limit -plan.used_credit }}</b></span> Order</p>
                                <p><strong>Paid Amount: </strong><span style="color: green; font-size: large;"> &#x20B9;
                                        <b>{{(plan.paid_amount | number:'1.2-2') || '0.00'}}</b>
                                    </span></p>
                                <p><strong>Applied On: </strong>{{ plan.createdAt | date :'dd-MM-YYYY' }} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>