<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Subscription Plan</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="pb-100 d-flex align-items-center">
    <div class="container">
        <div class="section-title text-center mb-5">
            <h2 class="mt-5 pb-5">{{subscriptionResponse?.sub_plan_name}} Plan</h2>
            <p>You're paying &#x20B9;{{subscriptionResponse?.sub_plan_price}} for this transaction. Get access to
                {{subscriptionResponse?.sub_plan_limit}} tracking
                orders whenever you need them. No expiration date for order usage.</p>
        </div>

        <div class="row justify-content-center mb-5">
            <div class="col-md-6">
                <div class="payment-form">
                    <form [formGroup]="paymentForm">
                        <div class="form-group row">
                            <label for="name" class="col-sm-3 col-form-label">Name</label>
                            <div class="col-sm-9">
                                <input type="text" name="name" [formControlName]="'user_name'" class="form-control"
                                    id="name" placeholder="Your name" required>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="email" class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                                <input type="email" name="email" [formControlName]="'user_email'" class="form-control"
                                    id="email" placeholder="Your email address" required readonly>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="amount" class="col-sm-3 col-form-label">Amount</label>
                            <div class="col-sm-9">
                                <input type="number" (wheel)="stopScrollingWheel($event)" name="amount"
                                    [formControlName]="'amount'" class="form-control" id="amount"
                                    placeholder="Your payment amount" required readonly>
                            </div>
                        </div>
                        <div class="text-center">
                            <button type="submit" class="default-btn-one" (click)="buyRazorPay()">Pay Now</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="mt-30 text-center">
            <p class="text-muted mb-0">Please ensure the provided information is accurate and up-to-date for smooth
                processing of your logistics.</p>
            <p class="text-muted">For any questions or assistance, contact our customer support at
                <a href="mailto:support@logisticsco.com" class="font-weight-bold"><i class='bx bxs-envelope'></i>
                        support@logisticsco.com</a>.</p>
        </div>
    </div>
</div>
