<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Warehousing</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Warehousing</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<div class="air-fright-area">
    <h1>Warehousing Services</h1>
    <h3>Warehousing Administrations Abbreviate stock lead times, make strides in general quality and fulfillment,
        diminish working costs, harm, and need of perceivability.</h3>
    <h6>Customized Distribution center and Dissemination Administration Arrangements -Abbreviate the Final Mile Costs!
    </h6>
    <p>lemonmode makes worldwide dispersion simple with our offices in USA and Canada, India, and affiliations with our
        accomplices spread over Canada, UK, Europe, Brazil, Turkey, UAE, KSA, Singapore, and India. We offer a extend of
        warehousing arrangements -counting</p>
    <ul>
        <li>Stripping</li>
        <li>Palletizing</li>
        <li>Fulfillment services- Drop Shipping & Outsourced Fulfillment</li>
        <li>Distribution</li>
        <li>Labeling</li>
        <li>Other ad-hoc value-added services- Sequencing, Kitting, Packaging, pick and pack, bundling/unbundling, etc.
        </li>
    </ul>

    <h3>Warehousing & Dispersion Administrations by lemonmode</h3>
    <h5>Coordinations - Your Mystery Trade Weapon Warehousing that fits your trade, and not the other way around!</h5>
    <div class="WR-image2">
        <div class="row">
            <div class="col">
                <img src="assets/img/WR-img-1.png" alt="air">
                <div>Full Container Load (FCL)</div>
            </div>
            <div class="col">
                <img src="assets/img/WR-img-2.png" alt="air">
                <div>Less Than Container-Load (LCL)</div>
            </div>
            <div class="col">
                <img src="assets/img/WR-img-3.png" alt="air">
                <div>Buyers Consolidation & Purchase Order (PO) Management</div>
            </div>
            <div class="col">
                <img src="assets/img/WR-img-4.png" alt="air">
                <div>Temperature-Controlled Loads</div>
            </div>
            <div class="col">
                <img src="assets/img/WR-img-5.png" alt="air">
                <div>Liquid Transportation & Flexitanks </div>
            </div>
            <div class="col">
                <img src="assets/img/WR-img-6.png" alt="air">
                <div>Break Bulk & Non-Containerized Loads</div>
            </div>
        </div>
    </div>
    <p>Adaptable and versatile warehousing arrangements by lemonmode are idealize for a developing commerce. As supply
        chain administrators, our group is prepared to step up to rising industry challenges and shopper requests.</p>
    <p>Oversee deficiency, move forward perceivability and decrease working costs with on-demand warehousing that lets
        you interface with state-of the craftsmanship warehousing and conveyance administrations spread over 1000+
        arrange stockrooms - verified American Cartage & Conveyance accomplices -include capacity and administrations
        anytime, wherever.</p>
    <h3>A Worldwide Group of Experienced Shipping Veterans </h3>
    <p>Our prepared and experienced group knows what works best for your commerce in a worldwide advertise and we are
        willing to share our information and ability at all times. We guarantee your merchandise stream easily and stock
        arrives when and where its required, this makes a difference you in lessening on-hand stock. We back day-to -day
        operations and assist you secure the most excellent bargains from distribution center suppliers as and when
        required. We offer you a centralized point of contact that makes a difference you cover the mainland Joined
        together States. </p>
    <p>Optimizing your warehousing solutions to ensure flow of in-bound inventory from suppliers and outbound to
        customers is managed within one network, giving you substantial cost benefits and ultimate value for money.

    </p>
    <p>The show world arrange is all almost being accessible, circular the clock. Combined with our wide run of
        administrations and shipping and coordinations choices, our warehousing gets to be a powerful back in your
        commerce development. With our warehousing arrangements, and making a dispersion middle close the foremost
        dynamic sales location is fair a number of clicks absent.</p>
</div>

<!-- Single Service Area -->
<!-- <div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-details-text">
                    <div class="service-image">
                        <img src="assets/img/services/single-services1.jpg" alt="image">
                    </div>
                    <h3>Easy Shipment Solution</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse</p>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                        totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae
                        dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
                        fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                    <p>NNeque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit,
                        sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
                        voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit
                        laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui
                        in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat
                        quo</p>
                    <div class="image">
                        <img src="assets/img/services/single-services2.jpg" alt="image">
                    </div>
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was
                        born and I will give you a complete account of the system, and expound the actual teachings of
                        the great explorer of the truth, the master-builder of human happiness. No one rejects,
                        dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know
                        how to pursue pleasure rationally encounter consequences that are extremely painful.</p>
                    <p>Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is
                        pain, but because occasionally circumstances occur in which toil and pain can procure him some
                        great pleasure. To take a trivial example, which of us ever undertakes laborious physical
                        exercise, except to obtain some advantage from it? But who has any right to find fault with a
                        man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain
                        that produces</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title">Our Services</h3>

                    <ul>
                        <li><a routerLink="/services-details"><i class='bx bxs-truck'></i> Road Freight</a></li>
                        <li><a routerLink="/services-details"><i class='bx bxs-ship'></i> Ocean Freight</a></li>
                        <li><a routerLink="/services-details"><i class='bx bxs-plane-take-off'></i> Air Freight</a></li>
                        <li><a routerLink="/services-details"><i class='bx bx-transfer'></i> B2B Exchange</a></li>
                        <li><a routerLink="/services-details"><i class='bx bx-home'></i> Home Shipping</a></li>
                        <li><a routerLink="/services-details"><i class='bx bx-line-chart-down'></i> Office Moving</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Single Service Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <!-- <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required=""
                            autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->
