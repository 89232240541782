<!-- amazon-button-page.component.html -->
<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Authorization</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<div class="main ">
    <div class="left">
        <div class="heading">Steps To Integrate Amazon</div>
        <span>For Developer's Access:</span>
        <div class="time-chart ui-widget">
            <!-- <h3 class="start">Journey Begins</h3> -->
            <ul class="timeline">
                <li>&nbsp;</li>
                <li>
                    <!-- <div class="event">New Product Launch</div> -->
                    <p class="description ">Note: Lemonmode does not pull "EASYSHIP" orders as they are fulfilled by
                        Amazon Transport Services (ATS). They can be
                        processed and shipped on Amazon Seller central only.</p>
                </li>
                <li class="green">
                    <!-- <div class="event">New York Times</div> -->
                    <p class="description ">To proceed, please click on the "AMAZON” button on your screen.</p>
                </li>
                <li class="green">
                    <!-- <div class="event">New York Times</div> -->
                    <p class="description ">Select your marketplace that you want to integrate, and click on the
                        "Connect to
                        AMAZON” button.</p>
                </li>
                <li class="green">
                    <!-- <div class="event">New York Times</div> -->
                    <p class="description ">You'll be diverted to the Amazon seller central login page. Log in to your
                        Amazon account by entering your username and
                        password.</p>
                </li>
                <li class="green">
                    <!-- <div class="event">New York Times</div> -->
                    <p class="description ">Once logged in, a page ‘Authorise Online Adda Selling Partner’ will open
                        where you can verify your account integration
                        with lemonmon by clicking “Confirm”.</p>
                </li>
                <li class="green">
                    <!-- <div class="event">New York Times</div> -->
                    <p class="description ">Now, you will be redirected to the Lemonmode page. Here you can import order
                        files and track as per
                        your preferences.</p>
                </li>
                <li>&nbsp;</li>
            </ul>
        </div>
    </div>
    <div class="Right   flex-grow-2">


        <!-- button -->
        <div class="text-center">
            <div class="text-custom">Select Your first marketplace</div>
            <div class="right-heading2">Click the Amazon button for dynamic order fulfillment and increased sales!</div>
            <hr />
            <div class=" amazon-image" (click)="toggleOpened()"><img
                    src="https://api.freelogodesign.org/assets/blog/img/20180911090509731amazon_logo_RGB.jpg"></div>
        </div>

        <!--start all country -->
        <div class="Divmain d-flex flex-column align-items-center justify-content-center " *ngIf="amazonButtonClicked">
            <div class="right-heading3">And which channel would you like to try tracking On first ?</div>
            <span>Select the option below <i class='bx bx-down-arrow-alt'></i></span>
            <div class="wrapper">
                <div class="AllCountry">
                    <div class="popCountry" [ngClass]="{ selected: selectedCountry === allMarketplace.countryCode }"
                        (click)="selectedCountry = allMarketplace.countryCode"
                        *ngFor="let allMarketplace of allMarketplaces">
                        <img class="image"
                            src="https://images.unsplash.com/photo-1597058712635-3182d1eacc1e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80" />
                        <span class="countryName">{{IND }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- end countries div -->

        <!-- start authorization -->
        <div>
            <div class="text-center footer-button" *ngIf="selectedCountry">
                <button class="default-btn-one " (click)="redirectToMarketplace()">Connect to AMAZON <i
                        class='bx bx-right-arrow-alt' style="font-size: 24px;"></i></button>
            </div>
        </div>
    </div>
    <!-- end authorization -->
</div>