import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User } from '../models/sign-up';
import { LocalService } from './local.service';
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class CustomerService {
	isLogin = new BehaviorSubject(false);
	constructor(
		private _httpClient: HttpClient,
		private _localService: LocalService
	) { }
	createUser(formData: User): Observable<User> {
		return this._httpClient
			.post<User>(environment.apiBaseUrl + '/customer/register', formData)
			.pipe(
				switchMap((response: any) => {
					if (response.user.statusCode === 201) {
						return of(response);
					} else {
						return throwError(response);
					}
				})
			);
	}

	login(formData): Observable<any> {
		const obj = {
			email: formData.email,
			password: formData.password,
		};
		return this._httpClient
			.post<any>(environment.apiBaseUrl + '/customer/login', obj)
			.pipe(
				switchMap((response: any) => {
					if (response.status === 200) {
						this._localService.setJsonValue('user', response.user);
						this._localService.setJsonValue('token', response.tokens.access.token);
						this.isLogin.next(true);
						return of(response);
					} else {
						return throwError(response);
					}
				})
			);
	}

	updateUserbyId(id: string, formData: User): any {
		return this._httpClient.put<User>(environment.apiBaseUrl + '/customer/update-profile/' + id, formData);
	}

	updatePassword(formData: User): any {
		return this._httpClient.post<User>(environment.apiBaseUrl + '/customer/change-password', formData);
	}

	getUserbyId(id: string): any {
		return this._httpClient.get<User>(environment.apiBaseUrl + '/customer/get-user/' + id);
	}

	forgotPassword(data): any {
		return this._httpClient.post<User>(environment.apiBaseUrl + '/customer/forgot-password', data);
	}

	resetPassword(token, data): any {
		return this._httpClient.post<User>(environment.apiBaseUrl + '/customer/reset-password/' + token, data);
	}

}
