import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Toast } from './toast.interface';

@Component({
    selector: 'app-toaster',
    template: `
        <div
            class="toast toast-{{ toast.type }}"
            [style.bottom.px]="i * 100"
            style="top: 150px;"
        >
            <ng-container>
                <i *ngIf="toast.type === 'success'" class="material-icons"
                    >check_circle_outline</i
                >
                <i *ngIf="toast.type === 'error'" class="material-icons"
                    >error_outline</i
                >
                <i *ngIf="toast.type === 'warning'" class="material-icons"
                    >warning_outline</i
                >
            </ng-container>
            <h4 class="toast-heading">{{ toast.title }}</h4>
            <p>{{ toast.body }}</p>
            <a class="close" (click)="remove.emit(i)">&times;</a>
        </div>
    `,
    styles: [
        `
            .toast {
                position: fixed;
                display: block !important;
                right: 0;
                width: 300px;
                height: 100px;
                padding: 0.75rem 1.25rem;
                margin-top: 1rem;
                border: 1px solid transparent;
                border-radius: 0.25rem;
                animation: move 1s both;
            }

            .toast-success {
                color: #155724;
                background-color: #d4edda;
                border-color: #c3e6cb;
            }

            .toast-error {
                color: #721c24;
                background-color: #f8d7da;
                border-color: #f5c6cb;
            }

            .toast-warning {
                color: #856404;
                background-color: #fff3cd;
                border-color: #ffeeba;
            }

            .close {
                position: absolute;
                top: 7px;
                right: 10px;
                font-size: 1.5em;
                cursor: pointer;
                color: black;
            }

            .toast-heading {
                margin: auto 30px;
                display: inline-block;
            }

            .material-icons {
                position: absolute;
                top: 11px;
            }

            @keyframes move {
                from {
                    transform: translateX(100%);
                }
                to {
                    transform: translateX(0);
                }
            }

            @import url(https://fonts.googleapis.com/icon?family=Material+Icons);
        `,
    ],
})
export class ToasterComponent {
    @Input() toast: Toast;
    @Input() i: number;

    @Output() remove = new EventEmitter<number>();
}
