import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalService } from './local.service';
import { AdminUserService } from './admin/user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Constructor
     */
    constructor(private _localService: LocalService, private _adminService: AdminUserService) {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request object
        let newReq = req.clone();
        let isCheck;
        let request_token;
        this._adminService.isLogin.subscribe((r) => {
            isCheck = r;
        });
        if (isCheck) {
            request_token = this._localService.getJsonValue('adminToken');
        } else {
            request_token = this._localService.getJsonValue('token');
        }
        if (request_token) {
            newReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + request_token)
            });
        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {
                // Catch "401 Unauthorized" responses
                if (error.status === 401) {
                    // Sign out
                    this._localService.clearValues();
                    location.reload();
                }
                return throwError(error);
            })
        );
    }
}
