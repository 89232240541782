import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ContactUsService } from 'app/services/contact_us/contact.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  btnDisable: boolean = false;
  contactFormGroup: FormGroup; isSubmit = false;
  isLoading: boolean = false;
  constructor(private _contactusService: ContactUsService,
    private toaster: ToastrService,
    private _router: Router,) { }

  ngOnInit(): void {

    // Create the customer form
    this.contactFormGroup = new FormGroup(
      {
        name: new FormControl('', Validators.required),
        email: new FormControl('', [
          Validators.required,
          Validators.email,
        ]),
        subject: new FormControl('', Validators.required),
        message: new FormControl('', Validators.required),
        phone_number: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')])
      },

    );
  }
  submitForm(): any {
    Object.keys(this.contactFormGroup.controls).forEach((key) => {
      this.contactFormGroup.get(key).markAsTouched();
    });
    if (this.contactFormGroup.invalid) {
      // this.toaster.error('Fill all required field.', '');
      this.isSubmit = true;
      return;
    }
    const formData = this.contactFormGroup.getRawValue();
    if (this.containsHtmlTags(formData)) {
      // Handle the case where HTML content is detected
      // You can display an error message or take appropriate action
      this.toaster.error('Invalid Content.', '');
      return;
    }
    this._contactusService.createContact(formData).subscribe({
      next: (data: any) => {
        this.toaster.info('Thank you for reaching out to us!', '');
        setTimeout(() => {
          this._router.navigate(['/']);
        }, 3000);
      },
      error: ({ error }) => {
        this.toaster.error(error.message = 'Something went wrong.', '');
      },
    });
  }

  containsHtmlTags(formData: any): boolean {
    const regex = /<[^>]*>/; // Regular expression to match HTML tags
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const value = formData[key];
        if (typeof value === 'string' && regex.test(value)) {
          return true; // HTML content found
        }
      }
    }
    return false; // No HTML content found
  }
}
